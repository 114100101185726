import { InputComponents } from "../AbstractReactiveMicrocomponent";
import { ReactiveMicrocomponentConfig } from "../ReactiveMicrocomponentConfig";
import { SimpleComponent } from "./SimpleComponent";

export class SimpleSink<I extends unknown[] = unknown[]> extends SimpleComponent<void, I> {
  public constructor(configOrName: string | ReactiveMicrocomponentConfig<void>, consumer: (...$inputValues: I) => Promise<void>, ...inputs: InputComponents<I>) {
    super(configOrName, consumer, ...inputs);
    this.store.subscribe(() => {});
  }
}
