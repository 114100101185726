<Boundary>
  <div class="blank-ad-slot"></div>
</Boundary>

<style>
  .blank-ad-slot {
    display: none;
  }</style>

<script lang="ts">import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
</script>
