import { injectable, Container } from "inversify";
import { injectToken, Token, TokenContainerModule } from "inversify-token";
import { YinzCamInjectModule } from 'yinzcam-inject';

// NOTE: This module no longer manages the CONFIG variable. The CONFIG variable is a global variable that is available very early in the
// startup process and contains static configuration. This module is for configuration that is loaded from the server and can change dynamically.

// TODO: Think about allowing other modules to register config sections and types here somehow?
// FIXME: When the json file changes during development, a hot reload doesn't update the config data. Killing and recompiling works though.....
// Uses node-config engine, more info here: https://github.com/lorenwest/node-config/wiki/Configuration-Files

export const JanusConfigManagerToken = new Token<JanusConfigManager>(Symbol.for("JanusConfigManager"));

export let JanusConfigManagerModule: YinzCamInjectModule = new YinzCamInjectModule((container: Container): void => {
  container.load(new TokenContainerModule((bindToken) => {
    bindToken(JanusConfigManagerToken).to(JanusConfigManager).inSingletonScope();
  }));
});

// TODO: eventually this will become the thing that manages configuration coming from the config server

injectable()
export class JanusConfigManager {
  public constructor() {    
  }
}
