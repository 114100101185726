<script lang="ts">export let Play = {};
</script>

<div class="live-reporting-quote-card">
  <div class="live-report">
    <p>{Play.Text}</p>
    <span class="reporter">{Play.Author}</span>
  </div>
</div>

<style lang="scss">*,
*:before,
*:after {
  box-sizing: border-box; }

.live-reporting-quote-card {
  width: 100%; }
  .live-reporting-quote-card .live-report {
    border-left: 6px solid var(--theme-primary-accent-color, black);
    padding: 7px 0px 7px 36px;
    width: 100%;
    height: 100%; }
    .live-reporting-quote-card .live-report p {
      margin: 0 0 18px 0;
      font-family: SFUIText;
      font-size: 28px;
      font-weight: 300;
      font-style: italic;
      line-height: 1.5;
      color: var(--theme-primary-foreground-color, black);
      display: block;
      white-space: pre-line; }
    .live-reporting-quote-card .live-report .reporter {
      display: block;
      margin: 0;
      font-size: 18px;
      color: var(--theme-primary-foreground-color, black); }
  @media (max-width: 767px) {
    .live-reporting-quote-card .live-report {
      padding: 0 0 0 12px;
      margin: 0 0 16px 0px; }
      .live-reporting-quote-card .live-report p {
        margin: 0 0 6px 0;
        font-size: 18px;
        line-height: 1.33; }
      .live-reporting-quote-card .live-report .reporter {
        font-size: 14px; } }</style>
