<script context="module" lang="ts">var _a, _b;
export { specification } from "./spec";
</script>

<script lang="ts">var _a, _b;
import { get } from "lodash";
import { BaseAtom } from "components/cards/atoms";
import { TextParticle, ImageParticle, } from "components/cards/particles/YinzCam";
import { writable } from "svelte/store";
import "js/url";
const hovering = writable(false);
export let container;
export let overlayPadding = undefined;
export let txtHeader;
export let txtOpponentPercent;
export let txtOpponentHalf;
export let txtOwnPercent;
export let txtOwnHalf;
export let imgPlayground;
export let colorOwnProgress;
export let colorOpponentProgress;
export let colorPercentBoxBorder;
export let colorPercentBox;
export let statsListLeft;
export let statsListRight;
export let playerStats = undefined;
let atomWidthRem;
const calcPercentage = (val, totalVal) => {
    var _a;
    let homeValue = parseInt((_a = val === null || val === void 0 ? void 0 : val.homeValue) === null || _a === void 0 ? void 0 : _a.text);
    let totalValue = parseInt(totalVal);
    if (!homeValue || !totalValue)
        return 0;
    return ((homeValue / totalValue) * 100).toFixed(1);
};
$: headerTextMultiplier =
    atomWidthRem <= 30 ? "0.416" : atomWidthRem <= 45 ? "0.666" : "1.00";
$: bodyTextMultiplier = atomWidthRem <= 30 ? "0.60" : "1.00";
$: isMobile = atomWidthRem <= 30;
$: isTablet = atomWidthRem <= 45 && atomWidthRem > 30;
$: ownHalfStat = playerStats === null || playerStats === void 0 ? void 0 : playerStats.find((stat) => { var _a; return ((_a = stat === null || stat === void 0 ? void 0 : stat.name) === null || _a === void 0 ? void 0 : _a.text) === statsListLeft; });
$: opponentHalfStat = playerStats === null || playerStats === void 0 ? void 0 : playerStats.find((stat) => { var _a; return ((_a = stat === null || stat === void 0 ? void 0 : stat.name) === null || _a === void 0 ? void 0 : _a.text) === statsListRight; });
$: totalValue =
    parseInt((_a = ownHalfStat === null || ownHalfStat === void 0 ? void 0 : ownHalfStat.homeValue) === null || _a === void 0 ? void 0 : _a.text) +
        parseInt((_b = opponentHalfStat === null || opponentHalfStat === void 0 ? void 0 : opponentHalfStat.homeValue) === null || _b === void 0 ? void 0 : _b.text);
$: ownPercent = `${calcPercentage(ownHalfStat, totalValue)}%`;
$: opponentPercent = `${calcPercentage(opponentHalfStat, totalValue)}%`;
$: txtPercentMultiplier = isMobile ? "0.6" : "1.00";
$: txtOtherMultiplier = isMobile ? "0.8" : "1.00";
</script>

<BaseAtom
    _isotope="PassAccuracyAtom"
    {...container}
    _cssVariablePrefix="pass-accuracy"
    {overlayPadding}
    {colorOwnProgress}
    {colorOpponentProgress}
    {colorPercentBoxBorder}
    {colorPercentBox}
    {ownPercent}
    {opponentPercent}
    bind:_clientWidthRem={atomWidthRem}
>
    <div
        class="main-container"
        class:layout-mobile-width={isMobile}
        class:layout-tablet-width={isTablet}
    >
        <div class="header-box">
            <TextParticle
                {...txtHeader}
                size={`calc(${get(txtHeader, "size")} * ${txtOtherMultiplier})`}
            />
        </div>
        <div class="percentage-box">
            <div class="own-half-percent">
                <TextParticle
                    {...txtOwnPercent}
                    text={ownPercent}
                    size={`calc(${get(txtOwnPercent, "size")} * ${txtPercentMultiplier})`}
                />
                <TextParticle
                    {...txtOwnHalf}
                    size={`calc(${get(txtOwnHalf, "size")} * ${txtOtherMultiplier})`}
                />
            </div>
            <div class="opponent-half-percent">
                <TextParticle
                    {...txtOpponentPercent}
                    text={opponentPercent}
                    size={`calc(${get(txtOpponentPercent, "size")} * ${txtPercentMultiplier})`}
                />
                <TextParticle
                    {...txtOpponentHalf}
                    size={`calc(${get(txtOpponentHalf, "size")} * ${txtOtherMultiplier})`}
                />
            </div>
        </div>
        <div class="playground-box">
            <div class="ground-bg">
                <ImageParticle {...imgPlayground} />
            </div>
            <div class="accuracy-overlay">
                <div class="own-progress"></div>
                <div class="opponent-progress"></div>
            </div>
        </div>
    </div>
</BaseAtom>

<style>
    .main-container {
        height: 100%;
        width: 100%;
        padding: var(--pass-accuracy-overlay-padding, unset);
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        justify-content: space-between;
        align-items: center;
    }
    .percentage-box {
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 0.75rem;
        align-items: center;
    }
    .opponent-half-percent,
    .own-half-percent {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0.75rem 0.8125rem;
        flex: 1 0 0;
        border-radius: 0.75rem;
        border: 1px solid var(--pass-accuracy-color-percent-box-border, unset);
        background-color: var(--pass-accuracy-color-percent-box, unset);
    }
    .playground-box {
        display: grid;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
    .playground-box > * {
        grid-area: 1/1;
        align-items: end;
    }
    .accuracy-overlay {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        height: 100%;
        padding-bottom: 0.5rem;
    }
    .opponent-progress,
    .own-progress {
        opacity: 0.7;
    }
    .opponent-progress {
        background-color: var(--pass-accuracy-color-opponent-progress, unset);
        width: var(--pass-accuracy-opponent-percent);
        height: var(--pass-accuracy-opponent-percent);
        align-self: right;
        justify-self: right;
        place-self: right;
    }
    .own-progress {
        background-color: var(--pass-accuracy-color-own-progress, unset);
        width: var(--pass-accuracy-own-percent);
        height: var(--pass-accuracy-own-percent);
        align-self: left;
        justify-self: left;
        place-self: left;
    }</style>
