import { generateBooleanSchema, generateRootObjectSchema, generateStringSchema, generateTranslationSchema, generateUrlSchema } from "components/cards/common/schema";
import { BaseParticleSpec, GetParticlePropsType, GetParticleSchemaType, generateParticleContainerSchema } from "components/cards/particles/BaseParticleSpec";
import { ImageParticleSpec } from "../Image/spec";
import { TextParticleSpec } from "../Text/spec";

export const ButtonParticleSpec = new BaseParticleSpec("Image", generateRootObjectSchema({
  container: generateParticleContainerSchema({
    defaultValues: { fillHeight: false, fillWidth: false } // Generally, users don't want buttons to fill their containers.
  }),
  link: generateUrlSchema("Link", "The link to open when the button is clicked. If not specified, the button will not be clickable."),
  translations: generateTranslationSchema("Button", {
    link: generateUrlSchema("Link", "The link to open when the button is clicked. If not specified, the button will not be clickable."),
  }),
  image: ImageParticleSpec.generateSchema("Label Image", "The image for the button label.", {
    defaultValues: { sizing: 'contain' }
  }),
  text: TextParticleSpec.generateSchema("Label Text", "The text for the button label.", {
    defaultValues: { wrap: 'nowrap' }
  }),
  orientation: generateStringSchema("Label Orientation", "The layout (horizontal or vertical) of the label image and text.", {
    choices: [ 'row', 'column' ],
    choiceTitles: [ 'Horizontal', 'Vertical' ],
    defaultValue: 'row'
  }),
  alignment: generateStringSchema("Label Alignment", "The alignment of the label(s) along the cross-axis of the orientation.", {
    choices: [ 'start', 'center', 'end' ],
    choiceTitles: [ 'Top (H) / Left (V)', 'Center', 'Bottom (H) / Right (V)' ],
    defaultValue: 'center'
  }),
  justification: generateStringSchema("Label Justification", "The alignment of the label(s) along the main-axis of the orientation.", {
    choices: [ 'start', 'center', 'end' ],
    choiceTitles: [ 'Left (H) / Top (V)', 'Center', 'Right (H) / Bottom (V)' ],
    defaultValue: 'center'
  }),
  imagePosition: generateStringSchema("Label Image Position", "The position of the label image relative to the text", {
    choices: [ 'before', 'after' ],
    choiceTitles: [ 'Before Text', 'After Text' ],
    defaultValue: 'before'
  }),
  showImage: generateBooleanSchema("Show Label Image", "Show the label image, if specified.", {
    defaultValue: true
  }),
  showText: generateBooleanSchema("Show Label Text", "Show the label text, if specified.", {
    defaultValue: true
  }),
  gap: generateStringSchema("Image/Text Gap", "The gap between the image and text in standard CSS units."),
}));

export const specification = ButtonParticleSpec;

export type ButtonParticleSchema = GetParticleSchemaType<typeof ButtonParticleSpec>;

export type ButtonParticleProps = GetParticlePropsType<typeof ButtonParticleSpec>;
