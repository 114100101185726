import { parse, format, MediaType as ContentTypeInfo } from 'content-type';

// More info on content-type library: https://github.com/jshttp/content-type

export class ContentType {
  static parse(header: string): ContentTypeInfo {
    return parse(header);
  }
  static format(mediaType: ContentTypeInfo): string {
    return format(mediaType);
  }
}

export type { MediaType as ContentTypeInfo } from 'content-type';
