<script lang="ts">import { BaseParticle } from "components/cards/particles";
import "./spec";
import { getTranslator } from "components/cards/utilities";
// props
export let container = undefined;
export let source = "";
export let translations = [];
export let controls = undefined;
export let autoplay = undefined;
export let muted = undefined;
export let loop = undefined;
// inputs
// outputs
$: xlator = getTranslator(translations, { source });
</script>

<style>
  audio {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }</style>

<BaseParticle _name="AudioParticle" _cssVariablePrefix="audio-particle" {...container}>
  <audio
    src={xlator({ source })}
    {controls}
    {autoplay}
    {muted}
    {loop}
  />
</BaseParticle>
