import { generateRootObjectSchema, generateArraySchema, generateObjectSchema, generateColorSchema, generateStringSchema, generateBooleanSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { ImageParticleSpec, RichTextParticle, RichTextParticleSpec, TextParticle, TextParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("MC-Commentary", generateRootObjectSchema({
    container: generateAtomContainerSchema(),
    containerPadding: generateStringSchema("Container Padding", "Padding for the container"),
    txtMinutes: TextParticleSpec.generateSchema("Match Minutes", "Enter minutes here", {
        defaultValues: { size: "1.5rem", weight: "700" }
    }),
    txtTitle: TextParticleSpec.generateSchema("Title", "Enter title to commentary", {
        defaultValues: { size: "1.5rem", weight: "700" }
    }),
    txtCommentary: TextParticleSpec.generateSchema("Commentary", "Enter commentary", {
        defaultValues: { size: "1.125rem", weight: "400" }
    }),
    imgTitleIcon: ImageParticleSpec.generateSchema("Icon to title", "Pick an icon for title", {
        defaultValues: {
            container: { width: "1.5rem", height: "1.5em" }, sizing: "contain",
        }
    }),
    imgCommentTeamLogo: ImageParticleSpec.generateSchema("Team Logo", "Add a team logo", {
        defaultValues: { container: { width: "4rem", height: "4rem" }, sizing: "cover" }
    }),
    isEventIcon: generateBooleanSchema("Event Icon", "Is an event logo required?", {
        defaultValue: false,
    }),
    htmlContent: RichTextParticleSpec.generateSchema("HTML Embed Content", "HTML Content to be embedded"),
    quoteText: TextParticleSpec.generateSchema("Quote Text", "Enter a quote text", {
        defaultValues: { size: "1.25rem", weight: "400", style: "italic" }
    }),
    imgPlayerPhoto: ImageParticleSpec.generateSchema("Player Photo", "Upload a player photo", {
        defaultValues: { container: { width: "20rem", height: "20rem" } }
    }),
    txtEventText: TextParticleSpec.generateSchema("Event Text", "Enter an event text", {
        defaultValues: { size: "4.5rem", weight: "900", color: "#FFF" }
    }),
    photoBackgroundColor: generateColorSchema("BG Color - Photo", "Background color for photo"),
    quoteBorderColor: generateColorSchema("Quote Bar Color", "Pick a color for quote bar"),
    variantType: generateStringSchema("Box Variant", "Variant of the box", {
        choices: ['comment', 'comment-and-embed', 'comment-and-quote', 'comment-and-photo'],
        choiceTitles: ["Commentary Box", "Comment and HTML Content", "Comment and a Quote", "Comment and a Photo"],
        defaultValue: "comment",
    }),
    eventType: generateStringSchema("Commentary Type", "Commentary Type"),
    backgroundImage: ImageParticleSpec.generateSchema("Event BG Image", "Event BG Image", {
        defaultValues: { sizing: "contain" }
    })
}));