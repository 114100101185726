<script context="module" lang="ts">import 'json-schema';
import 'components/cards/common/CardsElementInterfaces';
export function getDisplayName() {
    return "Core: Image Border Divider";
}
export function getElementConfigSpec() {
    return {
        type: "object",
        properties: {
            imageUrl: {
                type: "string",
                format: "url",
                title: "Thumbnail",
                description: "The thumbnail image for the card.",
                options: {
                    upload: {}
                },
                links: [{ href: "{{self}}" }]
            },
            linkUrl: {
                type: "string",
                format: "url",
                title: "Link",
                description: "The URL to open when the image is clicked."
            },
            imageAltText: {
                type: "string",
                title: "Image Alt Text",
                description: "The alt text for the image."
            },
            imageWidth: {
                type: "string",
                title: "Image Width",
                description: "The image fixed width in CSS units."
            },
            imageMaxWidth: {
                type: "string",
                title: "Image Max Width",
                description: "The image max width in CSS units."
            },
            imageMinWidth: {
                type: "string",
                title: "Image Min Width",
                description: "The image min width in CSS units."
            },
            imageHeight: {
                type: "string",
                title: "Image Height",
                description: "The image fixed height in CSS units."
            },
            imageMaxHeight: {
                type: "string",
                title: "Image Max Height",
                description: "The image max height in CSS units."
            },
            imageMinHeight: {
                type: "string",
                title: "Image Min Height",
                description: "The image min height in CSS units."
            },
            imageObjectFit: {
                type: "string",
                title: "Image Object Fit",
                description: "The image object fit strategy.",
                enum: [
                    "contain",
                    "cover",
                    "fill",
                    "none",
                    "scale-down"
                ],
                options: {
                    enum_titles: [
                        "Contain",
                        "Cover",
                        "Fill",
                        "None",
                        "Scale Down"
                    ]
                }
            },
            backgroundColor: {
                type: "string",
                title: "Background Color",
                description: "The background color behind the image."
            },
            hoverEffect: {
                type: "string",
                title: "Hover Effect",
                description: "The hover effect type.",
                enum: [
                    "none",
                    "zoom",
                    "overlay",
                    "background"
                ],
                options: {
                    enum_titles: [
                        "None",
                        "Zoom",
                        "Overlay",
                        "Background"
                    ]
                }
            },
            hoverAnimationDuration: {
                type: "string",
                title: "Hover Duration",
                description: "The hover animation duration in CSS units."
            },
            hoverBackgroundColor: {
                type: "string",
                title: "Hover Background Color",
                description: "The hover background color of the button."
            },
            hoverOverlayColor: {
                type: "string",
                title: "Hover Overlay Color",
                description: "The hover overlay color of the button."
            },
            hoverZoomFactor: {
                type: "string",
                title: "Hover Zoom Factor",
                description: "The hover zoom factor (1.0 is no zoom)."
            },
        }
    };
}
export function getElementDataSourceSpec() {
    return {
        sources: [
            {
                type: 'SOURCE',
                title: 'Card Data',
                description: 'Arbitrary source of data for this card matching the configuration fields.',
                classes: '*'
            }
        ]
    };
}
</script>

<script lang="ts">import "svelte/store";
import "js/i18n";
import CardBase from "components/cards/common/CardBase.svelte";
import "yinzcam-cards";
import "components/cards/common/CardsDataSourceRegistration";
import { getFirstSourceStore, getTemplatedElementDataFromSource, buildInlineStyle } from "components/cards/utilities";
import 'js/url';
export let element;
export let sources;
export let sourceStore;
$: {
    sourceStore = getFirstSourceStore($sources);
}
;
$: borderColor = getTemplatedElementDataFromSource(element, "borderColor", $sourceStore);
$: borderWidth = getTemplatedElementDataFromSource(element, "borderWidth", $sourceStore);
$: borderStyle = getTemplatedElementDataFromSource(element, "borderStyle", $sourceStore);
$: style = buildInlineStyle("border-color", borderColor, "border-width", borderWidth, "border-style", borderStyle);
</script>

<CardBase {element}>
  <div class="border-divider" {style}></div>
</CardBase>

<style>
  .border-divider {
    width: 100%;
    height: 0;
    border-top: var(--border-width, 1px) var(--border-style, solid) var(--border-color, black);
  }</style>
