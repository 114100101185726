import { injectable } from "inversify";
import { YinzCamAPIRequestParameterComponent, YinzCamAPIRequestParameters } from 'yinzcam-api';
import { AbstractReactiveMicrocomponent} from 'yinzcam-rma';

@injectable()
export class YinzCamAdsParameterComponent extends AbstractReactiveMicrocomponent<YinzCamAPIRequestParameters> implements YinzCamAPIRequestParameterComponent {
  public constructor() {
    super({ name: 'YinzCamAdsParameterComponent', saveToLocalStorage: false });
  }

  protected async update($control: unknown): Promise<YinzCamAPIRequestParameters> {
    return {
      services: {
        ads: {
          params: {
            format: 'json',
            ad_server_version: '2.0.0',
          }
        }
      }
    };
  }
}
