import { injectable } from 'inversify';
import { injectToken, Token } from 'inversify-token';
import { ReactiveMicrocomponent } from 'lib/yinzcam-rma';
import { YinzCamAPI, YinzCamAPIRequest, YinzCamAPIResponse, YinzCamAPIToken, YinzCamServer } from 'yinzcam-api';

export const YinzCamCardsServerV2Token = new Token<YinzCamCardsServerV2>(Symbol.for("YinzCamCardsServerV2Token"));

const HEADERS_WITH_LANG = { 'X-YinzCam-Static-API-Use-Environment': 'true', 'X-YinzCam-Static-API-Use-Language': 'true' };
const HEADERS_NO_LANG = { 'X-YinzCam-Static-API-Use-Environment': 'true', 'X-YinzCam-Static-API-Use-Language': 'false' }
const BASE_REQ = { addEnvironmentParams: true };
const BASE_REQ_WITH_LANG = { ...BASE_REQ, headers: HEADERS_WITH_LANG };
const BASE_REQ_NO_LANG = { ...BASE_REQ, headers: HEADERS_NO_LANG };

@injectable()
export class YinzCamCardsServerV2 {
  private readonly server: YinzCamServer;

  public constructor(@injectToken(YinzCamAPIToken) api: YinzCamAPI) {
    this.server = api.getServer('pages');
  }

  public getRequest(request: YinzCamAPIRequest) {
    return this.server.getRequest(request);
  }

  public getPage(id: string, language?: string): ReactiveMicrocomponent<YinzCamAPIResponse> {
    return this.server.getRequest({ ...BASE_REQ_WITH_LANG, path: `pages/${id}`, allowAllStatusCodes: true, language });
  }

  public getPageSingle(id: string, language?: string): Promise<YinzCamAPIResponse> {
    return this.server.singleRequest({ ...BASE_REQ_WITH_LANG, path: `pages/${id}`, allowAllStatusCodes: true, language });
  }

  public getFragment(id: string, language?: string): ReactiveMicrocomponent<YinzCamAPIResponse> {
    return this.server.getRequest({ ...BASE_REQ_WITH_LANG, path: `fragments/${id}`, language });
  }

  public getMessages(id: string): ReactiveMicrocomponent<YinzCamAPIResponse> {
    return this.server.getRequest({ ...BASE_REQ_NO_LANG, path: `messages/${id}` });
  }

  public getMenu(id: string): ReactiveMicrocomponent<YinzCamAPIResponse> {
    return this.server.getRequest({ ...BASE_REQ_WITH_LANG, path: `menus/${id}` });
  }

  public getStyle(id: string): ReactiveMicrocomponent<YinzCamAPIResponse> {
    return this.server.getRequest({ ...BASE_REQ_NO_LANG,path: `styles/${id}` });
  }
}
