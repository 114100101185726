import { Token, injectToken } from "inversify-token";
import { injectable } from "inversify";
import { CardsDynamicConfigurationProvider } from "components/cards/common/CardsDynamicConfigurationProvider";
import { CardsDynamicConfiguration } from "components/cards/common/CardsDynamicConfiguration";
import { ContentfulCDN, ContentfulCDNToken } from "./ContentfulCDN";
import { ContentfulCardsDynamicConfiguration } from "./ContentfulCardsDynamicConfiguration";

export const ContentfulCardsDynamicConfigurationProviderToken = new Token<ContentfulCardsDynamicConfigurationProvider>(Symbol.for("ContentfulCardsDynamicConfigurationProvider"));

@injectable()
export class ContentfulCardsDynamicConfigurationProvider implements CardsDynamicConfigurationProvider {

  private readonly dc: ContentfulCardsDynamicConfiguration;

  public constructor(@injectToken(ContentfulCDNToken) private readonly cdn: ContentfulCDN) {
    this.dc = new ContentfulCardsDynamicConfiguration(cdn);
  }

  public get(): CardsDynamicConfiguration {
    return this.dc;
  }

}
