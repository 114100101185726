import { AbstractReactiveMicrocomponent, InputComponents } from "../AbstractReactiveMicrocomponent";
import { ReactiveMicrocomponentConfig } from "../ReactiveMicrocomponentConfig";

export class SimpleComponent<O, I extends unknown[] = unknown[]> extends AbstractReactiveMicrocomponent<O, I> {
  private readonly updater: (...$inputValues: I) => Promise<O>;

  public constructor(configOrName: string | ReactiveMicrocomponentConfig<O>, updater: (...$inputValues: I) => Promise<O>, ...inputs: InputComponents<I>) {
    super((typeof configOrName === 'string')? { name: configOrName } : configOrName, ...inputs);
    this.updater = updater;
  }

  protected async update($control: unknown, ...$inputValues: I): Promise<O> {
    return this.updater(...$inputValues);
  }
}
