<CardBase {element}>
  <div style="
    width: {width || 'auto'};
    height: {height || 'auto'};
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: italic;
    text-align: center;
  ">
    {#if renderedImageUrl}
      <img src={renderedImageUrl} alt={renderedTitle || ""}>
    {:else if renderedTitle}
      {renderedTitle}
    {:else}
      Select this element and select an element type on the left to customize.
    {/if}
  </div>
</CardBase>

<style>
  *, *::before, *::after {
    box-sizing: border-box;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }</style>

<script lang="ts">var _a, _b;
import "svelte/store";
import "yinzcam-cards";
import CardBase from "components/cards/common/CardBase.svelte";
import "components/cards/common/CardsDataSourceRegistration";
export let element;
export let sources;
export let width = null;
export let height = null;
export let title = null;
export let imageUrl = null;
$: renderedTitle = ((_a = element === null || element === void 0 ? void 0 : element.data) === null || _a === void 0 ? void 0 : _a.title) || title;
$: renderedImageUrl = ((_b = element === null || element === void 0 ? void 0 : element.data) === null || _b === void 0 ? void 0 : _b.imageUrl) || imageUrl;
</script>
