import { generateRootObjectSchema, generateArraySchema, generateObjectSchema, generateColorSchema, generateStringSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { ButtonParticleSpec, ImageParticleSpec, TextParticleSpec, BadgeParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("MC-TimelineAtom", generateRootObjectSchema({
    container: generateAtomContainerSchema(),
    overlayPadding: generateStringSchema("Overlay padding", "Specify overlay padding"),
    numberOfMinutes: generateStringSchema("Number of minutes", "Specify the total number of minutes"),
    txtTimelineHeading: TextParticleSpec.generateSchema("Timeline Heading", "Enter a heading for the timeline", {
        defaultValues: { size: "1.5rem", weight: "700", text: "TIMELINE" }
    }),
    imgHomeLogo: ImageParticleSpec.generateSchema("Home team logo", "Upload home team logo", {
        defaultValues: { container: { height: "7rem", width: "7rem", padding: "0" } }
    }),
    imgAwayLogo: ImageParticleSpec.generateSchema("Away team logo", "Upload away team logo", {
        defaultValues: { container: { height: "7rem", width: "7rem", padding: "0" } }
    }),
    matchStatus: generateStringSchema("Match Status", "Match Status", {
        choices: ["pre", "post", "live"],
        choiceTitles: ["Pre", "Post", "Live"],
        defaultValue: "live",
    }),
    colorTimelineBorder: generateColorSchema("Heading Border Color", "Heading Border Color"),
    arrTimelineEvents: generateArraySchema("Timeline Events", "List of events to be added",
        generateObjectSchema("Timeline Event Object", "Timeline Event properties", {
            badgeType: generateStringSchema("Event Type", "Select an event type to add", {
                choices: ["substitution", "goal", "yellow-card", "red-card", "time-display", "others"],
                choiceTitles: ["Substitution", "Goal Scored", "Yellow Card Issued", "Red Card Issued", "Display Time", "Others"]
            }),
            eventTime: generateStringSchema("Event time", "Enter event time in minutes"),
            eventTeam: generateStringSchema("Event Team Name", "Select for which team the event is to be added", {
                choices: ["home-team", "away-team"],
                choiceTitles: ["Home Team", "Away Team"]
            }),
            badgeTextOne: TextParticleSpec.generateSchema("Line One", "Enter line one text for event", {
                defaultValues: { container: { centerContentHorizontally: false, centerContentVertically: false } }
            }),
            badgeTextTwo: TextParticleSpec.generateSchema("Line Two", "Enter line two text for event", {
                defaultValues: { container: { centerContentHorizontally: false, centerContentVertically: false } }
            }),
            badgeParticleProps: BadgeParticleSpec.generateSchema("Badge Particle Props", "Badge Particle Props", {
                defaultValues: {
                    textOne: { container: { centerContentHorizontally: false, centerContentVertically: false } },
                    textTwo: { container: { centerContentHorizontally: false, centerContentVertically: false } }
                }
            }),
        })
    )
}));