<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import "lodash";
import { BaseAtom } from "components/cards/atoms";
import { ImageParticle } from "components/cards/particles/YinzCam";
export let container = undefined;
export let image = undefined;
</script>

<style></style>

<BaseAtom _isotope="ButtonAtom" {...container}>
  <ImageParticle _block={true} {...image} />
</BaseAtom>
