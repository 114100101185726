import { library as coreLibrary } from "./Core";
import { library as mediaLibrary } from "./Media";
import { library as statsLibrary } from "./Stats";
import { library as profileLibrary } from "./Profile";

export const library = {
  ...coreLibrary,
  ...mediaLibrary,
  ...statsLibrary,
  ...profileLibrary,
};
