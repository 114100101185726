import { generateArraySchema, generateBooleanSchema, generateColorSchema, generateObjectSchema, generateRootObjectSchema, generateStringSchema, generateUrlSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { TextParticleSpec, DropDownParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("DropDown List Atom", generateRootObjectSchema({
    container: generateAtomContainerSchema({
        defaultValues: { themeMode: 'primary' },
        uneditableFields: { background: false }
    }),
    overlayPadding: generateStringSchema("Overlay Padding", "The padding (inset) of the overlay text/buttons relative to the background image.", {
        defaultValue: '4rem 2rem'
    }),
    isResultsYears: generateBooleanSchema("Is Results Years?", "Is Results Years?", {
        defaultValue: true
    }),
    dropdownParticle: DropDownParticleSpec.generateSchema("DropDown Props", "DropDown Props"),
}));
