import { generateArraySchema, generateObjectSchema, generateRootObjectSchema, generateStringSchema, generateUrlSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { TextParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("Navigation Submenu Card", generateRootObjectSchema({
  container: generateAtomContainerSchema({
    defaultValues: { themeMode: 'primary' },
  }),
  slug: generateStringSchema("Slug", "The site subsection slug."),
  headingText: TextParticleSpec.generateSchema("Title", "The title of the subsection.", {
    defaultValues: { useAccentColor: true }
  }),
  pages: generateArraySchema("Pages", "The list of pages within the subsection.", 
    generateObjectSchema("Page", "A page within the subsection.", {
      pageName: TextParticleSpec.generateSchema("Title", "The title of the page."),
      linkUrl: generateUrlSchema("Link URL", "The URL of the page"),
    }, { additionalProperties: true }),
  ),
}));
