import { generateBooleanSchema, generateRootObjectSchema, generateStringSchema, generateUrlSchema } from "components/cards/common/schema";
import { BaseParticleSpec, GetParticlePropsType, GetParticleSchemaType, generateParticleContainerSchema } from "components/cards/particles/BaseParticleSpec";
import { ImageParticleSpec } from "../Image/spec";
import { TextParticleSpec } from "../Text/spec";

export const BadgeParticleSpec = new BaseParticleSpec("Text", generateRootObjectSchema({
    container: generateParticleContainerSchema(),
    icon: ImageParticleSpec.generateSchema("Badge Icon", "The image for the badge", {
        defaultValues: { sizing: 'contain', container: { width: "1rem", height: "1rem" } }
    }),
    textOne: TextParticleSpec.generateSchema("Text One", "Text One", {
        defaultValues: { wrap: "nowrap", container: { centerContentHorizontally: false, centerContentVertically: false } }
    }),
    textTwo: TextParticleSpec.generateSchema("Text Two", "Text Two", {
        defaultValues: { wrap: "nowrap", container: { centerContentHorizontally: false, centerContentVertically: false } }
    }),
    showImage: generateBooleanSchema("Show Label Image", "Show the label image, if specified.", {
        defaultValue: true
    }),
    showText: generateBooleanSchema("Show Label Text", "Show the label text, if specified.", {
        defaultValue: true
    }),
    gap: generateStringSchema("Image/Text Gap", "The gap between the image and text in standard CSS units."),
    badgeType: generateStringSchema("Badge Type", "Select the type of badge", {
        choices: ["substitution", "goal", "yellow-card", "red-card", "time-display", "others"],
        choiceTitles: ["Substitution", "Goal Scored", "Yellow Card Issued", "Red Card Issued", "Display Time", "Others"]
    })
}));

export const specification = BadgeParticleSpec;

export type BadgeParticleSchema = GetParticleSchemaType<typeof BadgeParticleSpec>;

export type BadgeParticleProps = GetParticlePropsType<typeof BadgeParticleSpec>;
