<script lang="ts">import ImageWithPlaceholder from '../../ImageWithPlaceholder/ImageWithPlaceholder.svelte';
export let data = null;
export let text = "";
</script>

<div class="card-el">
  <div class="top-info">
    <span class="time">{data.Time}</span>
    <ImageWithPlaceholder src={data.TeamLogo} alt="TeamLogo" class="team-logo" placeholder="/static/images/default_club.png" />
  </div>
  <div class="player">
    <ImageWithPlaceholder
      src={data.ImageUrl}
      alt={data.Title}
      class="player-img"
      placeholder="/static/images/download.png"
      title={data.Title}
    />
    {#if data.Type === 'YELLOW' || data.Type === 'SECOND_YELLOW'}
      <div class="yellow-card" class:second={data.Type === 'SECOND_YELLOW'} />
    {/if}
    {#if data.Type === 'RED' || data.Type === 'SECOND_YELLOW'}
      <div class="red-card" class:second={data.Type === 'SECOND_YELLOW'} />
    {/if}
  </div>
  <div class="comment">{text}</div>
  <div class="player-name">{data.Title || ""}</div>
</div>

<style lang="scss">*,
*:before,
*:after {
  box-sizing: border-box; }

.card-el {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 11px 15px 11px 15px;
  background: var(--theme-primary-background-color, white);
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 12px; }

.top-info {
  position: absolute;
  left: 16px;
  top: 11px;
  right: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.time {
  line-height: 31px;
  font-size: 20px;
  font-weight: bold;
  color: var(--theme-primary-accent-color, black);
  display: block; }

:global(.team-logo) {
  height: 36px;
  display: block; }

.player {
  margin: auto 0 0 0;
  position: relative;
  background-color: #e1e1e1;
  border-radius: 50%;
  width: 144px;
  height: 138px; }
  .player :global(.player-img) {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: block; }
  .player .yellow-card,
  .player .red-card {
    position: absolute;
    top: 0;
    right: -4px;
    width: 39px;
    height: 51px;
    border-radius: 4.1px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5); }
  .player .yellow-card {
    z-index: 1;
    background: #ffb945; }
    .player .yellow-card.second {
      background: #FFCD00; }
  .player .red-card {
    background: #e3424f;
    z-index: 2; }
    .player .red-card.second {
      background: #F40D2A; }
  .player :global(.yellow-card.second) {
    width: 26px !important;
    height: 35px !important;
    top: 0px !important;
    right: 9px !important; }
  .player .yellow-card + .red-card {
    width: 26px;
    height: 35px;
    top: 15px; }

.comment {
  margin: 6px 0 0 0;
  line-height: 22px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: var(--theme-primary-foreground-color, black);
  text-transform: uppercase; }

.player-name {
  margin: 2px 0 0 0;
  line-height: 18px;
  font-size: 16px;
  text-align: center;
  color: var(--theme-primary-foreground-color, black); }

@media (max-width: 1px) {
  .card-el {
    padding: 11px 10px 11px 10px; }
  .top-info {
    position: absolute;
    left: 8px;
    top: 8px;
    right: 8px; }
  .time {
    line-height: 21px;
    font-size: 13.8px; }
  :global(.team-logo) {
    height: 26px; }
  .player {
    width: 99px;
    height: 99px; }
    .player .yellow-card,
    .player .red-card {
      right: -4px;
      width: 26px;
      height: 35px;
      border-radius: 2.8px; }
    .player .yellow-card {
      z-index: 1;
      background: #ffb945; }
    .player .red-card {
      background: #e3424f;
      z-index: 2; }
    .player :global(.yellow-card.second) {
      width: 18px !important;
      height: 25px !important;
      top: 0px !important;
      right: 9px !important; }
    .player .yellow-card + .red-card {
      width: 18px;
      height: 25px;
      top: 15px; }
  .comment {
    margin: 8px 0 0 0;
    line-height: 15px;
    font-size: 12.7px; }
  .player-name {
    margin: 2px 0 0 0;
    line-height: 15px;
    font-size: 12px; } }</style>
