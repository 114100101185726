import { generateRootObjectSchema, generateHtmlSchema, generateBooleanSchema } from "components/cards/common/schema";
import { BaseParticleSpec, GetParticlePropsType, GetParticleSchemaType, generateParticleContainerSchema } from "components/cards/particles";
import { TextParticleSpec } from "../Text";
import { TextInputParticleSpec } from "../TextInput";
import { ButtonParticle, ButtonParticleSpec } from "../Button";
import { ImageParticleSpec } from "../Image";
import ForgotPasswordTitle from "components/cards/library-old/WebSSO/ForgotPasswordTitle/ForgotPasswordTitle.svelte";

export const SearchParticleSpec = new BaseParticleSpec("Text", generateRootObjectSchema({
  container: generateParticleContainerSchema(),
  inputBox: TextInputParticleSpec.generateSchema("Text Input", "The text input for the search box.", {
    defaultValues: {
      container: { fillWidth: true, height: '3rem', overflow: 'clip' },
      image: { icon: "yc_icon_search", container: { width: '2.25rem' }, fillWidth: false },
      text: { text: 'Search' },
    }
  }),
  clearButton: ButtonParticleSpec.generateSchema("Clear Button", "The clear button for the search box.", {
    defaultValues: {
      image: {
        icon: 'yc_icon_close_fill',
        container: { width: '1.5rem', height: '1.5rem' }
      },
      text: {
        text: 'Clear',
      },
      gap: '0.25rem'
    }
  }),
  showClearButton: generateBooleanSchema("Should Show Clear Button", "Should Show Clear Button", {
    defaultValue: true,
  }),
  searchIconProps: ImageParticleSpec.generateSchema("Search Icon", "Search Icon Props", {
    defaultValues: { container: { fillWidth: false }, fillWidth: false }
  })
}));

export const specification = SearchParticleSpec;

export type SearchParticleSchema = GetParticleSchemaType<typeof SearchParticleSpec>;

export type SearchParticleProps = GetParticlePropsType<typeof SearchParticleSpec>;
