import { generateRootObjectSchema, generateArraySchema, generateObjectSchema, generateStringSchema, generateColorSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { ImageParticleSpec, TextParticleSpec } from "components/cards/particles/YinzCam";

const playerStatProps = {
    position: TextParticleSpec.generateSchema("Player Position", "Player Position", {
        defaultValues: { size: "1.25rem", weight: "700" }
    }),
    playerName: TextParticleSpec.generateSchema("Player name", "Player name", {
        defaultValues: { size: "1.25rem", weight: "400" }
    }),
    playerImageUrl: generateStringSchema("Image Url", "Image Url"),
    events: generateObjectSchema("Player Details", "Player Details", {
        goalTimes: generateArraySchema("Goal Times", "Goal Times", generateStringSchema("Goal Times", "Goal Times")),
        redCardTimes: generateArraySchema("Assist Times", "Assist Times", generateStringSchema("Red Card Times", "Red Card Times")),
        yellowCardTimes: generateArraySchema("Yellow Card Times", "Yellow Card Times", generateStringSchema("Yellow Card Times", "Yellow Card Times")),
        subbedOffTimes: generateArraySchema("Subbed Off Times", "Subbed Off Times", generateStringSchema("Subbed Off Times", "Subbed Off Times")),
        subbedOnTimes: generateArraySchema("Subbed On Times", "Subbed On Times", generateStringSchema("Subbed On Times", "Subbed On Times")),
    })
};
export const specification = new BaseAtomSpec("MC-LineupTable", generateRootObjectSchema({
    container: generateAtomContainerSchema(),
    overlayPadding: generateStringSchema("Container Padding", "Container Padding"),
    backgroundImageDesktop: ImageParticleSpec.generateSchema("Background Image", "Background Image"),
    colorTableRow: generateColorSchema("Table Row Color", "Select a color for alternate rows in table"),
    teamLogo: ImageParticleSpec.generateSchema("Team Logo", "Team Logo", {
        defaultValues: { container: { width: "3.5rem", height: "3.5rem" }, sizing: "cover" }
    }),
    txtStartersHeading: TextParticleSpec.generateSchema("Starters Heading", "Starters Heading", {
        defaultValues: { size: "1.5rem", weight: "700", align: "center" }
    }),
    txtSubHeading: TextParticleSpec.generateSchema("Substitutes Heading", "Substitutes Heading", {
        defaultValues: { size: "1.5rem", weight: "700", align: "center" }
    }),
    eventIcon: ImageParticleSpec.generateSchema("Event Name", "Event Name", {
        defaultValues: { container: { width: "1rem", height: "1rem" }, sizing: "contain" }
    }),
    eventText: TextParticleSpec.generateSchema("Event Text", "Event Text", {
        defaultValues: { size: "1.125rem", weight: "400" }
    }),
    playerImage: ImageParticleSpec.generateSchema("Player Image", "Player Image", {
        defaultValues: { container: { width: "2rem", height: "2rem" }, sizing: "cover" }
    }),
    teamSelection: generateStringSchema("Team Selection", "Select home or away", {
        choices: ["home", "away"],
        choiceTitles: ["Home", "Away"],
        defaultValue: "home"
    }),
    homeTeamLogo: generateStringSchema("Home Team Logo", "Home Logo URL"),
    awayTeamLogo: generateStringSchema("Away Team Logo", "Home Logo URL"),
    homePlayerStarterStats: generateArraySchema("Starter Player Stats", "Starter Player Stats",
        generateObjectSchema("Enter Player Stats", "Enter Player Stats", {
            ...playerStatProps
        })),
    homePlayerSubStats: generateArraySchema("Substitute Player Stats", "Substitute Player Stats",
        generateObjectSchema("Enter Player Stats", "Enter Player Stats", {
            ...playerStatProps
        })),
    awayPlayerStarterStats: generateArraySchema("Starter Player Stats", "Starter Player Stats",
        generateObjectSchema("Enter Player Stats", "Enter Player Stats", {
            ...playerStatProps
        })),
    awayPlayerSubStats: generateArraySchema("Substitute Player Stats", "Substitute Player Stats",
        generateObjectSchema("Enter Player Stats", "Enter Player Stats", {
            ...playerStatProps
        }))
}));