import { generateRootObjectSchema, generateArraySchema, generateObjectSchema, generateStringSchema, generateUrlSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { AudioParticleSpec, CaptionedParticleSpec, ImageParticleSpec, RichTextParticleSpec, TextParticleSpec, VideoParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("Accordion Card", generateRootObjectSchema({
    container: generateAtomContainerSchema({ defaultValues: { themeMode: 'primary' } }),
    accordionGap: generateStringSchema("Accordion Tab Gap", "Specify a gap between accordion tabs"),
    imgOpenIcon: ImageParticleSpec.generateSchema("Open Icon", "Select an icon for toggling accordion", {
        defaultValues: { container: { width: "2rem", height: "2rem" }, icon: 'yc_icon_add' }
    }),
    imgCloseIcon: ImageParticleSpec.generateSchema("Close Icon", "Select an icon for toggling accordion", {
        defaultValues: { container: { width: "2rem", height: "2rem" }, icon: 'yc_icon_subtract' }
    }),
    textDefaults: TextParticleSpec.generateSchema("Text Defaults", "Default styling for text sections of articles.", {
        defaultValues: { container: { centerContentHorizontally: false } }
    }),
    imageDefaults: ImageParticleSpec.generateSchema("Image Defaults", "Default styling for image blocks of articles.", {
        defaultValues: { container: { fillWidth: true, centerContentHorizontally: true, fillHeight: false } }
    }),
    videoDefaults: VideoParticleSpec.generateSchema("Video Defaults", "Default styling for video blocks of articles.", {
        defaultValues: { container: { fillWidth: true, centerContentHorizontally: true, fillHeight: false }, controls: true }
    }),
    audioDefaults: AudioParticleSpec.generateSchema("Audio Defaults", "Default styling for audio blocks of articles."),
    captionDefaults: CaptionedParticleSpec.generateSchema("Caption Defaults", "Default styling for captions on blocks within articles."),
    accordionTabs: generateArraySchema("Accordion Tabs", "Accordion tabs to display.",
        generateObjectSchema("Accordion", "Tab with title and content.", {
            heading: TextParticleSpec.generateSchema("Title", "Add a title", {
                defaultValues: { size: "1.5rem", weight: "700" }
            }),
            content: RichTextParticleSpec.generateSchema("Content", "Add content", {
                defaultValues: {
                    size: "1rem",
                    weight: "400",
                    container: {
                        margin: "0",
                        padding: "1rem"
                    }
                }
            }),
            articleBody: generateArraySchema("Article Body", "The article body as a series of component props.",
                generateObjectSchema("Body Item", "An item that is placed in the body of the article.", {}, { additionalProperties: true })
            ),
            linkUrl: generateUrlSchema("URL", "Specify the external URL to open")
        })
    ),

}));
