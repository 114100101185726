<script context="module" lang="ts">import 'json-schema';
import 'components/cards/common/CardsElementInterfaces';
export function getDisplayName() {
    return "Core: Text Button Card";
}
export function getElementConfigSpec() {
    return {
        type: "object",
        properties: {
            label: {
                type: "string",
                title: "Label",
                description: "The label that appears on the button."
            },
            labelPadding: {
                type: "string",
                title: "Label Padding",
                description: "The padding around the label text.",
                style: true
            },
            linkUrl: {
                type: "string",
                format: "url",
                title: "Link",
                description: "The URL to open when the button is clicked."
            },
            labelFontSize: {
                type: "string",
                title: "Label Font Size",
                description: "The font size of the label.",
                style: true
            },
            labelFontColor: {
                type: "string",
                title: "Label Font Color",
                description: "The font color of the label.",
                style: true
            },
            labelFontWeight: {
                type: "string",
                title: "Label Font Weight",
                description: "The font weight of the label.",
                style: true
            },
            backgroundColor: {
                type: "string",
                title: "Background Color",
                description: "The background color of the button.",
                style: true
            },
            hoverAnimationDuration: {
                type: "string",
                title: "Hover Duration",
                description: "The hover animation duration in CSS units.",
                style: true
            },
            hoverBackgroundColor: {
                type: "string",
                title: "Hover Background Color",
                description: "The hover background color of the button.",
                style: true
            },
        }
    };
}
export function getElementDataSourceSpec() {
    return {
        sources: [
            {
                type: 'SOURCE',
                title: 'Card Data',
                description: 'Arbitrary source of data for this card matching the configuration fields.',
                classes: '*'
            }
        ]
    };
}
</script>

<script lang="ts">import CardBase from "components/cards/common/CardBase.svelte";
import { resolveUrl } from 'js/url';
export let label;
export let linkUrl;
$: linkUrlResolved = resolveUrl(linkUrl).href;
</script>

<CardBase>
  <a href={linkUrlResolved}>
    <button class="configurable-button">
      {label || ''}
    </button>
  </a>
</CardBase>

<style>
  a {
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  .configurable-button {
    color: var(--label-font-color, var(--font-color, unset));
    font-size: var(--label-font-size, var(--font-size, unset));
    font-weight: var(--label-font-weight, var(--font-weight, unset));
    padding: var(--label-padding, unset);
    border: none;
    cursor: pointer;
    background-color: var(--background-color, unset);
    transition: background-color var(--hover-animation-duration, 0.25s);
    height: 100%;
  }

  .configurable-button:hover {
    background-color: var(
      --hover-background-color,
      var(--background-color, unset)
    );
  }</style>
