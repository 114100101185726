<script lang="ts">var _a, _b;
import { writable } from "svelte/store";
import "js/i18n";
import CardBase from "components/cards/common/CardBase.svelte";
import "yinzcam-cards";
import "components/cards/common/CardsDataSourceRegistration";
import { getFirstSourceStore, getTemplatedElementDataFromSource, buildInlineStyle } from "components/cards/utilities";
import { resolveUrl } from 'js/url';
import { PageParticle, PageParticleSpec } from "components/cards/particles/YinzCam";
export let element;
export let sources = writable();
export let sourceStore = undefined;
$: {
    sourceStore = getFirstSourceStore($sources);
}
;
$: title = (_a = getTemplatedElementDataFromSource(element, "title", $sourceStore)) === null || _a === void 0 ? void 0 : _a.toString();
$: titlePadding = getTemplatedElementDataFromSource(element, "titlePadding", $sourceStore);
$: titleFontSize = getTemplatedElementDataFromSource(element, "titleFontSize", $sourceStore);
$: titleFontColor = getTemplatedElementDataFromSource(element, "titleFontColor", $sourceStore);
$: titleFontFamily = getTemplatedElementDataFromSource(element, "titleFontFamily", $sourceStore);
$: titleBackgroundColor = getTemplatedElementDataFromSource(element, "titleBackgroundColor", $sourceStore);
$: titleHoverBackgroundColor = getTemplatedElementDataFromSource(element, "titleHoverBackgroundColor", $sourceStore);
$: titleHoverUnderlineColor = getTemplatedElementDataFromSource(element, "titleHoverUnderlineColor", $sourceStore);
$: titleChevronSize = getTemplatedElementDataFromSource(element, "titleChevronSize", $sourceStore);
$: titleShowChevron = getTemplatedElementDataFromSource(element, "titleShowChevron", $sourceStore) || false;
$: titleShowBackgroundOnHover = getTemplatedElementDataFromSource(element, "titleShowBackgroundOnHover", $sourceStore) || false;
$: titleShowUnderlineOnHover = getTemplatedElementDataFromSource(element, "titleShowUnderlineOnHover", $sourceStore) || false;
$: titleYShift = getTemplatedElementDataFromSource(element, "titleYShift", $sourceStore);
$: dropdownPadding = getTemplatedElementDataFromSource(element, "dropdownPadding", $sourceStore);
$: dropdownFontSize = getTemplatedElementDataFromSource(element, "dropdownFontSize", $sourceStore);
$: dropdownFontColor = getTemplatedElementDataFromSource(element, "dropdownFontColor", $sourceStore);
$: dropdownBackgroundColor = getTemplatedElementDataFromSource(element, "dropdownBackgroundColor", $sourceStore);
$: dropdownItemPadding = getTemplatedElementDataFromSource(element, "dropdownItemPadding", $sourceStore);
$: dropdownItemDividerSize = getTemplatedElementDataFromSource(element, "dropdownItemDividerSize", $sourceStore);
$: dropdownItemDividerColor = getTemplatedElementDataFromSource(element, "dropdownItemDividerColor", $sourceStore);
$: dropdownPageEmbed = getTemplatedElementDataFromSource(element, "dropdownPageEmbed", $sourceStore);
$: sectionSlug = getTemplatedElementDataFromSource(element, "sectionSlug", $sourceStore);
$: subsections = getTemplatedElementDataFromSource(element, "subsections", $sourceStore) || [];
$: hasItems = subsections && subsections.length > 0;
//$: console.log('HeaderNavSectionMenu ITEMS', items);
$: linkUrl = resolveUrl((_b = getTemplatedElementDataFromSource(element, "linkUrl", $sourceStore)) === null || _b === void 0 ? void 0 : _b.toString()).href;
$: style = buildInlineStyle("title-padding", titlePadding, "title-font-size", titleFontSize, "title-font-color", titleFontColor, "title-font-family", titleFontFamily, "title-background-color", titleBackgroundColor, "title-hover-background-color", titleHoverBackgroundColor, "title-hover-underline-color", titleHoverUnderlineColor, "title-chevron-size", titleChevronSize, "title-y-shift", titleYShift, "dropdown-padding", dropdownPadding, "dropdown-font-size", dropdownFontSize, "dropdown-font-color", dropdownFontColor, "dropdown-background-color", dropdownBackgroundColor, "dropdown-item-padding", dropdownItemPadding, "dropdown-item-divider-size", dropdownItemDividerSize, "dropdown-item-divider-color", dropdownItemDividerColor);
export let isHovering = false;
function toggleHover(state) {
    isHovering = state;
}
</script>

<CardBase {element} height="100%">
  <div
    class="dropdown"
    class:hovering={isHovering}
    class:noitems={!hasItems}
    class:show-chevron={titleShowChevron}
    class:show-background-on-hover={titleShowBackgroundOnHover}
    class:show-underline-on-hover={titleShowUnderlineOnHover}
    on:mouseleave={() => toggleHover(false)}
    {style}
  >
    <a class="anchor" class:with-y-shift={titleYShift} href={linkUrl}>
      <button class="trigger" on:mouseenter={() => toggleHover(true)}>
        {title}<svg xmlns="http://www.w3.org/2000/svg" class="chevron-svg" viewBox="0 0 24.52 13.38" fill="currentColor" ><path class="atcls-1" d="M13.85 12.26L24.2 1.92A1.13 1.13 0 1022.6.33L12.26 10.67 1.92.33A1.13 1.13 0 00.33 1.92L11.46 13a1.14 1.14 0 001.61 0z"></path></svg>
      </button>
    </a>
  </div>
  <div class="page-list-menu" 
    on:mouseenter={() => toggleHover(true)}
    on:mouseleave={() => toggleHover(false)}
  >
  {#if dropdownPageEmbed}
    <PageParticle {...PageParticleSpec.propsWithDefaults({
      slug: dropdownPageEmbed,
      pathParams: (sectionSlug)? [ sectionSlug ] : [],
    })}/>
  {:else}
    <div class="page-left-half">
      {#each subsections as subsection}
      <ul class="page-list-group">
        {#if subsection.title}
        <li class="group-title">{subsection.title}</li>
        {/if}
        {#each subsection.pages as page }
        <li><a href={resolveUrl(page.linkUrl).href}>{page.title}</a></li>
        {/each}
      </ul>
      {/each}
    </div>
  {/if}
  </div>
</CardBase>

<style>
  .dropdown {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    box-sizing: content-box;
  }

  .anchor.with-y-shift {
    transform: translateY(var(--title-y-shift));
  }

  .trigger {
    background-color: var(--title-background-color, unset);
    color: var(--title-font-color, var(--font-color, unset));
    font-size: var(--title-font-size, 1rem);
    font-weight: 600;
    text-wrap: nowrap;
    font-family: var(--title-font-family, inherit);
    padding: var(--title-padding, unset);
    border: none;
    cursor: pointer;
    outline: none;
    transition: background-color 0.2s ease;
  }

  .trigger .chevron-svg {
    fill: currentColor;
    width: var(--title-chevron-size, 10px);
    height: var(--title-chevron-size, 10px);
    margin-left: 10px;
    transform: rotate(0);
    transition: transform .4s cubic-bezier(.68, -.55, .27, 1.55);
  }

  .dropdown:not(.show-chevron) .chevron-svg {
    display: none;
  }

  .dropdown.noitems .trigger .chevron-svg {
    display: none;
  }

  /* Rotate chevron on hover */
  .dropdown.hovering .trigger .chevron-svg {
    transform: rotate(-180deg);
  }

  .dropdown.show-background-on-hover .trigger {
    padding: 0.75rem 1.5rem;
    border-radius: 0.375rem;
  }

  .dropdown.hovering.show-background-on-hover .trigger {
    background-color: var(--title-hover-background-color, lch(from var(--title-background-color, var(--theme-mode-background-color, transparent)) calc(l * 0.5) c h));
  }
  
  .dropdown.hovering.show-underline-on-hover {
    border-bottom: 4px solid var(--title-hover-underline-color, lch(from var(--title-background-color, var(--theme-mode-background-color, transparent)) calc(l * 0.5) c h));
  }

  /* compensates for removing 4px from the bottom of the content box to make room for the border */
  .dropdown.hovering.show-underline-on-hover {
    transform: translateY(-4px);
  }
  .dropdown.hovering.show-underline-on-hover .trigger {
    transform: translateY(4px);
  }

  .page-list-menu {
    position: absolute;
    top: 100%;
    left: 50%;
    background-color: white;
    width: 100%;
    box-shadow: 0 0 8px rgba(0,0,0,.1);
    z-index: 10;
    margin: 0px 0px 0px 0px; /* top margin should be negative of translateY value below */
    /*padding: 10px 20px;*/
    transform: translateX(-50%) translateY(0px);
    transition: all 0.2s ease;
    visibility: hidden;
    opacity: 0;

    /*
    display: flex;
    flex-direction: row;
    gap: 2rem;
    flex-wrap: wrap;
    */
  }

  .page-list-menu > * {
    flex: 1;
  }

  .page-left-half {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    flex-wrap: wrap;
  }

  .page-left-half > * {
    flex: 1;
  }

  ul.page-list-group {
    white-space: nowrap;
    font-size: var(--dropdown-font-size, unset);
    color: var(--dropdown-font-color, unset);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 1.25rem;
  }

  ul.page-list-group > li {
    color: var(--theme-primary-foreground-color, black);
    padding: var(--dropdown-item-padding, unset);
    -webkit-text-decoration: none;
    text-decoration: none;
    display: block;
    cursor: pointer;
  }

  ul.page-list-group > li.group-title {
    color: var(--theme-primary-accent-color, black);
    text-transform: uppercase;
    cursor: default;
    letter-spacing: 0.03125rem;
  }

  .page-right-half {
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    display: none;
  }

  /* Display dropdown on hover */
  .dropdown.hovering +.page-list-menu {
    visibility: visible;
    opacity: 1;
    transform: translateX(-50%) translateY(0px);
  }

  .dropdown.noitems +.page-list-menu {
    display: none;
  }

  .dropdown-arrow {
    position: absolute;
    top: -10px;
    left: 0;
    height: 10px;
    width: 100%;
    -webkit-filter: drop-shadow(0 -2px 1px rgba(0,0,0,.1));
            filter: drop-shadow(0 -2px 1px rgba(0,0,0,.1));
    overflow: hidden;
    fill: var(--dropdown-background-color, unset);
  }

  .page-list-menu li:not(:last-child) {
    border-bottom: var(--dropdown-item-divider-size, 0px) solid var(--dropdown-item-divider-color, transparent);
  }

  .page-list-menu li:not(.group-title):hover {
    -webkit-text-decoration: underline;
    text-decoration: underline;
  }</style>
