import { Container } from "inversify";
import { TokenContainerModule } from "inversify-token";
import { YinzCamInjectModule } from "yinzcam-inject";
import { YinzCamDropsServer, YinzCamDropsServerToken } from "./YinzCamDropsServer";
import { YinzCamDropsService, YinzCamDropsServiceToken } from "./YinzCamDropsService";

export let YinzCamDropsServerModule: YinzCamInjectModule = new YinzCamInjectModule((container: Container): void => {
  container.load(new TokenContainerModule((bindToken) => {
    bindToken<YinzCamDropsServer>(YinzCamDropsServerToken).to(YinzCamDropsServer).inSingletonScope();
    bindToken<YinzCamDropsService>(YinzCamDropsServiceToken).to(YinzCamDropsService).inSingletonScope();
  }));
});
