import { Container } from "inversify";
import { Token, TokenContainerModule } from "inversify-token";
import { YinzCamInjectModule } from "yinzcam-inject";
import { ContentfulAPIConfig, ContentfulAPIConfigToken } from "./ContentfulAPIConfig";
import { ContentfulCDN, ContentfulCDNToken } from "./ContentfulCDN";
import { ContentfulClientFactory, ContentfulClientFactoryToken } from "./ContentfulClient";
import { ContentfulCardsDynamicConfigurationProvider, ContentfulCardsDynamicConfigurationProviderToken } from "./ContentfulCardsDynamicConfigurationProvider";


export const ContentfulAPIModule: YinzCamInjectModule = new YinzCamInjectModule((container: Container): void => {
  container.load(new TokenContainerModule((bindToken) => {
    bindToken(ContentfulAPIConfigToken).toConstantValue(CONFIG as ContentfulAPIConfig);
    bindToken(ContentfulClientFactoryToken).to(ContentfulClientFactory).inSingletonScope();
    bindToken(ContentfulCDNToken).to(ContentfulCDN).inSingletonScope();
    bindToken(ContentfulCardsDynamicConfigurationProviderToken).to(ContentfulCardsDynamicConfigurationProvider).inSingletonScope();
  }));
});
