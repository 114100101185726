<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import { get } from "lodash";
import "components/cards/utilities";
import { BaseAtom } from "components/cards/atoms";
import { TextParticle, ImageParticle, } from "components/cards/particles/YinzCam";
export let container;
export let overlayPadding = undefined;
export let thumbnail;
export let txtTitle;
export let txtTextFieldNames;
export let colorStatBoxBackground;
export let statInField;
export let statBottomLeft;
export let statBottomRight;
export let playerStats = [];
let atomWidthRem;
$: txtGoalsMultiplier = atomWidthRem <= 30 ? "0.6" : "1.00";
$: txtFieldNameMultiplier = atomWidthRem <= 30 ? "0.8" : "1.00";
$: valInField = playerStats === null || playerStats === void 0 ? void 0 : playerStats.find((playerStat) => { var _a; return statInField === ((_a = playerStat === null || playerStat === void 0 ? void 0 : playerStat.name) === null || _a === void 0 ? void 0 : _a.text); });
$: valBottomLeft = playerStats === null || playerStats === void 0 ? void 0 : playerStats.find((playerStat) => { var _a; return statBottomLeft === ((_a = playerStat === null || playerStat === void 0 ? void 0 : playerStat.name) === null || _a === void 0 ? void 0 : _a.text); });
$: valBottomRight = playerStats === null || playerStats === void 0 ? void 0 : playerStats.find((playerStat) => { var _a; return statBottomRight === ((_a = playerStat === null || playerStat === void 0 ? void 0 : playerStat.name) === null || _a === void 0 ? void 0 : _a.text); });
</script>

<BaseAtom
    _isotope="ShotAccuracyAtom"
    {...container}
    _cssVariablePrefix="shot-accuracy"
    {overlayPadding}
    {colorStatBoxBackground}
    bind:_clientWidthRem={atomWidthRem}
>
    <div class="main-container">
        <div class="header-text">
            <TextParticle {...txtTitle} />
        </div>
        <div class="image-text">
            <div class="ground-image">
                <ImageParticle {...thumbnail} _block={true} />
            </div>
            <div class="goals-in-box">
                <TextParticle
                    {...valInField?.homeValue}
                    size={`calc(${get(valInField, "homeValue.size")}*${txtGoalsMultiplier})`}
                />
                <TextParticle
                    {...txtTextFieldNames}
                    text={valInField?.name?.text}
                    size={`calc(${get(txtTextFieldNames, "size")}*${txtFieldNameMultiplier})`}
                />
            </div>
        </div>
        <div class="other-stats">
            <div class="goals-outside-box">
                <TextParticle
                    {...valBottomLeft?.homeValue}
                    size={`calc(${get(valBottomLeft, "homeValue.size")}*${txtGoalsMultiplier})`}
                />
                <TextParticle
                    {...txtTextFieldNames}
                    text={valBottomLeft?.name?.text}
                    size={`calc(${get(txtTextFieldNames, "size")}*${txtFieldNameMultiplier})`}
                />
            </div>
            <div class="freekick-goals">
                <TextParticle
                    {...valBottomRight?.homeValue}
                    size={`calc(${get(valBottomRight, "homeValue.size")}*${txtGoalsMultiplier})`}
                />
                <TextParticle
                    {...txtTextFieldNames}
                    text={valBottomRight?.name?.text}
                    size={`calc(${get(txtTextFieldNames, "size")}*${txtFieldNameMultiplier})`}
                />
            </div>
        </div>
    </div>
</BaseAtom>

<style>
    .main-container {
        padding: var(--shot-accuracy-overlay-padding);
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        align-items: center;
        border-radius: 0.75rem;
    }
    .header-text {
        text-align: center;
    }
    .image-text {
        display: grid;
        align-items: center;
        justify-items: center;
        place-items: center;
        position: relative;
    }
    .image-text > * {
        grid-row: 1;
        grid-column: 1;
    }
    .goals-in-box {
        background-color: var(--theme-mode-background-color, #fff);
        padding: 0.75rem 0.8125rem;
        width: 60%;
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
    }
    .goals-in-box,
    .goals-outside-box,
    .freekick-goals {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        background-color: var(
            --shot-accuracy-color-stat-box-background,
            var(--theme-mode-background-color, #fff)
        );
    }
    .other-stats {
        display: flex;
        justify-content: space-between;
        gap: 0.75rem;
        width: 100%;
    }
    .goals-outside-box,
    .freekick-goals {
        flex-grow: 1;
        border-radius: 0.75rem;
        padding: 0.75rem 0.8125rem;
        border: 1px solid var(--theme-mode-accent-color, #d9d9d9);
    }</style>
