<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import "lodash";
import "components/cards/utilities";
import { BaseAtom } from "components/cards/atoms";
import { TextParticle, ImageParticle, ButtonParticle, } from "components/cards/particles/YinzCam";
import Axios from "axios";
import { t } from "js/i18n";
export let container;
export let overlayPadding;
export let imgTeamIcon;
export let txtHeading;
export let txtBody;
export let imgSubmitLogo;
export let btnLater;
export let btnSubscribe;
export let isLogoRequired;
export let isButtonsRequired;
let atomWidthRem;
let value;
async function handleSubmitClick() {
    if (!(value === null || value === void 0 ? void 0 : value.trim())) {
        return;
    }
    const _value = value;
    value = '';
    if (CONFIG.league === 'fa' && CONFIG.tricode === 'bha') {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `https://7twmzoj4ujgiztdsyd6tun7vl40ivgod.lambda-url.us-east-1.on.aws/?email=${_value}`,
            headers: {}
        };
        try {
            const rsp = await Axios.request(config);
            //console.log(JSON.stringify(rsp.data));
            alert($t('Your email has been submitted.'));
        }
        catch (e) {
            console.error(e);
            alert($t('Error submitting your email. Check that your email address is valid and try again.'));
        }
    }
}
function handleKeypress(e) {
    if (e.key === 'Enter') {
        handleSubmitClick();
    }
}
</script>

<BaseAtom
    _isotope="SubscribeNewsletter"
    _cssVariablePrefix="sub-news"
    {...container}
    {overlayPadding}
    bind:_clientWidthRem={atomWidthRem}
>
    <div class="main-container">
        <div class="icon-text-box">
            {#if isLogoRequired}
                <div class="icon-box">
                    <ImageParticle {...imgTeamIcon} />
                </div>
            {/if}
            <div class="text-box">
                <TextParticle {...txtHeading} />
            </div>
        </div>
        <div class="input-icon-box">
            <input type="text" class="input-box" bind:value on:keydown={handleKeypress} />
            <div class="submit-box">
                <ButtonParticle {...imgSubmitLogo} on:click={handleSubmitClick} />
            </div>
        </div>
        <div class="buttons-box">
            <div>
                <TextParticle {...txtBody} />
            </div>
            {#if isButtonsRequired}
                <div class="subscribe-buttons">
                    <ButtonParticle {...btnLater} />
                    <ButtonParticle {...btnSubscribe} on:click={handleSubmitClick} />
                </div>
            {/if}
        </div>
    </div>
</BaseAtom>

<style>
    .main-container {
        padding: var(--sub-news-overlay-padding, unset);
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
    }
    .icon-text-box {
        display: flex;
        align-items: center;
    }
    .text-box {
        display: flex;
        flex-direction: column;
    }
    .input-icon-box {
        position: relative;
        display: flex;
        width: 100%;
    }
    .input-box {
        background-color: var(--theme-mode-foreground-color, #fff);
        border-radius: 0.375rem;
        border: 1px solid #d9d9d9;
        padding: 0 3.5rem 0 1rem;
        width: 100%;
    }
    .buttons-box {
        display: flex;
        gap: 0.5rem;
        justify-content: space-between;
    }
    .submit-box {
        background-color: var(--theme-mode-accent-color, #4ce0af);
        align-self: center;
        text-align: center;
        margin-left: -1rem;
        border-radius: 0rem 0.375rem 0.375rem 0rem;
    }
    .subscribe-buttons{
        display: flex;
    }</style>