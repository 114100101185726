import { generateArraySchema, generateRootObjectSchema, generateStringSchema, generateUrlSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { AuthorParticleSpec, ButtonParticleSpec, ShareStripParticleSpec, TextParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("Article Footer Card", generateRootObjectSchema({
  container: generateAtomContainerSchema({
    defaultValues: { themeMode: 'primary' }
  }),
  slug: generateStringSchema("Slug", "The slug for this media item. This will be used to generate the media item link if an explicit link is not provided."),
  link: generateUrlSchema("Link", "The link for this media item. The default link will be generated from the slug if this is not provided (and the slug is)."),
  titleText: TextParticleSpec.generateSchema("Title Text", "The title text of the article.", {
    defaultValues: { size: '3rem', weight: '700' },
  }),
  authorText: TextParticleSpec.generateSchema("Author Text", "The text for the author prompt.", {
    defaultValues: { size: '1.125rem', weight: '600', text: "Author" },
  }),
  promptText: TextParticleSpec.generateSchema("Prompt Text", "The text for the share prompt.", {
    defaultValues: { size: '1.125rem', weight: '600', text: "Share this post" },
  }),
  categories: generateArraySchema("Categories", "The categories to list for the media item.",
    ButtonParticleSpec.generateSchema("Category", "A category for the media item.", {
      defaultValues: { showImage: false, container: { background: { visible: true, color: '#E4E4E4' }, border: { visible: true, radius: '8px' }, padding: '0.25rem 0.75rem' }, text: { size: '0.875rem;', weight: '600' } }
    })
  ),
  author: AuthorParticleSpec.generateSchema("Author", "The author of the article.", {
    defaultValues: { },
  }),
  socialLinks: ShareStripParticleSpec.generateSchema("Social Links", "The social links for the article.", {
    defaultValues: { },
    uneditableFields: { link: true }
  }),
}));
