import _ from 'lodash';

export function afterUpdateDebounce(func: () => void) {
  return _.debounce(func, CONFIG.afterUpdateDebounceIntervalMilliseconds, {
    leading: true,
    trailing: true,
    maxWait: CONFIG.afterUpdateDebounceMaxWaitMilliseconds
  });
}

let _objectIdCounter: number = 1;
const idMap: WeakMap<WeakKey, number> = new WeakMap<WeakKey, number>();
export function getObjectId(object: WeakKey): number {
  const objectId: number | undefined = idMap.get(object);
  if (objectId === undefined) {
    _objectIdCounter += 1;
    idMap.set(object, _objectIdCounter);
    return _objectIdCounter;
  }
  return objectId;
}

export function isVerticalScrollable(node: HTMLElement) {
  const style = getComputedStyle(node);
  return ['overflow'/*, 'overflow-x'*/, 'overflow-y'].some((propertyName) => {
    const value = style.getPropertyValue(propertyName);
    //console.log({ node, propertyName, value });
    return value === 'auto' || value === 'scroll';
  });
}

export function getVerticalScrollParent(node: HTMLElement): HTMLElement {
  let currentParent = node.parentElement;
  while (currentParent) {
    if (isVerticalScrollable(currentParent)) {
      return currentParent;
    }
    currentParent = currentParent.parentElement;
  }
  return document.scrollingElement as HTMLElement || document.documentElement;
}


/**
 * Returns the scroll target position for scrolling to the top of an element.
 *
 * Pass the result to a scrollTo method:
 * 
 * - $scrollContainer.scrollTop( result ) for instant scrolling
 * - $scrollContainer.scrollTo( result ) for animated scrolling with jQuery.scrollable
 *
 * @param   {HTMLElement} target           the target HTML element
 * @param   {HTMLElement} scrollContainer  a scrollable HTML element
 * @param   {number} [offset=0]       additional distance, inserted between the container and the top of the target
 * @returns {number}
 */
export function getScrollTargetPosition(target: HTMLElement, scrollContainer: HTMLElement, offset: number) {
  let containerBorderTopWidth, containerTop = 0;

  // The scroll container is an HTML element, not a window. Get its position, relative to the window.
  //
  // - We need to exclude the border of the container element because it doesn't scroll. (By contrast,
  //   padding does scroll.)
  // - We fetch the top border width with clientTop if available (should be supported universally, but we'd
  //   better be safe).
  containerBorderTopWidth = scrollContainer.clientTop;
  //console.log('containerBorderTopWidth', containerBorderTopWidth);
  if (containerBorderTopWidth === undefined)
    containerBorderTopWidth = parseFloat(getComputedStyle(scrollContainer).getPropertyValue("borderTopWidth"));
  containerTop = scrollContainer.getBoundingClientRect().top + containerBorderTopWidth;

  // Get the targeted scroll position, based on the top of the target element.
  return scrollContainer.scrollTop + target.getBoundingClientRect().top - containerTop - ( offset || 0 );
}