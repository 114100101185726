import { generateBooleanSchema, generateRootObjectSchema, generateColorSchema, generateStringSchema, generateUrlSchema, generateArraySchema, generateObjectSchema, generateNumberSchema } from "components/cards/common/schema";
import { BaseParticleSpec, GetParticlePropsType, GetParticleSchemaType, generateParticleContainerSchema } from "components/cards/particles/BaseParticleSpec";
import { ImageParticleSpec } from "../../Core/Image/spec";
import { TextParticleSpec } from "../../Core/Text/spec";
import { ButtonParticleSpec } from "../../Core";

export const DropDownParticleSpec = new BaseParticleSpec("DropDown Particle", generateRootObjectSchema({
    container: generateParticleContainerSchema({
        defaultValues: { themeMode: 'primary' },
        uneditableFields: { background: false }
    }),
    overlayPadding: generateStringSchema("Overlay Padding", "The padding (inset) of the overlay text/buttons relative to the background image.", {
        defaultValue: '4.31rem 2.25rem'
    }),
    txtListTitle: TextParticleSpec.generateSchema("List Title", "Enter a title for the list", {
        defaultValues: { size: "1.25rem", weight: "600", lineClamp: 1 }
    }),
    titleCharacters: generateNumberSchema("Title Characters Length", "Title Characters Length"),
    btnChevron: ButtonParticleSpec.generateSchema("Click Image", "Upload image/icon for click button", {
        defaultValues: {
            image: {
                container: {
                    width: "2rem",
                }, icon: "yc_icon_chevron_down"
            }
        }
    }),
    btnClearIcon: ButtonParticleSpec.generateSchema("Clear Icon", "Clear Icon", {
        defaultValues: {
            image: {
                container: {
                    width: "2rem",
                }, icon: "yc_icon_close"
            }
        }
    }),
    listOptionText: TextParticleSpec.generateSchema("List Option Model", "Enter the list option", {
        defaultValues: { size: "1.1rem", weight: "600", lineClamp: 1 }, uneditableFields: { text: true }
    }),
    txtOptions: generateArraySchema("List Options", "Enter the list options",
        generateObjectSchema("Option and Value", "Option and Value", {
            option: generateStringSchema("List Option", "List option to be displayed"),
            value: generateStringSchema("List Value", "Value to be updated"),
        })
    ),
    colorBorder: generateColorSchema("Border Color", "Pick a color for border"),
    colorDropdownBackground: generateColorSchema("Dropdown Content Background", "Dropdown Content Background"),
    paramsName: generateStringSchema("Params name", "Params Name", {
        defaultValue: "seasonStartYear"
    }),
    showSelectedOption: generateBooleanSchema("Show Selected Option???", "", {
        defaultValue: true
    }),
}));

export const specification = DropDownParticleSpec;

export type DropDownParticleSchema = GetParticleSchemaType<typeof DropDownParticleSpec>;

export type DropDownParticleProps = GetParticlePropsType<typeof DropDownParticleSpec>;
