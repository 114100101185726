<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import "lodash";
import { DateTime } from "luxon";
import { BaseAtom } from "components/cards/atoms";
import { TextParticle, ImageParticle, } from "components/cards/particles/YinzCam";
export let container;
export let imgLeaguelogo;
export let imgOpponentLogo;
export let txtNextMatch;
export let txtMatchDate;
export let txtOpponentTricode;
export let txtHomeOrAwayCode;
export let optionHomeOrAway;
export let matchDateTime;
export let txtGreaterThanSymbol;
let atomWidthRem;
const formatDateTime = (dateString) => {
    var _a;
    const dt = DateTime.fromISO(dateString);
    const convertedDateTime = (_a = dt
        .toLocaleString(Object.assign(Object.assign({}, DateTime.DATETIME_FULL), { weekday: "short" }))) === null || _a === void 0 ? void 0 : _a.split(" ");
    return convertedDateTime === null || convertedDateTime === void 0 ? void 0 : convertedDateTime.map((string) => string.trim().replace(",", ""));
};
$: isMobile = atomWidthRem <= 30;
$: isTablet = atomWidthRem <= 50 && atomWidthRem > 30;
$: homeOrAway = optionHomeOrAway === "home" ? "(H)" : "(A)";
$: [day, month, date, , , time, dayPeriod, timeZone] =
    formatDateTime(matchDateTime);
$: console.log({ day, month, date });
</script>

<BaseAtom
    _isotope="NextMatchAtom"
    _cssVariablePrefix="next-match"
    {...container}
    bind:_clientWidthRem={atomWidthRem}
>
    <div
        class="next-match-container"
        class:layout-tablet-width={isTablet}
        class:layout-mobile-width={isMobile}
    >
        <div class="competition-logo">
            <ImageParticle {...imgLeaguelogo} _block={true} />
        </div>
        <div class="next-match-date">
            <TextParticle {...txtNextMatch} />
            <TextParticle {...txtMatchDate} text={`${day} ${date} ${month}`} />
        </div>
        <div class="opponent-logo">
            <ImageParticle {...imgOpponentLogo} _block={true}/>
        </div>
        <div class="opponent-tricode">
            <TextParticle {...txtOpponentTricode} />
        </div>
        <div class="home-or-away">
            <TextParticle {...txtHomeOrAwayCode} text={homeOrAway} />
        </div>
        <div class="symbol-gt">
            <TextParticle {...txtGreaterThanSymbol}/>
        </div>
    </div>
</BaseAtom>

<style>
    .next-match-container{
        display: flex;
        align-items: center;
        justify-content: space-around;
        text-align: center;
        gap: 0.5rem;
    }
    /* .symbol-gt{
        font-size: 1.5rem;
        font-weight: 700;
        color: var(--theme-mode-accent-color, #164194);
    } */</style>
