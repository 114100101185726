import { Container } from "inversify";
import { TokenContainerModule } from "inversify-token";
import { YinzCamInjectModule } from "yinzcam-inject";
import { YinzCamCardsServerV1, YinzCamCardsServerV1Token } from "./YinzCamCardsServerV1";
import { YinzCamCardsServerV2, YinzCamCardsServerV2Token } from "./YinzCamCardsServerV2";
import { YinzCamCardsService, YinzCamCardsServiceToken } from "./YinzCamCardsService";

export let YinzCamCardsServerModule: YinzCamInjectModule = new YinzCamInjectModule((container: Container): void => {
  container.load(new TokenContainerModule((bindToken) => {
    bindToken<YinzCamCardsServerV1>(YinzCamCardsServerV1Token).to(YinzCamCardsServerV1).inSingletonScope();
    bindToken<YinzCamCardsServerV2>(YinzCamCardsServerV2Token).to(YinzCamCardsServerV2).inSingletonScope();
    bindToken<YinzCamCardsService>(YinzCamCardsServiceToken).to(YinzCamCardsService).inSingletonScope();
  }));
});
