<div class="cards-editor-edit-bar">
  <div class="cards-editor-layers-header">
    <div class="popup-title">Page Layers</div>
  </div>
  <div class="cards-editor-layers-selector">
    <Treeview>
      <CardsEditorLayerTree layer={$rootComponentLayerStore} />
    </Treeview>
  </div>
  {#if editorModeEnabled}
  {#if $editorOptionsStore}
  <div class="cards-editor-window-content" bind:this={windowRoot}>
    <div class="cards-editor-component-edit-control-bar theme-dark">
      <Menu>
        <div class="popup-title">{title}</div>
        <div style="flex-grow: 1"></div>
        <MenuItem on:click={onClose}>
          <div class="save-and-close-button">
            Save and Close
          </div>
        </MenuItem>
      </Menu>
    </div>
    <CardsEditorComponentEditPopup options={$editorOptionsStore} />
  </div>
  {/if}
  {/if}
</div>

<style>
  *, *::before, *::after {
    box-sizing: border-box;
  }

  .cards-editor-edit-bar {
    font-family: 'SF UI Display';
    background: white;
    color: black;
    padding-top: 13px;
    z-index: 999;
    height: 100%;

    display: flex;
    flex-direction: column;
    
    --f7-menu-bg-color: black;
    --f7-menu-text-color: white;
    --f7-menu-font-size: 12px;
    --f7-menu-item-height: 32px;
    /*--f7-treeview-item-padding-left: 5px;*/
    --f7-treeview-children-offset: 15px;
    --f7-treeview-item-height: 28px;
  }

  .cards-editor-layers-header {
    padding-left: 20px;
    padding-bottom: 20px;
  }

  .cards-editor-edit-bar-empty-content {
    width: 100%;
    text-align: center;
    padding: 20px 5px 0px 5px;
    font-style: italic;
    color: black;
    flex: 0 0 auto;

    display: none;
  }

  .cards-editor-layers-selector {
    overflow: auto;
    flex: 1 1 auto;
    /*
    flex: 0 0 fit-content;
    min-height: 0;
    max-height: 30vh;
    */
    padding-bottom: 10px;
  }

  .cards-editor-layers-selector :global(.treeview-item-selectable:not(.treeview-item-selected):hover) {
    background-color: rgba(0, 122, 255, 0.7);
    color: rgba(255, 255, 255, 1.0);
  }

  .cards-editor-layers-selector :global(.treeview-item-selectable:not(.treeview-item-selected):hover .treeview-toggle::after) {
    border-left-color: rgba(255, 255, 255, 1.0);
  }

  .cards-editor-layers-selector :global(.treeview-item-selected.treeview-item-root) {
    background-color: rgba(0, 122, 255, 1.0);
    color: rgba(255, 255, 255, 1.0);
    font-weight: bold;
  }

  .cards-editor-layers-selector :global(.treeview-item-selected .treeview-toggle::after) {
    border-left-color: rgba(255, 255, 255, 1.0);
  }

  .cards-editor-layers-selector :global(.treeview-toggle), .cards-editor-layers-selector :global(.treeview-item-content > * + *) {
    margin: 0;
  }


  .cards-editor-component-edit-control-bar {
    width: 100%;
    background-color: white;
    color: black;
    padding: 15px 13px 5px 15px;
    position: sticky;
    position: -webkit-sticky;
    top: 0px;
    z-index: 999;

    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    
    --f7-menu-bg-color: #5cb85c;
    --f7-menu-text-color: white;
    --f7-menu-font-size: 13px;
    --f7-menu-item-height: 25px;
  }

  .popup-title {
    font-size: 1.15rem;
    padding-right: 5px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .save-and-close-button {
    width: 100%;
    height: 100%;
    font-weight: bold;
    padding: 0px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cards-editor-component-edit-control-bar :global(.menu) {
    width: 100%;
  }

  .cards-editor-component-edit-control-bar :global(.menu-item:has(.save-and-close-button)) {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0,0,0,0.24);
  }

  :global(.cards-editor-edit-bar-window .wb-icon) {
    -webkit-filter: invert();
            filter: invert();
  }

  :global(.cards-editor-edit-bar-window .wb-header) {
    background-color: rgba(0, 122, 255, 1.0);
  }

  :global(.cards-editor-edit-bar-window .wb-title) {
    font-weight: bold;
  }

  .cards-editor-layers-selector :global(.treeview-item-content:first-child) {
    margin-left: calc(var(--f7-treeview-toggle-size) + 0px);
  }</style>

<script lang="ts">import { Menu, MenuItem, Treeview } from 'framework7-svelte';
import CardsEditorLayerTree from './CardsEditorLayerTree.svelte';
import { CardsPageContextKey } from './context';
import { getContext } from 'svelte';
import CardsEditorComponentEditPopup from './CardsEditorComponentEditPopup.svelte';
const pageContext = getContext(CardsPageContextKey);
const editorModeManager = pageContext.editorModeManager;
const editorOptionsStore = editorModeManager.getSelectedComponentEditorOptionsStore();
const rootComponentLayerStore = editorModeManager.getRootComponentLayerStore();
const editorModeEnabled = editorModeManager.getEnabledStore();
// closePopup tells the selected component to unselect itself
$: closePopup = ($editorOptionsStore === null || $editorOptionsStore === void 0 ? void 0 : $editorOptionsStore.closePopup) || (() => { });
$: title = ($editorOptionsStore === null || $editorOptionsStore === void 0 ? void 0 : $editorOptionsStore.title) || '';
let winbox = null;
let windowRoot = null;
$: if ($editorModeEnabled && $editorOptionsStore && !winbox && windowRoot) {
    winbox = new WinBox({
        class: "cards-editor-edit-bar-window no-full",
        icon: "https://static.yinzcam.com/images/disco/disco.svg",
        mount: windowRoot,
        width: 350,
        height: 700,
        y: 50,
        x: window.innerWidth - 400,
        title: title,
        index: 10000,
        onclose: function () {
            closePopup();
            winbox = null;
            windowRoot = null;
            return false;
        }
    });
}
$: if (!$editorModeEnabled && winbox) {
    winbox.close();
}
function onClose() {
    if (winbox) {
        winbox.close();
    }
}
</script>
