import { generateRootObjectSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { ButtonParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("Logout Button Card", generateRootObjectSchema({
  container: generateAtomContainerSchema(),
  defaultButton: ButtonParticleSpec.generateSchema("Default Button", "The default button styling properties."),
  loggedOutButton: ButtonParticleSpec.generateSchema("Logged Out Button", "The button to show when the user is logged out."),
  loggedInButton: ButtonParticleSpec.generateSchema("Logged In Button", "The button to show when the user is logged in."),
}));
