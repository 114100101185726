import { YinzCamSoccerAppServer } from './YinzCamSoccerAppServer';
import { injectable } from 'inversify';
import { YinzCamAPI } from 'yinzcam-api';
import { register } from 'yinzcam-registry';
import { APP_SERVER_REGISTRY } from './constants';

@register(APP_SERVER_REGISTRY, 'mls')
export class YinzCamMLSAppServer extends YinzCamSoccerAppServer {
  public constructor(api: YinzCamAPI) {
    super(api);
  }
}
