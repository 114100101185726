<script lang="ts">import Swiper from "swiper";
import { onMount, createEventDispatcher } from "svelte";
export let maxDigit = undefined;
let swiperEl;
let swiper;
const dispatch = createEventDispatcher();
onMount(async () => {
    swiper = new Swiper(swiperEl, {
        slidesPerView: 1,
        spaceBetween: 10,
        loop: true,
        direction: "vertical",
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });
    dispatch("swiper_init", {
        swiper,
    });
});
</script>

<div class="digit">
  <div class="swiper mySwiper" bind:this={swiperEl}>
    <div class="swiper-wrapper">
      {#each [...Array(maxDigit ? maxDigit : 10).keys()] as i}
        <div class="swiper-slide">{i}</div>
      {/each}
    </div>
  </div>
</div>

<style lang="scss">*,
*:before,
*:after {
  box-sizing: border-box; }

.digit {
  background-color: var(--theme-primary-background-color, white);
  border-radius: 4px;
  padding: 0px 10px; }

.mySwiper {
  height: 47.8px;
  overflow: hidden; }

.swiper-slide {
  line-height: 47.8px;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--theme-primary-foreground-color, black); }

@media (max-width: 767px) {
  .digit {
    background-color: var(--theme-primary-background-color, white);
    border-radius: 4px;
    padding: 0px 6.5px; }
  .mySwiper {
    height: 34px; }
  .swiper-slide {
    line-height: 34px;
    font-size: 16px; } }</style>
