// Based on the ES6 "Factory Pattern" concept here: https://medium.com/@SntsDev/the-factory-pattern-in-js-es6-78f0afad17e9
// TypeScript Generics: https://www.typescriptlang.org/docs/handbook/generics.html
export class Registry<T> {
  private name: string;
  // The funny new(...) => T syntax just means any type with a constructor that takes any number of arguments.
  // Fancy way of saying we don't care about what arguments the constructor takes, because we just pass them through in create().
  private types: Map<string, new (...args: any[]) => T>;

  constructor(name: string) {
    this.name = name;
    this.types = new Map();
  }

  // In Java I would express U as "? extends T" without the additional type parameter.
  // TS doesn't support this: https://stackoverflow.com/questions/48029375/typescript-generics-equivalent-of-javas-extends-myclass
  register<U extends T>(key: string, clazz: new (...args: any[]) => U) {
    if (this.types.has(key)) {
      let msg = `Registry[${this.name}]: Key '${key}' already exists in this registry.`;
      console.log(msg);
      throw new Error(msg);
    }
    this.types.set(key, clazz);
  }

  create(key: string, ...options: any[]) {
    if (!this.types.has(key)) {
      let msg = `Registry[${this.name}]: Key '${key}' does not exist in this registry.`;
      console.log(msg);
      throw new Error(msg);
    }
    let clazz = this.types.get(key);
    let instance = new clazz(...options);
    return instance;
  }
}
