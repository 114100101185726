import { AbstractTemporalCachedReactiveMicrocomponent } from 'yinzcam-rma';
import { ContentfulAPIConfig } from './ContentfulAPIConfig';
import { ContentfulCDNMetadata } from './ContentfulCDNMetadata';
import { CONTENT_TYPE_CONTENT_TYPE, ContentfulClient } from './ContentfulClient';


export class ContentfulCDNMetadataComponent extends AbstractTemporalCachedReactiveMicrocomponent<ContentfulCDNMetadata, []> {
  private readonly apiConfig: ContentfulAPIConfig;
  private readonly contentful: ContentfulClient;

  public constructor(name: string, apiConfig: ContentfulAPIConfig, contentful: ContentfulClient) {
    super(name, apiConfig);
    this.apiConfig = apiConfig;
    this.contentful = contentful;
  }

  protected override async execUpdate(now: number): Promise<ContentfulCDNMetadata> {
    const ret: ContentfulCDNMetadata = {
      contentTypeMetadata: {}
    };
    const entries = await this.contentful.getEntries({
      'content_type': CONTENT_TYPE_CONTENT_TYPE
    });
    for (const entry of entries.items) {
      ret.contentTypeMetadata[entry.fields['slug'] as string] = {
        name: entry.fields['name'] as string,
        slug: entry.fields['slug'] as string,
        timeToLive: entry.fields['timeToLive'] as number,
        depth: entry.fields['depth'] as number,
        mixed: entry.fields['mixed'] as boolean,
        mixedContentTypes: entry.fields['mixedContentTypes'] as any[],
        filterByLanguageTag: entry.fields['filterByLanguageTag'] as boolean,
      }
    }
    const contentTypeContentTypeMetadata = ret.contentTypeMetadata[CONTENT_TYPE_CONTENT_TYPE];
    if (contentTypeContentTypeMetadata?.timeToLive) {
      this.state.ttl = contentTypeContentTypeMetadata?.timeToLive;
    }
    return ret;
  }
}
