import { Readable, Writable } from "svelte/store";
import { DIYCardObject, DIYCardObjectControls } from "./schema";

export const DIYCardContextKey = {};

export interface DIYCardContext {
  editorModeEnabled: Readable<boolean>;
  lastCanvasClickedItemId: Writable<string>;
  selectedItemControls: Writable<DIYCardObjectControls>;
  refresh: () => void;
}
