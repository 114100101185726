import { generateRootObjectSchema, generateArraySchema, generateObjectSchema, generateColorSchema, generateStringSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { ButtonParticleSpec, TextParticleSpec } from "components/cards/particles/YinzCam";

import { statsNameList } from "components/cards/utilities/constants";

export const specification = new BaseAtomSpec("Circle Graph Card", generateRootObjectSchema({
    container: generateAtomContainerSchema(),
    statsListMax: generateStringSchema("Select Stat for Max Bar", "Select Stat to display", {
        choices: statsNameList,
        choiceTitles: statsNameList,
    }),
    statsListProgress: generateStringSchema("Select Stat for Progress Bar", "Select Stat to display", {
        choices: statsNameList,
        choiceTitles: statsNameList,
    }),
    playerStats: generateArraySchema("-NA-", "Specify the number of cards to be created",
        generateObjectSchema("Circle Card", "Enter Stat Name and Value", {
            name: TextParticleSpec.generateSchema("Stat Name", "Enter stat name", {
                defaultValues: { size: "1.25rem", weight: "600" }
            }),
            homeValue: TextParticleSpec.generateSchema("Stat Value", "Enter stat value", {
                defaultValues: { size: "2.5rem", weight: "900" }
            }),
            successRate: TextParticleSpec.generateSchema("Success Rate", "Success Rate", {
                defaultValues: { text: "Success Rate", size: "1.25rem", weight: "600" }, uneditableFields: { text: true }
            }),
        })),
    progressColor: generateColorSchema("Progress Bar Color", "Pick a color for progress bar"),
    graphFillColor: generateColorSchema("Graph Fill Color", "Pick a color to fill the graph"),
    borderColor: generateColorSchema("Border Color", "Pick a color for text stat"),
    graphVariant: generateStringSchema("Select computation", "Computation to be displayed in circle graph", {
        choices: ["progress-vs-max", "progress-vs-total"],
        choiceTitles: ["Progress Vs. Max", "Progress Vs. Total"],
        defaultValue: "progress-vs-max",
    }
    ),
    circleBackgroundColor: generateColorSchema("Circle Background Color", "Circle Background Color")
}));