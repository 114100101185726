import { injectable, Container } from "inversify";
import { injectToken, getToken, Token, TokenContainerModule } from "inversify-token";
import { YinzCamAPIRequestParameters, MergedYinzCamAPIRequestParameterComponent, YinzCamAPIRequestParameterComponentToken } from "yinzcam-api";
import { ManualPassthrough, ReactiveMicrocomponent } from "yinzcam-rma";
import { YinzCamInjectModule } from 'yinzcam-inject';
import { getDeviceId } from './stores';
import _ from "lodash";
import { Readable } from "svelte/store";

export const JanusModeContextManagerToken = new Token<JanusModeContextManager>(Symbol.for("JanusModeContextManager"));

export let JanusModeContextManagerModule: YinzCamInjectModule = new YinzCamInjectModule((container: Container): void => {
  container.load(new TokenContainerModule((bindToken) => {
    bindToken(JanusModeContextManagerToken).to(JanusModeContextManager).inSingletonScope();
    bindToken(YinzCamAPIRequestParameterComponentToken).toDynamicValue((context) => getToken(context.container, JanusModeContextManagerToken).getMergedRequestParameterComponent());
  }));
});

@injectable()
export class JanusModeContextManager {

  private static getParamObject(param: string, value?: string): YinzCamAPIRequestParameters {
    if (!value) {
      return { };
    }
    return { params: { [param]: value } };
  }

  private static getHeaderObject(key: string, value?: string): YinzCamAPIRequestParameters {
    if (!value) {
      return { };
    }
    return { headers: { [key]: value } };
  }

  private readonly modeInput: ManualPassthrough<string>;
  private readonly modeParamInput: ManualPassthrough<YinzCamAPIRequestParameters>;
  private readonly compIdInput: ManualPassthrough<string>;
  private readonly compIdParamInput: ManualPassthrough<YinzCamAPIRequestParameters>;
  private readonly teamIdInput: ManualPassthrough<string>;
  //private readonly teamNameInput: ManualPassthrough<string>;
  private readonly countryIdInput: ManualPassthrough<string>;
  private readonly langInput: ManualPassthrough<string>;
  private readonly langHeaderParamInput: ManualPassthrough<YinzCamAPIRequestParameters>;
  private readonly langQueryParamInput: ManualPassthrough<YinzCamAPIRequestParameters>;
  private readonly deviceIdParamInput: ManualPassthrough<YinzCamAPIRequestParameters>;
  private readonly mergedParamComponent: MergedYinzCamAPIRequestParameterComponent;

  public constructor() {
    this.modeInput = new ManualPassthrough('JanusModeContextManager_modeInput', CONFIG.defaultModeName);
    this.modeParamInput = new ManualPassthrough('JanusModeContextManager_modeParamInput', JanusModeContextManager.getParamObject('mode', CONFIG.defaultModeName));
    this.compIdInput = new ManualPassthrough('JanusModeContextManager_compIdInput', CONFIG.defaultCompetitionId);
    this.compIdParamInput = new ManualPassthrough('JanusModeContextManager_compIdParamInput', JanusModeContextManager.getParamObject('compId', CONFIG.defaultCompetitionId));
    this.teamIdInput = new ManualPassthrough('JanusModeContextManager_teamIdInput', '');
    //this.teamNameInput = new ManualPassthrough('JanusModeContextManager_teamNameInput', '');
    this.countryIdInput = new ManualPassthrough('JanusModeContextManager_countryIdInput', CONFIG.defaultCountryId);
    // Leaving team ID out intentionally because some endpoints override their behavior automatically if a team ID is specified
    let initialLang = this.getClosestAvailableLanguage(navigator.language.toLowerCase().split("-", 2)[0]);
    this.langInput = new ManualPassthrough('JanusModeContextManager_langInput', initialLang);
    // TODO: Merge the two below
    this.langHeaderParamInput = new ManualPassthrough('JanusModeContextManager_langHeaderParamInput', JanusModeContextManager.getHeaderObject('Accept-Language', initialLang));
    this.langQueryParamInput = new ManualPassthrough('JanusModeContextManager_langQueryParamInput', JanusModeContextManager.getParamObject('language', initialLang));
    this.deviceIdParamInput = new ManualPassthrough('JanusModeContextManager_deviceIdParamInput', JanusModeContextManager.getParamObject('deviceId', getDeviceId()));
    
    // Build the merged parameter component
    let inputs = [ this.modeParamInput, this.compIdParamInput, this.langHeaderParamInput, this.langQueryParamInput, this.deviceIdParamInput ];
    inputs = inputs.filter(i => !_.isNil(i));
    this.mergedParamComponent = new MergedYinzCamAPIRequestParameterComponent({ name: 'JanusModeContextManager_MergedRequestParameters' }, ...inputs);
  }

  public setModeName(mode: string) {
    this.modeInput.setValue(mode);
    this.modeParamInput.setValue(JanusModeContextManager.getParamObject('mode', mode));
  }

  public getModeNameComponent(): ReactiveMicrocomponent<string> {
    return this.modeInput;
  }

  public setCompetitionId(compId: string) {
    this.compIdInput.setValue(compId);
    this.compIdParamInput.setValue(JanusModeContextManager.getParamObject('compId', compId));
  }

  public getCompetitionIdComponent(): ReactiveMicrocomponent<string> {
    return this.compIdInput;
  }

  public setTeamId(teamId: string) {
    this.teamIdInput.setValue(teamId);
  }

  public getTeamIdComponent(): ReactiveMicrocomponent<string> {
    return this.teamIdInput;
  }

  /*
  public setTeamName(teamName: string) {
    this.teamNameInput.setValue(teamName);
  }

  public getTeamNameComponent(): ReactiveMicrocomponent<string> {
    return this.teamNameInput;
  }
  */

  public setCountryId(countryId: string) {
    this.countryIdInput.setValue(countryId);
  }

  public getCountryIdComponent(): ReactiveMicrocomponent<string> {
    return this.countryIdInput;
  }

  public setLanguage(lang: string) {
    lang = this.getClosestAvailableLanguage(lang);
    this.langInput.setValue(lang);
    this.langHeaderParamInput.setValue(JanusModeContextManager.getHeaderObject('Accept-Language', lang));
    this.langQueryParamInput.setValue(JanusModeContextManager.getParamObject('language', lang));
  }

  public getLanguageComponent(): ReactiveMicrocomponent<string> {
    return this.langInput;
  }

  public getCurrentLanguageStore(): Readable<string> {
    return this.getLanguageComponent().store;
  }

  public getCurrentLanguage(): string {
    return this.langInput.getValue();
  }

  public getAvailableLanguages(): string[] {
    return Object.values(CONFIG.supportedLanguages);
  }

  public getClosestAvailableLanguage(lang: string): string {
    lang = lang.toLowerCase();
    if (this.getAvailableLanguages().indexOf(lang) >= 0) {
      return lang;
    }
    return CONFIG.defaultLanguage;
  }

  public getMergedRequestParameterComponent(): ReactiveMicrocomponent<YinzCamAPIRequestParameters> {
    return this.mergedParamComponent;
  }
}
