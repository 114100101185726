import { YinzCamAPI } from 'yinzcam-api';
import { register } from 'yinzcam-registry';
import { APP_SERVER_REGISTRY } from './constants';
import { YinzCamAppServer } from './YinzCamAppServer';

@register(APP_SERVER_REGISTRY, 'pga')
export class YinzCamPGAAppServer extends YinzCamAppServer {
  public constructor(api: YinzCamAPI) {
    console.log ('yinzcam pga server: ', api)
    super(api, undefined); //This is test. In future, the prefix should be removed.
  }
}
