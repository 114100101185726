<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import "lodash";
import { BaseAtom } from "components/cards/atoms";
import { CaptionedParticle, ImageParticle } from "components/cards/particles/YinzCam";
import "components/cards/particles/YinzCam/Media/Author/AuthorParticle.svelte";
export let container = undefined;
export let thumbnail = undefined;
export let caption = undefined;
</script>

<style>
  .article-lead-content-atom {
    width: 100%;
    height: 100%;
  }</style>

<BaseAtom _isotope="ArticleLeadContentAtom" {...container}>
  <div class="article-lead-content-atom">
    <CaptionedParticle _fillHeight={true} _fillWidth={true} {...caption}>
      <ImageParticle _block={true} {...thumbnail} />
    </CaptionedParticle>
  </div>
</BaseAtom>
