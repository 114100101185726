<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import { formatDateTimeRelative } from "components/cards/utilities";
import _, { isEqual, merge } from "lodash";
import { ButtonParticle, TextParticle, DropDownParticle, } from "components/cards/particles/YinzCam";
import { BaseAtom, getMergedParams, } from "components/cards/atoms";
import Axios from "axios";
import RichTextParticle from "components/cards/particles/YinzCam/Core/RichText/RichTextParticle.svelte";
import { resolveUrl } from "js/url";
import { t } from "js/i18n";
import "svelte/internal";
export let container;
export let feedUrl = undefined;
export let itemBoxPadding = undefined;
export let overlayPadding = undefined;
export let topLeftButton;
export let topRightButton;
export let titleText;
export let descriptionText;
export let ctaButton;
export let footerBackgroundColor;
export let footerText;
export let footerButton;
export let dropdownProps;
export let shouldAddBoxBorder;
export let boxBorderColor;
let atomWidthRem;
let dataPromise = null;
let postings = null;
let filterLocation = [];
let filterJobType = [];
let filterIndustry = [];
let filterSalary = [];
const mergedParams = getMergedParams();
const checkIfObjectExists = (arr, obj) => {
    return arr.some((ele) => isEqual(ele, obj));
};
const insertFilterOption = (arr, item, filterType) => {
    !checkIfObjectExists(arr, {
        option: item.getElementsByTagName(filterType)[0].textContent,
        value: item.getElementsByTagName(filterType)[0].textContent,
    }) &&
        arr.push({
            option: item.getElementsByTagName(filterType)[0].textContent,
            value: item.getElementsByTagName(filterType)[0].textContent,
        });
};
async function fetchData(feedUrl) {
    if (feedUrl) {
        dataPromise = Axios.get(feedUrl).then((rsp) => {
            const doc = new window.DOMParser().parseFromString(rsp.data, "text/xml");
            const items = Array.from(doc.getElementsByTagName("item"));
            postings = items.map((item, idx) => {
                insertFilterOption(filterLocation, item, "Location");
                insertFilterOption(filterJobType, item, "JobTypes");
                insertFilterOption(filterIndustry, item, "Industries");
                insertFilterOption(filterSalary, item, "Salary");
                return {
                    title: item.getElementsByTagName("title")[0].textContent,
                    link: item.getElementsByTagName("link")[0].textContent,
                    type: item.getElementsByTagName("JobTypes")[0].textContent,
                    industry: item.getElementsByTagName("Industries")[0].textContent,
                    description: item.getElementsByTagName("Description")[0].textContent,
                    salary: item.getElementsByTagName("Salary")[0].textContent,
                    location: item.getElementsByTagName("Location")[0].textContent,
                    industries: item.getElementsByTagName("Industries")[0].textContent,
                    livedate: formatDateTimeRelative(item.getElementsByTagName("LiveDate")[0].textContent),
                };
            });
            filterIndustry = [...new Set(filterIndustry)];
            filterLocation = [...new Set(filterLocation)];
            filterJobType = [...new Set(filterJobType)];
            filterSalary = [...new Set(filterSalary)];
            // return postings;
        });
    }
}
$: {
    if (feedUrl) {
        fetchData(feedUrl);
    }
}
$: isMobile = atomWidthRem <= 41;
$: isTablet = atomWidthRem <= 54 && atomWidthRem > 41;
$: titleCharacters = isMobile ? 10 : isTablet ? 40 : 50;
$: filteredPostings = postings === null || postings === void 0 ? void 0 : postings.filter((posting) => {
    let filIndustry = true;
    let filLocation = true;
    let filType = true;
    let filSalary = true;
    if ($mergedParams === null || $mergedParams === void 0 ? void 0 : $mergedParams.filterIndustry) {
        filIndustry = (posting === null || posting === void 0 ? void 0 : posting.industry) === ($mergedParams === null || $mergedParams === void 0 ? void 0 : $mergedParams.filterIndustry);
    }
    if ($mergedParams === null || $mergedParams === void 0 ? void 0 : $mergedParams.filterLocation) {
        filLocation = (posting === null || posting === void 0 ? void 0 : posting.location) === ($mergedParams === null || $mergedParams === void 0 ? void 0 : $mergedParams.filterLocation);
    }
    if ($mergedParams === null || $mergedParams === void 0 ? void 0 : $mergedParams.filterJobType) {
        filType = (posting === null || posting === void 0 ? void 0 : posting.type) === ($mergedParams === null || $mergedParams === void 0 ? void 0 : $mergedParams.filterJobType);
    }
    if ($mergedParams === null || $mergedParams === void 0 ? void 0 : $mergedParams.filterSalary) {
        filSalary = (posting === null || posting === void 0 ? void 0 : posting.salary) === ($mergedParams === null || $mergedParams === void 0 ? void 0 : $mergedParams.filterSalary);
    }
    return filIndustry && filLocation && filType && filSalary;
});
</script>

<BaseAtom
  bind:_clientWidthRem={atomWidthRem}
  _isotope="BlueOctopusCareersAtom"
  _cssVariablePrefix="boca"
  {...container}
  {overlayPadding}
  {itemBoxPadding}
  {footerBackgroundColor}
  {boxBorderColor}
>
  <div class="outer-container">
    <div class="left-panel"></div>
    <div class="right-panel">
      <div
        class="filter-container"
        class:layout-mobile-width={isMobile}
        class:layout-tablet-width={isTablet}
      >
        {#if filterIndustry?.length > 0}
          <div class="dropdown-item">
            <DropDownParticle
              {...merge({}, dropdownProps, {
                txtOptions: filterIndustry,
                isResultsYears: false,
                txtListTitle: { text: "Department" },
                paramsName: "filterIndustry",
                id: "filterIndustry",
                titleCharacters,
              })}
            />
          </div>
        {/if}
        {#if filterJobType?.length > 0}
          <div class="dropdown-item">
            <DropDownParticle
              {...merge({}, dropdownProps, {
                txtOptions: filterJobType,
                isResultsYears: false,
                txtListTitle: { text: "Job Type" },
                paramsName: "filterJobType",
                id: "filterJobType",
                titleCharacters,
              })}
            />
          </div>
        {/if}
        {#if filterLocation?.length > 0}
          <div class="dropdown-item">
            <DropDownParticle
              {...merge({}, dropdownProps, {
                txtOptions: filterLocation,
                isResultsYears: false,
                txtListTitle: { text: "Location" },
                paramsName: "filterLocation",
                id: "filterLocation",
                titleCharacters,
              })}
            />
          </div>
        {/if}
        {#if filterSalary?.length > 0}
          <div class="dropdown-item">
            <DropDownParticle
              {...merge({}, dropdownProps, {
                txtOptions: filterSalary,
                isResultsYears: false,
                txtListTitle: { text: "Salary" },
                paramsName: "filterSalary",
                id: "filterSalary",
                titleCharacters,
              })}
            />
          </div>
        {/if}
      </div>
      {#if filteredPostings}
        {#each filteredPostings as item}
          <div
            class="main-container"
            class:layout-mobile-width={atomWidthRem < 30}
            class:layout-tablet-width={atomWidthRem < 45}
            class:box-border={shouldAddBoxBorder}
          >
            <div class="overlay-area">
              <div class="top-row">
                <div class="header-area">
                  <div class="category-list">
                    <ButtonParticle
                      {..._.merge({}, topLeftButton, {
                        text: { text: item?.industry || "" },
                      })}
                    />
                  </div>
                </div>
              </div>
              <div class="bottom-row">
                <div class="text-icon-area">
                  <div class="text-area">
                    <div class="title-text">
                      {#if !_.isNil(titleText)}
                        <div class="title-inner">
                          <TextParticle
                            _block={true}
                            {...titleText}
                            lineClamp={2}
                            text={item?.title || ""}
                          />
                        </div>
                      {/if}
                      <div class="top-right-area">
                        <ButtonParticle
                          {..._.merge({}, topRightButton, {
                            text: { text: item?.location || "" },
                          })}
                        />
                      </div>
                    </div>
                    <div class="description-text">
                      {#if !_.isNil(descriptionText)}
                        <RichTextParticle
                          _block={true}
                          {...descriptionText}
                          html={item?.description || ""}
                        />
                      {/if}
                    </div>
                  </div>
                </div>
              </div>
              <div class="cta-row">
                {#if ctaButton?.text?.text}
                  <div class="cta-button">
                    <ButtonParticle
                      {...ctaButton}
                      link={resolveUrl(item?.link || "#").href || "#"}
                    />
                  </div>
                {/if}
              </div>
              <div class="footer-row">
                <div class="footer-row-text1">
                  <TextParticle {...footerText} text={item?.type} />
                </div>
                <div class="footer-row-text2">
                  <TextParticle {...footerText} text={item?.salary} />
                </div>
                <!-- <div class="footer-row-spacer"></div> -->
                <div class="footer-row-button">
                  <ButtonParticle
                    {..._.merge({}, footerButton, {
                      text: {
                        text: `${$t("Posted")} ${item?.livedate}` || "",
                      },
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        {/each}
      {/if}
    </div>
  </div>
</BaseAtom>

<style>
  .outer-container {
    width: 100%;
  }

  .box-border {
    border: 2px solid var(--boca-box-border-color, #e9e9e9);
  }

  .left-panel {
    width: 0;
  }

  .right-panel {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    gap: 3rem;
    width: 100%;
  }

  .main-container {
    padding: var(--boca-item-box-padding, unset);

    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    background-size: cover;
    background-position: top center;
    position: relative;
    height: 100%;
    width: 100%;

    background-color: white;
    border-radius: 1.5rem;
    overflow: hidden;
  }

  .main-container > .overlay-area {    
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0rem;
    padding: 0;
  }

  .main-container > .overlay-area > .top-row {
    padding: var(--boca-overlay-padding, unset);
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
  }

  .main-container > .overlay-area > .top-row > .header-area {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
  }

  .main-container > .overlay-area > .top-row > .header-area > .category-list {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: start;
    gap: 1rem;
  }

  .main-container > .overlay-area > .bottom-row {
    padding: var(--boca-overlay-padding, unset);
    display: flex;
    justify-content: space-between;
  }

  .main-container > .overlay-area > .bottom-row > .text-icon-area > .text-area {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .main-container > .overlay-area > .cta-row {
    padding: var(--boca-overlay-padding, unset);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    transform: translateY(50%);
  }

  .main-container > .overlay-area > .cta-row > .cta-button {
    background-color: var(--cta-background-color);
    color: var(--cta-font-color);
    font-size: var(--cta-font-size, 1rem);
    font-weight: var(--cta-font-weight, 600);

    display: block;
    padding: 0rem 1rem;
    border-radius: 8px;
    white-space: nowrap;
  }

  .main-container.layout-mobile-width
    > .overlay-area
    > .top-row
    > .header-area {
    flex-direction: column;
    align-items: start;
    flex-wrap: wrap;
  }

  .text-icon-area {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .title-text {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 2px solid rgb(233, 233, 233);
    justify-content: space-between;
  }

  .description-text {
    width: 100%;
    max-height: 12rem;
    overflow: hidden;
    -webkit-mask-image: linear-gradient(to bottom, black 75%, transparent 100%);
            mask-image: linear-gradient(to bottom, black 75%, transparent 100%);
  }

  .main-container > .overlay-area > .footer-row {
    padding: var(--boca-overlay-padding, unset);
    padding-top: 1.5rem;
    padding-bottom: 1rem;

    display: flex;
    flex-flow: row wrap;
    justify-content: start;
    align-items: center;
    width: 100%;
    gap: 0.75rem;
    background-color: var(--boca-footer-background-color, unset);
  }

  .main-container.layout-mobile-width .overlay-area .footer-row {
        gap: 0;
      }

  .main-container.layout-mobile-width .footer-row-button {
      margin: 1rem 0 0 1rem;
    }

  .main-container.layout-tablet-width .overlay-area .footer-row {
        gap: 0;
      }

  .main-container > .overlay-area > .footer-row > * {
    flex: 0 0 max-content;
  }

  .footer-row-button{
    margin-left: auto;
  }
  .main-container > .overlay-area > .footer-row > .footer-row-spacer {
    flex: 1 0 auto;
  }

  .filter-container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    gap: 1rem;
    padding: 1rem;
  }
  .filter-container.layout-tablet-width,
  .filter-container.layout-mobile-width {
    display: grid;
    grid-template-columns: repeat(2,minmax(13rem, 1fr));
    grid-column-gap: 0.5rem;
    -webkit-column-gap: 0.5rem;
       -moz-column-gap: 0.5rem;
            column-gap: 0.5rem;
    grid-row-gap: 1rem;
    row-gap: 1rem;
    padding: 1rem;
    align-content: center;
    justify-content: center;
    place-content: center;
  }
  .dropdown-item{
    width: 100%;
  }

  /* .top-right-area :global(svg) {
    width: 2.5rem;
    height: 2.5rem;
  } */

  .top-right-area :global(div){
    flex: unset;
  }</style>
