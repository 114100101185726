import "reflect-metadata";

// Import Framework7
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';
import Framework73dPanels from 'framework7-plugin-3d-panels/dist/framework7.3dpanels.esm';

// Import Framework7-Svelte Plugin
import Framework7Svelte from 'framework7-svelte';

// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/fonts.css';
import '../css/icons.css';
import '../css/app.scss';
import 'framework7-plugin-3d-panels/dist/framework7.3dpanels.css';
import 'swiper/swiper.scss';

// Import App Component
import YCApp from '../components/YCApp.svelte';

// core version + navigation, pagination modules:
import Swiper, { Navigation, Pagination, Thumbs, EffectCoverflow, EffectCube, EffectFade, EffectFlip, Autoplay } from 'swiper';
// configure Swiper to use modules

window.yc = {};

Swiper.use([Navigation, Pagination, Thumbs, EffectCoverflow, EffectCube, EffectFade, EffectFlip, Autoplay]);

// Init F7 Svelte Plugin
Framework7.use(Framework7Svelte);
Framework7.use(Framework73dPanels);

// Mount Svelte App
const app = new YCApp({
  target: document.getElementById('app'),
});

export default app;
