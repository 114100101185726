<script context="module" lang="ts">import 'json-schema';
export function getDisplayName() {
    return "Basic: Column Header Card";
}
export function getElementConfigSpec() {
    return {
        type: "object",
        properties: {
            padding: {
                type: "string",
                title: "Padding",
                description: "Padding around the column header."
            },
            borderColor: {
                type: "string",
                title: "Border Color",
                description: "Color of the border."
            },
            borderWidth: {
                type: "string",
                title: "Border Width",
                description: "Width of the border."
            },
            borderStyle: {
                type: "string",
                title: "Border Style",
                description: "Style of the border (e.g., solid, dashed)."
            },
            title: {
                type: "string",
                title: "Title Text",
                description: "Text for the title."
            },
            titleFontColor: {
                type: "string",
                title: "Title Font Color",
                description: "Font color for the title."
            },
            titleFontSize: {
                type: "string",
                title: "Title Font Size",
                description: "Font size for the title."
            },
            titleFontWeight: {
                type: "string",
                title: "Title Font Weight",
                description: "Font weight for the title."
            },
            actionText: {
                type: "string",
                title: "Action Text",
                description: "Text for the action link."
            },
            actionFontColor: {
                type: "string",
                title: "Action Font Color",
                description: "Font color for the action text."
            },
            actionFontSize: {
                type: "string",
                title: "Action Font Size",
                description: "Font size for the action text."
            },
            actionFontWeight: {
                type: "string",
                title: "Action Font Weight",
                description: "Font weight for the action text."
            }
        }
    };
}
</script>
    
    <script lang="ts">import "yinzcam-cards";
import CardBase from "components/cards/common/CardBase.svelte";
import { getFirstSourceStore, getTemplatedElementDataFromSource, buildInlineStyle } from "components/cards/utilities";
import "svelte/store";
export let element;
export let sources;
export let sourceStore;
$: sourceStore = getFirstSourceStore($sources);
$: padding = getTemplatedElementDataFromSource(element, "padding", $sourceStore);
$: borderColor = getTemplatedElementDataFromSource(element, "borderColor", $sourceStore);
$: borderWidth = getTemplatedElementDataFromSource(element, "borderWidth", $sourceStore);
$: borderStyle = getTemplatedElementDataFromSource(element, "borderStyle", $sourceStore);
$: titleText = getTemplatedElementDataFromSource(element, "titleText", $sourceStore);
$: titleFontColor = getTemplatedElementDataFromSource(element, "titleFontColor", $sourceStore);
$: titleFontSize = getTemplatedElementDataFromSource(element, "titleFontSize", $sourceStore);
$: titleFontWeight = getTemplatedElementDataFromSource(element, "titleFontWeight", $sourceStore);
$: actionText = getTemplatedElementDataFromSource(element, "actionText", $sourceStore);
$: actionLinkUrl = getTemplatedElementDataFromSource(element, "actionLinkUrl", $sourceStore);
$: actionFontColor = getTemplatedElementDataFromSource(element, "actionFontColor", $sourceStore);
$: actionFontSize = getTemplatedElementDataFromSource(element, "actionFontSize", $sourceStore);
$: actionFontWeight = getTemplatedElementDataFromSource(element, "actionFontWeight", $sourceStore);
$: style = buildInlineStyle("padding", padding, "border-color", borderColor, "border-width", borderWidth, "border-style", borderStyle, "title-font-color", titleFontColor, "title-font-size", titleFontSize, "title-font-weight", titleFontWeight, "action-font-color", actionFontColor, "action-font-size", actionFontSize, "action-font-weight", actionFontWeight);
</script>
    
    <CardBase {element}>
      <div class="column-header" style={style}>
        <div class="title">
          {titleText}
        </div>
        <a href={actionLinkUrl} class="action-link">
          <i class="f7-icons action-icon">arrow_right_circle</i><span class="action-text">{actionText}</span>
        </a>
      </div>
    </CardBase>
    
    <style>
      *, *::before, *::after {
        box-sizing: border-box;
      }

      .column-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: var(--padding, 1em);
        border-top: var(--border-width, 2px) var(--border-style, solid) var(--border-color, transparent);
      }

      .title {
        color: var(--title-font-color, unset);
        font-size: var(--title-font-size, 1.2em);
        font-weight: var(--title-font-weight, 800);
      }

      .action-link {
        display: block;
        color: var(--action-font-color, unset);
        font-size: var(--action-font-size, 0.8em);
        font-weight: var(--action-font-weight, unset);
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      .action-link:hover .action-text {
        -webkit-text-decoration: underline;
        text-decoration: underline;
      }

      .action-icon {
        font-size: 2.0em;
        font-weight: bold;
        margin-right: 0.2em;
      }</style>