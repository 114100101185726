{#key previewEnabled}
<MenuItem on:click={handleClick}>
  <div class="button-contents">
    {#if previewEnabled}
    View Schematic
    {:else}
    View Actual Page
    {/if}
  </div>
</MenuItem>
{/key}

<style>
  *, *::before, *::after {
    box-sizing: border-box;
  }

  .button-contents {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    font-weight: bold;
    font-size: 13px;

    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0,0,0,0.24);
    text-transform: uppercase;
  }</style>

<script lang="ts">// iconF7={(previewEnabled)? "eye_slash" : "eye"} iconOnly {iconSize} 
import { MenuItem } from 'framework7-svelte';
import { CardsPageContextKey } from './context';
import { getContext } from 'svelte';
export let iconSize = 20;
const pageContext = getContext(CardsPageContextKey);
const previewStore = pageContext.editorModeManager.getPreviewStore();
$: previewEnabled = $previewStore;
function handleClick() {
    pageContext.editorModeManager.togglePreview();
}
</script>