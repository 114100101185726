<div class="cc-wrapper">
  {#each buttons as button (button.id)}
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div class="cc-button" class:cc-active={!button.showOnlyWhenActive || active} class:clickable={!!button.onClick} on:click={((!button.showOnlyWhenActive || active) && button.onClick) || (() => {})}>
    {#if button.icon && button.icon.startsWith("yc_")}
      <svg viewBox="0 0 32 32" class="cc-icon">
        <use xlink:href={`#${button.icon}`}></use>
      </svg>
    {:else if button.icon}
      <Icon size="16px" tooltip={(!button.showOnlyWhenActive || active)? button.tooltip : undefined} f7={button.icon} />
    {/if}
    {#if button.label}
      <span>{button.label}</span>
    {/if}
  </div>
  {/each}
</div>

<style>
  *, *::before, *::after {
    box-sizing: border-box;
  }

  .cc-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    height: 100%;
    padding-left: 3px;
    width: 100%;
    flex-wrap: wrap;
  }

  .cc-button {
    margin-right: 5px;
    transition: 0.3s;
    opacity: 1;
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }

  .cc-button.clickable {
    cursor: pointer;
  }

  .cc-button:not(.clickable) {
    cursor: not-allowed;
  }

  .cc-button:not(.cc-active) {
    margin-right: 0px;
    width: 0px;
    opacity: 0;
  }

  svg.cc-icon {
    height: 16px;
    width: 16px;
  }</style>

<script lang="ts">import { Icon } from 'framework7-svelte';
import './CardsEditorInterfaces';
export let active;
export let buttons = [];
</script>
