import { Mode } from "./Mode";
import { AbstractReactiveMicrocomponent } from "../AbstractReactiveMicrocomponent";
import { ReactiveMicrocomponent } from "../ReactiveMicrocomponent";

export abstract class AbstractTimer extends AbstractReactiveMicrocomponent<number, [number], { lastFiredTime: number }, { checkpointTime: number }> {
  private readonly mode: Mode;
  private readonly initialDelay: number | null;
  private timerHandle: number;
  // for mode=DEADLINE, this is the deadline timestamp
  // for mode=REPEATING, this is the time that the timer last fired
  private promiseFuncs: { resolve, reject };

  public constructor(name: string, mode: Mode, initialDelay: number | null, input: ReactiveMicrocomponent<number>) {
    super({ name }, input);
    this.mode = mode;
    this.initialDelay = initialDelay;
    this.timerHandle = null;
    this.promiseFuncs = null;
  }

  private cancelTimer(): void {
    this.log.debug('AbstractTimer: cancelTimer');
    if (this.timerHandle) {
      clearTimeout(this.timerHandle);
      this.timerHandle = null;
    }
    if (this.promiseFuncs) {
      this.promiseFuncs.reject();
      this.promiseFuncs = null;
    }
  }
  
  protected update($control: { lastFiredTime: number }, $inputValue: number): Promise<number> {
    if (this.timerHandle) {
      throw new Error("AbstractTimer: the timerHandle wasn't cleared!");
    }

    if (typeof $inputValue === 'undefined') {
      // our input isn't ready yet, just do nothing
      return Promise.reject();
    }

    if ($inputValue === null) {
      // null is a signal to stop the timer
      this.log.debug('AbstractTimer: no frequency, stopping timer');
      this.state.checkpointTime = null;
      return Promise.reject();
    }

    let now = Date.now();

    if (!this.state.checkpointTime) {
      switch (this.mode) {
        case Mode.DEADLINE:
          // $inputValue is the deadline
          if ($inputValue < now) {
            // if the deadline has already passed, ignore
            this.log.debug('AbstractTimer: in DEADLINE mode and deadline has already passed');
            return Promise.reject();
          }
          this.state.checkpointTime = $inputValue;
          break;
        case Mode.REPEATING:
          // $inputValue is the frequency
          this.state.checkpointTime = now;
          break;
      }
      this.log.debug(`AbstractTimer: setting checkpoint to ${this.state.checkpointTime}`);
    }
    this.saveCheckpoint(); // for checkpointTime

    let timeout: number;
    switch (this.mode) {
      case Mode.DEADLINE:
        timeout = this.state.checkpointTime - now;
        break;
      case Mode.REPEATING:
        if (!$control?.lastFiredTime && this.initialDelay !== null) {
          timeout = 0;
        } else {
          timeout = (this.state.checkpointTime + $inputValue) - now;
        }
        break;
    }
    // clamp timeout between the min (0) and the max (about 24 days)
    timeout = Math.min(0x7FFFFFFF, Math.max(timeout, 0));
    this.log.debug(`AbstractTimer: setting new timer with timeout ${timeout}`);
    return new Promise<number>((resolve, reject) => {
      this.promiseFuncs = { resolve, reject };
      this.timerHandle = setTimeout(() => {
        this.log.debug(`AbstractTimer: timer fired!`);
        this.timerHandle = null;
        this.promiseFuncs = null;
        this.state.checkpointTime = null;
        let now = Date.now();
        resolve(now); // fire output
        this.setControl({ lastFiredTime: now }); // fire update again to set another timer
      }, timeout);
    });
  }

  protected inputChanged(): void {
    this.cancelTimer();
  }

  protected stop(): void {
    this.cancelTimer();
  }
}
