<script context="module" lang="ts">var _a, _b;
export { specification } from "./spec";
</script>

<script lang="ts">var _a, _b;
import { get } from "lodash";
import "components/cards/utilities";
import { BaseAtom } from "components/cards/atoms";
import { TextParticle, } from "components/cards/particles/YinzCam";
import { writable } from "svelte/store";
import "js/url";
const hovering = writable(false);
export let container;
export let overlayPadding;
export let txtSubStat;
export let colorProgressBackground;
export let colorProgressBar;
export let playerStats = [];
export let statsListMax;
export let statsListProgress;
let atomWidthRem;
$: valMax = playerStats === null || playerStats === void 0 ? void 0 : playerStats.find((playerStat) => { var _a; return statsListMax === ((_a = playerStat === null || playerStat === void 0 ? void 0 : playerStat.name) === null || _a === void 0 ? void 0 : _a.text); });
$: valProgress = playerStats === null || playerStats === void 0 ? void 0 : playerStats.find((playerStat) => { var _a; return statsListProgress === ((_a = playerStat === null || playerStat === void 0 ? void 0 : playerStat.name) === null || _a === void 0 ? void 0 : _a.text); });
$: progressPercent = `${((+((_a = valProgress === null || valProgress === void 0 ? void 0 : valProgress.homeValue) === null || _a === void 0 ? void 0 : _a.text) / +((_b = valMax === null || valMax === void 0 ? void 0 : valMax.homeValue) === null || _b === void 0 ? void 0 : _b.text)) * 100).toFixed(2)}%`;
$: txtNameMultiplier = atomWidthRem < 30 ? "0.8" : "1.00";
$: txtSubStatMultiplier = atomWidthRem < 30 ? "0.75" : "1.00";
$: txtStatValueMultiplier = atomWidthRem < 30 ? "0.6" : "1.00";
</script>

<BaseAtom
    _isotope="ArticleAtom"
    _cssVariablePrefix="single-combo-graph"
    {...container}
    {overlayPadding}
    {colorProgressBackground}
    {colorProgressBar}
    {progressPercent}
    bind:_clientWidthRem={atomWidthRem}
>
    <div
        class="main-container"
        class:layout-mobile-width={atomWidthRem < 30}
        class:layout-tablet-width={atomWidthRem < 45}
    >
        <div class="graph-box">
            <div class="label-area">
                <div class="right-text">
                    <TextParticle
                        {...valProgress?.name}
                        size={`calc(${get(valProgress, "name.size")}*${txtNameMultiplier})`}
                    />
                    <TextParticle
                        {...txtSubStat}
                        size={`calc(${get(txtSubStat, "size")}*${txtSubStatMultiplier})`}
                    />
                </div>
                <div class="left-text">
                    <TextParticle
                        {...valProgress?.homeValue}
                        size={`calc(${get(valProgress, "homeValue.size")}*${txtStatValueMultiplier})`}
                    />
                </div>
            </div>
            <div class="outer-bar">
                <div class="inner-bar"></div>
            </div>
        </div>
    </div>
</BaseAtom>

<style>
    .main-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: var(--single-combo-graph-overlay-padding, unset);
    }
    .graph-box {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 0.75rem;
        gap: 0.75rem;
    }
    .label-area {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
    .right-text {
        display: flex;
        align-items: center;
        gap: 0.62rem;
    }
    .outer-bar {
        background-color: var(
            --single-combo-graph-color-progress-background,
            #D9D9D9
        );
        width: 100%;
        height: 1rem;
        border-radius: 1.685rem;
    }
    .inner-bar {
        background-color: var(--single-combo-graph-color-progress-bar, --theme-mode-accent-color);
        height: 1rem;
        width: var(--single-combo-graph-progress-percent, 0);
        border-radius: 1.685rem;
    }</style>
