<div class="cards-editor-component-edit-popup" style="--bar-color: {barColor}; --text-color: {textColor};">
  <div bind:this={jsonEditorDiv} class="cards-editor-component-edit-json-editor">
  </div>
</div>

<style>
  *, *::before, *::after {
    box-sizing: border-box;
  }

  .cards-editor-component-edit-popup {
    width: 100%;
    height: auto;
    overflow: auto;
    font-family: 'SF UI Display';
  }

  .cards-editor-component-edit-json-editor {
    width: 100%;
    height: auto;
    color: black;
    padding: 0px 20px;
  }

  /*
  .cards-editor-component-edit-json-editor :global(.form-control) {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
  }

  .cards-editor-component-edit-json-editor :global(.form-control) > :global(.je-form-input-label) {
    order: 0;
  }

  .cards-editor-component-edit-json-editor :global(.form-control) > :global:not(.je-form-input-label) {
    order: 1;
  }

  .cards-editor-component-edit-json-editor :global(.form-control) > :global(p.je-form-input-label),
  .cards-editor-component-edit-json-editor :global(.je-object__container) > :global(p) {
    margin: 0;
    padding-bottom: 5px;
    font-weight: normal;
    font-style: italic;
  }

  .cards-editor-component-edit-json-editor :global(.form-control) > :global(select),
  .cards-editor-component-edit-json-editor :global(.form-control) > :global(input) {
    border: 1px solid #6f7e95;
    padding: 5px 0px 3px 3px;
  }

  .cards-editor-component-edit-json-editor :global(.form-control) > :global(.je-checkbox) {
    padding-left: 5px;
  }

  .cards-editor-component-edit-json-editor :global(.je-object__container) > :global(.je-indented-panel) {
    margin: 0;
    border: 0;
  }

  .cards-editor-component-edit-json-editor :global(.je-object__title) {
    margin: 0;
  }
  */

  :global(html.jodit_fullsize-box_true), :global(body.jodit_fullsize-box_true) {
    overflow: visible !important;
  }

  .inactive-button-style {
    background: rgb(241, 241, 241);
    border-radius: 10px;
    border: 0;
    color: #202020;
  }

  .active-button-style {
    background: rgb(0, 122, 255);
    border-radius: 10px;
    border: 0;
    color: #202020;
  }</style>

<script lang="ts">import { onDestroy, onMount, getContext } from 'svelte';
import { JSONEditor } from '@json-editor/json-editor/dist/jsoneditor.js';
import _ from 'lodash';
import './CardsEditorComponentInterfaces';
import { Jodit } from 'jodit/es2018/jodit';
import 'jodit/es2018/jodit.min.css';
import { pbt } from '../../../js/i18n';
import { CardsPageContextKey } from './context';
const pageContext = getContext(CardsPageContextKey);
export let options;
$: barColor = (options === null || options === void 0 ? void 0 : options.barColor) || '#aaaaaa';
$: textColor = (options === null || options === void 0 ? void 0 : options.textColor) || 'white';
$: schema = (options === null || options === void 0 ? void 0 : options.schema) || {};
$: data = (options === null || options === void 0 ? void 0 : options.data) || {};
$: componentId = options === null || options === void 0 ? void 0 : options.componentId;
$: onUpdate = (options === null || options === void 0 ? void 0 : options.onUpdate) || (() => { });
$: onFileUpload = (options === null || options === void 0 ? void 0 : options.onFileUpload) || (() => { });
let jsonEditorDiv;
let jsonEditor;
let lastSchema = {};
let lastComponentId = null;
if (!window.Jodit) {
    window.Jodit = Jodit;
}
function createJsonEditor() {
    lastSchema = schema;
    lastComponentId = componentId;
    JSONEditor.defaults.themes.yinzcam = class yinzcam extends JSONEditor.defaults.themes.html {
        markTabActive(row) {
            super.markTabActive(row);
            super.applyStyles(row.tab, {
                'opacity': '1',
                'color': '#202020',
                'font-weight': 'bold',
                'background-color': '#d0d0d0'
            });
        }
        markTabInactive(row) {
            super.markTabInactive(row);
            super.applyStyles(row.tab, {
                'opacity': '1',
                'color': '#202020',
                'font-weight': 'normal',
                'background-color': '#f1f1f1'
            });
        }
    };
    JSONEditor.defaults.themes.yinzcam.rules = Object.assign(Object.assign({}, JSONEditor.defaults.themes.html.rules), { 'div[data-schemaid="root"] > .je-header.je-object__title': `
        display: none;
      `, 'div[data-schemaid="root"] > .je-object__controls': `
        margin: 0;
        display: none;
      `, 'button': `
        border: 0;
        border-radius: 8px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0,0,0,0.24);
      `, 'button.json-editor-btn-delete': `
      `, 
        // json-editor bug with new 2.5 version?
        'div[data-schemaid="root"]:has(> .je-switcher)': `
        display: none;
      `, '.je-indented-panel': `
        padding: 0;
        margin: 20px 0px 10px 0px;
        border: 0;
        width: 100%25;
      `, 'span:has(> .json-editor-btn-add)': `
        display: flex !important;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        width: 100%25;
        gap: 10px;
      `, 'span:has(> .json-editor-btn-add)::before': `
        content: 'Overall List:'
      `, '.json-editor-btn-add': `
        flex-basis: 100%25;
        min-width: 100%25;
      `, '.tabs.je-tabholder--top': `
        margin: 0;
        border-bottom: none;
      `, '.je-tab--top': `
        border: none;
        padding: 0px 15px;
        opacity: 1;
        margin: 2px;
        font-size: 13px;
        border-radius: 10px;
        font-weight: normal;
      `, '.je-tabholder--clear': `
        clear: unset;
        display: inline-flex;
        border-top: 0.5px solid #888888;
        margin-top: 20px;
        width: 100%25;
      `, '.je-object__container': `
      `, 
        // hide descriptions
        '.je-object__container > p, .je-header ~ p': `
        display: block;
      `, '.je-child-editor-holder.je-object__container': `
        padding-bottom: 20px;
        border-bottom: 0.5px solid black;
        margin-bottom: 20px;
      `, '.je-child-editor-holder.je-object__container .je-tabholder--clear': `
        border-top: 0;
        margin-top: 0;
      `, '.je-child-editor-holder.je-object__container > span:has(button)': `
        display: flex !important;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        width: 100%25;
        gap: 10px;
      `, '.je-child-editor-holder.je-object__container > .je-header.je-object__title': `
        justify-content: start;
      `, '.row + .row': `
        margin-top: 10px;
      `, 
        // form control empty div
        '.row > div': `
        width: 100%25;
      `, 'span > :has(input ~ label)': `
        display: flex;
        flex-direction: row;
        align-items: center;
      `, 'input[type="checkbox"]': `
        transform: scale(1.25);
      `, '.je-header.je-object__title': `
        font-size: 13px;
      `, 
        // json-editor uses decodeURIComponent, so %25 => %
        '.form-control': `
        display: flex;
        flex-direction: row;
        padding-bottom: 10px;
        justify-content: space-between;
        align-items: start;
        width: 100%25;
        flex-wrap: nowrap;
      `, 
        // form control title
        '.form-control > label.je-form-input-label': `
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: #202020;
        font-size: 13px;
        font-weight: normal;
      `, 
        /*
        '.form-control > label.je-form-input-label + *': `
        `,
        */
        // json-editor uses decodeURIComponent, so %25 => %
        '.form-control > label.je-form-input-label + :is(input, select), .form-control > label.je-form-input-label ~ div:has(> div > :is(input, select))': `
        flex: 0 0 50%25;
        min-width: 50%25;
        max-width: 50%25;
        padding: 5px;
        font-size: 13px;
        appearance: revert;
        -moz-appearance: revert;
        -webkit-appearance: revert;
      `, '.form-control > label.je-form-input-label + :is(input, select)': `
        background-color: #f1f1f1;
      `, 
        // image upload widget
        '.form-control:has(> label.je-form-input-label ~ div > div.je-dropzone)': `
        flex-wrap: wrap;
      `, '.form-control:has(> label.je-form-input-label ~ div > div.je-dropzone) > label.je-form-input-label ~ div': `
        min-width: 100%25;
      `, '.form-control .je-dropzone': `
        padding: 0;
        margin: 5px 0 0 0;
        background-color: #f1f1f1;
        border: 1px dashed #505050;
      `, '.form-control .je-dropzone::before': `
        color: black;
      `, '.form-control > label.je-form-input-label ~ div > div > :is(input, select, button)': `
        margin-bottom: 5px;
        width: 100%25;
      `, '.form-control > input[type="color"]': `
        position: relative;
        margin-bottom: 5px;
        width: 100%25;
        
      `, 
        /*
        '.form-control > input[type="color"]::before': `
          position: absolute;
          content: 'test';
          top: 0;
          left: 0;
          color: white;
        `,
        */
        '.form-control > label.je-form-input-label ~ div > div > input': `
        background: transparent;
        padding: 0;
        margin: 0;
        border: 0;
        font-style: italic;
      `, '.form-control > label.je-form-input-label ~ div > div > button': `
        background: rgb(241, 241, 241);
        border-radius: 10px;
        border: 0;
        color: #202020;
      `, 
        // form control empty div
        '.form-control > div:not(:has(input, button))': `
        display: none;
      `, 
        // form control description
        /*
        '.form-control > p.je-form-input-label': `
          display: none;
          color: #202020;
        `,
  
        '.form-control > p.je-form-input-label::before': `
          display: block;
          position: absolute;
          content: \'\';
          color: rgba(0, 0, 0, 0.6);
          left: 50%25;
          top: 50%25;
          transform: translate(-50%25, -50%25);
        `,
        */
        '.form-control:has(.jodit-container)': `
        flex-wrap: wrap;
      `, '.form-control .jodit-container': `
        flex-basis: 100%25;
        margin-top: 5px;
      `, 
        // inline-block
        '.form-control > p.je-form-input-label': `
        position: relative;
        display: none;
        margin-left: 10px;
        cursor: pointer;
      `, '.form-control > p.je-form-input-label::before': `
        content: "?";
        display: inline-block;
        width: 20px;
        height: 20px;
        background-color: #ccc;
        color: white;
        border-radius: 50%25;
        text-align: center;
        line-height: 20px;
        font-weight: bold;
        font-size: 14px;
      `, '.form-control > p.je-form-input-label::after': `
        content: attr(id);
        position: absolute;
        bottom: 125%25;
        left: 50%25;
        transform: translateX(-50%25);
        background-color: #555;
        color: white;
        padding: 5px;
        border-radius: 4px;
        white-space: nowrap;
        opacity: 0;
        transition: opacity 0.3s;
        pointer-events: none;
      `, '.form-control > p.je-form-input-label:hover::after': `
        opacity: 1;
      ` });
    // 
    // I don't like doing this, but json-editor won't pass options to Jodit
    // Restrict fullsize to next position relative parent rather than entire window.
    //(<any>window).Jodit.defaultOptions.globalFullSize = false;
    // Sometimes Jodit gets stuck in a wide width after going fullscreen, this makes it snap back inside its container.
    window.Jodit.defaultOptions.width = '100%';
    window.Jodit.defaultOptions.height = '100%';
    window.Jodit.defaultOptions.controls.image = {
        //name: "Upload Image",
        tooltip: $pbt("Upload image to website."),
        //iconURL: "https://www.kindpng.com/picc/m/261-2619141_cage-clipart-victorian-cloud-upload-icon-svg-hd.png",
        exec: (async (editor) => {
            const fileUpload = (file) => {
                return new Promise((resolve, reject) => {
                    onFileUpload(file, {
                        onFileUploadStarted(id) {
                            console.info('FILE UPLOAD STARTED', id);
                        },
                        onFileUploadProgress(id, percent) {
                            console.info('FILE UPLOAD PROGRESS', id, percent);
                        },
                        onFileUploadComplete(id, url) {
                            console.info('FILE UPLOAD COMPLETE', id, url);
                            resolve(url);
                        },
                        onFileUploadError(id, reason) {
                            console.error('FILE UPLOAD ERROR', id, reason);
                            reject(reason);
                        }
                    });
                });
            };
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');
            input.click();
            input.onchange = async function () {
                const imageFile = input.files[0];
                if (!imageFile) {
                    return;
                }
                if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
                    return;
                }
                const url = await fileUpload(imageFile);
                const image = editor.selection.j.createInside.element('img');
                image.setAttribute('src', url);
                image.setAttribute('style', 'max-width: 100%');
                editor.selection.insertNode(image);
            };
        })
    };
    //(<any>window).Jodit.defaultOptions.buttonsXS[1] = 'uploadImage';
    // Custom fonts
    window.Jodit.defaultOptions.controls.font.list["'SF UI Display',sans-serif"] = "San Francisco";
    window.Jodit.defaultOptions.controls.font.list["'Inter',sans-serif"] = "Inter";
    window.Jodit.defaultOptions.controls.font.list["'Fira Sans',sans-serif"] = "Fira Sans";
    window.Jodit.defaultOptions.controls.font.list["'LCFC Sans',sans-serif"] = "LCFC Sans";
    window.Jodit.defaultOptions.controls.font.list["'LCFC Sans Headline',sans-serif"] = "LCFC Sans Headline";
    window.Jodit.defaultOptions.controls.fontsize.list = window.Jodit.atom({
        "0.5rem": "8",
        "0.625rem": "10",
        "0.75rem": "12",
        "0.875rem": "14",
        "1rem": "16",
        "1.125rem": "18",
        "1.25rem": "20",
        "1.375rem": "22",
        "1.5rem": "24",
        "1.75rem": "28",
        "2rem": "32",
        "2.25rem": "36",
        "2.5rem": "40",
        "3rem": "48",
        "3.5rem": "56",
        "4rem": "64",
        "4.5rem": "72"
    });
    JSONEditor.defaults.language = 'en';
    JSONEditor.defaults.languages.es = {
        button_add_row_title: "Agregar {{0}}",
        button_collapse: "Colapsar",
        button_copy_row_title_short: "Copiar",
        button_delete_all: "Todos",
        button_delete_all_title: "Borrar Todos",
        button_delete_last: "Ultimo {{0}}",
        button_delete_last_title: "Borrar Ultimo {{0}}",
        button_delete_node_warning: "Seguro que quieres borrarlo?",
        button_delete_row_title: "Borrar {{0}}",
        button_delete_row_title_short: "Borrar",
        button_expand: "Expandir",
        button_move_down_title: "Mover abajo",
        button_move_up_title: "Mover arriba",
        button_object_properties: "Propiedades del Objeto",
        choices_placeholder_text: "Empeza a escribir para agregar un valor",
        default_array_item_title: "item",
        error_additionalItems: "No se admiten mas items en esta lista",
        error_additional_properties: "No se admiten mas propiedades, pero la propiedad {{0}} esta seteada",
        error_anyOf: "El valor debe corresponder con uno de los schemas provistos",
        error_date: "La fecha debe estar en formato {{0}}",
        error_datetime_local: "La fecha y hora deben estar en formato {{0}}",
        error_dependency: "Tiene que tener la propiedad {{0}}",
        error_disallow: "El valor no puede ser del tipo {{0}}",
        error_disallow_union: "El valor no puede ser uno de los tipos no admitidos",
        error_enum: "El valor debe ser una de las opciones posible",
        error_hostname: "El hostname esta en el formato incorrecto",
        error_invalid_epoch: "La fecha debe ser posterior al 1 enero de 1970",
        error_ipv4: "El valor debe ser una IPv4 valida con el formato de 4 numeros entre 0 y 255 separados por puntos",
        error_ipv6: "El valor debe ser una IPv6 valida",
        error_maxItems: "El valor debe tener como maximo {{0}} items",
        error_maxLength: "El valor debe tener como maximo {{0}} caracteres",
        error_maxProperties: "El valor debe tener como maximo {{0}} propiedades",
        error_maximum_excl: "El valor debe tener menos de {{0}}",
        error_maximum_incl: "El valor debe tener como maximo {{0}}",
        error_minItems: "El valor debe ser al menos {{0}} items",
        error_minLength: "El valor debe ser al menos {{0}} caracteres",
        error_minProperties: "El objeto debe tener al menos {{0}} propiedades",
        error_minimum_excl: "El valor debe ser mas que {{0}}",
        error_minimum_incl: "El valor debe ser al menos {{0}}",
        error_multipleOf: "El valor debe ser un multiplo de {{0}}",
        error_not: "El valor no debe ser validado contra el schema provisto",
        error_notempty: "Valor requerido",
        error_notset: "La propiedad debe estar seteada",
        error_oneOf: "El valor debe ser validado exactamente contra todos los schemas provisto. Ahora solo valida contra {{0}} de los mismos.",
        error_pattern: "El valor debe ser compatible con el patron {{0}}",
        error_required: "Al objeto le falta la propiedad '{{0}}'",
        error_time: "El valor debe estar en el formato {{0}}",
        error_type: "El valor debe ser del tipo {{0}}",
        error_type_union: "El valor debe ser de uno de los tipos provistos",
        error_uniqueItems: "La lista debe tener items unicos",
        flatpickr_clear_button: "Vaciar",
        flatpickr_toggle_button: "Expandir/Colapsar"
    };
    JSONEditor.defaults.options.upload.title = "Upload File";
    JSONEditor.defaults.options.upload.drop_zone_text = "Drag & Drop File";
    jsonEditor = new JSONEditor(jsonEditorDiv, {
        schema,
        startval: data,
        disable_collapse: true,
        disable_edit_json: true,
        disable_properties: true,
        remove_empty_properties: true,
        no_additional_properties: true,
        use_default_values: false,
        show_opt_in: true,
        display_required_only: false,
        object_layout: 'categories',
        theme: 'yinzcam', //'bootstrap5', //'html',
        // NOTE: This doesn't work, but it should.
        /*
        jodit: {
          disabled: false,
          controls: {
            uploadImage: ...
          }
        },
        */
        upload: {
            auto_upload: true,
            enable_drag_drop: true,
            upload_handler: (type, file, cbs) => {
                if (type === 'root.upload_fail') {
                    cbs.failure($pbt('Upload failed.'));
                    return;
                }
                onFileUpload(file, {
                    onFileUploadStarted(id) {
                        console.info('FILE UPLOAD STARTED', id);
                    },
                    onFileUploadProgress(id, percent) {
                        console.info('FILE UPLOAD PROGRESS', id, percent);
                        cbs.updateProgress(percent);
                    },
                    onFileUploadComplete(id, url) {
                        console.info('FILE UPLOAD COMPLETE', id, url);
                        cbs.updateProgress();
                        cbs.success(url);
                    },
                    onFileUploadError(id, reason) {
                        console.error('FILE UPLOAD ERROR :(', id, reason);
                        cbs.failure(reason);
                    }
                });
            }
        }
    });
    jsonEditor.on('change', onDataChanged);
    jsonEditor.on('allSchemasLoaded', () => {
        console.log('jsonEditor - allSchemasLoaded');
    });
    //console.log('JSONEDITOR', jsonEditor);
}
function destroyJsonEditor() {
    jsonEditor.off('change', onDataChanged);
    jsonEditor.destroy();
}
let rootActiveTabId = null;
function saveJsonEditorState() {
    var _a, _b, _c;
    rootActiveTabId = (_c = (_b = (_a = jsonEditor === null || jsonEditor === void 0 ? void 0 : jsonEditor.editors) === null || _a === void 0 ? void 0 : _a.root) === null || _b === void 0 ? void 0 : _b.active_tab) === null || _c === void 0 ? void 0 : _c.id;
}
function findSiblingWithId(el, id) {
    var _a;
    const siblings = el.parentNode.children;
    let sibling = null;
    for (let i = 0; i < siblings.length; i++) {
        if (((_a = siblings[i]) === null || _a === void 0 ? void 0 : _a.id) === id) {
            sibling = siblings[i];
            break;
        }
    }
    return sibling;
}
function restoreJsonEditorState() {
    var _a, _b;
    if (rootActiveTabId) {
        let rootActiveTab = (_b = (_a = jsonEditor === null || jsonEditor === void 0 ? void 0 : jsonEditor.editors) === null || _a === void 0 ? void 0 : _a.root) === null || _b === void 0 ? void 0 : _b.active_tab;
        if (rootActiveTab) {
            const tabHandle = findSiblingWithId(rootActiveTab, rootActiveTabId);
            if (tabHandle) {
                tabHandle.click();
            }
        }
    }
}
function updateSchema(newSchema, newComponentId, force) {
    //console.log('updateSchema', newSchema);
    if (!force) {
        if (!jsonEditor || (_.isEqual(lastSchema, newSchema) && lastComponentId === newComponentId)) {
            return;
        }
    }
    //console.log('updateSchema RECREATING JSON EDITOR', newSchema);
    saveJsonEditorState();
    destroyJsonEditor();
    createJsonEditor();
    restoreJsonEditorState();
}
$: updateSchema(schema, componentId);
onMount(() => {
    createJsonEditor();
});
onDestroy(() => {
    if (jsonEditor) {
        destroyJsonEditor();
    }
});
function onDataChanged() {
    //console.log('ON DATA CHANGED', jsonEditor.getValue());
    onUpdate(jsonEditor.getValue());
    pageContext.editorModeManager.notifyComponentDataUpdate('SCHEMA', options === null || options === void 0 ? void 0 : options.componentId);
}
pageContext.editorModeManager.registerForComponentDataUpdate((event) => {
    if ((event === null || event === void 0 ? void 0 : event.source) !== 'SCHEMA' && (event === null || event === void 0 ? void 0 : event.componentId) === (options === null || options === void 0 ? void 0 : options.componentId)) {
        updateSchema(schema, componentId, true);
    }
});
</script>
