import { generateGenericContainerSchema } from "components/cards/common/schema";
import { ImageParticleSpec, TextParticleSpec } from "components/cards/particles/YinzCam";
import { JSONSchema4 } from "json-schema";
import _ from "lodash";

export interface DIYCardProperties {
  sourcePath?: string;
  contentRoot: DIYCardObject;
}

export interface DIYCardObject {
  id: string;
  name: string;
  type: string;
  data: any;
  items: DIYCardObject[];
}

export interface DIYCardObjectControls {
  parent: DIYCardObjectControls;
  item: DIYCardObject;
  refresh: () => void;
  addChild: () => void;
  removeChild: (childId: string) => void;
  removeSelf: () => void;
}

// TODO: fix types here
export function getSchemaForType(type: string): any {
  switch (type) {
    case 'YinzCam__Core__Container':
      return generateGenericContainerSchema("", "");
    case 'YinzCam__Core__Text':
      return TextParticleSpec.generateSchema("", "");
    case 'YinzCam__Core__Image':
      return ImageParticleSpec.generateSchema("", "");
    default:
      return {};
  }
}

export function getIconForType(type: string) {
  switch (type) {
    case 'YinzCam__Core__Container':
      return 'list_bullet_indent';
    case 'YinzCam__Core__Text':
      return 'textformat';
    case 'YinzCam__Core__Image':
      return 'photo';
    default:
      return 'question_diamond';
  }
}

export function getBindingSchemaForPropertiesSchema(cardSchema: JSONSchema4, sourceSchema: JSONSchema4): JSONSchema4 {
  if (cardSchema?.type !== 'object') {
    throw new Error(`card schema must have type object, got ${cardSchema?.type}`);
  }
  if (sourceSchema?.type !== 'object') {
    throw new Error(`card schema must have type object, got ${sourceSchema?.type}`);
  }

  interface FieldContext { choices: string[], choiceTitles: string [] };

  function buildEmptyFieldContext(): FieldContext {
    return { choices: [ "" ], choiceTitles: [ "" ] };
  }
  
  function buildTypeFieldsContext(rootSchema: JSONSchema4): Record<string, FieldContext> {
    console.log('build type fields map', rootSchema);
    function buildTypeFieldsMap(schema: JSONSchema4, typeFieldsMap?: Record<string, Record<string, { name: string }>>, path?: string) {
      if (_.isNil(typeFieldsMap)) {
        typeFieldsMap = {};
      }
  
      if (_.isNil(path)) {
        path = '/';
      }
      path = path.replaceAll('//', '/');
      if (!path.startsWith('/')) {
        throw new Error(`invalid path (must start with forward slash): ${path}`);
      }
  
      if (schema?.type === 'array') {
        console.log('buildFieldsContext: arrays ignored for now');
      } else if (schema?.type === 'object') {
        // add children to context, recursively
        for (const propKey in (schema.properties || {})) {
          const prop = schema.properties[propKey];
          buildTypeFieldsMap(prop, typeFieldsMap, `${path}/${propKey}`);
        }
      } else if (schema?.type === 'string' || schema?.type === 'number' || schema?.type === 'boolean' || schema?.type === 'integer') {
        let fieldsMap = typeFieldsMap[schema?.type];
        if (_.isNil(fieldsMap)) {
          fieldsMap = typeFieldsMap[schema?.type] = {};
        }
        fieldsMap[path] = {
          name: path.substring(1).replaceAll('/', '.'),
        };
      } else {
        console.log(`buildFieldsContext: don't know how to map type ${schema?.type}, ignoring`);
      }

      return typeFieldsMap;
    }

    const typeFieldsMap = buildTypeFieldsMap(rootSchema);

    return Object.fromEntries(
      Object.entries(typeFieldsMap).map((typeFields) => {
        return [ 
          typeFields[0], 
          Object.entries(typeFields[1]).sort((a, b) => a[1].name.localeCompare(b[1].name)).reduce((obj, val) => {
            obj.choices.push(val[0]);
            obj.choiceTitles.push(val[1].name);
            return obj;
          }, buildEmptyFieldContext())
        ];
      })
    );
  }

  function getBindingSchemaForObject(schema: JSONSchema4, typeFieldsContext: Record<string, FieldContext>): JSONSchema4 {
    const bindingSchema = {
      id: schema.id,
      title: schema.title,
      description: schema.description,
      type: schema.type,
    } as JSONSchema4;

    if (schema.type === 'array') {
      console.log('getBindingSchemaForObject: arrays ignored for now');
    } else if (schema.type === 'object') {
      _.merge(bindingSchema, { properties: {}, required: [] });
      for (const propKey in (schema.properties || {})) {
        const prop = schema.properties[propKey];
        bindingSchema.properties[propKey] = getBindingSchemaForObject(prop, typeFieldsContext);  
      }
    } else if (schema?.type === 'string' || schema?.type === 'number' || schema?.type === 'boolean' || schema?.type === 'integer') {
      let fieldContext = typeFieldsContext[schema?.type];
      if (_.isNil(fieldContext)) {
        fieldContext = buildEmptyFieldContext();
      }
      _.merge(bindingSchema, { enum: fieldContext.choices, options: { enum_titles: fieldContext.choiceTitles } });
    } else {
      console.log(`getBindingSchemaForObject: don't know how to type ${schema?.type}, ignoring`);
    }

    return bindingSchema;
  }

  return getBindingSchemaForObject(cardSchema, buildTypeFieldsContext(sourceSchema));
}
