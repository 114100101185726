import { JSONSchema4 } from "json-schema";
import { DIYSourceResponse } from "../../DIYSourceResponse";
import { AbstractDomain } from "../AbstractDomain";
import { ManualPassthrough, ReactiveMicrocomponent } from "lib/yinzcam-rma";
import { DIYSourceRequest } from "../../DIYSourceRequest";
import { injectable } from "inversify";

@injectable()
export class ExampleDomain extends AbstractDomain {
  public static DomainName: string = 'example.org';
  public static DisplayName: string = 'Example';

  public constructor() {
    super();
  }

  public getDomainName(): string {
    return ExampleDomain.DomainName;
  }

  public getDisplayName(): string {
    return ExampleDomain.DisplayName;
  }

  public async getPaths(specData?: Record<string, any>): Promise<{ name: string; path: string; }[]> {
    return [];
  }

  public async getConfigSchema(path?: string, specData?: Record<string, any>): Promise<JSONSchema4> {
    return {};
  }

  public async getResponseSchema(path?: string, specData?: Record<string, any>): Promise<JSONSchema4> {
    return {};
  }

  public getExtraParams(path: string, specData?: Record<string, any>): Record<string, string> {
    return {};
  }

  public getRequest(request: DIYSourceRequest): ReactiveMicrocomponent<DIYSourceResponse> {
    return new ManualPassthrough('example');
  }
  
  public processResponse(path: string, response: DIYSourceResponse, specData?: Record<string, any>): object | any[] {
    return [];
  }
}
