<script context="module" lang="ts">import 'json-schema';
export function getDisplayName() {
    return "Media: Headline Horizontal A";
}
export function getElementConfigSpec() {
    return {
        type: "object",
        properties: {
            padding: {
                type: "string",
                title: "Padding",
                description: "The padding around the content.",
                style: true
            },
            thumbnail: {
                type: "string",
                format: "url",
                title: "Thumbnail",
                description: "The thumbnail image."
            },
            aspectRatio: {
                type: "string",
                title: "Aspect Ratio",
                description: "The aspect ratio of the background image. Defaults to 21/4.",
                style: true
            },
            category: {
                type: "string",
                title: "Category Text",
                description: "The text representing the category."
            },
            publishDateTime: {
                type: "string",
                format: "date-time",
                title: "Publish Date and Time",
                description: "The publish date and time in ISO format."
            },
            readingTimeDuration: {
                type: "string",
                title: "Reading Time Duration",
                description: "The duration of reading time in ISO 8601 duration format."
            },
            headerFontColor: {
                type: "string",
                title: "Header Font Color",
                description: "The font color for the header text.",
                style: true
            },
            headerFontSize: {
                type: "string",
                title: "Header Font Size",
                description: "The font size for the header text.",
                style: true
            },
            headerFontWeight: {
                type: "string",
                title: "Header Font Weight",
                description: "The font weight for the header text.",
                style: true
            },
            name: {
                type: "string",
                title: "Title Text",
                description: "The main title text."
            },
            titleFontColor: {
                type: "string",
                title: "Title Font Color",
                description: "The font color for the title text.",
                style: true
            },
            titleFontSize: {
                type: "string",
                title: "Title Font Size",
                description: "The font size for the title text.",
                style: true
            },
            titleFontWeight: {
                type: "string",
                title: "Title Font Weight",
                description: "The font weight for the title text.",
                style: true
            },
            description: {
                type: "string",
                title: "Description Text",
                description: "The main description text."
            },
            descriptionFontColor: {
                type: "string",
                title: "Description Font Color",
                description: "The font color for the description text.",
                style: true
            },
            descriptionFontSize: {
                type: "string",
                title: "Description Font Size",
                description: "The font size for the description text.",
                style: true
            },
            descriptionFontWeight: {
                type: "string",
                title: "Description Font Weight",
                description: "The font weight for the description text.",
                style: true
            },
            cta: {
                type: "string",
                title: "CTA Text",
                description: "The text for the call-to-action button."
            },
            ctaLink: {
                type: "string",
                format: "url",
                title: "CTA Link",
                description: "The link for the call-to-action button."
            },
            ctaFontColor: {
                type: "string",
                title: "CTA Font Color",
                description: "The font color for the CTA text.",
                style: true
            },
            ctaFontSize: {
                type: "string",
                title: "CTA Font Size",
                description: "The font size for the CTA text.",
                style: true
            },
            ctaFontWeight: {
                type: "string",
                title: "CTA Font Weight",
                description: "The font weight for the CTA text.",
                style: true
            },
            slug: {
                type: "string",
                title: "Slug",
                description: "The slug for this media item. This will be used to generate the media item link if an explicit link is not provided.",
            },
            link: {
                type: "string",
                title: "Link",
                description: "The link for this media item. The default link will be generated from the slug if this is not provided (and the slug is).",
            },
        }
    };
}
export function getElementDataSourceSpec() {
    return {
        sources: [
            {
                type: 'SOURCE',
                title: 'Card Data',
                description: 'Arbitrary source of data for this card matching the configuration fields.',
                classes: '*'
            }
        ]
    };
}
</script>
    
<script lang="ts">import CardBase from "components/cards/common/CardBase.svelte";
import { buildInlineStyle, formatDateTimeRelative, formatDuration, imageAspectRatioAction, addClassOnHoverAction } from "components/cards/utilities";
import { writable } from "svelte/store";
import { t } from "js/i18n";
import { resolveUrl } from "js/url";
import 'components/cards/common/CardsElementInterfaces';
export let thumbnail;
export let category;
export let publishDateTime;
export let readingTimeDuration;
export let name;
export let description;
export let cta;
export let ctaLink;
export let slug;
export let link;
$: publishDateTimeDisplay = formatDateTimeRelative(publishDateTime);
$: readingTimeDurationDisplay = formatDuration(readingTimeDuration);
let outerHref = "#";
$: if (link) {
    outerHref = resolveUrl(link).href;
}
else if (slug) {
    // TODO: figure out a way to do this 
    outerHref = resolveUrl(`/modals/en/videopopup/${slug}`).href;
}
const aspectRatio = writable();
$: style = buildInlineStyle("aspect-ratio", $aspectRatio);
</script>

<CardBase>
  <a class="link" href={outerHref}>
    <div class="content-header" use:addClassOnHoverAction={'hovering'} {style}>
      <div class="image-area">
          <img src={thumbnail || ''} alt="" use:imageAspectRatioAction={aspectRatio} />
      </div>
      <div class="text-area">
          <div class="header-text">
              {category || ''} |
              <i class="f7-icons content-icon">clock</i>{publishDateTimeDisplay || ''} |
              <i class="f7-icons content-icon">book</i>{readingTimeDurationDisplay || '??'} {$t("reading time")}
          </div>
          <div class="title-text">
              {name || ''}
          </div>
          <div class="description-text">
              {description || ''}
          </div>
          {#if cta && ctaLink}
          <a href={ctaLink} class="cta-text">
              <i class="f7-icons content-icon">arrow_right</i>{cta}
          </a>
          {/if}
      </div>
    </div>
  </a>
</CardBase>

<style>
  *, *::before, *::after {
    box-sizing: border-box;
  }

  a.link {
    display: block;
  }

  .content-header {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    background-size: cover;
    background-position: top center;
    cursor: pointer;
    position: relative;
    aspect-ratio: var(--aspect-ratio, 21/4);
  }

  .image-area {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .image-area img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
    transition: transform 1.0s ease;
    -webkit-filter: brightness(80%);
            filter: brightness(80%)
  }

  .content-header.hovering .image-area img {
    transform: scale(1.2);
  }

  .text-area {
    z-index: 1;
    padding: var(--padding, 20px);
  }

  .header-text {
    color: var(--header-font-color, unset);
    font-size: var(--header-font-size, 1em);
    font-weight: var(--header-font-weight, 800);

    display: flex;
    align-items: center;
  }

  .title-text {
    color: var(--title-font-color, unset);
    font-size: var(--title-font-size, 2em);
    font-weight: var(--title-font-weight, 800);
  }

  .description-text {
    color: var(--description-font-color, unset);
    font-size: var(--description-font-size, 1em);
    font-weight: var(--description-font-weight, unset);
  }

  .cta-text {
    display: block;
    color: var(--cta-font-color);
    font-size: var(--cta-font-size, 1em);
    font-weight: var(--cta-font-weight, 800);
    margin-top: 2em;
  }

  .content-icon {
    font-size: 0.75em;
    font-weight: bold;
    margin-left: 0.4em;
    margin-right: 0.4em;
  }</style>