import { generateNumberSchema, generateRootObjectSchema, generateStringSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { ButtonParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("View More Button Card", generateRootObjectSchema({
  container: generateAtomContainerSchema(),
  elementId: generateStringSchema("Card Layer ID", "The Layer ID of the card to repeat when this button is clicked. Layer IDs can be found at the top of the editor form when an layer is selected."),
  repeatCount: generateNumberSchema("Repeat Count", "The number of repeats of the given element to create each time that the button is clicked.", {
    defaultValue: 6,
  }),
  button: ButtonParticleSpec.generateSchema("Button", "The button that will create more repeats of the given element when clicked.", {
    defaultValues: {
      showImage: false,
      showText: true,
      container: { padding: '1rem' },
      text: { text: "View More" }
    },
    uneditableFields: { link: true }
  }),
}));
