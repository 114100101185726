import { generateBooleanSchema, generateRootObjectSchema, generateStringSchema, generateUrlSchema, generateDateTimeSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { TextParticleSpec, ImageParticleSpec, ButtonParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("Subscribe newsletter card", generateRootObjectSchema({
    container: generateAtomContainerSchema({
        defaultValues: { themeMode: "inverted", background: { visible: true } }
    }),
    overlayPadding: generateStringSchema("Overlay Padding", "The padding (inset) of the overlay text/buttons relative to the background image.", {
        defaultValue: '1rem 1.5rem'
    }),
    imgTeamIcon: ImageParticleSpec.generateSchema("Team Icon", "Upload a team icon", {
        defaultValues: { container: { width: "5rem", height: "5rem" } }
    }),
    txtHeading: TextParticleSpec.generateSchema("Subscriber Heading", "Subscriber Heading", {
        defaultValues: { size: "1.25rem", weight: "900", }
    }),
    txtBody: TextParticleSpec.generateSchema("Body Text", "Body Text", {
        defaultValues: { size: "1rem", weight: "400" }
    }),
    imgSubmitLogo: ButtonParticleSpec.generateSchema("Submit Icon", "Select an icon for submit", {
        defaultValues: { container: { width: "1.5rem", height: "1.5rem" } }
    }),
    btnLater: ButtonParticleSpec.generateSchema("Later", "Later", {
        defaultValues: {
            text: { size: "1rem", weight: "600" },
            container: {
                padding: "0.875rem 1.5rem",
                border: {
                    visible: true, radius: "0.75rem",
                    style: "solid", width: "1px",
                },
            }
        }
    }),
    btnSubscribe: ButtonParticleSpec.generateSchema("Subscribe", "Subscribe", {
        defaultValues: {
            text: { size: "1rem", weight: "600", color: "var(--theme-mode-foreground-color, #0054A6)" },
            container: {
                padding: "0.875rem 1.5rem",
                border: { visible: true, radius: "0.75rem" },
                background: { visible: true, color: "var(--theme-mode-highlight-color,#4CE0AF)" }
            }
        }
    }),
    isLogoRequired: generateBooleanSchema("Is team logo required?", "Is team logo required?", {
        defaultValue: false,
    }),
    isButtonsRequired: generateBooleanSchema("Are buttons required?", "Are buttons required?", {
        defaultValue: false,
    })
}));