import { generateRootObjectSchema, generateArraySchema, generateObjectSchema, generateStringSchema, generateUrlSchema, generateColorSchema, generateBooleanSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { ButtonParticleSpec, ImageParticleSpec, TextParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("Loyalty Value", generateRootObjectSchema({
    container: generateAtomContainerSchema({ defaultValues: { themeMode: 'primary' } }),
    overlayPadding: generateStringSchema("Overlay Padding", "Overlay Padding"),
    imgCardBackground: ImageParticleSpec.generateSchema("Card Background Image", "Card Background Image", {
        defaultValues: { sizing: "cover" }
    }),
    txtMemberValue: TextParticleSpec.generateSchema("Member Value", "Member Value", {
        defaultValues: { size: "5.5rem", weight: "800" }
    }),
    txtRewardsText: TextParticleSpec.generateSchema("Rewards Text", "Rewards Text", {
        defaultValues: { size: "3rem", weight: "800" }
    }),
    txtSpendText: TextParticleSpec.generateSchema("Spend Text", "Spend Text", {
        defaultValues: { size: "3rem", weight: "500" }
    }),
    btnCTA: ButtonParticleSpec.generateSchema("CTA Button", "CTA Button", {
        defaultValues: { container: { border: { visible: true, radius: "50%" }, width: "4rem", height: "4rem" }, image: { icon: "yc_icon_share" } }
    }),
    colorBackgroundFill: generateColorSchema("Card Background Fill", "Card Background Fill"),
    colorPointsCard: generateColorSchema("Point Card Color", "Point Card Color"),
    colorRankCard: generateColorSchema("Rank Card Color", "Rank Card Color"),
    colorCtaButton: generateColorSchema("CTA Button Color", "CTA Button Color"),
    colorLinearGradient: generateColorSchema("Linear Gradient Color", "Linear Gradient Color"),
    profileSegment: generateStringSchema("Profile Segment", "The profile segment to pull value from."),
    profileField: generateStringSchema("Profile Field", "The profile field to pull value from."),
    hideIfNoValue: generateBooleanSchema("Hide If No Value", "Hide this card if no value data is available."),
}));
