<Boundary>
  {#if outlineMode}
  <CardsContainerWrapper style="width: 100%; overflow-x: scroll; display: grid; grid-auto-flow: column; grid-auto-columns: 200px; grid-column-gap: 10px; justify-content: safe center;" {editorOptions}>
    {#each layout.elements as element, i (element.id)}
      <CardsElement {parentSequenceId} sequenceId={generateSequenceId('ELT', parentSequenceId, i)} {element} height="100%" updater={elementUpdater.bind(null, i)} />
    {/each}
  </CardsContainerWrapper>
  {:else}
  <CardsContainerWrapper style="width: 100%;" {editorOptions}>
    <swiper-container
      bind:this={swiperComponent}
      init={false}
      simulate-touch={false}
      navigation={false}
      pagination={!!layout.swiperPagination}
      speed={layout.scrollSpeed || 400}
      slides-per-view={layout.maxColumns || 'auto'}
      space-between={layout.gapPixels || 0}
      effect={swipeEffect}
      free-mode={!layout.slideSnap}
      observer={true}
      observe-slide-children={false}
      center-insufficient-slides={false}
      slides-per-group={layout.slidesPerGroup || 1}
      grab-cursor={true}
      coverflow-effect-rotate={0}
      coverflow-effect-stretch={0}
      coverflow-effect-depth={100}
      coverflow-effect-modifier={4}
      coverflow-effect-slide-shadows={false}
      loop={!!layout.loop}
      autoplay={!!layout.autoplay}
      lazy-preload-prev-next={layout.preloadSlideCount || 3}
      loop-additional-slides={layout.loopExtraSlides || 3}
    >
      {#each layout.elements as element, i (element.id)}
      <swiper-slide>
        <div class="cards-array-splide-slide-wrapper">
          <CardsElement {parentSequenceId} sequenceId={generateSequenceId('ELT', parentSequenceId, i)} {element} updater={elementUpdater.bind(null, i)} />
        </div>
      </swiper-slide>
      {/each}
    </swiper-container>
  </CardsContainerWrapper>
  {/if}
</Boundary>

<style>
  *, *::before, *::after {
    box-sizing: border-box;
  }</style>

<script lang="ts" context="module">import { register } from 'swiper11/swiper-element-bundle.mjs';
register();
</script>

<script lang="ts">import "yinzcam-cards";
import { generateSequenceId } from "../utilities";
import CardsElement from "./CardsElement.svelte";
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import "./CardsEditorInterfaces";
import CardsContainerWrapper from "./CardsContainerWrapper.svelte";
import { CardsPageContextKey } from "./context";
import { getContext, onMount } from "svelte";
import './actions';
export let parentSequenceId = "";
export let layout;
export let updater;
export const messageHandler = (action) => {
    switch (action === null || action === void 0 ? void 0 : action.effect) {
        case 'carousel-left': {
            onPrev();
            break;
        }
        case 'carousel-right': {
            onNext();
            break;
        }
    }
};
let swiperComponent = null;
const onPrev = () => {
    var _a;
    (_a = swiperComponent === null || swiperComponent === void 0 ? void 0 : swiperComponent.swiper) === null || _a === void 0 ? void 0 : _a.slidePrev(layout.scrollSpeed || 400);
};
const onNext = () => {
    var _a;
    (_a = swiperComponent === null || swiperComponent === void 0 ? void 0 : swiperComponent.swiper) === null || _a === void 0 ? void 0 : _a.slideNext(layout.scrollSpeed || 400);
};
const pageContext = getContext(CardsPageContextKey);
const emmEnabled = pageContext.editorModeManager.getEnabledStore();
const emmPreview = pageContext.editorModeManager.getPreviewStore();
$: outlineMode = $emmEnabled && !$emmPreview;
$: originalSwipeEffect = (layout.swipeEffect || '').toLowerCase();
$: swipeEffect = (function (_swipeEffect) {
    _swipeEffect = (_swipeEffect || 'slide').toLowerCase();
    switch (_swipeEffect) {
        case 'magnify':
            _swipeEffect = 'coverflow';
            break;
    }
    return _swipeEffect;
})(layout.swipeEffect);
function elementUpdater(i, element) {
    layout.elements[i] = element;
    updater(layout.elements);
}
let editorOptions;
$: editorOptions = {
    parentSequenceId,
    items: layout.elements,
    updater
};
function onSwiperInit(swiper) {
    if (layout.initialSlideNumber > 0) {
        for (let i = 0; i < layout.initialSlideNumber; i++) {
            swiper.slideNext(0);
        }
    }
    // little hack to get it to load previous slides 
    if (layout.loop) {
        swiper.slideNext(0);
        swiper.slidePrev(0);
    }
}
onMount(() => {
    swiperComponent.on = {
        afterInit: onSwiperInit
    };
    swiperComponent.initialize();
});
</script>
