<script lang="ts">import 'yinzcam-cards';
import { getFirstSourceStore } from '../../../utilities';
import '../../../common/CardsDataSourceRegistration';
import 'svelte/store';
export let element;
export let sources;
export let source;
let data = [];
let ClientIsHome = false;
let HomeTeam = {};
let AwayTeam = {};
$: {
    source = getFirstSourceStore($sources);
    ClientIsHome = $source === null || $source === void 0 ? void 0 : $source.ClientIsHome;
    HomeTeam = ($source === null || $source === void 0 ? void 0 : $source.HomeTeam) || {};
    AwayTeam = ($source === null || $source === void 0 ? void 0 : $source.AwayTeam) || {};
    data = ($source === null || $source === void 0 ? void 0 : $source.data) || [];
}
const getStyle = (item) => {
    const total = item.HomeValue + item.AwayValue;
    const percent = total ? (ClientIsHome ? item.HomeValue : item.AwayValue) * 100 / total : 50;
    const ret = `
      width: ${Math.round(percent)}%;
      ${ClientIsHome ? 'left: 0; border-top-right-radius: 5px; border-bottom-right-radius: 5px;' : 'right: 0; border-top-left-radius: 5px; border-bottom-left-radius: 5px;'}
    `;
    return ret;
};
</script>

<div class="analysis-card">
  <div class="team-info">
    <div class="logo-wrapper">
      <img src={HomeTeam.Logo} alt="TeamLogo">
    </div>
    <div class="logo-wrapper">
      <img src={AwayTeam.Logo} alt="TeamLogo">
    </div>
  </div>
  {#each data as item}
    <div class="analysis-item">
      <div class="analysis-info">
        <div class="home">{item.Home}</div>
        <div class="type">{item.Name}</div>
        <div class="away">{item.Away}</div>
      </div>
      <div class="bar">
        <div class="colored" style={getStyle(item)}></div>
      </div>
    </div>
  {/each}
</div>

<style lang="scss">*,
*:before,
*:after {
  box-sizing: border-box; }

.analysis-card {
  border: 2px solid #f1f1f1;
  border-radius: 12px;
  padding: 0 0 40px 0;
  background: var(--theme-primary-background-color, white);
  width: 100%; }

.team-info {
  display: flex;
  height: 75px;
  justify-content: space-between;
  align-items: center; }
  .team-info .logo-wrapper {
    width: 91px;
    text-align: center; }
    .team-info .logo-wrapper img {
      display: inline-block;
      height: 43px; }

.analysis-item {
  padding: 0 0 10px 0; }
  .analysis-item .analysis-info {
    display: flex;
    line-height: 40px;
    width: 100%;
    border-top: 1px solid #f1f1f1;
    justify-content: space-between; }
    .analysis-item .analysis-info .home, .analysis-item .analysis-info .away {
      width: 91px;
      font-size: 14px;
      text-align: center;
      border-right: 1px solid #f1f1f1;
      color: var(--theme-primary-foreground-color, black); }
    .analysis-item .analysis-info .away {
      border-left: 1px solid #f1f1f1;
      border-right: none; }
    .analysis-item .analysis-info .type {
      text-align: center; }
    .analysis-item .analysis-info .client {
      background: var(--theme-inverted-background-color, white);
      border: 1px solid var(--theme-inverted-background-color, white);
      color: var(--theme-inverted-foreground-color, white); }
  .analysis-item .bar {
    height: 10px;
    width: 100%;
    background: #f1f1f1;
    position: relative; }
    .analysis-item .bar .colored {
      position: absolute;
      top: 0;
      right: 0;
      background: var(--theme-inverted-background-color, white);
      height: 100%; }

@media (max-width: 767px) {
  .analysis-card {
    padding: 0 0 20px 0; }
  .team-info {
    height: 56px; }
    .team-info .logo-wrapper {
      width: 56px;
      text-align: center; }
      .team-info .logo-wrapper img {
        height: 32px; }
  .analysis-item {
    padding: 0 0 8px 0; }
    .analysis-item .analysis-info {
      line-height: 30px; }
      .analysis-item .analysis-info .home, .analysis-item .analysis-info .away {
        width: 56px;
        font-size: 10.4px; }
      .analysis-item .analysis-info .type {
        font-size: 10.4px; }
    .analysis-item .bar {
      height: 7px; } }</style>