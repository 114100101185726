import { Container } from "inversify";
import { TokenContainerModule } from "inversify-token";
import { YinzCamInjectModule } from "yinzcam-inject";
import { InstagramServer, InstagramServerToken } from "./InstagramServer";

export let InstagramServerModule: YinzCamInjectModule = new YinzCamInjectModule((container: Container): void => {
  container.load(new TokenContainerModule((bindToken) => {
    bindToken<InstagramServer>(InstagramServerToken).to(InstagramServer).inSingletonScope();
  }));
});
