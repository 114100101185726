<script lang="ts">import 'yinzcam-cards';
import CardBase from '../../../common/CardBase.svelte';
import '../../../common/CardsDataSourceRegistration';
import 'svelte/store';
import { windowMetrics as wm } from '../../../../../js/stores';
export let element;
export let sources;
const teamLogo = 'https://resources-uk.yinzcam.com/soccer/shared/logos/fa_tot_dark.png';
let elemHeader;
let isDesktop;
$: isDesktop = ($wm.width >= 768);
</script>

<CardBase {element}>
  <header class="page-header" bind:this={elemHeader}>
    <div class="menu">
      <a href="https://www.tottenhamhotspur.com/" class="external logo" target="_blank">
        <img src={teamLogo} alt="logo" />
      </a>
      <span class="title">Matchday Centre</span>
      <a href="https://www.tottenhamhotspur.com/" class="chevron button external" target="_blank">
        <i class="f7-icons">chevron_left</i>
      </a>
    </div>
  </header>
</CardBase>

<style lang="scss">*,
*:before,
*:after {
  box-sizing: border-box; }

.page-header {
  width: 100%;
  z-index: 1; }

.menu {
  height: 60px;
  width: 100%;
  background-color: #0B0E1E;
  flex-direction: row;
  padding: 0;
  border-bottom: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; }
  .menu .title {
    display: block;
    font-size: 28px;
    color: white; }
  .menu .logo {
    position: fixed;
    left: 10px;
    height: 45px; }
    .menu .logo img {
      height: 100%; }
  .menu .chevron {
    position: fixed;
    margin: 0;
    right: 15px;
    color: white; }

@media (max-width: 767px) {
  .menu {
    height: 60px;
    width: 100%;
    flex-direction: row;
    padding: 0;
    border-bottom: none; }
    .menu .title {
      display: block;
      font-size: 20px;
      color: white; }
    .menu .logo {
      position: fixed;
      left: 10px;
      height: 45px; }
    .menu .chevron {
      position: fixed;
      margin: 0;
      right: 15px; } }</style>
