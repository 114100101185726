<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import _ from "lodash";
import { BaseAtom, getSignonManager } from "components/cards/atoms";
import { ButtonParticle } from "components/cards/particles/YinzCam";
import { resolveUrl } from "js/url";
export let container = undefined;
export let defaultButton = undefined;
export let loggedOutButton = undefined;
export let loggedInButton = undefined;
const sso = getSignonManager();
const profile = sso.getDefaultProfileSegmentStore();
$: loginStatus = sso.getStatusComponent().store;
$: isLoggedIn = $loginStatus.loggedIn;
let currentButton = loggedOutButton;
let buttonOverrides = {};
$: if (isLoggedIn) {
    currentButton = loggedInButton;
    if ($profile === null || $profile === void 0 ? void 0 : $profile['first_name']) {
        buttonOverrides = { text: { text: $profile === null || $profile === void 0 ? void 0 : $profile['first_name'] } };
    }
}
else {
    currentButton = loggedOutButton;
    buttonOverrides = {};
}
async function handleSignInButtonClick() {
    if (isLoggedIn) {
        resolveUrl('profile').func();
    }
    else {
        sso.getSdk().requireAuth();
    }
}
</script>

<style></style>

<BaseAtom _isotope="LoginButtonAtom" {...container}>
  <ButtonParticle {..._.merge({}, defaultButton, currentButton, buttonOverrides)} on:click={handleSignInButtonClick} />
</BaseAtom>
