import { CardsDataSourceBase } from '../../common/CardsDataSourceBase'
import { injectable } from 'inversify'
import { injectToken } from 'inversify-token'
import { YinzCamAPIResponse } from 'yinzcam-api'
import { YinzCamAppServerToken } from 'yinzcam-appserver'

@injectable()
export class YinzCamAppServerGameCommentaryArray extends CardsDataSourceBase {
  public constructor(@injectToken(YinzCamAppServerToken) server) {
    super({ server })
  }

  protected processResponse(path: string, response: YinzCamAPIResponse, specData?: { [key: string]: any }): object | any[] {
    const data = response?.data as any
    let ret = this.getPlays(data)
    if (specData?.checkAvaliable) {
      return { dataReady: ret.length > 0 }
    }

    if (ret.length) {
      ret.unshift( { heading: "Live Reporting" } );
    }
    return ret
  }

  getType(e: any): string {
    switch (e?._attributes?.Type) {
      case 'foul':
        return 'FOUL'
      case 'Y':
      case 'yellow':
        return 'YELLOW'
      case 'red':
        return 'RED'
      case 'corner':
        return 'CORNER'
      case 'S':
      case 'sub':
        return 'SUB'
      case 'D':
      case 'secondyellow':
        return 'SECOND_YELLOW'
      case 'offside':
        return 'OFF_SIDE'
      case 'R':
      case 'red':
        return 'RED'
      case 'G':
      case 'goal':
        return 'GOAL'
      case 'penalty goal':
        return 'PENALTY GOAL'
      case 'startperiod':
        return 'START_PERIOD'
      case 'endperiod':
        return 'END_PERIOD'
      case 'embed':
        if (e?.EmbedCode?._text?.indexOf("https://www.youtube.com") > -1) {
          return 'YOUTUBE'
        } else if (e?.EmbedCode?._text?.indexOf("twitter-tweet") > -1) {
          return 'TWITTER'
        }
        return 'EMBED'
      case 'quote':
        return 'QUOTE'
      case 'text':
        return 'TEXT'
      case 'image':
        return 'IMAGE'
      case 'own goal':
        return 'OWN GOAL'
      default:
        return 'NONE'
    }
  }

  getTitle(Type: string,  e: any) {
    if (e?._attributes?.Title) {
      return e?._attributes?.Title
    }

    return (Type === 'GOAL' && 'Goal')
      || (Type === 'SUB' && 'Substitution')
      || (Type === 'FOUL' && 'Foul')
      || (Type === 'YELLOW' && 'Yellow')
      || (Type === 'RED' && 'Red')
      || (Type === 'SECOND_YELLOW' && 'Second Yellow')
      || (Type === 'OFF_SIDE' && 'Offside')
      || (Type === 'START_PERIOD' && 'KICK-OFF')
      || (Type === 'END_PERIOD' && 'End Period')
      || (Type === 'CORNER' && 'Corner')
      || (Type === 'OWN GOAL' && 'OWN GOAL')
      || (Type === 'PENALTY GOAL' && 'GOAL')
  }

  getPlays(data: any): any[] {
    if (!data?.PlayByPlay?.Plays?.Play) {
      return []
    }

    const HomeTeam = {
      Id: data?.PlayByPlay?.HomeTeam?._attributes?.Id,
      TriCode: data?.PlayByPlay?.HomeTeam?._attributes?.TriCode,
      LogoId: data?.PlayByPlay?.HomeTeam?._attributes?.LogoId,
      FullName: data?.PlayByPlay?.HomeTeam?._attributes?.FullName,
      ImageUrl: data?.PlayByPlay?.HomeTeam?.ImageUrl?._text,
      ImageUrlDark: data?.PlayByPlay?.HomeTeam?.ImageUrlDark?._text,
    }

    const AwayTeam = {
      Id: data?.PlayByPlay?.AwayTeam?._attributes?.Id,
      TriCode: data?.PlayByPlay?.AwayTeam?._attributes?.TriCode,
      LogoId: data?.PlayByPlay?.AwayTeam?._attributes?.LogoId,
      FullName: data?.PlayByPlay?.AwayTeam?._attributes?.FullName,
      ImageUrl: data?.PlayByPlay?.AwayTeam?.ImageUrl?._text,
      ImageUrlDark: data?.PlayByPlay?.AwayTeam?.ImageUrlDark?._text,
    }
    const commentaryLength = data?.PlayByPlay?.Plays?.Play?.length;
    // const arr = []
    const Plays = [].concat(data?.PlayByPlay?.Plays?.Play).filter(e => e?._attributes).map((e, i) => {
      // if (!arr.includes(e?._attributes?.Type)) {
      //   arr.push(e?._attributes?.Type)
      // }
      const Type = this.getType(e)
      const IsHome = e?._attributes?.IsHome ? JSON.parse(e?._attributes?.IsHome) : null
      let PlayerTeam = IsHome ? HomeTeam : AwayTeam
      if (Type === "OWN GOAL") {
        PlayerTeam = (Number(HomeTeam.Id) === Number(e?.Player1?.TeamId?._text || 0) ? AwayTeam : HomeTeam)
      }

      return {
        Id: e?._attributes?.Id,
        IsHome,
        Type,
        Time: e?._attributes?.Time,
        TimeDetail: e?._attributes?.TimeDetail,
        Timestamp: e?._attributes?.Timestamp,
        Title: this.getTitle(Type, e),
        Author: e?._attributes?.Author,
        HomeScore: e?._attributes?.HomeScore,
        AwayScore: e?._attributes?.AwayScore,
        Text: e?.Text?._text,
        ImageUrl: e?.ImageUrl?._text,
        FacebookUrl: e?.FacebookUrl?._text,
        TwitterUrl: e?.TwitterUrl?._text,
        Url: e?.Url?._text,
        EmbedCode: e?.EmbedCode?._text,
        HomeTeam,
        AwayTeam,
        PlayerTeam,
        Player1: {
          Id: e?.Player1?.Id?._text,
          Name: e?.Player1?.Name?._text,
          ImageUrl: e?.Player1?.ImageUrl?._text,
          TeamId: e?.Player1?.TeamId?._text,
        },
        Player2: {
          Id: e?.Player2?.Id?._text,
          Name: e?.Player2?.Name?._text,
          ImageUrl: e?.Player2?.ImageUrl?._text,
          TeamId: e?.Player2?.TeamId?._text,
        },
        LastCard: i === 0,
        FirstCard: i === commentaryLength - 1,
        BackgroundImageUrl: e?.BackgroundImageUrl?._text,
        CommentsCount: commentaryLength,
      }
    }).filter(e => !((e.Type === 'NONE' || e.Type === 'TEXT') && !e.Text && !e.Title))

    // console.log("arr: ", arr)
    return Plays
  }
}
