export const statsNameList = [
    "Accurate Crosses", "Appearances", "Assists", "Away Assists", "Away Goals", "Backward Passes", "Blocks",
    "Clean Sheets", "Clearances", "Corner Kicks", "Corners Won", "Did Not Play", "Draws", "Duels", "Duels Lost",
    "Effective Head Clearance", "Expected Goals", "Foul Attempted Tackles", "Fouls Committed", "Fouls Suffered",
    "Forward Passes", "Games Started", "Game Winning Assists", "Game Winning Goals", "GK Smother",
    "GK Successful Distribution", "GK Unsuccessful Distribution", "Goal Kicks", "Goals", "Goals Conceded",
    "Goals Conceded Inside Box", "Goals Conceded Outside Box", "Goals For", "Ground Duels Lost", "Ground Duels Won",
    "Handballs Conceded", "Head Clearance", "Headed Goals", "Hit Woodwork", "Home Assists", "Home Goals", "Inactive",
    "Index", "Intentional Assists", "Interceptions", "Key Passes", "Left Foot Goals", "Left Side Passes", "Losses",
    "Minutes Played", "Offsides", "Own Goals", "Parried Saves", "Pass Accuracy", "Passes", "Penalty Goals Conceded",
    "Penalty Kick Attempts", "Penalty Kick Goals", "Penalties Won by Fouls", "Possession", "Punches",
    "Put Through/Blocked Distribution", "Recoveries", "Red Cards", "Right Foot Goals", "Right Side Passes", "Saves",
    "Saves Made fron Inside Box", "Saves Made fron Outside Box", "Shots", "Shots Blocked", "Shots Off Target",
    "Shots On Target", "Shutouts", "Substitute Off", "Substitutions", "Successful Aerial Duels",
    "Successful Crosses and Corners", "Successful Dribbles", "Successful Layoffs", "Successful Long Passes",
    "Successful Open Play Crosses", "Successful Passes", "Successful Passes in Opposition Half",
    "Successful Passes in Own Half", "Successful Short Passes", "Successful Launches", "Tackles", "Tackles Suffered",
    "Throw-Ins", "Through Balls", "Touches", "Total Long Passes", "Total Losses of Possession", "Total Open Play Crosses",
    "Unsuccessful Dribbles", "Unsuccessful Layoffs", "Unsuccessful Launches", "Unsuccessful Short Passes", "Wins",
    "Winning Goals", "Yellow Cards"
];

export const matchCenterStatList = [
    "Passes",
    "Pass Accuracy",
    "Possession",
    "Shots",
    "Shots On Target",
    "Fouls Suffered",
    "Corners",
    "Offsides",
    "Saves",
    "Clearances",
    "Blocks",
    "Fouls Committed",
    "Yellow Cards",
    "Red Cards",
    "Shooting Accuracy",
    "Throw-Ins"
]

export const personalStatList = [
    "Name",
    "ShortName",
    "LastName",
    "Team",
    "Number",
    "Position",
    "Date of Birth",
    "Nationality",
    "Height",
    "Weight",
    "Age",
    "BioValues",
    "ImageUrl"
]

export const monthList = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

export const resultsYears = [{
    option: "2024/25",
    value: "2024"
}, {
    option: "2023/24",
    value: "2023"
}, {
    option: "2022/23",
    value: "2022"
}, {
    option: "2021/22",
    value: "2021"
}, {
    option: "2020/21",
    value: "2020"
}, {
    option: "2019/20",
    value: "2019"
}, {
    option: "2018/19",
    value: "2018"
}, {
    option: "2017/18",
    value: "2017"
},]