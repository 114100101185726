<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import { get } from "lodash";
import { BaseAtom } from "components/cards/atoms";
import { TextParticle, ImageParticle, ButtonParticle, } from "components/cards/particles/YinzCam";
import { writable } from "svelte/store";
import { resolveUrl } from "js/url";
const hovering = writable(false);
export let container;
export let overlayPadding = undefined;
export let ctaType = "button";
export let headerIcon;
export let headerText;
export let bodyText;
export let thumbnail;
export let ctaButton;
export let isBodyTextRequired = true;
export let link;
export let headerObject;
export let ctaIcon;
export let imageGradient;
export let headerBarBackgroundColor;
export let reverseOrientation;
export let textBackgroundColor;
export let scaleToThumbnailAspectRatio;
export let textBoxWidth;
let atomWidthRem;
let thumbnailAspectRatio;
$: headerTextMultiplier =
    atomWidthRem <= 30 ? "0.416" : atomWidthRem <= 45 ? "0.666" : "1.00";
$: bodyTextMultiplier = atomWidthRem <= 30 ? "0.60" : "1.00";
$: textWidth = `${parseInt(textBoxWidth) < 100 ? textBoxWidth : 50}%`;
$: imageWidth = `${100 - parseInt(textWidth)}%`;
</script>

<BaseAtom
    _isotope="CTAHorizontalAtom"
    {...container}
    _link={resolveUrl(link).href}
    _cssVariablePrefix="cta-horizontal"
    backgroundColor={headerBarBackgroundColor}
    {overlayPadding}
    {textBackgroundColor}
    {thumbnailAspectRatio}
    {textWidth}
    {imageWidth}
    bind:_clientWidthRem={atomWidthRem}
>
    <div
        class="main-container"
        class:layout-mobile-width={atomWidthRem <= 30}
        class:layout-tablet-width={atomWidthRem <= 45 && atomWidthRem > 30}
        class:reverse-orientation={reverseOrientation === "text"}
        class:scale-to-thumbnail-aspect-ratio={scaleToThumbnailAspectRatio}
    >
        <div class="image-container">
            <ImageParticle
                _block={true}
                _hovering={$hovering}
                bind:_sourceAspectRatio={thumbnailAspectRatio}
                {...thumbnail}
            />
        </div>
        <div
            class="text-container"
            class:layout-mobile-width={atomWidthRem < 30}
            class:layout-tablet-width={atomWidthRem < 45}
        >
            <div class="heading-text">
                <TextParticle
                    {...headerText}
                    size={`calc(${get(headerText, "size")} * ${headerTextMultiplier})`}
                />
            </div>
            <div class="body-text">
                <TextParticle
                    {...bodyText}
                    size={`calc(${get(bodyText, "size")} * ${bodyTextMultiplier})`}
                />
            </div>
            {#if ctaButton && (ctaButton.text?.text || ctaButton.image?.source || ctaButton.image?.icon)}
                <ButtonParticle {...ctaButton} />
            {/if}
        </div>
    </div>
</BaseAtom>

<style>
    .main-container {
        display: flex;
        flex-direction: row;
        background-color: #fff;
        height: 100%;
        width: 100%;
    }

    .main-container.layout-mobile-width,
    .main-container.layout-tablet-width {
        flex-direction: column;
        width: 100%;
    }

    .main-container.layout-mobile-width .text-container, .main-container.layout-tablet-width .text-container {
            width: 100%;
        }

    .main-container.layout-mobile-width .image-container, .main-container.layout-tablet-width .image-container {
            width: 100%;
        }

    .main-container.reverse-orientation {
        flex-direction: row-reverse;
        width: 100%;
    }

    .main-container.layout-mobile-width.reverse-orientation,
    .main-container.layout-tablet-width.reverse-orientation {
        flex-direction: column-reverse;
        width: 100%;
    }

    .main-container.layout-mobile-width.reverse-orientation .text-container, .main-container.layout-tablet-width.reverse-orientation .text-container {
            width: 100%;
        }

    .main-container.layout-mobile-width.reverse-orientation .image-container, .main-container.layout-tablet-width.reverse-orientation .image-container {
            width: 100%;
        }

    .main-container.scale-to-thumbnail-aspect-ratio .image-container {
        aspect-ratio: var(--cta-horizontal-thumbnail-aspect-ratio);
    }

    .main-container.scale-to-thumbnail-aspect-ratio {
        height: auto;
    }

    .text-container {
        width: var(--cta-horizontal-text-width, 50%);
        padding: var(--cta-horizontal-overlay-padding, unset);
        display: flex;
        flex-direction: column;
        gap: 1.125rem;
        background-color: var(--cta-horizontal-text-background-color);
    }

    /* .text-container.layout-tablet-width {
        width: 100%;
    }
    .text-container.layout-mobile-width {
        width: 100%;
    }
    .image-container.layout-tablet-width {
        width: 100%;
    }
    .image-container.layout-mobile-width {
        width: 100%;
    } */
    .image-container {
        width: var(--cta-horizontal-image-width, 50%);
    }</style>
