<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import "lodash";
import { BaseAtom } from "components/cards/atoms";
import { ButtonParticle } from "components/cards/particles/YinzCam";
import { sendMessageToComponent } from "components/cards/utilities";
import "components/cards/common/actions";
export let container = undefined;
export let elementId = undefined;
export let repeatCount = undefined;
export let button = undefined;
function handleClick() {
    sendMessageToComponent(elementId, {
        target: elementId,
        effect: 'add-repeats',
        repeats: repeatCount
    });
}
</script>

<style></style>

<BaseAtom _isotope="ButtonAtom" {...container}>
  <ButtonParticle {...button} on:click={handleClick} />
</BaseAtom>
