import { parse, format } from '@tusbar/cache-control';

// More info on cache-control library: https://github.com/tusbar/cache-control

// Useful reference: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Cache-Control
// Used this tool to generate this interface from a raw JSON object produced by the library: https://app.quicktype.io/
export interface CacheControlInfo {
  maxAge?:               number;
  sharedMaxAge?:         number;
  maxStale?:             boolean;
  maxStaleDuration?:     number;
  minFresh?:             number;
  immutable?:            boolean;
  mustRevalidate?:       boolean;
  noCache?:              boolean;
  noStore?:              boolean;
  noTransform?:          boolean;
  onlyIfCached?:         boolean;
  private?:              boolean;
  proxyRevalidate?:      boolean;
  public?:               boolean;
  staleWhileRevalidate?: number;
  staleIfError?:         number;
}

export class CacheControl {
  static parse(header: string): CacheControlInfo {
    return parse(header);
  }
  static format(cci: CacheControlInfo) {
    return format(cci);
  }
}
