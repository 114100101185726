import { generateRootObjectSchema, generateArraySchema, generateObjectSchema, generateColorSchema, generateStringSchema, generateBooleanSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { ButtonParticle, ButtonParticleSpec, ImageParticleSpec, TextParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("League Table Two", generateRootObjectSchema({
    container: generateAtomContainerSchema(),
    containerPadding: generateStringSchema("Container Padding", "Padding for the container"),
    txtHeading: TextParticleSpec.generateSchema("Title", "Enter title to commentary", {
        defaultValues: { size: "1rem", weight: "800" }
    }),
    txtHeaderData: TextParticleSpec.generateSchema("Table Header", "Table Header", {
        defaultValues: { size: "1rem", weight: "700" }
    }),
    txtRowData: TextParticleSpec.generateSchema("Table Row Data", "Table Row Data", {
        defaultValues: { size: "1rem", weight: "700" }
    }),
    imgTeamLogo: ImageParticleSpec.generateSchema("Team Logo", "Add a team logo", {
        defaultValues: { container: { width: "2.5rem", height: "2.5rem" }, sizing: "cover" }
    }),
    btnFullTable: ButtonParticleSpec.generateSchema("Full Table", "Show full table button", {
        defaultValues: { text: { size: "1rem", weight: "800" } }
    }),
    colorHeadingBackground: generateColorSchema("Heading Background", "Pick a color for heading background"),
    tableHeaderColor: generateColorSchema("Table Header Color", "Pick a color for table header")
}));