import { injectable } from 'inversify';
import { injectToken, Token } from 'inversify-token';
import { ReactiveMicrocomponent } from 'lib/yinzcam-rma';
import { YinzCamAPI, YinzCamAPIRequest, YinzCamAPIResponse, YinzCamAPIToken, YinzCamServer } from 'yinzcam-api';

export const YinzCamCardsServerV1Token = new Token<YinzCamCardsServerV1>(Symbol.for("YinzCamCardsServerV1Token"));

@injectable()
export class YinzCamCardsServerV1 {
  private readonly server: YinzCamServer;

  public constructor(@injectToken(YinzCamAPIToken) private readonly api: YinzCamAPI) {
    this.server = api.getServer('cards', 'V1/');
  }

  public getRequest(request: YinzCamAPIRequest) {
    return this.server.getRequest(request);
  }

  public getCardsList(name: string, isPreview: boolean = false): ReactiveMicrocomponent<YinzCamAPIResponse> {
    return this.server.getRequest({ path: `Card/${name}${(isPreview) ? '/Preview' : ''}` });
  }

  public getWaistband(name: string, isPreview: boolean = false): ReactiveMicrocomponent<YinzCamAPIResponse> {
    return this.server.getRequest({ path: `Waistband/${name}${(isPreview) ? '/Preview' : ''}` });
  }
}
