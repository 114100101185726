<script lang="ts">import ImageWithPlaceholder from '../../ImageWithPlaceholder/ImageWithPlaceholder.svelte';
import footballImage from '../../image/football_icon_white.png';
import 'framework7-svelte';
export let Play = {};
</script>

<div class="live-reporting-goal-card">
  <div class="headline">
    <div class="time">
      {Play.Time || ""}
    </div>
    <div class="score">
      <div class="team-name">{Play.HomeTeam.TriCode}</div>
      <div class="score-info">
        {Play.HomeScore || 0}-{Play.AwayScore || 0}
      </div>
      <div class="team-name">{Play.AwayTeam.TriCode}</div>
    </div>
  </div>
  <div class="live-content">
    <div class="goal-content-media">
      <div class="media">
        <ImageWithPlaceholder
          title={Play.Player1.Name}
          src={Play.Player1.ImageUrl}
          alt="Player"
          class="player-image"
          placeholder="/static/images/download.png"
        />
      </div>
      <div class="media-body">
        <div class="heading">
          <img src={footballImage} alt="Footballl" class="heading-icon" />
          <h3>{Play.Title}!</h3>
        </div>
        <div>
          <div class="name">{Play.Player1.Name}</div>
        </div>
      </div>
    </div>
    <div class="live-body">
      {Play.Text || ""}
    </div>
  </div>
  <!-- <div class="link-container">
    <Link href={Play.FacebookUrl} external={true} target="_blank">
      <i class="f7-icons">logo_facebook</i>
    </Link>
    <Link href={Play.TwitterUrl} external={true} target="_blank">
      <i class="f7-icons">logo_twitter</i>
    </Link>
    <Link href={Play.Url} external={true} target="_blank">
      <i class="f7-icons">link</i>
    </Link>
  </div> -->
</div>

<style lang="scss">*,
*:before,
*:after {
  box-sizing: border-box; }

.live-reporting-goal-card {
  padding: 28px 24px 24px 42px;
  background: var(--theme-inverted-background-color, black);
  border-radius: 12px;
  width: 100%; }

.headline {
  display: flex;
  justify-content: space-between; }
  .headline .time {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.23;
    text-align: center;
    color: var(--theme-inverted-foreground-color, white); }
  .headline .score {
    display: flex;
    align-items: center; }
    .headline .score .team-name {
      font-size: 16px;
      font-weight: bold;
      line-height: 1.38;
      color: var(--theme-inverted-foreground-color, white); }
    .headline .score .score-info {
      margin: 0px 12px;
      font-size: 24px;
      font-weight: bold;
      line-height: 0.95;
      letter-spacing: normal;
      text-align: center;
      color: var(--theme-inverted-foreground-color, white); }

.live-content {
  display: flex;
  justify-content: space-between;
  margin: 15px 0 0 0; }
  .live-content .goal-content-media {
    display: flex;
    align-items: center; }
    .live-content .goal-content-media .media {
      width: 75px;
      height: 75px;
      overflow: hidden;
      border-radius: 50%;
      background: #9aa7a7; }
      .live-content .goal-content-media .media :global(.player-image) {
        width: 100%;
        height: 100%; }
      .live-content .goal-content-media .media :global(img) {
        width: 100%;
        height: 100%; }
    .live-content .goal-content-media .media-body {
      margin: 0 0 0 24px; }
      .live-content .goal-content-media .media-body .heading {
        margin: 0 0 9px 0;
        display: flex; }
        .live-content .goal-content-media .media-body .heading img {
          width: 29px;
          height: 29px;
          display: block; }
        .live-content .goal-content-media .media-body .heading h3 {
          font-size: 32px;
          font-weight: bold;
          line-height: 1;
          color: #ffffff;
          margin: 0 0 0 12px;
          text-transform: uppercase; }
      .live-content .goal-content-media .media-body .name {
        display: inline-block;
        margin: 9px 0 0 0;
        padding: 0 8px 1px;
        background-color: var(--theme-primary-highlight-color, black);
        line-height: 36px;
        font-size: 32px;
        font-weight: 900;
        color: var(--theme-primary-foreground-color, black); }
  .live-content .live-body {
    width: 50%;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: var(--theme-inverted-foreground-color, white);
    white-space: pre-line; }

.link-container {
  display: flex;
  justify-content: flex-end;
  gap: 24px; }
  .link-container :global(a .f7-icons) {
    font-size: 24px;
    color: var(--theme-inverted-foreground-color, white); }

@media (max-width: 767px) {
  .live-reporting-goal-card {
    padding: 6px 12px 12px 10px; }
  .headline {
    display: flex;
    justify-content: space-between; }
    .headline .time {
      font-size: 14px;
      line-height: 1.83; }
    .headline .score {
      display: flex;
      align-items: center; }
      .headline .score .team-name {
        font-size: 12px;
        line-height: 1; }
      .headline .score .score-info {
        margin: 0px 4px;
        font-size: 16px;
        line-height: 1.42; }
  .live-content {
    display: flex;
    flex-direction: column;
    margin: 15px 0 8px 0; }
    .live-content .goal-content-media {
      display: flex;
      align-items: center;
      margin: 0 0 10px 0; }
      .live-content .goal-content-media .media {
        width: 58px;
        height: 58px; }
        .live-content .goal-content-media .media :global(img) {
          width: 100%;
          height: 100%; }
      .live-content .goal-content-media .media-body {
        margin: 0 0 0 15px; }
        .live-content .goal-content-media .media-body .heading {
          margin: 0 0 9px 0;
          display: flex; }
          .live-content .goal-content-media .media-body .heading img {
            width: 24px;
            height: 24px;
            display: block; }
          .live-content .goal-content-media .media-body .heading h3 {
            font-size: 27.7px;
            font-weight: bold;
            line-height: 1;
            color: var(--theme-inverted-foreground-color, white);
            margin: 0 0 0 12px; }
        .live-content .goal-content-media .media-body .name {
          margin: 9px 0 0 0;
          padding: 3px 5px;
          background-color: var(--theme-inverted-highlight-color, white);
          line-height: 1.05;
          font-size: 32px; }
    .live-content .live-body {
      width: 100%;
      font-size: 14px;
      line-height: 21px; }
  .link-container {
    display: flex;
    justify-content: flex-end;
    gap: 12px; }
    .link-container :global(a .f7-icons) {
      font-size: 20px;
      color: var(--theme-inverted-foreground-color, white); } }</style>
