import { generateArraySchema, generateBooleanSchema, generateColorSchema, generateObjectSchema, generateRootObjectSchema, generateStringSchema, generateUrlSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema, } from "components/cards/atoms/BaseAtomSpec";
import { TextParticleSpec, ImageParticleSpec, ButtonParticleSpec } from "components/cards/particles/YinzCam";

import { statsNameList } from "components/cards/utilities/constants";

export const specification = new BaseAtomSpec("Pass Direction", generateRootObjectSchema({
    container: generateAtomContainerSchema({ defaultValues: { container: {} } }),
    headerText: TextParticleSpec.generateSchema("Pass Direction", "Pass Direction", {
        defaultValues: { text: "Pass Direction%", size: "1.25rem", weight: "600" }
    }),
    statForward: generateStringSchema("Select Stat for Forward Direction", "Select Stat to display", {
        choices: statsNameList,
        choiceTitles: statsNameList,
    }),
    statBackward: generateStringSchema("Select Stat for Backward Direction", "Select Stat to display", {
        choices: statsNameList,
        choiceTitles: statsNameList,
    }),
    statLeft: generateStringSchema("Select Stat for Left Direction", "Select Stat to display", {
        choices: statsNameList,
        choiceTitles: statsNameList,
    }),
    statRight: generateStringSchema("Select Stat for Right Direction", "Select Stat to display", {
        choices: statsNameList,
        choiceTitles: statsNameList,
    }),
    playerStats: generateArraySchema("-NA-", "Specify the number of cards to be created",
        generateObjectSchema("Circle Card", "Enter Stat Name and Value", {
            name: TextParticleSpec.generateSchema("Stat Name", "Enter stat name", {
                defaultValues: { size: "1.25rem", weight: "600" }
            }),
            homeValue: TextParticleSpec.generateSchema("Stat Value", "Enter stat value", {
                defaultValues: { size: "2rem", weight: "900" }
            }),
        })),
}));
