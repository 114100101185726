<CardBase {element}>
  <div style="
    width: {width || 'auto'};
    height: {height || 'auto'};
  ">
    {#if html}
    {@html html}
    {/if}
  </div>
</CardBase>

<style>
  *, *::before, *::after {
    box-sizing: border-box;
  }</style>

<script lang="ts">var _a;
import "svelte/store";
import "yinzcam-cards";
import CardBase from "components/cards/common/CardBase.svelte";
import "components/cards/common/CardsDataSourceRegistration";
export let element;
export let sources;
export let width = null;
export let height = null;
export let html = (_a = element === null || element === void 0 ? void 0 : element.data) === null || _a === void 0 ? void 0 : _a.html;
</script>
