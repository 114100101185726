import { generateRootObjectSchema, generateHtmlSchema } from "components/cards/common/schema";
import { BaseParticleSpec, GetParticlePropsType, GetParticleSchemaType, generateParticleContainerSchema } from "components/cards/particles";
import { TextParticleSpec } from "../Text";

export const MultiSelectParticleSpec = new BaseParticleSpec("Text", generateRootObjectSchema({
  container: generateParticleContainerSchema(),
  html: generateHtmlSchema("Rich Text (HTML)", "The HTML markup for this text."),
  textDefaults: TextParticleSpec.generateSchema("Text Defaults", "The default styling for rich text that is not otherwise styled."),
}));

export const specification = MultiSelectParticleSpec;

export type MultiSelectParticleSchema = GetParticleSchemaType<typeof MultiSelectParticleSpec>;

export type MultiSelectParticleProps = GetParticlePropsType<typeof MultiSelectParticleSpec>;
