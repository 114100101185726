import { generateArraySchema, generateBooleanSchema, generateColorSchema, generateObjectSchema, generateRootObjectSchema, generateStringSchema, generateUrlSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema, } from "components/cards/atoms/BaseAtomSpec";
import { TextParticleSpec, ImageParticleSpec, ButtonParticleSpec } from "components/cards/particles/YinzCam";

import { statsNameList } from "components/cards/utilities/constants";

export const specification = new BaseAtomSpec("Shot Accuracy Card", generateRootObjectSchema({
    container: generateAtomContainerSchema({ defaultValues: { container: {} } }),
    overlayPadding: generateStringSchema("Overlay Padding", "Enter an overlay padding"),
    thumbnail: ImageParticleSpec.generateSchema("Player Image", "Upload/specify player image"),
    txtTitle: TextParticleSpec.generateSchema("Player Title", "Title of the card", {
        defaultValues: { text: "Passing Accuracy by Area %", size: "1.25rem", weight: "600" }
    }),
    txtTextFieldNames: TextParticleSpec.generateSchema("Field Names", "Field Names", {
        defaultValues: { size: "1.25rem", weight: "600" }
    }),
    colorStatBoxBackground: generateColorSchema("Background Color", "Background Color for boxes"),
    statInField: generateStringSchema("Select Stat for In field box", "Select Stat to display", {
        choices: statsNameList,
        choiceTitles: statsNameList,
    }),
    statBottomLeft: generateStringSchema("Select Stat for Bottom Left", "Select Stat to display", {
        choices: statsNameList,
        choiceTitles: statsNameList,
    }),
    statBottomRight: generateStringSchema("Select Stat for Bottom Right", "Select Stat to display", {
        choices: statsNameList,
        choiceTitles: statsNameList,
    }),
    playerStats: generateArraySchema("-NA-", "Specify the number of cards to be created",
        generateObjectSchema("Circle Card", "Enter Stat Name and Value", {
            name: TextParticleSpec.generateSchema("Stat Name", "Enter stat name", {
                defaultValues: { size: "1.25rem", weight: "600" }
            }),
            homeValue: TextParticleSpec.generateSchema("Stat Value", "Enter stat value", {
                defaultValues: { size: "2.5rem", weight: "900" }
            }),
        })),
}));
