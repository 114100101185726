import { generateBooleanSchema, generateColorSchema, generateRootObjectSchema, generateStringSchema, generateUrlSchema } from "components/cards/common/schema";
import { BaseParticleSpec, GetParticlePropsType, GetParticleSchemaType, generateParticleContainerSchema } from "components/cards/particles";
import { TextParticleSpec } from "../../Core/Text";

export const CaptionedParticleSpec = new BaseParticleSpec("Captioned", generateRootObjectSchema({
  container: generateParticleContainerSchema(),
  captionBarColor: generateColorSchema("Caption Bar Color", "The color of the vertical bar to the left of the caption."),
  captionText: TextParticleSpec.generateSchema("Caption Text", "The text of the caption.")
}));

export const specification = CaptionedParticleSpec;

export type CaptionedParticleSchema = GetParticleSchemaType<typeof CaptionedParticleSpec>;

export type CaptionedParticleProps = GetParticlePropsType<typeof CaptionedParticleSpec>;
