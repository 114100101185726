<CardBase {element}>
  <div class="wrapper">
    <span class="text">Unknown Card (class: {element.class})</span>
  </div>
</CardBase>

<style>
  *, *::before, *::after {
    box-sizing: border-box;
  }
  
  .wrapper {
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('./images/missing.png');
    background-repeat: repeat;
  }

  .text {
    font-size: 150%;
    font-weight: bold;
    background-color: rgb(234, 58, 213);
    color: black;
    padding: 2px 5px;
  }</style>

<script lang="ts">import "yinzcam-cards";
import CardBase from "components/cards/common/CardBase.svelte";
export let element;
</script>