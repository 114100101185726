import { generateRootObjectSchema, generateArraySchema, generateObjectSchema, generateColorSchema, generateStringSchema, generateBooleanSchema, generateNumberSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { ImageParticleSpec, RichTextParticleSpec, TextParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("MC-Commentary-B", generateRootObjectSchema({
    container: generateAtomContainerSchema(),
    containerPadding: generateStringSchema("Container Padding", "Padding for the container"),
    txtMinutes: TextParticleSpec.generateSchema("Match Minutes", "Enter minutes here", {
        defaultValues: { size: "3rem", weight: "800", lineHeight: '0.75' }
    }),
    txtTitle: TextParticleSpec.generateSchema("Title", "Enter title to commentary", {
        defaultValues: { size: "1.75rem", weight: "700", transform: "uppercase" }
    }),
    txtCommentary: TextParticleSpec.generateSchema("Commentary", "Enter commentary", {
        defaultValues: { size: "1.25rem", weight: "400" }
    }),
    imgTitleIcon: ImageParticleSpec.generateSchema("Icon to title", "Pick an icon for title", {
        defaultValues: {
            container: { width: "2rem", height: "2rem" }, sizing: "contain",
        }
    }),
    imgCommentTeamLogo: ImageParticleSpec.generateSchema("Team Logo", "Add a team logo", {
        defaultValues: { container: {} }
    }),
    isEventIcon: generateBooleanSchema("Event Icon", "Is an event logo required?", {
        defaultValue: false,
    }),
    htmlContent: RichTextParticleSpec.generateSchema("HTML Embed Content", "HTML Content to be embedded"),
    quoteText: TextParticleSpec.generateSchema("Quote Text", "Enter a quote text", {
        defaultValues: { size: "1.25rem", weight: "400", style: "italic" }
    }),
    imgPlayerPhoto: ImageParticleSpec.generateSchema("Player Photo", "Upload a player photo", {
        defaultValues: { container: {}, sizing: "cover" }
    }),
    txtEventText: TextParticleSpec.generateSchema("Event Text", "Enter an event text", {
        defaultValues: { size: "4.5rem", weight: "900" }
    }),
    photoBackgroundColor: generateColorSchema("BG Color - Photo", "Background color for photo"),
    quoteBorderColor: generateColorSchema("Quote Bar Color", "Pick a color for quote bar"),
    variantType: generateStringSchema("Box Variant", "Variant of the box", {
        choices: ['comment', 'comment-and-embed', 'comment-and-quote', 'comment-and-photo'],
        choiceTitles: ["Commentary Box", "Comment and HTML Content", "Comment and a Quote", "Comment and a Photo"],
        defaultValue: "comment",
    }),
    eventType: generateStringSchema("Commentary Type", "Commentary Type"),
    txtExtra: TextParticleSpec.generateSchema("Extra Mins", "Extra Mins", {
        defaultValues: { size: "1.125rem", weight: "800" }
    }),
    txtMins: TextParticleSpec.generateSchema("Mins Text", "Mins Text", {
        defaultValues: { size: "1.125rem", weight: "800" }
    }),
    commentTeam: generateStringSchema("Comment Team Tricode", "Comment Team Tricode"),
    shapeColor: generateColorSchema("Shape Color", "Shape Color"),
    shapeColorNormal: generateColorSchema("Shape Color - Normal", "Shape Color - Normal"),
    lineColor: generateColorSchema("Line Color", "Line Color"),
    imgPlayerOff: ImageParticleSpec.generateSchema("Player Off Icon", "Player Off Icon", {
        defaultValues: { container: { width: "1.5rem", height: "1.5rem" } }
    }),
    imgPlayerOn: ImageParticleSpec.generateSchema("Player On Icon", "Player On Icon", {
        defaultValues: { container: { width: "1.5rem", height: "1.5rem" } }
    }),
    txtPlayerOff: TextParticleSpec.generateSchema("Player Off", "Player Off", {
        defaultValues: { size: "1.25rem", weight: "700" }
    }),
    txtPlayerOn: TextParticleSpec.generateSchema("Player On", "Player On", {
        defaultValues: { size: "1.25rem", weight: "700" }
    }),
    txtPlayerSub: TextParticleSpec.generateSchema("Sub Player Text", "Sub Player Text", {
        defaultValues: { size: "1.25rem", weight: "400" }
    }),
    darkTitleColor: generateColorSchema("Dark Title Font Color", "Dark Font Color", {
        defaultValue: "#164193"
    }),
    lightTitleColor: generateColorSchema("Light Title Font Color", "Light Font Color", {
        defaultValue: "#FFF"
    }),
    darkMinsColor: generateColorSchema("Dark Mins Font Color", "Dark Font Color", {
        defaultValue: "#164193"
    }),
    lightMinsColor: generateColorSchema("Light Mins Font Color", "Light Font Color", {
        defaultValue: "#FFF"
    }),
    darkBodyColor: generateColorSchema("Dark Body Font Color", "Dark Font Color", {
        defaultValue: "#636466"
    }),
    lightBodyColor: generateColorSchema("Light Body Font Color", "Light Font Color", {
        defaultValue: "#FFF"
    }),
    darkPlayerColor: generateColorSchema("Dark Player Font Color", "Dark Font Color", {
        defaultValue: "#164193"
    }),
    lightPlayerColor: generateColorSchema("Light Player Font Color", "Light Font Color", {
        defaultValue: "#FFF"
    }),
    lastCard: generateBooleanSchema("Last Card of Commentary", "Last Card of Commentary"),
    firstCard: generateBooleanSchema("First Card of Commentary", "First Card of Commentary"),
    primaryEventBackground: generateColorSchema("Primary Event BG Color", "Primary Event BG Color"),
    secondaryEventBackground: generateColorSchema("Primary Event BG Color", "Primary Event BG Color"),
    commentsCount: generateNumberSchema("Comments Length", "Comments Length")
}));