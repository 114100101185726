import _ from 'lodash';
import { CardsElementDataSourceSpec } from './CardsElementInterfaces';
import { YinzCamCardsServiceSource } from 'yinzcam-cards';
import { cardLibraryClassNamePairs, cardLibraryDisplayNamePairs } from './cards';
import { cardDataSourceEnumTitles, cardDataSourceEnumValues, getDataSourceByClassName } from './sources';
import { layerBackgroundSchema, layerBorderSchema, layerMarginSchema, layerPaddingSchema, layerRepeatSchema, layerStickySchema } from './common-schema';


export function generateElementDataSourcesSchema(spec: CardsElementDataSourceSpec, sources: YinzCamCardsServiceSource[]) {
  if (!spec?.sources?.length) {
    return undefined;
  }
  return {
    "title": "Data Sources",
    "type": "array",
    "description": "Configure the data sources used by this element, if required. Data sources can be defined on the view containing this element.",
    "minItems": spec.sources.length,
    "maxItems": spec.sources.length,
    "items": spec.sources.map(s => {
      const allowedSources = (s.classes === '*')? sources : sources.filter(t => s.classes.includes(t.class));
      return {
        "type": "string",
        "title": s.title,
        "headerTemplate": s.title,
        "description": s.description,
        "enum": allowedSources.map(t => t.id),
        "options": {
          "enum_titles": allowedSources.map(t => t.name)
        }
      };
    })
  };
}

const COMPONENT_COMMON_PROPS = {
  "displayName": {
    "type": "string",
    "title": "Layer Nickname",
    "description": "The nickname of this layer within the editor."
  },
  "createStackingContext": {
    "type": "boolean",
    "title": "Create Stacking Context",
    "description": "Create a new stacking context starting with this layer. The stacking context applies to this layer and all of its descendants."
  },
};

const CARDS_ELEMENT_RESPONSIVE_PROPS = {
  "hidden": {
    "type": "boolean",
    "title": "Hidden",
    "description": "Hide this card's contents on the page."
  },
  repeat: layerRepeatSchema,
  "span": {
    "title": "Grid",
    "type": "object",
    "format": "normal",
    "description": "When placed in a Grid array, specifies how many rows or columns this element should span. Defaults to 1 for both rows and columns.",
    "properties": {
      "rows": {
        "type": "integer",
        "title": "Rows",
        "description": "The number of rows this element should span. Defaults to 1.",
        "minimum": 1
      },
      "columns": {
        "type": "integer",
        "title": "Columns",
        "description": "The number of columns this element should span. Defaults to 1.",
        "minimum": 1
      }
    }
  },
  "flex": {
    "title": "Flex",
    "type": "object",
    "format": "normal",
    "description": "When placed in a Flex array, specifies the flex layout behavior for this element.",
    "properties": {
      "grow": {
        "type": "boolean",
        "title": "Grow To Container",
        "description": "Whether the width of this element will be allowed to grow beyond the width specified to fit the container."
      },
      "shrink": {
        "type": "boolean",
        "title": "Shrink To Content",
        "description": "Whether the width of this element will be allowed to shrink below the width specified to fit the content."
      },
      "basis": {
        "type": "string",
        "title": "Flex Basis",
        "description": "The desired width of this element in the flex container, if there is space for it. Specified in standard CSS units."
      },
    },
  },
  padding: layerPaddingSchema,
  margin: layerMarginSchema,
  "width": {
    "type": "string",
    "title": "Fixed Width",
    "description": "Specifies a fixed width for the element in standard CSS units."
  },
  "height": {
    "type": "string",
    "title": "Fixed Height",
    "description": "Specifies a fixed height for the element in standard CSS units."
  },
};

export const CARDS_ELEMENT_SCHEMA = {
  "title": " ",
  "description": " ",
  "type": "object",
  "properties": {
    ...COMPONENT_COMMON_PROPS,
    "class": {
      "type": "string",
      "title": "Card Type",
      "description": "The type of card to place in this position.",
      "enum": [
      ],
      "options": {
        "enum_titles": [
        ]
      }
    },
    "responsiveness": {
      "type": "array",
      "title": "Responsiveness",
      "description": "Configure how this Card responds at different screen sizes. The values set here override values set directly on the Array if the screen-size condition is met.",
      "items": {
        "type": "object",
        "title": "Responsive Configuration",
        "properties": {
          "maxWidth": {
            "type": "number",
            "title": "Screen Size",
            "description": "The screen size at which these settings will be applied.",
            "enum": Object.values(CONFIG.breakpoints),
            "options": {
              "enum_titles": Object.values(CONFIG.breakpointTitles),
            },
          },
          ...CARDS_ELEMENT_RESPONSIVE_PROPS,
        },
        "required": [
          "maxWidth"
        ]
      }
    },
    "conditions": {
      "type": "object",
      "title": "Conditions",
      "description": "Conditions under which to show this card.",
      "properties": {
        "not": {
          "type": "boolean",
          "title": "Not",
          "description": "Show this card only if all of the conditions below are NOT met."
        },
        "isNative": {
          "type": "boolean",
          "title": "Is Native?",
          "description": "Show this card only if the user is on a native iOS or Android app (not a web browser)."
        },
        "isDesktop": {
          "type": "boolean",
          "title": "Is Dsktop?",
          "description": "Show this card only if the user is on a desktop browser."
        },
        "language": {
          "type": "string",
          "title": "Language",
          "description": "Show this card only if the user is in the provided language."
        },
        "loggedIn": {
          "type": "boolean",
          "title": "Logged In?",
          "description": "Show this card only if the user is logged in."
        },
        "dataSource": {
          "type": "object",
          "title": "Data Source",
          "description": "Data source conditions.",
          "properties": {
            "sourceId": {
              "type": "string",
              "title": "Source ID",
              "description": "The data source ID for this condition."
            },
            "hasData": {
              "type": "boolean",
              "title": "Has Data?",
              "description": "Show this card only if the referenced data source has data available."
            },
          }
        },
        "param": {
          "type": "object",
          "title": "Variable",
          "description": "Variable conditions.",
          "properties": {
            "key": {
              "type": "string",
              "title": "Name",
              "description": "The variable name condition."
            },
            "hasValue": {
              "type": "boolean",
              "title": "Has Value?",
              "description": "Show this card only if the specified variable is set."
            },
            "value": {
              "type": "string",
              "title": "Value",
              "description": "Show this card only if the specified variable is set to the specified value."
            },
          }
        },
      }
    },
    "transform": {
      "type": "string",
      "title": "Data Source Transform",
      "description": "Specifies data source transformation function to apply.",
      "enum": [
        "",
        "contentful-article-header",
        "contentful-article-body",
        "contentful-gallery-viewer",
        "contentful-gallery-photo",
        "contentful-media",
        "contentful-media-button",
        "contentful-media-header",
        "contentful-navigation",
        "contentful-page-accordion",
        "contentful-page-article",
        "contentful-page-header",
        "contentful-page-image",
        "contentful-page-metadata",
        "contentful-page-cta",
        "contentful-pages-as-buttons",
        "contentful-search-metadata",
        "contentful-video-viewer",
        "contentful-site-navigation",
        "yinzcam-game-box",
        "yinzcam-game-filter-dropdown",
        "yinzcam-game-filter-radio-buttons",
        "yinzcam-game-list",
        "yinzcam-game-scores",
        "yinzcam-stats-standings",
        "yinzcam-stats-team",
        "yinzcam-stats-player",
        "yinzcam-team-roster",
        "yinzcam-game-formation",
        "yinzcam-game-commentary",
        "yinzcam-game-events",
        "yinzcam-player-action-image",
      ],
      "options": {
        "enum_titles": [
          "None",
          "Contentful Article Header",
          "Contentful Article Body",
          "Contentful Gallery Viewer",
          "Contentful Gallery Photo",
          "Contentful Media",
          "Contentful Media Button",
          "Contentful Media Header",
          "Contentful Navigation",
          "Contentful Page Accordion",
          "Contentful Page Article",
          "Contentful Page Header",
          "Contentful Page Image",
          "Contentful Page Metadata",
          "Contentful Page Call to Action",
          "Contentful Pages as Buttons",
          "Contentful Search Metadata",
          "Contentful Video Viewer",
          "Contentful Site Navigation",
          "YinzCam Game Box",
          "YinzCam Game Filter Dropdown",
          "YinzCam Game Filter Radio Buttons",
          "YinzCam Game List",
          "YinzCam Game Scores",
          "YinzCam Stats Standings",
          "YinzCam Stats Team",
          "Yinzcam Stats Player",
          "Yinzcam Team Roster",
          "Yinzcam Game Formation",
          "Yinzcam Game Commentary",
          "Yinzcam Game Events",
          "Yinzcam Player Action Image"
        ]
      }
    },
    ...CARDS_ELEMENT_RESPONSIVE_PROPS,
    sticky: layerStickySchema,
    border: layerBorderSchema,
    background: layerBackgroundSchema,
    /*conditions*/
    "sourceIds": {
      "title": "Data Sources",
      "type": "array",
      "description": "The IDs of data sources that this card uses.",
      "items": {
        "type": "string"
      }
    },
    "data": {
      "title": "Type-Specific Data",
      "type": "object",
      "description": "Additional data specific to the element type.",
      "properties": {
      },
      "additionalProperties": true
    },
  },
  "required": [
    "class"
  ]
};

for (let pair of cardLibraryDisplayNamePairs) {
  CARDS_ELEMENT_SCHEMA.properties.class.enum.push(pair[0]);
  CARDS_ELEMENT_SCHEMA.properties.class.options.enum_titles.push(pair[1]);
}
CARDS_ELEMENT_SCHEMA.properties.class.enum.push('');
CARDS_ELEMENT_SCHEMA.properties.class.options.enum_titles.push("-----");
for (let pair of cardLibraryClassNamePairs) {
  CARDS_ELEMENT_SCHEMA.properties.class.enum.push(pair[0]);
  CARDS_ELEMENT_SCHEMA.properties.class.options.enum_titles.push(pair[1]);
}

const CARDS_ARRAY_LAYOUT_RESPONSIVE_PROPS = {
  "type": {
    "type": "string",
    "title": "Grouping Style",
    "description": "How the cards elements will be grouped on the page. Single: A single full-wdith element. Grid: A grid of elements. Carousel: A horizontally scrolling carousel.",
    "enum": [
      "GRID",
      "SINGLE",
      "SWIPER",
      "FLEX",
      "SWIPER11",
      "NONE"
    ],
    "options": {
      "enum_titles": [
        "Grid",
        "Single",
        "Carousel",
        "Flex",
        "Carousel v2",
        "None"
      ]
    }
  },
  "hidden": {
    "type": ["boolean", "string"],
    "title": "Hidden",
    "description": "Hide this array's contents on the page."
  },
  "width": {
    "type": "string",
    "title": "Fixed Width",
    "description": "Specifies a fixed width for the array in standard CSS units."
  },
  "height": {
    "type": "string",
    "title": "Fixed Height",
    "description": "Specifies a fixed height for the array in standard CSS units."
  },
  "maxColumns": {
    "type": "integer",
    "minimum": 1,
    "title": "Maximum Columns",
    "description": "The maximum number of columns visible on screen in a carousel or grid layout."
  },
  "gap": {
    "type": "string",
    "title": "Element Gap (Standard)",
    "description": "Gap between elements in standard CSS units.",
  },
  "gapPixels": {
    "type": "number",
    "title": "Element Gap (px) - Deprecated",
    "description": "Gap between elements in pixels.",
    "default": 10
  },
  "direction": {
    "type": "string",
    "title": "Flex Direction",
    "description": "The layout direction (horizontal or vertical) of the flex items.",
    "enum": [ 'row', 'column' ],
    "options": {
      "enum_titles": [ 'Horizontal', 'Vertical' ]
    },
    "default": 'row'
  },
  "justifyContent": {
    "type": "string",
    "title": "Flex Justification",
    "description": "The alignment of the flex items (children of this layer) along the main-axis of the orientation.",
    "enum": [ 'start', 'center', 'end' ],
    "options": {
      "enum_titles": [ 'Left (Horiz) or Top (Vert.)', 'Center', 'Right (Horiz.) or Bottom (Vert.)' ]
    },
    "default": 'start'
  },
  "alignItems": {
    "type": "string",
    "title": "Flex Alignment",
    "description": "The alignment of the flex items (children of this layer) along the cross-axis of the orientation.",
    "enum": [ 'start', 'center', 'end' ],
    "options": {
      "enum_titles": [ 'Top (Horiz.) or Left (Vert.)', 'Center', 'Bottom (Horiz.) or Right (Vert.)' ]
    },
    "default": 'start'
  },
  "splitEqually": {
    "type": "boolean",
    "title": "Split Equally",
    "description": "In a grid layout, this forces the number of columns to be equal to the number of elements. If this is true, maxColumns is ignored."
  },
  "slideWidth": {
    "type": "string",
    "title": "Slide Width",
    "description": "The width of each slide in a carousel in standard CSS units."
  },
  "swiperPagination": {
    "type": "boolean",
    "title": "Carousel Pagination Indicators",
    "description": "Whether pagination indicators are enabled on carousels.",
  },
  "swiperNavigation": {
    "type": "boolean",
    "title": "Carousel Navigation Buttons",
    "description": "Whether navigation buttons are enabled on carousels.",
  },
  "zoom": {
    "type": "string",
    "title": "Zoom",
    "description": "Scales this array's content according to the specified zoom factor in pixels or percentages. The value 1.0 = 100% = normal zoom. Values larger than 1.0 (100%) zoom in, values smaller than 1.0 (100%) zoom out. This should be a last resort for fixing responsiveness issues (try other scaling methods first).",
  },
  "zIndex": {
    "type": "number",
    "title": "Stacking Order",
    "description": "Manually override the stacking order (Z-Index) for this array relative to others. The default stacking order is 0.",
  },
  "paginationOffset": {
    "type": "number",
    "title": "Pagination Offset",
    "description": "For carousels, the offset of the pagination indicators from the bottom of the array, in standard CSS units.",
  },
  "swiperNavigationOffset": {
    "type": "number",
    "title": "Navigation Button Offset",
    "description": "For carousels, the offset of the navigation buttons from the left and right sides of the array, in standard CSS units.",
  },
  "swiperNavigationOnHover": {
    "type": "boolean",
    "title": "Swiper Navigation on Hover",
    "description": "For carousels, whether navigation buttons only appear when the user hovers over the carousel.",
  },
  "autoplay": {
    "type": "boolean",
    "title": "Autoplay",
    "description": "Enable autoplay mode.",
  },
  padding: layerPaddingSchema,
  margin: layerMarginSchema,
};

export const CARDS_ARRAY_LAYOUT_SCHEMA = {
  "title": " ",
  "type": "object",
  "properties": {
    ...COMPONENT_COMMON_PROPS,
    "responsiveness": {
      "type": "array",
      "title": "Responsiveness",
      "description": "Configure how this Array responds at different screen sizes. The values set here override values set directly on the Array if the screen-size condition is met.",
      "items": {
        "type": "object",
        "title": "Responsive Configuration",
        "properties": {
          "maxWidth": {
            "type": "number",
            "title": "Screen Size",
            "description": "The screen size at which these settings will be applied.",
            "enum": Object.values(CONFIG.breakpoints),
            "options": {
              "enum_titles": Object.values(CONFIG.breakpointTitles),
            },
          },
          ...CARDS_ARRAY_LAYOUT_RESPONSIVE_PROPS,
        },
        "required": [
          "maxWidth"
        ]
      }
    },
    ...CARDS_ARRAY_LAYOUT_RESPONSIVE_PROPS,
    sticky: layerStickySchema,
    "scrollSpeed": {
      "type": "number",
      "title": "Scroll Speed",
      "description": "The scrolling speed of a carousel in pixels per second. Default is 300."
    },
    "slideSnap": {
      "type": "boolean",
      "title": "Slide Snap",
      "description": "Should slides in a carousel snap into position (like a pager)?",
    },
    "swipeEffect": {
      "type": "string",
      "title": "Carousel Animation",
      "description": "The animation used when swiping between items in a carousel. The default sliding animiation is \"Slide\".",
      "enum": [
        "SLIDE",
        "MAGNIFY",
        "FADE",
        "CUBE",
        "COVERFLOW",
        "FLIP"
      ],
      "options": {
        "enum_titles": [
          "Slide",
          "Magnify",
          "Fade",
          "Cube",
          "Coverflow",
          "Flip"
        ]
      }
    },
    "overflow": {
      "type": "string",
      "title": "Overflow",
      "description": "The overflow mode for the array. The default value differs based on the type of the array. For example, this allows Carousels to overflow their container on the left as the user scrolls.",
      "enum": [
        "hidden",
        "visible",
        "scroll",
        "clip"
      ],
      "options": {
        "enum_titles": [
          "Hidden",
          "Visible",
          "Scroll",
          "Clip",
        ]
      }
    },
    "scrollToActive": {
      "type": "boolean",
      "title": "Scroll to Active",
      "description": "Whether a carousel should automatically scroll to the first active element (for example, an upcoming or live match). This requires the elements within the carousel to support that functionality.",
    },
    "uniformHeight": {
      "type": "boolean",
      "title": "Uniform Height",
      "description": "In a grid layout, require all rows to be the same height. The height of all rows (and thus all cells) will be the height of the tallest cell contents.",
      "default": true
    },
    "wrap": {
      "type": "string",
      "title": "Flex Wrap",
      "description": "The wrap property of the items inside a flex container. Specify wrap or nowrap.",
      "enum": [
        "wrap",
        "nowrap",
        "wrap-reverse",
      ],
      "options": {
        "enum_titles": [
          "Wrap",
          "No Wrap",
          "Wrap Reverse",
        ]
      }
    },
    background: layerBackgroundSchema,
    border: layerBorderSchema,
    "slidesPerGroup": {
      "type": "number",
      "title": "Slides per Group",
      "description": "For carousels, the number of slides to advance at once, forward or backward.",
    },
    "loop": {
      "type": "boolean",
      "title": "Loop",
      "description": "Enable loop mode.",
    },
    "preloadSlideCount": {
      "type": "number",
      "title": "Preload Slide Count",
      "description": "For carousels, the number of slides to preload on the left or right off screen.",
    },
    "loopExtraSlides": {
      "type": "number",
      "title": "Loop Extra Slides",
      "description": "For carousels when loop is set to true, the number of slides to display on the left or right side of the carousel when looping.",
    },
    "initialSlideNumber": {
      "type": "number",
      "title": "Initial Slide Number",
      "description": "For carousels, the position of the first slide in the carousel.",
    },
  },
  "required": [
    "type"
  ]
};

const CARDS_COLUMN_RESPONSIVE_PROPS = {
  "hidden": {
    "type": "boolean",
    "title": "Hidden",
    "description": "Hide this column's contents on the page."
  },
  "width": {
    "type": "integer",
    "minimum": 0,
    "maximum": 100,
    "multipleOf": 1,
    "title": "Width (percent)",
    "description": "The percentage of the width of the parent section that this column will occupy. This can be left unspecified or set to 0, in which case this column will be automatically sized based on the size of its content."
  },
  "grow": {
    "type": "boolean",
    "title": "Grow To Fit Container",
    "description": "Whether the width of this column will be allowed to grow beyond the width specified to fit the content. Ignored if width is unspecified or zero."
  },
  "shrink": {
    "type": "boolean",
    "title": "Shrink To Fit Content",
    "description": "Whether the width of this column will be allowed to shrink below the width specified to tightly fit the content. Ignored if width is unspecified or zero."
  },
  "zoom": {
    "type": "string",
    "title": "Zoom",
    "description": "Scales this column's content according to the specified zoom factor in pixels or percentages. The value 1.0 = 100% = normal zoom. Values larger than 1.0 (100%) zoom in, values smaller than 1.0 (100%) zoom out. This should be a last resort for fixing responsiveness issues (try other scaling methods first)."
  },
  "justifyContent": {
    "type": "string",
    "title": "Justify Content",
    "description": "Justify this column's content vertically.",
    "enum": [ 'start', 'center', 'end' ],
    "options": {
      "enum_titles": [ 'Top', 'Center', 'Bottom' ]
    },
  },
  padding: layerPaddingSchema,
  margin: layerMarginSchema,
};

export const CARDS_COLUMN_SCHEMA = {
  "title": " ",
  "type": "object",
  "properties": {
    ...COMPONENT_COMMON_PROPS,
    "responsiveness": {
      "type": "array",
      "title": "Responsiveness",
      "description": "Configure how this Column responds at different screen sizes. The values set here override values set directly on the Column if the screen-size condition is met.",
      "items": {
        "type": "object",
        "title": "Responsive Configuration",
        "properties": {
          "maxWidth": {
            "type": "number",
            "title": "Screen Size",
            "description": "The screen size at which these settings will be applied.",
            "enum": Object.values(CONFIG.breakpoints),
            "options": {
              "enum_titles": Object.values(CONFIG.breakpointTitles),
            },
          },
          ...CARDS_COLUMN_RESPONSIVE_PROPS,
        },
        "required": [
          "maxWidth"
        ]
      }
    },
    ...CARDS_COLUMN_RESPONSIVE_PROPS,
    "reverse": {
      "type": ["boolean", "string"],
      "title": "Reverse",
      "description": "Reverse this column's items."
    },
  },
  "required": [
  ]
};

const CARDS_SECTION_RESPONSIVE_PROPS = {
  "hidden": {
    "type": "boolean",
    "title": "Hidden",
    "description": "Hide this column's contents on the page."
  },
  "height": {
    "type": "string",
    "title": "Fixed Height",
    "description": "Specifies a fixed height for the section in standard CSS units. If a fixed height is specified and the section's content is larger than the height specified (vertically), the section will scroll."
  },
  "zoom": {
    "type": "string",
    "title": "Zoom",
    "description": "Scales this section's content according to the specified zoom factor in pixels or percentages. The value 1.0 = 100% = normal zoom. Values larger than 1.0 (100%) zoom in, values smaller than 1.0 (100%) zoom out. This should be a last resort for fixing responsiveness issues (try other scaling methods first)."
  },
  padding: layerPaddingSchema,
  margin: layerMarginSchema,
  "wrap": {
    "type": "boolean",
    "title": "Wrap",
    "description": "Whether this section's columns should wrap down the screen if they cannot all be displayed on one line.",
    "default": false,
  },
};

export const CARDS_SECTION_SCHEMA = {
  "title": " ",
  "type": "object",
  "properties": {
    ...COMPONENT_COMMON_PROPS,
    "responsiveness": {
      "type": "array",
      "title": "Responsiveness",
      "description": "Configure how this Section responds at different screen sizes. The values set here override values set directly on the Section if the screen-size condition is met.",
      "items": {
        "type": "object",
        "title": "Responsive Configuration",
        "properties": {
          "maxWidth": {
            "type": "number",
            "title": "Screen Size",
            "description": "The screen size at which these settings will be applied.",
            "enum": Object.values(CONFIG.breakpoints),
            "options": {
              "enum_titles": Object.values(CONFIG.breakpointTitles),
            },
          },
          ...CARDS_SECTION_RESPONSIVE_PROPS,
        },
        "required": [
          "maxWidth"
        ]
      }
    },
    ...CARDS_SECTION_RESPONSIVE_PROPS,
    sticky: layerStickySchema,
    "autoscroll": {
      "type": "boolean",
      "title": "Auto Scroll",
      "description": "Auto-scroll the section to the first active element (for example, an upcoming or live match). Auto-scroll must be supported by elements within this section."
    },
    border: layerBorderSchema,
    background: layerBackgroundSchema,
  },
  "required": [
  ]
};

//console.log("DATA SOURCE RECORDS", cardDataSourceClassNamePairs);

const CARDS_TAB_DATA_SOURCE_DEFAULT_SCHEMA = {
  "type": "object",
  "format": "normal",
  "title": 'Undefined Data Source',
  "headerTemplate": 'Undefined Data Source',
  "description": '',
  "properties": {
    "id": {
      "type": "string",
      "title": "Identifier",
      "description": "A unique identifier for this data source that is referenced by card elements."
    },
    "name": {
      "type": "string",
      "title": "Name",
      "description": "A human-readable name for the data source.",
      "default": "New Data Source"
    },
    "class": {
      "type": "string",
      "title": "Data Source Type",
      "description": "The type of the data source (must match a valid data source in the code).",
      "enum": cardDataSourceEnumValues,
      "options": {
        "enum_titles": cardDataSourceEnumTitles
      }
    },
    "reverse": {
      "type": ["boolean", "string"],
      "title": "Reverse",
      "description": "Reverse this data source's items (if it is an array)."
    },
    "data": {
      "type": "object",
      "format": "normal",
      "title": "Configuration Options",
      "description": "Configuration data for the source based on the source's type.",
      "additionalProperties": true,
      "options": {
        "disable_properties": false
      }
    },
    "path": {
      "type": "string",
      "title": "Endpoint",
      "description": "The API endpoint of the data source. Note that the valid options here may change based on the data source's type and configuration."
    },
    "exposedVariables": {
      type: "array",
      title: "Exposed Variables",
      description: "A set of JSON Path strings to expose as variables from the Contentful API response.",
      items: {
        type: "object",
        title: "Exposed Variable",
        description: "A variable exposed from the Contentful API response.",
        properties: {
          "path": {
            type: "string",
            title: "JSON Path",
            description: "The JSON Path string indicating a field in the Contentful API response.",
          },
          "variable": {
            type: "string",
            title: "Variable Name",
            description: "The variable to set with the value of the field in the Contentful API response.",
          },
        }
      }
    },
  },
  "required": [
    "id",
    "class",
    "name"
  ]
};

const CARDS_TAB_DATA_SOURCE_NEW_SCHEMA = (function() {
  const item = _.cloneDeep(CARDS_TAB_DATA_SOURCE_DEFAULT_SCHEMA);
  item.title = item.headerTemplate = "New Data Source";
  item.description = "Select a type to begin creating this data source.";
  delete item.properties.data;
  delete item.properties.path;
  return item;
})();

const MAX_DATA_SOURCES_PER_TAB = 20;

export async function generateTabDataSourcesSchema(sources: YinzCamCardsServiceSource[]) {
  if (!sources?.length) {
    sources = [];
  }
  const items = [];
  for (let i = 0; i < sources.length && i < MAX_DATA_SOURCES_PER_TAB; i++) {
    const spec = sources[i];
    // console.log('SOURCE', i, spec);
    let item;
    if (!spec?.class) {
      item = CARDS_TAB_DATA_SOURCE_NEW_SCHEMA;
    } else {
      item = _.cloneDeep(CARDS_TAB_DATA_SOURCE_DEFAULT_SCHEMA);
      const source = getDataSourceByClassName(spec.class);
      if (source) {
        item.title = item.headerTemplate = source.getDisplayName();
        const paths = await source.getDataSourcePaths(spec.data);
        if (paths) {
          const pathSchema = item.properties.path as any;
          pathSchema.enum = paths.map(p => p.path);
          pathSchema.options = {
            enum_titles: paths.map(p => p.name)
          };
          item.required.push("path");
        } else if (_.isNull(paths)) {
          // if null set the enum to no options available, path is required still though (so this is an error)
          item.properties.path.enum = [];
          item.properties.path.description = "The data source indicated that a path is required but none are available with the current data-source configuration.";
          item.required.push("path");
        } // otherwise undefined, leave as is (path is optional)
        const dataSchema = await source.getDataSourceConfigSpec(spec.path, spec.data);
        if (dataSchema) {
          // if the source provided a schema, use it and make it required
          delete item.properties.data.additionalProperties;
          delete item.properties.data.options;
          item.properties.data = { ...item.properties.data, ...dataSchema };
          item.required.push("data");
        } else if (_.isNull(dataSchema)) {
          // if null delete the property (the source doesn't care about extra data)
          delete item.properties.data;
        } // otherwise undefined, leave as is
      }
    }
    items.push(item);
  }
  if (sources.length < MAX_DATA_SOURCES_PER_TAB) {
    items.push(CARDS_TAB_DATA_SOURCE_NEW_SCHEMA);
  }

  const schema = {
    "title": "Data Sources",
    "type": "array",
    "description": "Define data sources for use by cards within this view.",
    "minItems": 0,
    "maxItems": MAX_DATA_SOURCES_PER_TAB,
    "items": items,
    "options": {
      "disable_array_reorder": true
    }
  };
  //console.log('SCHEMA', schema);
  return schema;
}

const CARDS_TAB_RESPONSIVE_PROPS = {
  "hidden": {
    "type": "boolean",
    "title": "Hidden",
    "description": "Hide this column's contents on the page."
  },
  "zoom": {
    "type": "string",
    "title": "Zoom",
    "description": "Scales this section's content according to the specified zoom factor in pixels or percentages. The value 1.0 = 100% = normal zoom. Values larger than 1.0 (100%) zoom in, values smaller than 1.0 (100%) zoom out. This should be a last resort for fixing responsiveness issues (try other scaling methods first)."
  },
  padding: layerPaddingSchema,
  margin: layerMarginSchema,
};

export const CARDS_TAB_SCHEMA = {
  "title": " ",
  "type": "object",
  "properties": {    
    ...COMPONENT_COMMON_PROPS,
    "responsiveness": {
      "type": "array",
      "title": "Responsiveness",
      "description": "Configure how this Section responds at different screen sizes. The values set here override values set directly on the Section if the screen-size condition is met.",
      "items": {
        "type": "object",
        "title": "Responsive Configuration",
        "properties": {
          "maxWidth": {
            "type": "number",
            "title": "Screen Size",
            "description": "The screen size at which these settings will be applied.",
            "enum": Object.values(CONFIG.breakpoints),
            "options": {
              "enum_titles": Object.values(CONFIG.breakpointTitles),
            },
          },
          ...CARDS_TAB_RESPONSIVE_PROPS,
        },
        "required": [
          "maxWidth"
        ]
      }
    },
    ...CARDS_TAB_RESPONSIVE_PROPS,
    "default": {
      "type": "boolean",
      "title": "Is Default View?",
      "description": "If set to true, this view will be the first view shown on page load within this region.",
      "default": true
    },
    sticky: layerStickySchema,
    border: layerBorderSchema,
    background: layerBackgroundSchema,
    /*sources: ...,*/
    "name": {
      "type": "string",
      "title": "Name",
      "description": "A name for this component that can be referenced by other components within a fragment URI."
    },
    "fragmentUri": {
      "type": "string",
      "title": "Fragment URI",
      "description": "If specified, this fragment URI will be used to display the contents of the view. Fragment URI must be of the form {page-slug}#{json.path}."
    }
  }
};

export const CARDS_PAGE_SCHEMA = {
  "title": " ",
  "type": "object",
  "properties": {
    ...COMPONENT_COMMON_PROPS,
    padding: layerPaddingSchema,
    margin: layerMarginSchema,
    background: layerBackgroundSchema,
    /* font, */
    "titleText": {
      "type": "string",
      "title": "Page Title",
      "description": "The page title."
    },
    "descriptionText": {
      "type": "string",
      "title": "Page Description",
      "description": "The page description."
    },
    "keywords": {
      "type": "string",
      "title": "Page Keywords",
      "description": "The page keywords."
    },
    "analytics": {
      "type": "object",
      "title": "Analytics",
      "description": "Configuration of analytics tracking data for this page.",
      "properties": {
        "path": {
          "type": "string",
          "title": "Path",
          "description": "The page path to report for analytics tracking."
        },
        "name": {
          "type": "string",
          "title": "Name",
          "description": "The page name to report for analytics tracking."
        }
      },
      "required": [
        "path",
        "name"
      ]
    },
    "editorParameters": {
      "type": "object",
      "title": "Editor Parameters",
      "description": "Parameters to inject into the page when working in the editor (page builder).",
      "properties": {
        "path0": {
          "type": "string",
          "title": "Page Slug (path0)",
          "description": "The page slug. Override this for template pages."
        },
        "path1": {
          "type": "string",
          "title": "Primary Path Slug (path1)",
          "description": "The primary path slug. Use this for content slugs."
        },
        "path2": {
          "type": "string",
          "title": "Secondary Path Slug (path2)",
          "description": "The secondary path slug."
        },
        "query": {
          "type": "string",
          "title": "Query Parameter (query)",
          "description": "The standard query-string parameter for search terms."
        },
        "gameId": {
          "type": "string",
          "title": "Game/Match ID (gameId)",
          "description": "The standard query-string parameter for game/match IDs."
        }
      },
      "patternProperties": {
        "^.+$": {
          "type": "string"
        }
      },
      "additionalProperties": true,
      "options": {
        "disable_properties": false
      }
    },
    "defaultParameters": {
      "type": "object",
      "title": "Default Parameters",
      "description": "Parameters to set by default if they are not provided from another source (e.g. the URL path or query string).",
      "patternProperties": {
        "^.+$": {
          "type": "string"
        }
      },
      "additionalProperties": true,
      "options": {
        "disable_properties": false
      }
    }
  }
};