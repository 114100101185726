<Segmented raised tag="div">
{#each dropdownItems as item (item.slug) }
  <Button active={selectedDevice === item.slug} on:click={() => onDeviceClick(item.slug)}>
    <div class="button-contents">
      {item.name}
    </div>
  </Button>
{/each}
</Segmented>
<div class="custom-button-container">
  <MenuItem dropdown>
    <div class="button-contents">
      Custom
    </div>
    <MenuDropdown left>
      <div class="cards-editor-viewport-dropdown" bind:this={wrapperEl}>
        <List accordionList>
          {#each dropdownItems as item (item.slug) }
          <ListItem accordionItem on:click={() => onDeviceClick(item.slug)} bind:this={accordions[item.slug]}>
            <i slot="media" class="list-item-icon f7-icons">device_{item.slug}_portrait</i>
            <div slot="title" class={`device-${item.slug} title-text`} class:selected={selectedDevice === item.slug}>
              {item.name}
            </div>
            <div slot="after" class={`device-${item.slug} after-text`} class:selected={selectedDevice === item.slug}>
              {windowWidth}px
            </div>
            <AccordionContent>
              <List>
                <ListItem>
                  <Range min={item.minRange} max={item.maxRange} step={1} value={item.defaultValue} label={false} scale={true} scaleSteps={2} scaleSubSteps={0} color={'white'} bind:this={ranges[item.slug]} onRangeChange={(val) => onWidthSelect(item.slug, val)} />
                </ListItem>
              </List>
            </AccordionContent>
          </ListItem>
          {/each}
        </List>
      </div>
    </MenuDropdown>
  </MenuItem>  
</div>

<style>
  *, *::before, *::after {
    box-sizing: border-box;
  }

  .custom-button-container {
    --f7-menu-bg-color: #f1f1f1;
    --f7-menu-text-color: black;
    --f7-menu-font-size: 13px;
    --f7-menu-item-height: 36px;
    --f7-menu-item-padding-horizontal: 5px;
    --f7-menu-item-border-radius: 4px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0,0,0,0.24);
    margin-left: 5px;
  }

  .button-contents {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    font-weight: bold;
    font-size: 13px;
    text-transform: uppercase;
  }

  .cards-editor-viewport-dropdown {
    white-space: nowrap;
    min-width: 250px;
    --f7-list-item-title-font-size: 14px;
    --f7-list-item-media-margin: 0px;
    --f7-range-scale-text-color: #212529;
    --f7-range-label-text-color: #212529;
    --f7-list-item-after-text-color: #212529;
    --f7-range-size: 30px;
    --f7-list-bg-color: #f1f1f1;
    --f7-range-knob-color: black;
    --f7-range-bar-active-bg-color: #81d4fa;
    background-color: #f1f1f1;
    color: #212529;
  }

  .cards-editor-viewport-dropdown .title-text.selected {
    font-weight: 800;
  }

  .cards-editor-viewport-dropdown .after-text:not(.selected) {
    display: none;
  }

  .cards-editor-viewport-dropdown :global(.item-title) {
    flex-shrink: 0;
    width: 70px;
  }

  .list-item-icon {
    font-size: 20px;
  }</style>

<script lang="ts">// iconF7="arrow_left_right_square" iconOnly {iconSize}
import { f7, MenuItem, MenuDropdown, List, ListItem, AccordionContent, Range, Segmented, Button } from 'framework7-svelte';
import { tick } from 'svelte';
import { overrideWindowWidth } from '../../../js/stores';
import 'svelte/internal';
export let iconSize = 20;
export let breakpoints = [];
export const setSelectedDevice = (name) => {
    onDeviceClick(name);
};
const accordions = {};
const ranges = {};
let wrapperEl = null;
let dropdownItems = [];
let selectedDevice = null;
let selectedAccordion = null;
let selectedRange = null;
let windowWidth = null;
let initialized = false;
function onDeviceClick(device) {
    selectedDevice = device;
    selectedAccordion = accordions[device];
    selectedRange = ranges[device];
    //console.log('onDeviceClick', device, selectedAccordion, selectedRange);
    if (selectedRange) {
        onWidthSelect(selectedDevice, selectedRange.getValue());
    }
}
function onWidthSelect(device, width) {
    if (device === selectedDevice) {
        windowWidth = width;
        overrideWindowWidth(width);
        //console.log('onSizeSelect', device, width);
    }
}
async function initialize(defaultDevice) {
    initialized = true;
    await tick();
    onDeviceClick(defaultDevice);
    // This sucks.
    const accordionEl = wrapperEl.querySelector(`.device-${defaultDevice}.title-text`).closest('.accordion-item');
    //console.log(accordionEl);
    f7.accordion.open(accordionEl);
}
$: if ((breakpoints === null || breakpoints === void 0 ? void 0 : breakpoints.length) > 0 && wrapperEl) {
    const sortedBreakpoints = breakpoints.toSorted((a, b) => b.minWidth - a.minWidth);
    //console.log('sortedBreakboints', sortedBreakpoints);
    const newDropdownItems = [];
    let defaultDevice = null;
    for (let i = 0; i < sortedBreakpoints.length; i++) {
        const cur = sortedBreakpoints[i];
        const minRange = cur.minWidth + 1;
        const maxRange = (i === 0) ? 1919 : sortedBreakpoints[i - 1].minWidth - 1;
        newDropdownItems.push({ slug: cur.slug, minRange, maxRange, defaultValue: minRange + Math.floor((maxRange - minRange) / 2), name: cur.name });
        if (!defaultDevice && cur.default) {
            defaultDevice = cur.slug;
        }
    }
    //console.log('newDropdownItems', newDropdownItems);
    dropdownItems = newDropdownItems;
    if (!initialized && defaultDevice) {
        initialize(defaultDevice);
    }
}
</script>
