<Boundary>
  {#if outlineMode}
  <CardsContainerWrapper style="width: 100%; overflow-x: scroll; display: grid; grid-auto-flow: column; grid-auto-columns: 200px; grid-column-gap: 10px; justify-content: safe center;" {editorOptions}>
    {#each layout.elements as element, i (element.id)}
      <CardsElement {parentSequenceId} sequenceId={generateSequenceId('ELT', parentSequenceId, i)} {element} height="100%" updater={elementUpdater.bind(null, i)} />
    {/each}
  </CardsContainerWrapper>
  {:else}
  <CardsContainerWrapper style="width: 100%;" {editorOptions}>
    <Splide hasTrack={ false } aria-label="...">
      <SplideTrack>
        {#each layout.elements as element, i (element.id)}
        <SplideSlide>
          <div class="cards-array-splide-slide-wrapper">
            <CardsElement {parentSequenceId} sequenceId={generateSequenceId('ELT', parentSequenceId, i)} {element} updater={elementUpdater.bind(null, i)} />
          </div>      
        </SplideSlide>
        {/each}
      </SplideTrack>
      <!--
      <div class="splide__arrows">
        <button class="splide__arrow splide__arrow--prev">Prev</button>
        <button class="splide__arrow splide__arrow--next">Next</button>
      </div>
      -->
    </Splide>
  </CardsContainerWrapper>
  {/if}
</Boundary>

<style>
  *, *::before, *::after {
    box-sizing: border-box;
  }</style>

<script lang="ts">import "yinzcam-cards";
import { generateSequenceId } from "../utilities";
import CardsElement from "./CardsElement.svelte";
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import "./CardsEditorInterfaces";
import CardsContainerWrapper from "./CardsContainerWrapper.svelte";
import { Splide, SplideSlide, SplideTrack } from '@splidejs/svelte-splide';
import '@splidejs/svelte-splide/css/splide.min.css';
import { CardsPageContextKey } from "./context";
import { getContext } from "svelte";
export let parentSequenceId = "";
export let layout;
export let updater;
const pageContext = getContext(CardsPageContextKey);
const emmEnabled = pageContext.editorModeManager.getEnabledStore();
const emmPreview = pageContext.editorModeManager.getPreviewStore();
$: outlineMode = $emmEnabled && !$emmPreview;
function elementUpdater(i, element) {
    layout.elements[i] = element;
    updater(layout.elements);
}
let editorOptions;
$: editorOptions = {
    parentSequenceId,
    items: layout.elements,
    updater
};
</script>
