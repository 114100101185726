
<script lang="ts">import "lodash";
import "./schema";
import DIYCardContentTreeItem from './DIYCardContentTreeItem.svelte';
export let root = undefined;
</script>

<style>
  .diy-card-content-tree {
    width: 100%;
  }</style>

<div class="diy-card-content-tree">
  <DIYCardContentTreeItem item={root} />
</div>
