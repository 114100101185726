<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import { BaseParticle, } from "components/cards/particles";
import "components/cards/atoms";
import { TextParticle, } from "components/cards/particles/YinzCam";
import "svelte/store";
import { createEventDispatcher } from 'svelte';
export let container;
export let overlayPadding = undefined;
export let headerTabs;
export let colorActiveTab;
export let colorTabBackground;
export let colorTabBorder;
export let colorTab;
export let colorContainerBackground;
export let colorInactiveText;
export let colorActiveText;
export let txtTabName;
export let selectedTab;
const dispatch = createEventDispatcher();
function changeSelectedTab(newTab) {
    dispatch('change', { selectedTab: newTab });
}
let atomWidthRem;
</script>

<BaseParticle
    _isotope="SegmentedButtonParticle"
    {...container}
    _cssVariablePrefix="segment-button"
    {overlayPadding}
    {colorActiveTab}
    {colorTabBackground}
    {colorTabBorder}
    {colorTab}
    {colorContainerBackground}
    {colorInactiveText}
    {colorActiveText}
    bind:_clientWidthRem={atomWidthRem}
>
    <div
        class="main-container"
        class:layout-mobile-width={atomWidthRem <= 30}
        class:layout-tablet-width={atomWidthRem <= 45 && atomWidthRem > 30}
    >
        <div class="header-box">
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            {#each headerTabs || [] as header, i}
                <div
                    class="header-tab"
                    class:active={ selectedTab ? header === selectedTab : i===0}
                    on:click={() => changeSelectedTab(header)}
                >
                    <TextParticle {...txtTabName} text={header} />
                </div>
            {/each}
        </div>
    </div>
</BaseParticle>

<style>
    .main-container {
        padding: var(--segment-button-overlay-padding);
        background-color: var(
            --segment-button-color-container-background,
            var(--theme-mode-background-color, #fff)
        );
        width: 100%;
    }
    .header-box {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        /* gap: 0.0625rem; */
        padding: 0.5rem;
        border-radius: 2rem;
        border: 1px solid
            var(
                --segment-button-color-tab-border,
                var(--theme-mode-foreground-color, #000)
            );
        background-color: var(
            --segment-button-color-tab-background,
            var(--theme-mode-background-color, #fff)
        );
    }
    .header-tab {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        padding: 1rem 1rem;
        border-radius: 2rem;
        cursor: pointer;
    }
    .header-tab > :global(*) > :global(*) {
            color: var(--segment-button-color-inactive-text, #9b9b9b);
        }
    .header-tab.active {
        background-color: var(
            --segment-button-color-active-tab,
            var(--theme-mode-highlight-color, #41e1ae)
        );
    }
    .header-tab.active > :global(*) > :global(*) {
            color: var(--segment-button-color-active-text, #000);
        }</style>
