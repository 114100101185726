import { generateBooleanSchema, generateColorSchema, generateRootObjectSchema, generateStringSchema, generateUrlSchema } from "components/cards/common/schema";
import { BaseParticleSpec, GetParticlePropsType, GetParticleSchemaType, generateParticleContainerSchema } from "components/cards/particles/BaseParticleSpec";
import { ImageParticleSpec } from "../Image/spec";
import { TextParticleSpec } from "../Text/spec";

export const TextInputParticleSpec = new BaseParticleSpec("Image", generateRootObjectSchema({
  container: generateParticleContainerSchema({
    defaultValues: { 
      fillHeight: false,
      fillWidth: false,
      padding: '0.375rem 1rem',
      border: {
        visible: true,
        radius: '0.375rem',
        style: 'solid',
        width: '1px',
      }
    },
  }),
  backgroundColor: generateColorSchema("Background Color", "The color of the background within the input box."),
  text: TextParticleSpec.generateSchema("Text", "The text for the Input box. Any specified text becomes the placeholder for the input."),
  image: ImageParticleSpec.generateSchema("Image", "The image for the Input box.", {
    defaultValues: {
      container: { fillHeight: true, fillWidth: false }
    }
  }),
  showImage: generateBooleanSchema("Show Image", "Show the image, if specified.", {
    defaultValue: true
  }),
}));

export const specification = TextInputParticleSpec;

export type TextInputParticleSchema = GetParticleSchemaType<typeof TextInputParticleSpec>;

export type TextInputParticleProps = GetParticlePropsType<typeof TextInputParticleSpec>;
