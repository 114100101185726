import { Container } from "inversify";

export class YinzCamInjectModule {
  public constructor(private readonly configureFunc: (container: Container) => void) {
  }
  
  public configure(container: Container): void {
    this.configureFunc(container);
  }
}
