import { injectable, Container } from "inversify";
import { injectToken, Token, TokenContainerModule } from "inversify-token";
import { YinzCamInjectModule } from 'yinzcam-inject';
import { init, addMessages, locale, t, date, time, number } from 'svelte-i18n';
import type { MessageFormatter, DateFormatter, TimeFormatter, NumberFormatter, ConfigureOptions } from 'svelte-i18n/types/runtime/types/index';
import { JanusModeContextManager, JanusModeContextManagerToken } from "./mode";
import { derived, get, Readable, Writable, writable } from "svelte/store";
import { YinzCamCardsService, YinzCamCardsServiceToken } from "yinzcam-cards";
import _ from "lodash";
import pben from "../static/i18n/pagebuilder/en.json";
import pbes from "../static/i18n/pagebuilder/es.json";
import { ReactiveMicrocomponent } from "lib/yinzcam-rma";

export const JanusInternationalizationManagerToken = new Token<JanusInternationalizationManager>(Symbol.for("JanusInternationalizationManager"));

export let JanusInternationalizationManagerModule: YinzCamInjectModule = new YinzCamInjectModule((container: Container): void => {
  container.load(new TokenContainerModule((bindToken) => {
    bindToken(JanusInternationalizationManagerToken).to(JanusInternationalizationManager).inSingletonScope();
  }));
});

const PAGE_BUILDER_PREFIX = '__PAGEBUILDER__';

@injectable()
export class JanusInternationalizationManager {
  public constructor(
      @injectToken(JanusModeContextManagerToken) private readonly contextManager: JanusModeContextManager,
      @injectToken(YinzCamCardsServiceToken) private readonly cardsService: YinzCamCardsService) {
  }

  public getCurrentLanguage(): string {
    return this.contextManager.getCurrentLanguage();
  }

  public getCurrentLanguageComponent(): ReactiveMicrocomponent<string> {
    return this.contextManager.getLanguageComponent();
  }

  public getCurrentLanguageStore(): Readable<string> {
    return this.contextManager.getCurrentLanguageStore();
  }

  public setCurrentLanguage(lang: string) {
    this.contextManager.setLanguage(lang);
  }

  private readonly lineSpacingMultiplierStore: Writable<number> = writable(1.0);
  public getLineSpacingMultiplierStore(): Readable<number> {
    return this.lineSpacingMultiplierStore;
  }

  public init() {
    let fallbackLocale = CONFIG.defaultLanguage;
    let initialLocale = get(this.contextManager.getLanguageComponent().store);
    let warnOnMissingMessages = CONFIG.warnOnMissingTranslations || false;
    let config: ConfigureOptions = { fallbackLocale, initialLocale, warnOnMissingMessages };
    //console.log('JanusInternationalizationManager: INIT WITH CONFIG ', config);
    init(config);
    this.contextManager.getLanguageComponent().store.subscribe((lang) => {
      console.log('JanusInternationalizationManager: UPDATE LOCALE TO ' + lang);
      locale.set(lang);
      yc.lang = lang; // sorry, need to ref this from global scope
      // Thai fonts need more line spacing because they have additional strokes above and below the line.
      switch (lang?.substring(0, 2).toLowerCase()) {
        case 'th':
          this.lineSpacingMultiplierStore.set(1.2);
          break;
        default:
          this.lineSpacingMultiplierStore.set(1.0);
          break;
      }
    });
    // TODO: load available languages from a config file
    for (let lang of this.contextManager.getAvailableLanguages()) {
      //console.log('JanusInternationalizationManager: SUBSCRIBE MESSAGES FOR ' + lang);
      this.cardsService.getMessages(lang).store.subscribe((messages) => {
        if (!messages) {
          return;
        }
        console.log('JanusInternationalizationManager: UPDATE MESSAGES FOR ' + lang, messages);
        //delete lookupCache[lang];
        addMessages(lang, messages);
      });
    }
    //console.log('JanusInternationalizationManager: INIT COMPLETE');

    for (let [ lang, messages ] of [ [ 'en', pben ], [ 'es', pbes ] ]) {
      const prefixed = {};
      for (let message in messages as object) {
        prefixed[PAGE_BUILDER_PREFIX + message] = messages[message];
      }
      //console.log('addmessages', lang, prefixed);
      addMessages(lang as string, prefixed);
    }
  }
}

const tWrapper = derived(t, ($t) => {
  return function(...args) {
    if (_.isNil(args[0])) {
      return args[0];
    }
    return $t(...args);
  } as MessageFormatter;
});

const tdateWrapper = derived(date, ($tdate) => {
  return function(...args) {
    if (_.isNil(args[0])) {
      return '';
    }
    return $tdate(...args);
  } as DateFormatter;
});

const ttimeWrapper = derived(time, ($ttime) => {
  return function(...args) {
    if (_.isNil(args[0])) {
      return '';
    }
    return $ttime(...args);
  } as TimeFormatter;
});

const tnumWrapper = derived(number, ($tnum) => {
  return function(...args) {
    if (_.isNil(args[0])) {
      return '';
    }
    return $tnum(...args);
  } as NumberFormatter;
});

const pbtWrapper = derived(t, ($t) => {
  return function(...args) {
    if (_.isNil(args[0])) {
      return args[0];
    }
    args[0] = PAGE_BUILDER_PREFIX + args[0];
    let ret = $t(...args);
    if (ret.startsWith(PAGE_BUILDER_PREFIX)) {
      ret = ret.substring(PAGE_BUILDER_PREFIX.length);
    }
    return ret;
  } as MessageFormatter;
});

export { tWrapper as t, tdateWrapper as tdate, ttimeWrapper as ttime, tnumWrapper as tnum, pbtWrapper as pbt };
