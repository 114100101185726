<script lang="ts">var _a, _b, _c, _d, _e, _f;
import 'yinzcam-cards';
import '../../../common/CardBase.svelte';
import { getFirstSourceStore } from '../../../utilities';
import '../../../common/CardsDataSourceRegistration';
import { CardsTabContextKey } from '../../../common/context';
import '../../../../../js/i18n';
import 'svelte/store';
import { afterUpdate, getContext } from 'svelte';
import { windowMetrics as wm } from '../../../../../js/stores';
import { Popup } from 'framework7-svelte';
import PlayerStatsSummary from '../PlayerStatsSummary/PlayerStatsSummary.svelte';
import ImageWithPlaceholder from '../ImageWithPlaceholder/ImageWithPlaceholder.svelte';
import iconFootball from '../image/football_icon_black_white.png';
import iconYellow from '../image/actions/icon_yellow@3x.png';
import iconRed from '../image/actions/icon_red@3x.png';
import iconAssistTimes from '../image/actions/action_assist.png';
import iconSubbedOff from '../image/actions/icon_substitution_out@3x.png';
import iconSubbedOn from '../image/actions/icon_substitution_in@3x.png';
export let element;
export let sources;
export let source;
const tabContext = getContext(CardsTabContextKey);
$: mergedParams = (_a = tabContext === null || tabContext === void 0 ? void 0 : tabContext.mergedParamsComp) === null || _a === void 0 ? void 0 : _a.store;
$: source = getFirstSourceStore($sources);
let key;
let teamId;
let Formation;
let IsClientHome;
let elThis;
let isDesktop;
let popupOpened = false;
let popupPlayer = {};
$: key = ((_b = element === null || element === void 0 ? void 0 : element.data) === null || _b === void 0 ? void 0 : _b.key) || "teamId";
$: teamId = $mergedParams && $mergedParams[key];
$: {
    const FormationRaw = teamId === "HOME" ? (_c = $source === null || $source === void 0 ? void 0 : $source.Formations) === null || _c === void 0 ? void 0 : _c.HomeTeamFormation : (_d = $source === null || $source === void 0 ? void 0 : $source.Formations) === null || _d === void 0 ? void 0 : _d.AwayTeamFormation;
    Formation = {
        Name: (_e = FormationRaw === null || FormationRaw === void 0 ? void 0 : FormationRaw._attributes) === null || _e === void 0 ? void 0 : _e.Name,
        Row: FormationRaw === null || FormationRaw === void 0 ? void 0 : FormationRaw.Row.map((r) => ([].concat(r === null || r === void 0 ? void 0 : r.Player).filter((p) => p === null || p === void 0 ? void 0 : p._attributes).map((p) => {
            var _a, _b;
            return (Object.assign(Object.assign({}, p === null || p === void 0 ? void 0 : p._attributes), { ImageUrl: (_a = p === null || p === void 0 ? void 0 : p.ImageUrl) === null || _a === void 0 ? void 0 : _a._text, GameStats: [].concat((_b = p === null || p === void 0 ? void 0 : p.GameStats) === null || _b === void 0 ? void 0 : _b.Stat).filter((s) => s === null || s === void 0 ? void 0 : s._attributes).map((s) => (Object.assign({}, s === null || s === void 0 ? void 0 : s._attributes))) }));
        })))
    };
    IsClientHome = (_f = $source === null || $source === void 0 ? void 0 : $source.IsClientHome) === null || _f === void 0 ? void 0 : _f._text;
}
$: isDesktop = $wm.width >= 768;
$: popupOpened = isDesktop ? false : false;
afterUpdate(() => {
    if (elThis === null || elThis === void 0 ? void 0 : elThis.parentElement) {
        elThis.parentElement.style.overflow = "visible";
    }
});
const getPosition = (iRow, rowLength, iPlayer, playerLength) => {
    let ret = '';
    let playerWidth = 1;
    const topPosition = (teamId === "HOME" ? playerLength - iPlayer : iPlayer + 1);
    if (isDesktop) {
        playerWidth = 80;
        ret = `
        ${teamId == "HOME" ? "left" : "right"}: calc(15.3% + 69.3% * ${iRow} / ${rowLength} + ${(teamId == "HOME" ? 50 : 130) + 200 * (topPosition / (playerLength + 1)) * Math.tan(1.3 * (iRow / rowLength - 0.5)) - playerWidth / 2}px);
        top: calc(140% * ${topPosition / (playerLength + 1)} - 25% - ${playerWidth / 2 + 30}px);
      `;
    }
    else {
        playerWidth = 43;
        ret = `
        left: calc(100% * ${(playerLength - iPlayer) / (playerLength + 1)} - ${playerWidth / 2}px);
        bottom: calc(40% + 19% * ${iRow} - ${playerWidth / 2}px);
      `;
    }
    return ret;
};
const handleClickPlayer = (Player) => {
    popupOpened = true;
    popupPlayer = Player;
};
</script>
<div class="lineup" bind:this={elThis}>
  <div class="playground">
    {#if Formation && Formation.Row}
      {#each Formation.Row as Row, iRow}
        {#each Row as Player, iPlayer}
          <div class="player" style={getPosition(iRow, Formation.Row.length, iPlayer, Row.length)}>
            <div class="player-container" on:click={() => handleClickPlayer(Player)}>
              <div class="image-wrapper">
                <ImageWithPlaceholder src={Player.ImageUrl} title={Player.Name} alt="Player" class='player-image' placeholder="/static/images/download.png" />
              </div>
              <div class="hover-mask" />
              <div class="shadow" />
              <div class="action-card">
                {#if Player.Goals !== "0"}
                  <img src={iconFootball} alt="FootballIcon" class="action icon-football" />
                {/if}
                {#if Player.RedCards !== "0"}
                  <img src={iconRed} alt="Red card" class="action icon-red" />
                {/if}
                {#if Player.YellowCards !== "0"}
                  <img src={iconYellow} alt="Yellow card" class="action icon-yellow" />
                {/if}
                {#if Player.Assists !== "0"}
                  <img src={iconAssistTimes} alt="Assist" class="action icon-assist" />
                {/if}
                {#if Player.SubbedOff === "true"}
                  <img src={iconSubbedOff} alt="Sub off" class="action icon-sub" />
                {:else if Player.SubbedOn === "true"}
                  <img src={iconSubbedOn} alt="Sub on" class="action icon-sub" />
                {/if}
              </div>
            </div>
          </div>
        {/each}
      {/each}
    {/if}
  </div>
  <!-- <div class="player-list">
    {#each players as player}
      {#if !player.actions.length}
        <div class="image-wrapper">
          <img src={player.image} alt="Player" />
        </div>
      {/if}
    {/each}
  </div> -->
  <Popup class="player-stats-summary-popup" opened={popupOpened} onPopupClosed={() => popupOpened = false} swipeToClose={true} closeByBackdropClick={true}>
    <PlayerStatsSummary player={popupPlayer}/>
  </Popup>
</div>
<style lang="scss">*,
*:before,
*:after {
  box-sizing: border-box; }

.lineup {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }

.playground {
  width: 1042px;
  height: 290px;
  background: url("../image/football_ground_landscape.png");
  background-size: 100% 100%;
  position: relative; }

.player {
  position: absolute; }
  .player .player-container {
    position: relative; }
  .player .image-wrapper {
    position: absolute;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #f1f1f1;
    z-index: 1; }
    .player .image-wrapper :global(img) {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      display: block; }
  .player .hover-mask {
    position: absolute;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: var(--theme-primary-highlight-color, gray);
    z-index: 2;
    opacity: 0;
    transition: all 0.2s;
    cursor: pointer; }
    .player .hover-mask:hover {
      opacity: 0.4; }
  .player .shadow {
    width: 92px;
    height: 17px;
    background-image: radial-gradient(rgba(102,102,102,0.66667) 0%, rgba(0,0,255,0) 80%);
    position: absolute;
    top: 83px; }
  .player .action-card .action {
    position: absolute;
    z-index: 2;
    overflow: visible;
    height: auto;
    display: block; }
    .player .action-card .action.icon-football {
      top: 55px;
      left: 63px;
      width: 30px; }
    .player .action-card .action.icon-red {
      width: 35px;
      top: -3px;
      left: 59px; }
    .player .action-card .action.icon-yellow {
      width: 35px;
      top: -3px;
      left: 59px; }
    .player .action-card .action.icon-assist {
      top: 63px;
      left: 0px;
      width: 35px;
      height: auto; }
    .player .action-card .action.icon-sub {
      top: -3px;
      left: -8px;
      width: 33px; }
    .player .action-card .action.icon-penalty-missed {
      top: 55px;
      left: 63px;
      width: 30px; }

.player-list {
  display: none;
  gap: 4px;
  margin: 6px 0 0 0; }
  .player-list .image-wrapper {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: #d5d5d5; }
    .player-list .image-wrapper :global(img) {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      display: block; }

:global(.player-image) {
  width: 100%;
  height: 100%; }

:global(.player-stats-summary-popup) {
  --f7-popup-tablet-width: 600px;
  --f7-popup-tablet-height: 400px;
  background: var(--theme-primary-background-color, white);
  align-items: center;
  overflow: visible; }

:global(.player-stats-summary-popup.modal-in, .player-stats-summary-popup.modal-out) {
  display: flex !important; }

@media (max-width: 767px) {
  .playground {
    width: 300px;
    height: 274px;
    background: url("../image/football_ground_portrait.png");
    background-size: 100% 100%;
    position: relative; }
  .player {
    position: absolute; }
    .player .player-container {
      position: relative; }
    .player .image-wrapper {
      width: 43px;
      height: 43px; }
    .player .hover-mask {
      width: 43px;
      height: 43px; }
    .player .shadow {
      width: 43px;
      height: 8px;
      background-image: radial-gradient(rgba(102,102,102,0.66667) 0%, rgba(0,0,255,0) 80%);
      position: absolute;
      top: 40px; }
    .player .action-card .action.icon-football {
      top: 31px;
      left: 32px;
      width: 16px; }
    .player .action-card .action.icon-red {
      top: -2px;
      left: 30px;
      width: 21px; }
    .player .action-card .action.icon-yellow {
      top: -2px;
      left: 30px;
      width: 21px; }
    .player .action-card .action.icon-assist {
      top: 35px;
      left: -6px;
      width: 26px; }
    .player .action-card .action.icon-sub {
      top: -2px;
      left: -4px;
      width: 20px; }
    .player .action-card .action.icon-penalty-missed {
      top: 31px;
      left: 32px;
      width: 16px; }
  .player-list {
    display: flex; }
  :global(.player-stats-summary-popup) {
    --f7-popup-tablet-width: 100%;
    --f7-safe-area-top: 0px;
    --f7-safe-area-bottom: 0px;
    width: var(--f7-popup-tablet-width);
    height: var(--f7-popup-tablet-height);
    left: 0%;
    top: 50%;
    margin-top: calc(-1 * var(--f7-popup-tablet-height) / 2);
    transform: translate3d(0, 100vh, 0);
    box-shadow: var(--f7-popup-box-shadow);
    border-radius: var(--f7-popup-tablet-border-radius, var(--f7-popup-border-radius)); } }</style>