<script lang="ts">var _a, _b;
import { BaseParticle } from "components/cards/particles";
import "../Image";
import "../Text";
import ImageParticle from "../Image/ImageParticle.svelte";
import "../Text/TextParticle.svelte";
import { useThemeAccentColor } from "../Text/spec";
// props
export let container = undefined;
export let backgroundColor = undefined;
export let text = undefined;
export let image = undefined;
export let showImage = true;
// inputs
export let _block = false;
// outputs
export let _value = undefined;
$: inputTextStyles = Object.fromEntries(Object.entries(text || {}).filter(e => ['family', 'size', 'color', 'weight', 'style', 'decoration', 'transform'].includes(e[0])));
let modifiedContainer = container;
$: {
    const ret = Object.assign({}, container);
    ret.border = ret.border || {};
    if (!ret.border.color) {
        let baseColor = 'var(--text-input-particle-color, var(--theme-mode-foreground-color, inherit))';
        if (useThemeAccentColor) {
            baseColor = 'var(--text-input-particle-color, var(--theme-mode-accent-color, inherit))';
        }
        ret.border.color = `lch(from ${baseColor} 75% c h)`;
    }
    modifiedContainer = ret;
}
$: imageBorderRadius = (_b = (_a = image === null || image === void 0 ? void 0 : image.container) === null || _a === void 0 ? void 0 : _a.border) === null || _b === void 0 ? void 0 : _b.radius;
</script>

<style>
  input {
    background-color: var(--text-input-particle-background-color, var(--theme-mode-background-color, inherit));
    font-family: var(--text-input-particle-family, var(--theme-font-family, inherit));
    font-size: var(--text-input-particle-size, unset);
    color: var(--text-input-particle-color, var(--theme-mode-foreground-color, inherit));
    font-weight: var(--text-input-particle-weight, unset);
    font-style: var(--text-input-particle-style, unset);
    -webkit-text-decoration: var(--text-input-particle-decoration, unset);
            text-decoration: var(--text-input-particle-decoration, unset);
    text-transform: var(--text-input-particle-transform, unset);
    text-wrap: var(--text-input-particle-wrap, unset);

    /* scale with container, default container is not to fill height/width */
    width: 100%;
    height: 100%;
  }

  input.accent-color {
    color: var(--text-input-particle-color, var(--theme-mode-accent-color, inherit));
  }

  input::-webkit-input-placeholder {
    font-family: var(--text-input-particle-family, var(--theme-font-family, inherit));
    font-size: var(--text-input-particle-size, unset);
    color: var(--text-input-particle-color, var(--theme-mode-foreground-color, inherit));
    font-weight: var(--text-input-particle-weight, unset);
    font-style: var(--text-input-particle-style, unset);
    -webkit-text-decoration: var(--text-input-particle-decoration, unset);
            text-decoration: var(--text-input-particle-decoration, unset);
    text-transform: var(--text-input-particle-transform, unset);
    text-wrap: var(--text-input-particle-wrap, unset);
  }

  input::-moz-placeholder {
    font-family: var(--text-input-particle-family, var(--theme-font-family, inherit));
    font-size: var(--text-input-particle-size, unset);
    color: var(--text-input-particle-color, var(--theme-mode-foreground-color, inherit));
    font-weight: var(--text-input-particle-weight, unset);
    font-style: var(--text-input-particle-style, unset);
    text-decoration: var(--text-input-particle-decoration, unset);
    text-transform: var(--text-input-particle-transform, unset);
    text-wrap: var(--text-input-particle-wrap, unset);
  }

  input::placeholder {
    font-family: var(--text-input-particle-family, var(--theme-font-family, inherit));
    font-size: var(--text-input-particle-size, unset);
    color: var(--text-input-particle-color, var(--theme-mode-foreground-color, inherit));
    font-weight: var(--text-input-particle-weight, unset);
    font-style: var(--text-input-particle-style, unset);
    -webkit-text-decoration: var(--text-input-particle-decoration, unset);
            text-decoration: var(--text-input-particle-decoration, unset);
    text-transform: var(--text-input-particle-transform, unset);
    text-wrap: var(--text-input-particle-wrap, unset);
  }

  input.accent-color::-webkit-input-placeholder {
    color: var(--text-input-particle-color, var(--theme-mode-accent-color, inherit));
  }

  input.accent-color::-moz-placeholder {
    color: var(--text-input-particle-color, var(--theme-mode-accent-color, inherit));
  }

  input.accent-color::placeholder {
    color: var(--text-input-particle-color, var(--theme-mode-accent-color, inherit));
  }

  @supports (color: lch(from white l c h)) {
    input::-webkit-input-placeholder {
      color: lch(from var(--text-input-particle-color, var(--theme-mode-foreground-color, inherit)) 75% c h);
    }
    input::-moz-placeholder {
      color: lch(from var(--text-input-particle-color, var(--theme-mode-foreground-color, inherit)) 75% c h);
    }
    input::placeholder {
      color: lch(from var(--text-input-particle-color, var(--theme-mode-foreground-color, inherit)) 75% c h);
    }

    input.accent-color::-webkit-input-placeholder {
      color: lch(from var(--text-input-particle-color, var(--theme-mode-accent-color, inherit)) 75% c h);
    }

    input.accent-color::-moz-placeholder {
      color: lch(from var(--text-input-particle-color, var(--theme-mode-accent-color, inherit)) 75% c h);
    }

    input.accent-color::placeholder {
      color: lch(from var(--text-input-particle-color, var(--theme-mode-accent-color, inherit)) 75% c h);
    }
  }

  .image-container {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;

    height: 100%;

    padding: var(--text-input-particle-overlay-padding, unset);
    background-color: var(--text-input-particle-background-color, var(--theme-mode-background-color, inherit));
    border-radius: var(--text-input-particle-image-border-radius, unset);
  }</style>

<BaseParticle _name="TextInputParticle" _cssVariablePrefix="text-input-particle" _inline={!_block} _position="relative" {...modifiedContainer} {...inputTextStyles} {backgroundColor} overlayPadding={container && container.padding} {imageBorderRadius}>
  <input bind:value={_value} on:input on:focus on:blur on:keydown type="text" placeholder={(text && text.text) || ""} class:accent-color={text && text.useAccentColor} />
  {#if showImage}
  <div class="image-container">
    <ImageParticle _block={true} {...image} />
  </div>
  {/if}
</BaseParticle>
