<div class="cards-editor-control-bar theme-dark">
  <Menu>
    <!--
    <CardsEditorLayerControlBarItem />
    <CardsEditorConfigControlBarItem />
    <CardsEditorAddChildControlBarItem />
    <CardsEditorRemoveChildControlBarItem />
    -->
    <div class="cards-editor-button-area-container">
      <div class="cards-editor-control-area-buttons">
        <CardsEditorPreviewControlBarItem />
        <CardsEditorFullscreenControlBarItem />
      </div>
      <div class="cards-editor-content-area-buttons">
        <CardsEditorViewportWidthControlBarItem {breakpoints} />
      </div>
    </div>
  </Menu>
</div>

<style>
  *, *::before, *::after {
    box-sizing: border-box;
  }

  .cards-editor-control-bar {
    font-family: 'SF UI Display';
    background: white;
    color: black;
    padding: 15px 0px 10px 0px;
    
    --f7-menu-bg-color: #f1f1f1;
    --f7-menu-text-color: black;
    --f7-menu-font-size: 13px;
    --f7-menu-item-height: 36px;
    --f7-menu-item-padding-horizontal: 5px;
    --f7-menu-item-border-radius: 4px;
    --f7-button-text-color: black;
    --f7-button-fill-text-color: black;
    --f7-button-pressed-bg-color: #d0d0d0;
    --f7-button-fill-bg-color: #d0d0d0;
    --f7-button-bg-color: #f1f1f1;
    --f7-button-hover-bg-color: #d0d0d0;
    --f7-button-fill-hover-bg-color: #d0d0d0;
  }

  .cards-editor-control-bar :global(.menu-item.active-state) {
    background-color: #d0d0d0;
  }

  .cards-editor-control-bar :global(.menu-item:hover) {
    background-color: #d0d0d0;
  }

  .cards-editor-button-area-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: start;
    width: 100%;
  }

  .cards-editor-control-area-buttons {
    flex: 0 0 300px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: start;
  }

  .cards-editor-content-area-buttons {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: start;
  }

  .cards-editor-content-area-buttons :global(.segmented .button) {
    height: 36px;
  }</style>

<script lang="ts">import { Menu } from 'framework7-svelte';
import './CardsEditorLayerControlBarItem.svelte';
import './CardsEditorConfigControlBarItem.svelte';
import './CardsEditorAddChildControlBarItem.svelte';
import './CardsEditorRemoveChildControlBarItem.svelte';
import CardsEditorPreviewControlBarItem from './CardsEditorPreviewControlBarItem.svelte';
import CardsEditorFullscreenControlBarItem from './CardsEditorFullscreenControlBarItem.svelte';
import CardsEditorViewportWidthControlBarItem from './CardsEditorViewportWidthControlBarItem.svelte';
const breakpoints = [
    { slug: 'phone', minWidth: 0, name: 'Phone', default: false },
    { slug: 'tablet', minWidth: 640, name: 'Tablet', default: false },
    { slug: 'desktop', minWidth: 1024, name: 'Desktop', default: true },
];
</script>