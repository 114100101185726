<script lang="ts">import loader from "@beyonk/async-script-loader";
import { onMount } from "svelte";
import { v4 as uuidv4 } from 'uuid';
export let width;
export let entryId;
let wrapperDivEl;
let ready = false;
function testStreamAMG() {
    return !!window.Playback;
}
function readyStreamAMG() {
    console.log('STREAMAMG INIT', window.Playback);
    window.Playback.initialize('6WEUmCA4Py4PDNbMbH3tO1dfPymCIQxd6BOAmmw3', { autoplay: true, muted: false });
    requestAnimationFrame(() => {
        ready = true;
    });
}
function initStreamAMG() {
    loader([
        {
            type: "script",
            url: "https://sdk.playback.streamamg.com/v1/playback.js",
        },
    ], testStreamAMG, readyStreamAMG);
}
$: if (ready && entryId && wrapperDivEl) {
    const playOptions = {
        container: wrapperDivEl.getAttribute("id"),
        entryId: entryId
    };
    console.log('STREAMAMG PLAY', window.Playback, playOptions);
    window.Playback.play(playOptions)
        .catch((error) => {
        // Handle any unexpected error as you desire.
        console.log('error playing the video:', error);
    });
}
onMount(() => {
    initStreamAMG();
});
</script>

<div id={`STREAMAMG_PLAYER_${uuidv4()}`} bind:this={wrapperDivEl} style={`width: ${width || 'auto'};`}>
</div>


