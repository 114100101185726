<script lang="ts">var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
import 'yinzcam-cards';
import CardBase from '../../../common/CardBase.svelte';
import { findSourceStore } from '../../../utilities';
import '../../../common/CardsDataSourceRegistration';
import { CardsTabContextKey } from '../../../common/context';
import '../../../../../js/i18n';
import 'svelte/store';
import { getContext } from 'svelte';
import '../../../../../js/stores';
export let element;
export let sources;
export let sourceStore;
let teamLogo1;
let teamLogo2;
let heading = "Premier League";
let overStatus = "Full Time";
let teamName1 = "TOT";
let teamName2 = "SHE";
let stadium = "Stadium Name";
let score1 = 0;
let score2 = 0;
const tabContext = getContext(CardsTabContextKey);
const dateToString = (date) => {
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    let d = date.getDate();
    let res = `${y}-${m > 9 ? m : '0' + m}-${d > 9 ? d : '0' + d}`;
    return res;
};
$: boxSource = findSourceStore($sources, 'YinzCamAppServerGameBox');
$: mergedParams = (_a = tabContext === null || tabContext === void 0 ? void 0 : tabContext.mergedParamsComp) === null || _a === void 0 ? void 0 : _a.store;
$: {
    tabContext.setParam("compId", (_c = (_b = $boxSource === null || $boxSource === void 0 ? void 0 : $boxSource.Competition) === null || _b === void 0 ? void 0 : _b._attributes) === null || _c === void 0 ? void 0 : _c.OptaId);
    tabContext.setParam("date", dateToString(new Date((_d = $boxSource === null || $boxSource === void 0 ? void 0 : $boxSource.Timestamp) === null || _d === void 0 ? void 0 : _d._text)));
}
$: {
    sourceStore = findSourceStore($sources, 'YinzCamAppServerGameScores');
    teamLogo1 = `https://resources-uk.yinzcam.com/soccer/shared/logos/${(_g = (_f = (_e = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.HomeTeam) === null || _e === void 0 ? void 0 : _e._attributes) === null || _f === void 0 ? void 0 : _f.LogoId) === null || _g === void 0 ? void 0 : _g.toLowerCase()}_light.png`;
    teamLogo2 = `https://resources-uk.yinzcam.com/soccer/shared/logos/${(_k = (_j = (_h = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.AwayTeam) === null || _h === void 0 ? void 0 : _h._attributes) === null || _j === void 0 ? void 0 : _j.LogoId) === null || _k === void 0 ? void 0 : _k.toLowerCase()}_light.png`;
    heading = ((_m = (_l = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.Competition) === null || _l === void 0 ? void 0 : _l._attributes) === null || _m === void 0 ? void 0 : _m.Name) || "";
    overStatus = ((_o = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.GameState) === null || _o === void 0 ? void 0 : _o._text) || "";
    teamName1 = ((_q = (_p = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.HomeTeam) === null || _p === void 0 ? void 0 : _p._attributes) === null || _q === void 0 ? void 0 : _q.TriCode) || "";
    teamName2 = ((_s = (_r = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.AwayTeam) === null || _r === void 0 ? void 0 : _r._attributes) === null || _s === void 0 ? void 0 : _s.TriCode) || "";
    stadium = ((_t = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.Venue) === null || _t === void 0 ? void 0 : _t._text) || "";
    score1 = (_v = (_u = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.HomeTeam) === null || _u === void 0 ? void 0 : _u._attributes) === null || _v === void 0 ? void 0 : _v.Score;
    score2 = (_x = (_w = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.AwayTeam) === null || _w === void 0 ? void 0 : _w._attributes) === null || _x === void 0 ? void 0 : _x.Score;
    if (score1 === "" || score1 === undefined || score1 === null) {
        score1 = " ";
    }
    if (score2 === "" || score2 === undefined || score2 === null) {
        score2 = " ";
    }
}
</script>

<CardBase {element}>
  <div class="live-score-wide">
    <div class="heading-bar">
      <h3>{heading}</h3>
      <span class="over-status">{overStatus}</span>
    </div>
    <div class="wide-body">
      <div class="team">
        <img src={teamLogo1} alt="logo" class="logo" />
        <div class="name">{teamName1}</div>
      </div>
      <div class="match-info">
        <div class="score-info">
          <span class="score">{score1}</span>
          <span class="vs">-</span>
          <span class="score">{score2}</span>
        </div>
        <div class="stadium">{stadium}</div>
      </div>
      <div class="team">
        <img src={teamLogo2} alt="logo" class="logo" />
        <div class="name">{teamName2}</div>
      </div>
    </div>
  </div>
</CardBase>

<style lang="scss">*,
*:before,
*:after {
  box-sizing: border-box; }

.live-score-wide {
  width: 412px;
  height: 164px !important;
  background: var(--theme-primary-background-color, white); }

.heading-bar {
  color: var(--theme-inverted-foreground-color, white);
  background-color: var(--theme-inverted-background-color, black);
  font-size: 11.6px;
  font-weight: bold;
  height: 26px;
  line-height: 26px;
  padding: 0 19px;
  display: flex;
  justify-content: space-between;
  border: solid 1.5px var(--theme-inverted-background-color, black);
  border-top-left-radius: 5.8px;
  border-top-right-radius: 5.8px; }
  .heading-bar h3 {
    padding: 0;
    margin: 0; }

.wide-body {
  height: 138px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 15px 38px;
  border: solid 2px #e5e5e5;
  border-bottom-left-radius: 5.8px;
  border-bottom-right-radius: 5.8px; }
  .wide-body .team {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
    .wide-body .team .logo {
      display: block;
      height: 59px; }
    .wide-body .team .name {
      margin: 13px 0 0 0;
      line-height: 26px;
      font-size: 13.9px;
      font-weight: bold;
      text-align: center;
      color: var(--theme-primary-foreground-color, black);
      background-color: var(--theme-primary-background-color, white); }
  .wide-body .match-info {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end; }
    .wide-body .match-info .score-info {
      height: 49px;
      font-family: Arial;
      font-size: 44.1px;
      font-weight: bold;
      color: var(--theme-primary-foreground-color, black);
      background-color: var(--theme-primary-background-color, white);
      display: flex;
      align-items: center;
      justify-content: center; }
      .wide-body .match-info .score-info .score {
        font-weight: bold;
        display: block; }
      .wide-body .match-info .score-info .vs {
        margin: 0 20px 10px 20px;
        display: block; }
    .wide-body .match-info .stadium {
      height: 26px;
      line-height: 26px;
      margin: 17px 0 0 0;
      font-size: 13.9px;
      text-align: center;
      color: var(--theme-primary-foreground-color, black);
      background-color: var(--theme-primary-background-color, white); }

@media (max-width: 767px) {
  .live-score-wide {
    width: 100%;
    height: unset !important;
    background: var(--theme-primary-background-color, white); }
  .heading-bar {
    font-size: 8.4px;
    height: 19px;
    line-height: 19px;
    padding: 0 14px;
    border-top-left-radius: 5.8px;
    border-top-right-radius: 5.8px; }
  .wide-body {
    height: 100px;
    display: flex;
    padding: 15px 33px; }
    .wide-body .team .logo {
      height: 46px; }
    .wide-body .team .name {
      margin: 8px 0 0 0;
      line-height: 19px;
      font-size: 10.1px; }
    .wide-body .match-info .score-info {
      height: 36px;
      font-size: 32px; }
      .wide-body .match-info .score-info .vs {
        margin: 0 8px 10px 8px;
        display: block; }
    .wide-body .match-info .stadium {
      line-height: 19px;
      margin: 12px 0 0 0;
      font-size: 10.1px; } }</style>
