import _ from 'lodash';
import { CardsDataSourceToken } from './CardsDataSourceToken';
import { getAllToken, getNamed } from 'inversify-token';
import ROOT from '../../../inversify.config';

const cardDataSourceRecords = getAllToken(ROOT, CardsDataSourceToken);
const cardDataSourceClassNamePairs: [string, string][] = [['', 'Select Type']];
for (let rec of cardDataSourceRecords) {
  const clazz = (<any>rec).clazz;
  const name = (_.isFunction(rec.getDisplayName))? rec.getDisplayName() : clazz;
  cardDataSourceClassNamePairs.push([clazz, name]);
}
cardDataSourceClassNamePairs.sort((a, b) => a[1].localeCompare(b[1]));
export const cardDataSourceEnumValues = cardDataSourceClassNamePairs.map(p => p[0]);
export const cardDataSourceEnumTitles = cardDataSourceClassNamePairs.map(p => p[1]);

export function getDataSourceByClassName(className: string) {
  return getNamed(ROOT, CardsDataSourceToken, className);
}
