<script lang="ts">const footerBarItems = [
    {
        title: 'Contact Us',
        url: 'https://www.tottenhamhotspur.com/information/contact-us/'
    },
    {
        title: 'Cookie Policy',
        url: 'https://www.tottenhamhotspur.com/information/cookie-policy/'
    },
    {
        title: 'Privacy Policy',
        url: 'https://www.tottenhamhotspur.com/information/privacy-policy/'
    },
    {
        title: 'Terms and Conditions',
        url: 'https://www.tottenhamhotspur.com/information/terms-and-conditions/'
    },
    {
        title: 'My Account Help',
        url: 'https://ask.tottenhamhotspur.com/hc/en-us/categories/200990832-My-Account'
    }
];
</script>

<div class="page-footer">
  <div class="links">
    <a href="https://www.tottenhamhotspur.com/the-club/careers/" class="external">CAREERS</a>
    <a href="https://www.tottenhamhotspur.com/the-club/" class="external">THE CLUB</a>
    <a href="https://www.tottenhamhotspur.com/the-club/safeguarding/" class="external">SAFEGUARDING</a>
    <a href="https://www.tottenhamhotspur.com/the-club/foundation/" class="external">FOUNDATION</a>
    <a href="https://www.tottenhamhotspur.com/the-stadium/accessibility/" class="external">ACCESSIBILITY</a>
  </div>
  <div class="subscribe">
    <span>STAY UP TO DATE</span>
    <a href="https://www.tottenhamhotspur.com/subscribe/" class="external">Subscribe</a>
  </div>
  <div class="footer-bar">
    {#each footerBarItems as e}
      <a href={e.url} class="external">{e.title}</a>
    {/each}
  </div>
</div>

<style lang="scss">*,
*:before,
*:after {
  box-sizing: border-box; }

.page-footer {
  background: #0F142A;
  width: 100%;
  padding: 45px 0 0 0; }

.links {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 40px 0;
  flex-wrap: wrap; }
  .links a {
    color: white;
    font-size: 12.8px;
    padding: 0 10px;
    border-right: 1px solid #fff;
    text-transform: uppercase;
    margin: 6px 0; }
    .links a:hover {
      -webkit-text-decoration: underline;
      text-decoration: underline; }
    .links a:last-child {
      border-right: none; }

.subscribe {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 30px; }
  .subscribe span {
    text-transform: uppercase;
    font-size: 24px;
    line-height: 32px;
    color: white;
    letter-spacing: -1px; }
  .subscribe a {
    margin: 0 0 0 16px;
    background: #fff;
    color: #111836;
    padding: 0 1em;
    color: #111836;
    display: inline-block;
    text-transform: uppercase;
    line-height: 32px; }

.footer-bar {
  width: 100%;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  padding: 16px 0; }
  .footer-bar a {
    margin: 0 10px 0 0;
    color: #999;
    line-height: 18.4px;
    font-size: 16px; }
    .footer-bar a:hover {
      -webkit-text-decoration: underline;
      text-decoration: underline; }
    .footer-bar a:last-child {
      margin-right: 0; }

@media (max-width: 767px) {
  .subscribe span {
    font-size: 20px; } }</style>