import { YinzCamAPIRequest } from "./YinzCamAPIRequest";

export let YCAPIREQUEST_DEFAULTS: YinzCamAPIRequest = {
  path: null,
  method: 'GET',
  addStandardParams: true,
  addStandardHeaders: true,
  headers: {},
  params: {},
  data: null,
  bypassCache: false,
};
