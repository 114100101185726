<script lang="ts">//import Hls from 'hls.js'
import { onMount } from 'svelte';
import PlayButton from '../PlayButton/PlayButton.svelte';
export let streamUrl = 'https://open.http.mp.streamamg.com/p/2000012/sp/200001200/playManifest/entryId/0_7nqzdt52/format/applehttp/protocol/https/a.m3u8';
//'https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8'
export let controls = false;
let clazz;
export { clazz as class };
let videoRef;
let isPlaying = false;
function iOS() {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
}
onMount(() => {
    if (videoRef) {
        const isIos = iOS();
        if (isIos) {
            videoRef.src = streamUrl;
        }
        else {
            const ret = videoRef.canPlayType('audio/mp4');
            if (ret === "probably") {
                videoRef.src = streamUrl;
            } /* else if (Hls.isSupported()) {
              const hls = new Hls()
              hls.loadSource(streamUrl)
              hls.attachMedia(videoRef)
            }*/
        }
        // videoRef.play()
    }
});
const handleClick = (e) => {
    return;
    isPlaying = e.detail.value;
    if (isPlaying) {
        videoRef.play();
    }
    else {
        videoRef.pause();
    }
};
</script>

<div className="video-stream-player">
  <PlayButton on:click={handleClick} value={isPlaying} />
  <audio
    preload="auto"
    type="audio/mpeg"
    class={clazz}
    {controls}
    bind:this={videoRef}
    src={streamUrl}
  />
</div>

<style lang="scss">*,
*::after,
*::before {
  box-sizing: border-box; }</style>
