<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import _ from "lodash";
import { BaseAtom, getSignonManager } from "components/cards/atoms";
import { ButtonParticle } from "components/cards/particles/YinzCam";
import "js/url";
export let container = undefined;
export let defaultButton = undefined;
export let loggedOutButton = undefined;
export let loggedInButton = undefined;
const sso = getSignonManager();
const profile = sso.getDefaultProfileSegmentStore();
$: loginStatus = sso.getStatusComponent().store;
$: isLoggedIn = $loginStatus.loggedIn;
let currentButton = loggedOutButton;
let buttonOverrides = {};
$: if (isLoggedIn) {
    currentButton = loggedInButton;
    buttonOverrides = {};
}
else {
    currentButton = loggedOutButton;
    buttonOverrides = { container: { hidden: true } };
}
async function handleLogoutButtonClick() {
    if (isLoggedIn) {
        sso.logout();
    }
    else {
        // do nothing
    }
}
</script>

<style></style>

<BaseAtom _isotope="LogoutButtonAtom" {...container}>
  <ButtonParticle {..._.merge({}, defaultButton, currentButton, buttonOverrides)} on:click={handleLogoutButtonClick} />
</BaseAtom>
