import { Device } from 'framework7';
import { Container, injectable } from "inversify";
import { getToken, Token, TokenContainerModule } from 'inversify-token';
import { YinzCamAPIRequestParameterComponent, YinzCamAPIRequestParameters, YinzCamAPIRequestParameterComponentToken } from 'yinzcam-api';
import { AbstractReactiveMicrocomponent, ControlBase, ManualPassthrough, RepeatingTimer } from 'yinzcam-rma';
import { YinzCamInjectModule } from 'yinzcam-inject';
import { getDeviceId } from './stores';

export const JanusDefaultRequestParameterComponentToken = new Token<JanusDefaultRequestParameterComponent>(Symbol.for("JanusDefaultRequestParameterComponent"));

export let JanusDefaultRequestParameterModule: YinzCamInjectModule = new YinzCamInjectModule((container: Container): void => {
  container.load(new TokenContainerModule((bindToken) => {
    bindToken(YinzCamAPIRequestParameterComponentToken).to(JanusDefaultRequestParameterComponent);
  }));
});

@injectable()
export class JanusDefaultRequestParameterComponent extends AbstractReactiveMicrocomponent<YinzCamAPIRequestParameters, [ number ]>
  implements YinzCamAPIRequestParameterComponent {

  static buildRefreshPipeline() {
    let frequencyInput = new ManualPassthrough<number>('JanusDefaultRequestParameterComponent_Refresh_Frequency', CONFIG.defaultParameterUpdateIntervalSeconds*1000);
    let timerInput = new RepeatingTimer('JanusDefaultRequestParameterComponent_Refresh_Timer', 0, frequencyInput);
    frequencyInput.setValue(CONFIG.defaultParameterUpdateIntervalSeconds*1000);
    return timerInput;
  }

  private readonly appId: string;

  public constructor() {
    super({ name: 'JanusDefaultRequestParameterComponent' }, JanusDefaultRequestParameterComponent.buildRefreshPipeline());
    this.appId = `${CONFIG.league}_${CONFIG.tricode}`.toUpperCase();
  }

  protected async update($control: ControlBase, $timer: number): Promise<YinzCamAPIRequestParameters> {
    // required params for app server with somewhat sane defaults
    const headers: { [key: string]: string } = { };
    const params: { [key: string]: string } = {
      'os': 'iOS',
      'platform': 'iOS',
      'ff': 'mobile',
      'rt': 'web',
      'application': 'YC_UNDEFINED',
      'version': '0.0',
      'app_version': '0.0.0',
      'os_version': '0.0' // TODO: figure out what this should be?
    };
    // TODO: This might need to be reactive at some point
    const width = document?.documentElement?.clientWidth;
    const height = document?.documentElement?.clientHeight;
    if (width) {
      if (width > 768) {
        params.ff = 'tablet';
      }
      params.width = width.toString();
    }
    if (height) {
      params.height = height.toString();
    }
    if (Device) {
      if (Device.android) {
        params.os = 'Android';
        params.platform = 'Android';
      }
      if (Device.ipad) {
        params.ff = 'tablet';
      }
      if (CONFIG.sendXYinzCamDeviceIdHeader) {
        // @ts-ignore
        if (Device.cordova && device) {
          // @ts-ignore
          headers['X-YinzCam-DeviceId'] = device.uuid;
        } else {
          let id: string = getDeviceId();
          if (id) {
            headers['X-YinzCam-DeviceId'] = id;
          }
        }
      }
      params.rt = (Device.cordova)? 'hybrid' : 'web';
    }
    if (this.appId) {
      params.application = this.appId;
      if (CONFIG.sendXYinzCamAppIdHeader) {
        headers['X-YinzCam-AppId'] = this.appId;
      }
    }
    if (CONFIG.version) {
      params['app_version'] = CONFIG.version;
    }
    if (CONFIG.apiVersion) {
      params.version = CONFIG.apiVersion;
    }

    return { headers, params };
  }
}