import { generateRootObjectSchema, generateArraySchema, generateObjectSchema, generateStringSchema, generateColorSchema, generateNumberSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { ImageParticleSpec, TextParticleSpec, SegmentedButtonParticleSpec } from "components/cards/particles/YinzCam";


export const specification = new BaseAtomSpec("Player Header", generateRootObjectSchema({
    container: generateAtomContainerSchema(),
    overlayPadding: generateStringSchema("Container Padding", "Container Padding"),
    txtHeaderTabs: SegmentedButtonParticleSpec.generateSchema("Header Tabs", "Header Tabs"),
    defaultSelectTabTeam: generateStringSchema("Default Selection", "Default Selection"),
}));