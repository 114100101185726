<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import "lodash";
import { setStoreOnHoverAction } from "components/cards/utilities";
import { BaseAtom } from "components/cards/atoms";
import { TextParticle, ImageParticle, ButtonParticle, } from "components/cards/particles/YinzCam";
import { writable } from "svelte/store";
import { resolveUrl } from "js/url";
const hovering = writable(false);
export let container;
export let overlayPadding = undefined;
export let ctaType = "button";
export let headerIcon;
export let headerText;
export let bodyText;
export let thumbnail;
export let ctaButton;
export let ctaButton2;
export let isBodyTextRequired = true;
export let link;
export let headerObject;
export let ctaIcon;
export let imageGradient;
export let headerBarBackgroundColor;
export let gradientColor;
export let centerAlignContent;
let atomWidthRem;
</script>

<BaseAtom
    _isotope="CTAZDimensionAtom"
    {...container}
    _link={resolveUrl(link).href}
    _cssVariablePrefix="cta-z-dimension"
    backgroundColor={headerBarBackgroundColor}
    {overlayPadding}
    {gradientColor}
    bind:_clientWidthRem={atomWidthRem}
>
    <div
        class="main-container"
        use:setStoreOnHoverAction={hovering}
        class:layout-mobile-width={atomWidthRem < 30}
        class:layout-tablet-width={atomWidthRem < 45}
    >
        <div class="background-container">
            <ImageParticle _block={true} _hovering={$hovering} {...thumbnail} />
        </div>
        <div
            class={`overlay-area ${imageGradient ? "overlay-area-gradient" : ""}`}
            class:center-align={centerAlignContent}
        >
            <div class="overlay-left-content">
                {#if headerObject}
                    <div class="left-content-icon">
                        {#if headerObject === "icon"}
                            <ImageParticle {...headerIcon} />
                        {/if}
                        {#if headerObject === "shape"}
                            <div class="rectangle-shape"></div>
                        {/if}
                    </div>
                {/if}

                <div class="left-content-heading">
                    <TextParticle {...headerText} />
                </div>
                {#if isBodyTextRequired}
                    <div class="left-content-body">
                        <TextParticle {...bodyText} />
                    </div>
                {/if}
            </div>
            <div class="overlay-right-content">
                {#if ctaType === "icon"}
                    <div class="right-content-arrow">
                        <ImageParticle {...ctaIcon} />
                    </div>
                {:else if ctaType === "button"}
                    {#if ctaButton && (ctaButton.text?.text || ctaButton.image?.source || ctaButton.image?.icon)}
                        <div class="right-content-button">
                            <ButtonParticle {...ctaButton} />
                        </div>
                    {/if}
                    {#if ctaButton2 && (ctaButton2.text?.text || ctaButton2.image?.source || ctaButton2.image?.icon)}
                        <div class="right-content-button">
                            <ButtonParticle {...ctaButton2} />
                        </div>
                    {/if}
                {/if}
            </div>
        </div>
    </div>
</BaseAtom>

<style>
    .main-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        background-size: cover;
        background-position: top center;
        cursor: pointer;
        position: relative;
        height: 100%;
        width: 100%;
    }

    .main-container > .background-container {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .main-container > .overlay-area {
        z-index: 2;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        align-self: stretch;
        justify-content: space-between;
        padding: var(--cta-z-dimension-overlay-padding, unset);
        gap: 1rem;
    }

    .overlay-area.center-align {
        flex-direction: column;
        align-items: center;
        justify-content: end;
        text-align: center;
    }

    .overlay-area-gradient {
        /* background: linear-gradient(180deg, rgba(0, 0, 0, 0) 25.97%, var(--cta-z-dimension-gradient-color, #000) 100%); */
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 25.97%,
            var(--cta-z-dimension-gradient-color, --theme-mode-accent-color)
                100%
        );
    }

    .overlay-right-content {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .overlay-right-content > * {
        flex: 1 1 100%;
    }

    .right-content-arrow {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .right-content-button {
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
    }

    .right-content-button > :global(*) {
        flex: 1 1 100%;
    }

    .left-content-heading {
        /* line-height: 34px !important; */
    }

    .rectangle-shape {
        width: 7.5rem;
        height: 0.25rem;
        background: var(
            --cta-z-dimension-background-color,
            var(--theme-mode-highlight-color, unset)
        );
    }

    .main-container.layout-mobile-width > .overlay-area {
        /* flex-wrap: wrap; */
        align-self: flex-end;
    }

    .main-container.layout-mobile-width > .overlay-area .right-content-arrow{
            align-self: flex-start;
        }

    .main-container.layout-mobile-width > .right-content-button {
        align-self: flex-end;
    }</style>
