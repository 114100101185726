import { generateRootObjectSchema, generateStringSchema, generateTranslationSchema, generateUrlSchema } from "components/cards/common/schema";
import { BaseParticleSpec, GetParticlePropsType, GetParticleSchemaType, generateParticleContainerSchema } from "components/cards/particles";

export const WebEmbedParticleSpec = new BaseParticleSpec("Web Embed", generateRootObjectSchema({
  container: generateParticleContainerSchema(),
  name: generateStringSchema("Name", "The name of the embedded web page."),
  source: generateUrlSchema("Source URL", "The source URL of the embedded web page."),
  translations: generateTranslationSchema("Web Embed", {
    source: generateUrlSchema("Source URL", "The source URL of the embedded web page."),
  }),
}));

export const specification = WebEmbedParticleSpec;

export type WebEmbedParticleSchema = GetParticleSchemaType<typeof WebEmbedParticleSpec>;

export type WebEmbedParticleProps = GetParticlePropsType<typeof WebEmbedParticleSpec>;
