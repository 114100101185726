
<script lang="ts">var _a;
import "lodash";
import "./schema";
import { getContext } from "svelte";
import { DIYCardContextKey } from "./context";
import { TextParticle, ImageParticle } from "components/cards/particles/YinzCam";
import { buildInlineStyleFromObjects, buildThemeModeInlineStyle, mergeInlineStyles } from "components/cards/utilities";
import "components/cards/common/schema";
import { resizeObserverAction } from "components/cards/common/actions";
export let item = undefined;
const context = getContext(DIYCardContextKey);
const editorModeEnabled = context === null || context === void 0 ? void 0 : context.editorModeEnabled;
const selectedItemControls = context === null || context === void 0 ? void 0 : context.selectedItemControls;
const lastCanvasClickedItemId = context === null || context === void 0 ? void 0 : context.lastCanvasClickedItemId;
let contentTreeItem;
$: isSelected = (item === null || item === void 0 ? void 0 : item.id) === ((_a = $selectedItemControls === null || $selectedItemControls === void 0 ? void 0 : $selectedItemControls.item) === null || _a === void 0 ? void 0 : _a.id);
$: isContainer = (item === null || item === void 0 ? void 0 : item.type) === 'YinzCam__Core__Container';
$: hasSelectedChild = isContainer && ((item === null || item === void 0 ? void 0 : item.items) || []).find((subItem) => { var _a; return (subItem === null || subItem === void 0 ? void 0 : subItem.id) === ((_a = $selectedItemControls === null || $selectedItemControls === void 0 ? void 0 : $selectedItemControls.item) === null || _a === void 0 ? void 0 : _a.id); });
$: containerData = item === null || item === void 0 ? void 0 : item.data;
$: position = 'static'; // TODO
$: inlineStyle = (isContainer) ? mergeInlineStyles(buildThemeModeInlineStyle(containerData.themeMode), buildInlineStyleFromObjects([containerData.background, 'particle-container-background'], [containerData.border, 'particle-container-border'], [containerData.filter, 'particle-container-filter'], [containerData.flex, 'particle-container-flex'], [containerData.overflow, 'particle-container-overflow'], [containerData.height, 'particle-container-height'], [containerData.margin, 'particle-container-margin'], [containerData.padding, 'particle-container-padding'], [containerData.width, 'particle-container-width'], [containerData.boxShadow, 'particle-container-box-shadow'])) : '';
let containerWidthPx, containerHeightPx;
let containerWidthRem, containerHeightRem;
function handleResize({ borderBoxSize, contentBoxSize }) {
    if (!(borderBoxSize === null || borderBoxSize === void 0 ? void 0 : borderBoxSize.length) || !contentBoxSize.length) {
        return;
    }
    containerWidthPx = borderBoxSize[0].inlineSize;
    containerHeightPx = borderBoxSize[0].blockSize;
    const pxPerRem = parseFloat(getComputedStyle(document.documentElement).fontSize);
    containerWidthRem = containerWidthPx / pxPerRem;
    containerHeightRem = containerHeightPx / pxPerRem;
}
function onEditorClick() {
    if (item === null || item === void 0 ? void 0 : item.id) {
        lastCanvasClickedItemId.set(item.id);
    }
}
</script>

<style>
  .diy-card-content-tree-item {

  }

  .diy-card-content-tree-item.is-in-editor-mode {
    cursor: pointer;
  }

  .diy-card-content-tree-item.is-in-editor-mode:hover {
    outline: 1px dashed gray;
  }

  .diy-card-content-tree-item.is-selected {
    outline: 1px solid blue;
  }

  .diy-card-content-tree-item.is-container {
    position: relative;
  }

  .diy-card-content-tree-item.is-container.with-width {
    width: var(--particle-container-width);
  }

  .diy-card-content-tree-item.is-container.with-height {
    height: var(--particle-container-height);
  }

  .diy-card-content-tree-item.is-container.with-background {
    background-image: var(--particle-container-background-image, unset);
    background-color: var(--particle-container-background-color, var(--theme-mode-background-color, unset));
    background-repeat: var(--particle-container-background-repeat, unset);
    background-size: var(--particle-container-background-size, unset);
    background-attachment: var(--particle-container-background-attachment, unset);
    background-position-x: var(--particle-container-background-horizontal-alignment, unset);
    background-position-y: var(--particle-container-background-vertical-alignment, unset);
    background-clip: var(--particle-container-background-clip, unset);
    -webkit-backdrop-filter: var(--particle-container-background-filter, unset);
            backdrop-filter: var(--particle-container-background-filter, unset);
  }

  .diy-card-content-tree-item.has-selected-child:not(.with-background),
  .diy-card-content-tree-item.is-selected.is-container:not(.with-background) {
    background-size: 1rem 1rem;
    background-image: linear-gradient(to right, lightgrey 1px, transparent 1px), linear-gradient(to bottom, lightgrey 1px, transparent 1px);
  }

  .diy-card-content-tree-item.has-selected-child.with-background,
  .diy-card-content-tree-item.is-selected.is-container.with-background {
    background-size: 1rem 1rem, 1rem 1rem, var(--particle-container-background-size, auto);
    background-image: linear-gradient(to right, lightgrey 1px, transparent 1px), linear-gradient(to bottom, lightgrey 1px, transparent 1px), var(--particle-container-background-image, linear-gradient(transparent, transparent));
  }

  .diy-card-content-tree-item > .size-indicator {
    display: none;
  }

  .diy-card-content-tree-item.has-selected-child > .size-indicator,
  .diy-card-content-tree-item.is-selected.is-container > .size-indicator {
    display: block;
    position: absolute;
    z-index: 100;
    padding: 0px 10px;
    border-radius: 8px;
    box-shadow: 0 0 8px rgba(0, 0, 0, .1);
    bottom: 3px;
    right: 5px;
    background-color: white;
    border: 0.5px solid gray;
    color: gray;
    font-size: 10px;
  }

  /*
  .diy-card-content-tree-item.is-container.with-background.use-highlight-color {
    background-color: var(--particle-container-background-color, var(--theme-mode-highlight-color, unset));
  }

  @supports (background-color: lch(from white l c h)) {
    .diy-card-content-tree-item.is-container.with-background.background-with-lightness-modifier {
      background-color: lch(from var(--particle-container-background-color, var(--theme-mode-background-color, transparent)) calc(l * calc(var(--particle-container-background-color-lightness, 100)/100)) c h);
    }

    .diy-card-content-tree-item.is-container.with-background.use-highlight-color {
      background-color: lch(from var(--particle-container-background-color, var(--theme-mode-highlight-color, transparent)) calc(l * calc(var(--particle-container-background-color-lightness, 100)/100)) c h);
    }
  }
  */

  .diy-card-content-tree-item.is-container.with-border {
    border-style: var(--particle-container-border-style, unset);
    border-color: var(--particle-container-border-color, var(--theme-mode-foreground-color, unset));
    border-width: var(--particle-container-border-width, unset);
    border-radius: var(--particle-container-border-radius, unset);
  }

  .diy-card-content-tree-item.is-container.with-filter {
    -webkit-filter: var(--particle-container-filter, unset);
            filter: var(--particle-container-filter, unset);
  }

  .diy-card-content-tree-item.is-container.with-overflow {
    overflow: var(--particle-container-overflow, unset)
  }

  .diy-card-content-tree-item.is-container.with-hidden {
    display: none;
  }

  .diy-card-content-tree-item.is-container.with-margin {
    margin: var(--particle-container-margin, unset);
  }

  .diy-card-content-tree-item.is-container.with-padding {
    padding: var(--particle-container-padding, unset);
  }

  .diy-card-content-tree-item.is-container.with-box-shadow {
    box-shadow: var(--particle-container-box-shadow, unset);
  }

  .diy-card-content-tree-item.is-container.with-flex {
    display: flex;
    flex-direction: var(--particle-container-flex-direction, unset);
    align-items: var(--particle-container-flex-align-items, unset);
    justify-content: var(--particle-container-flex-justify-content, unset);
    gap: var(--particle-container-flex-gap, unset);
  }</style>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="diy-card-content-tree-item"
  bind:this={contentTreeItem}
  on:click|capture={$editorModeEnabled && onEditorClick}
  use:resizeObserverAction={handleResize}
  class:is-in-editor-mode={editorModeEnabled}
  class:is-selected={isSelected}
  class:is-container={isContainer}
  class:has-selected-child={hasSelectedChild}
  class:with-flex={containerData?.layoutMode === 'flex'}
  class:with-background={containerData?.background?.visible}
  class:with-border={containerData?.border?.visible}
  class:with-filter={containerData?.filter}
  class:with-height={containerData?.height}
  class:with-hidden={containerData?.hidden}
  class:with-margin={containerData?.margin}
  class:with-overflow={containerData?.overflow}
  class:with-padding={containerData?.padding}
  class:with-width={containerData?.width}
  class:with-box-shadow={containerData?.boxShadow}
  style={inlineStyle}>
  {#if isContainer}
    <div class="size-indicator">
      {Math.round((containerWidthRem + Number.EPSILON) * 1000) / 1000}rem x {Math.round((containerHeightRem + Number.EPSILON) * 1000) / 1000}rem
    </div>
    {#each (item?.items || []) as subItem }
      <svelte:self item={subItem} />
    {/each}
  {:else if item?.type === 'YinzCam__Core__Text'}
    <TextParticle {...item?.data} />
  {:else if item?.type === 'YinzCam__Core__Image'}
    <ImageParticle {...item?.data} />
  {:else}
    <span>{item?.name || ''}</span>
  {/if}
</div>
