
<script lang="ts">import "lodash";
import "./schema";
import { Row, Col, Button } from 'framework7-svelte';
import { DIYCardContextKey } from "./context";
import { getContext } from "svelte";
const context = getContext(DIYCardContextKey);
const selectedItemControls = context === null || context === void 0 ? void 0 : context.selectedItemControls;
function onAddClick() {
    $selectedItemControls === null || $selectedItemControls === void 0 ? void 0 : $selectedItemControls.addChild();
}
function onRemoveClick() {
    $selectedItemControls === null || $selectedItemControls === void 0 ? void 0 : $selectedItemControls.removeSelf();
}
</script>

<style></style>

<div class="diy-card-editor-actions">
  <Row>
    <Col>
      <Button fill round color="blue" onClick={onAddClick}>Add Layer</Button>
    </Col>
    <Col>
      <Button fill round color="red" onClick={onRemoveClick}>Remove Layer</Button>
    </Col>
  </Row>
</div>
