<script lang="ts">import { createEventDispatcher } from 'svelte';
export let value = false;
export let title = "Listen Live";
const dispatch = createEventDispatcher();
const handleClick = () => {
    window.open('https://yi.nzc.am/81/ZWNlih', '_blank');
    //window.open('https://play.tottenhamhotspur.com/', '_blank');
    // dispatch('click', {
    //   value: !value
    // })
};
</script>

<div class="play-button">
  <a href="#" target="_blank" class="round-button" style={!value ? 'padding-left: 6px' : ''} on:click={handleClick}>
    <i class="f7-icons">{!value ? 'play_fill' : 'pause_fill'}</i>
  </a>
  <div class="title">{title}</div>
</div>

<style lang="scss">*, *::after, *::before {
  box-sizing: border-box; }

.play-button {
  display: flex;
  align-items: center; }

.round-button {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border: 4px solid var(--theme-inverted-foreground-color, white);
  border-radius: 50%;
  color: var(--theme-inverted-foreground-color, white);
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: var(--theme-inverted-background-color, black);
  font-weight: bold;
  transition: all 0.3s ease;
  z-index: 2; }
  .round-button .f7-icons {
    font-size: 30px; }
  .round-button:hover {
    box-shadow: 0px 0px 10px var(--theme-inverted-highlight-color, gray);
    text-shadow: 0px 0px 10px #ffff64; }

.title {
  color: var(--theme-inverted-foreground-color, white);
  padding: 4px 13px 4px 27px;
  border-radius: 20px;
  background-color: var(--theme-inverted-background-color, black);
  border: 2px solid var(--theme-inverted-foreground-color, white);
  margin: 0 0 0 -20px;
  font-size: 14px; }

@media (max-width: 767px) {
  .round-button {
    width: 35px;
    height: 35px;
    border: 3px solid var(--theme-inverted-foreground-color, white); }
    .round-button .f7-icons {
      font-size: 25px; }
  .title {
    padding: 2px 5px 2px 21px;
    border-radius: 20px;
    margin: 0 0 0 -20px;
    font-size: 8px; } }</style>
