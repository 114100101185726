
<script lang="ts">import "lodash";
import "./schema";
import { Treeview } from 'framework7-svelte';
import DIYCardEditorTreeItem from './DIYCardEditorTreeItem.svelte';
export let root = undefined;
</script>

<style>
  .diy-card-editor-tree {
    --f7-treeview-selectable-hover-bg-color: rgba(0, 0, 0, 0.1);
    --f7-treeview-selectable-selected-bg-color: rgba(0, 0, 0, 0.3);
  }</style>

<div class="diy-card-editor-tree">
  <Treeview>
    <DIYCardEditorTreeItem item={root} />
  </Treeview>
</div>
