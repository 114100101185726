import { JSONSchema4 } from "json-schema";
import { DIYSourceResponse } from "../DIYSourceResponse";
import { ReactiveMicrocomponent } from "lib/yinzcam-rma";
import { DIYSourceRequest } from "../DIYSourceRequest";
import { Token } from "inversify-token";

export const DIYSourceDomainToken = new Token<Domain>(Symbol.for("DIYSourceDomain"));

export interface Domain {
  getDomainName(): string;

  getDisplayName(): string;

  getPaths(specData?: Record<string, any>): Promise<{ name: string, path: string }[]>;

  getConfigSchema(path?: string, specData?: Record<string, any>): Promise<JSONSchema4>;

  getResponseSchema(path?: string, specData?: Record<string, any>): Promise<JSONSchema4>;

  getExtraParams(path: string, specData?: Record<string, any>): Record<string, string>;

  getRequest(request: DIYSourceRequest): ReactiveMicrocomponent<DIYSourceResponse>;
  
  processResponse(path: string, response: DIYSourceResponse, specData?: Record<string, any>): object | any[];
}
