<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import _, { update, cloneDeep } from "lodash";
import { ButtonParticle, ButtonParticleSpec, ImageParticle, SearchParticle, SearchParticleSpec } from "components/cards/particles/YinzCam";
import { BaseAtom, getModeManager, getSignonManager } from "components/cards/atoms";
import HeaderNavSectionMenu from "./HeaderNavSectionMenu.svelte";
import HeaderNavFlyoutMenu from "./HeaderNavFlyoutMenu.svelte";
import { onMount, tick } from "svelte";
import { resizeObserverAction } from "components/cards/common/actions";
import TextParticle from "components/cards/particles/YinzCam/Core/Text/TextParticle.svelte";
import { buildThemeModeInlineStyle } from "components/cards/utilities";
import { resolveUrl } from "js/url";
import { f7, f7ready } from 'framework7-svelte';
export let container;
export let items = [];
export let menuTitleShowChevron = false;
export let menuTitleShowActiveBackground = false;
export let menuTitleActiveBackgroundColor = undefined;
export let menuTitleShowActiveUnderline = true;
export let menuTitleActiveUnderlineColor = undefined;
export let menuTitleFontFamily = undefined;
export let logoButton = undefined;
export let promptText = undefined;
export let browseDropdownLabel = undefined;
export let browseButtons = undefined;
export let languageFlagTemplate = undefined;
export let languageLabelTemplate = undefined;
export let ddlLanguageFlagTemplate = undefined;
export let ddlLanguageLabelTemplate = undefined;
export let sponsorButtons = undefined;
export let dropdownThemeMode = 'primary';
export let flyoutMenuBackgroundColor = undefined;
export let flyoutMenuPage = undefined;
export let mobileMenuHeaderLayout = false;
export let backButton = undefined;
export let signInButton = undefined;
let atomWidthPx;
let mainContainer;
let menusRightGap;
let topCenterButtonsGap;
let layoutClass = 'full';
let searchBarOpen = false;
async function updateLayout(_atomWidthPx) {
    if (!mainContainer || !menusRightGap || !topCenterButtonsGap) {
        return;
    }
    // This works because tick() allows reflows to happen but does not allow a repaint.
    layoutClass = 'full';
    burgerTapInteraction = false;
    burgerOpenFullPage = false;
    await tick();
    //console.log("test full right gap: " + menusRightGap.clientWidth);
    if (Math.min(topCenterButtonsGap.clientWidth, menusRightGap.clientWidth) < 40) {
        layoutClass = 'collapsed';
        await tick();
        //console.log("test collapsed right gap: " + menusRightGap.clientWidth);
        if (Math.min(topCenterButtonsGap.clientWidth, menusRightGap.clientWidth) < 40) {
            layoutClass = 'burger-collapsed';
            burgerTapInteraction = true;
            burgerOpenFullPage = true;
        }
    }
    //console.log('LAYOUT CLASS: ' + layoutClass);
}
$: updateLayout(atomWidthPx);
function handleMenusRightGapResize() {
    updateLayout(0);
}
function handleTopCenterButtonsGapResize() {
    updateLayout(0);
}
onMount(() => {
    setTimeout(updateLayout, 500);
    f7ready(() => {
        f7.view.main.router.on('routeChange', (newRoute, prevRoute) => {
            //console.log('route change in header', newRoute, prevRoute);
            let languageSwitch = false;
            if ((newRoute === null || newRoute === void 0 ? void 0 : newRoute.path) && (prevRoute === null || prevRoute === void 0 ? void 0 : prevRoute.path)) {
                const newRouteParts = newRoute.path.split('/').filter((part) => part);
                const prevRouteParts = prevRoute.path.split('/').filter((part) => part);
                if (newRouteParts.length === prevRouteParts.length && newRouteParts.length >= 3) {
                    if (newRouteParts[0] === prevRouteParts[0] && newRouteParts[2] === prevRouteParts[2]) {
                        languageSwitch = true;
                    }
                }
            }
            if (burgerOpened && !languageSwitch) {
                burgerOpened = false;
            }
        });
    });
});
$: isBurgerLayout = layoutClass.startsWith('burger-');
$: logoHeightMultiplier = (layoutClass === 'burger-collapsed') ? '0.85' : (layoutClass === 'burger-full') ? '0.85' : '1.00';
$: topCenterButtonsShowImage = isBurgerLayout;
const mode = getModeManager();
$: langStore = mode.getCurrentLanguageStore();
$: langValue = ($langStore || "").toLowerCase();
$: langs = mode.getAvailableLanguages() || [];
function handleLanguageSelect(lang) {
    /*
    if (mobileMenuHeaderLayout) {
      resolveUrl('home', undefined, { language: lang }).func()
    } else {
      mode.setLanguage(lang);
    }
    */
    console.log("set language in header");
    mode.setLanguage(lang);
    //resolveUrl('home', undefined, { language: lang }).func()
}
const sso = getSignonManager();
const profile = sso.getDefaultProfileSegmentStore();
$: loginStatus = sso.getStatusComponent().store;
$: isLoggedIn = $loginStatus.loggedIn;
$: signInButtonText = (isLoggedIn) ? ($profile === null || $profile === void 0 ? void 0 : $profile['first_name']) || "My Profile" : "Sign In or Register";
async function handleSignInButtonClick() {
    if (isLoggedIn) {
        resolveUrl('profile').func();
    }
    else {
        sso.getSdk().requireAuth();
    }
}
/*
function handleSearchButtonClick() {
  searchBarOpen = !searchBarOpen;
}
*/
let burgerOpened = false;
let burgerNavigating = false;
let burgerButtonHovering = false;
let burgerMenuHovering = false;
let burgerTapInteraction = false;
let burgerOpenFullPage = false;
function handleBurgerButtonTap() {
    /*
    if (layoutClass?.startsWith('burger-') && flyoutMenuPage?.slug) {
      burgerNavigating = true;
      resolveUrl(flyoutMenuPage?.slug).func();
    } else {
      burgerOpened = !burgerOpened;
    }
    */
    //console.log('burgertap', burgerTapInteraction, burgerOpened);
    if (burgerTapInteraction) {
        burgerOpened = !burgerOpened;
    }
}
//$: console.log('burger opened', burgerOpened);
/*
function handleBurgerButtonMouse() {
  burgerOpened = !burgerOpened;
}
*/
function handleBurgerButtonMouseOver() {
    if (!burgerNavigating && !burgerTapInteraction) {
        burgerOpened = true;
    }
    burgerButtonHovering = true;
}
function handleBurgerButtonMouseLeave() {
    burgerButtonHovering = false;
    setTimeout(() => {
        if (!burgerNavigating && !burgerMenuHovering && !burgerTapInteraction) {
            burgerOpened = false;
        }
    }, 500);
}
function handleBurgerMenuMouseOver() {
    burgerMenuHovering = true;
}
function handleBurgerMenuMouseLeave() {
    burgerMenuHovering = false;
    setTimeout(() => {
        if (!burgerNavigating && !burgerButtonHovering && !burgerTapInteraction) {
            burgerOpened = false;
        }
    }, 500);
}
function handleBackButtonClick() {
    if (burgerOpened) {
        burgerOpened = false;
    }
    else {
        //history.back();
        const event = new CustomEvent('navigate-back', {
            detail: {},
            bubbles: true
        });
        mainContainer.dispatchEvent(event);
    }
}
</script>

<style>
  .main-container {
    /*aspect-ratio: var(--aspect-ratio, 21/4);*/
    height: 100%;
    width: 100%;
    padding: 0 10%;

    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
  }

  .main-container.layout-collapsed,.main-container.layout-burger-full {
    padding: 0 10%;
  }

  .main-container.layout-burger-collapsed {
    padding: 0 5%;
  }

  .main-container > * {
    flex: 0 1 max-content;
  }

  .main-container > .two-row-container {
    flex-grow: 1;
    height: 100%;
    /*
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    */
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .main-container.layout-burger-collapsed > .two-row-container {
    grid-template-rows: 1fr;
  }

  .main-container > .two-row-container > * {
    width: 100%;
  }

  .main-container > .two-row-container > .top-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .main-container.layout-burger-collapsed > .two-row-container > .top-row .top-left-buttons .prompt-text, .main-container.layout-burger-collapsed > .two-row-container > .top-row .top-left-buttons .language-dropdown{
        display: none;
      }

  .main-container > .two-row-container > .top-row > * {
    flex: 0 1 max-content;
  }

  .main-container > .two-row-container > .top-row > .top-left-buttons {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  .main-container > .two-row-container > .top-row > .top-left-buttons > * {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main-container > .two-row-container > .top-row > .top-center-buttons-gap {
    flex: 1 0 auto;
    height: 100%;
  }

  .main-container > .two-row-container > .top-row > .top-right-buttons {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  .main-container > .two-row-container > .top-row > .top-right-buttons > :global(*) {
    flex: 0 1 max-content;
  }

  .main-container > .two-row-container > .thin-white-line {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 1px;
  }

  .main-container > .two-row-container > .thin-white-line > * {
    flex: 1 1 100%;
    background-color: white;
    height: 100%;
  }

  .main-container.layout-burger-full > .two-row-container > .thin-white-line > .center-line {
    flex: 0 0 10rem;
    background-color: transparent;
  }

  .main-container.layout-burger-collapsed > .two-row-container > .thin-white-line {
    /* display: none; */
  }

  .main-container.main-container.layout-burger-full .top-right-signin-button,
  .main-container.main-container.layout-burger-collapsed .top-right-signin-button {
    display: none;
  }

  .main-container > .two-row-container > .bottom-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }

  .main-container > .two-row-container > .bottom-row > * {
    flex: 0 1 max-content;
  }

  .main-container > .two-row-container > .bottom-row > .menus-container {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2.0rem;
    padding-left: 2.0rem;
  }

  .main-container.layout-collapsed > .two-row-container > .bottom-row > .menus-container {
    gap: 1rem;
  }

  .main-container.layout-burger-full > .two-row-container > .bottom-row > .menus-container{
    display: none;
  }
  .main-container.layout-burger-collapsed > .two-row-container > .bottom-row {
    justify-content: space-between;
  }
  .main-container.layout-burger-collapsed > .two-row-container > .bottom-row .menus-container {
        display: none;
     }

  .main-container > .two-row-container > .bottom-row > .menus-container > :global(*) {
    flex: 0 1 max-content;
  }

  .main-container > .two-row-container > .bottom-row > .menus-right-gap {
    flex: 1 0 auto;
    height: 100%;
  }

  .main-container.layout-burger-full > .two-row-container > .bottom-row > .menus-right-gap,
  .main-container.layout-burger-collapsed > .two-row-container > .bottom-row > .menus-right-gap {
    display: none;
  }

  .main-container > .two-row-container > .bottom-row > .bottom-right-buttons {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    gap: 2rem;
    height: 100%;
    padding: 0 3rem;
  }

  .main-container > .two-row-container > .bottom-row > .bottom-right-buttons .burger-button{
      transform: translateX(3rem);
    }

  .main-container.layout-burger-full > .two-row-container > .bottom-row > .bottom-right-buttons {
    flex: 1 0 100%;
    justify-content: space-between;
  }

  .main-container:not(.layout-burger-collapsed) > .two-row-container > .bottom-row > .bottom-right-buttons > :global(*) {
    flex: 0 1 max-content;
    height: 100%;
  }

  .search-bar {
    position: absolute;
    top: 100%;
    left: 50%;
    background-color: white;
    width: 100%;
    box-shadow: 0 0 8px rgba(0,0,0,.1);
    z-index: 10;
    margin: 0px 0px 0px 0px; /* top margin should be negative of translateY value below */
    padding: 0.75rem 10%;
    transform: translateX(-50%) translateY(0px);
    transition: all 0.2s ease;
    visibility: hidden;
    opacity: 0;
  }

  .search-bar.search-bar-open {
    visibility: visible;
    opacity: 1;
    transform: translateX(-50%) translateY(0px);
  }

  .chevron-svg {
    /* fill: white; */
    width: var(--title-chevron-size, 0.625rem);
    height: var(--title-chevron-size, 0.625rem);
    margin-left: 0.125rem;
    transform: rotate(0);
    transition: transform .4s cubic-bezier(.68, -.55, .27, 1.55);
    color: var(--theme-mode-foreground-color);
    flex-shrink: 0;
  }

  .top-bar-dropdown {
    position: relative;
    cursor: pointer;
  }

  .top-bar-dropdown-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* gap: 0.5rem; */
  }

  .browse-dropdown-label{
    gap: 0.5rem;
  }

  .top-bar-dropdown-list {
    position: absolute;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 15;

    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.5rem 0 0.5rem 0.5rem;

    background-color: var(--theme-mode-background-color);
    box-shadow: 6px 6px 6px rgba(0,0,0,.2);
  }

  .top-bar-dropdown:hover > .top-bar-dropdown-list {
    display: flex;
  }

  .top-bar-dropdown:hover .chevron-svg {
    transform: rotate(-180deg);
  }

  .top-bar-dropdown-list-item {
    /* width: 100%; */
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-around;
    /* gap: 0.5rem; */
  }

  .language-dropdown-list-item {
    cursor: pointer;
  }

  .main-container-mobile-menu {
    width: 100%;
  }

  .main-container-mobile-menu > .top-row {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }

  .main-container-mobile-menu > .top-row > * {
    flex: 0 1 max-content;
  }

  .main-container-mobile-menu > .top-row > .top-center-buttons-gap {
    flex: 1 0 auto;
  }

  .main-container-mobile-menu .language-dropdown-label {
    gap: 0rem;
  }

  .main-container-mobile-menu .language-dropdown-label .chevron-svg{
      fill: unset;
      color: var(--theme-mode-accent-color)
    }

  .main-container-mobile-menu .language-dropdown-list {
    gap: 0.5rem;
    padding: 0.5rem 0 0.5rem 0;
  }

  .main-container-mobile-menu .language-dropdown-list-item {
    gap: 0rem;
    padding: 0rem;
  }</style>

<BaseAtom bind:_clientWidthPx={atomWidthPx} _isotope="NavigationMenuCAtom" _position="relative" {...container}>
  {#if mobileMenuHeaderLayout}
  <div 
    bind:this={mainContainer}
    class="main-container-mobile-menu"
    class:layout-full={layoutClass === 'full'}
    class:layout-collapsed={layoutClass === 'collapsed'}
    class:layout-burger-full={layoutClass === 'burger-full'}
    class:layout-burger-collapsed={layoutClass === 'burger-collapsed'}
  >
    <div class="top-row">
      <div class="back-button">
        <ButtonParticle {...backButton} on:click={handleBackButtonClick} />
      </div>
      <div class="top-bar-dropdown language-dropdown">
        <div class="top-bar-dropdown-label language-dropdown-label">
            <ImageParticle _block={true} {...languageFlagTemplate} languageFlag={langValue} source={`https://resources-uk.yinzcam.com/soccer/shared/yc_icon_flag_${langValue}.jpg`} />
            <TextParticle _block={true} {...languageLabelTemplate} text={langValue.toUpperCase()} />
            <svg xmlns="http://www.w3.org/2000/svg" class="chevron-svg" viewBox="0 0 24.52 13.38" fill="currentColor" ><path class="atcls-1" d="M13.85 12.26L24.2 1.92A1.13 1.13 0 1022.6.33L12.26 10.67 1.92.33A1.13 1.13 0 00.33 1.92L11.46 13a1.14 1.14 0 001.61 0z"></path></svg>
        </div>
        <div class="top-bar-dropdown-list language-dropdown-list" style={buildThemeModeInlineStyle(dropdownThemeMode)}>
          {#each langs as lang}
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <div class="top-bar-dropdown-list-item language-dropdown-list-item" on:click={() => handleLanguageSelect(lang)}>
              <ImageParticle _block={true} {...ddlLanguageFlagTemplate} source={`https://resources-uk.yinzcam.com/soccer/shared/yc_icon_flag_${lang}.jpg`} />
              <TextParticle _block={true} {...ddlLanguageLabelTemplate} text={lang.toUpperCase()} />
            </div>
          {/each}
        </div>
      </div>
      <div bind:this={topCenterButtonsGap} class="top-center-buttons-gap" use:resizeObserverAction={handleTopCenterButtonsGapResize} />
      <div class="top-right-buttons">
        <ButtonParticle on:click={handleSignInButtonClick} {..._.merge({}, signInButton, { text: { text: signInButtonText, useAccentColor: true }})}/>
      </div>
    </div>
  </div>
  {:else}
  <div 
    bind:this={mainContainer}
    class="main-container"
    class:layout-full={layoutClass === 'full'}
    class:layout-collapsed={layoutClass === 'collapsed'}
    class:layout-burger-full={layoutClass === 'burger-full'}
    class:layout-burger-collapsed={layoutClass === 'burger-collapsed'}
  >
    <div class="two-row-container">
      <div class="top-row">
        <div class="top-left-buttons">
          <div class="prompt-text">
            <TextParticle {...promptText} />
          </div>
          <div class="top-bar-dropdown browse-dropdown">
            <div class="top-bar-dropdown-label browse-dropdown-label">
              <TextParticle {...browseDropdownLabel} />
              <svg xmlns="http://www.w3.org/2000/svg" class="chevron-svg" viewBox="0 0 24.52 13.38" fill="currentColor" ><path class="atcls-1" d="M13.85 12.26L24.2 1.92A1.13 1.13 0 1022.6.33L12.26 10.67 1.92.33A1.13 1.13 0 00.33 1.92L11.46 13a1.14 1.14 0 001.61 0z"></path></svg>
            </div>
            <div class="top-bar-dropdown-list browse-dropdown-list" style={buildThemeModeInlineStyle(dropdownThemeMode)}>
              {#each (browseButtons || []) as browseButton}
                <div class="top-bar-dropdown-list-item browse-dropdown-list-item">
                  <ButtonParticle {...browseButton} />
                </div>
              {/each}
            </div>
          </div>
          <div class="top-bar-dropdown language-dropdown">
            <div class="top-bar-dropdown-label language-dropdown-label">
                <ImageParticle _block={true} {...languageFlagTemplate} languageFlag={langValue} source={`https://resources-uk.yinzcam.com/soccer/shared/yc_icon_flag_${langValue}.jpg`} />
                <TextParticle _block={true} {...languageLabelTemplate} text={langValue.toUpperCase()} />
                <svg xmlns="http://www.w3.org/2000/svg" class="chevron-svg" viewBox="0 0 24.52 13.38" fill="currentColor" ><path class="atcls-1" d="M13.85 12.26L24.2 1.92A1.13 1.13 0 1022.6.33L12.26 10.67 1.92.33A1.13 1.13 0 00.33 1.92L11.46 13a1.14 1.14 0 001.61 0z"></path></svg>
            </div>
            <div class="top-bar-dropdown-list language-dropdown-list" style={buildThemeModeInlineStyle(dropdownThemeMode)}>
              {#each langs as lang}
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <div class="top-bar-dropdown-list-item language-dropdown-list-item" on:click={() => handleLanguageSelect(lang)}>
                  <ImageParticle _block={true} {...ddlLanguageFlagTemplate} source={`https://resources-uk.yinzcam.com/soccer/shared/yc_icon_flag_${lang}.jpg`} />
                  <TextParticle _block={true} {...ddlLanguageLabelTemplate} text={lang.toUpperCase()} />
                </div>
              {/each}
            </div>
          </div>
        </div>
        <div bind:this={topCenterButtonsGap} class="top-center-buttons-gap" use:resizeObserverAction={handleTopCenterButtonsGapResize} />
        <div class="top-right-buttons">
          <div class="top-right-signin-button">
            <ButtonParticle on:click={handleSignInButtonClick} {..._.merge({}, signInButton, { text: { text: signInButtonText }})}/>
          </div>
          {#each (sponsorButtons || []) as sponsorButton}
            <ButtonParticle {...sponsorButton} />
          {/each}
        </div>
      </div>
      <div class="thin-white-line">
        <div class="left-line"></div>
        <div class="center-line"></div>
        <div class="right-line"></div>
      </div>
      <div class="bottom-row">
        <div class="logo-container">
          <ButtonParticle {...update(cloneDeep(logoButton), 'image.container.height', (v) => `calc(${v} * ${logoHeightMultiplier})`)} />
        </div>    
        <div class="menus-container">
          {#each ((items || []).slice(0, 4)) as item}
          <HeaderNavSectionMenu element={{ data: {
            title: item.title,
            titleFontSize: "1.5rem",
            titleFontColor: "white",
            titleFontWeight: "600",
            titleFontFamily: menuTitleFontFamily,
            titleShowChevron: menuTitleShowChevron,
            titleShowBackgroundOnHover: menuTitleShowActiveBackground,
            titleHoverBackgroundColor: menuTitleActiveBackgroundColor,
            titleShowUnderlineOnHover: menuTitleShowActiveUnderline,
            titleHoverUnderlineColor: menuTitleActiveUnderlineColor,
            dropdownBackgroundColor: "white",
            dropdownPageEmbed: item.menuPage?.slug,
            sectionSlug: item.slug,
            linkUrl: item.linkUrl,
            subsections: item.subsections
          }}} />
          {/each}
        </div>
        <div bind:this={menusRightGap} class="menus-right-gap" use:resizeObserverAction={handleMenusRightGapResize} />
        <div class="bottom-right-buttons">
          <div class="burger-button">
            <ButtonParticle on:click={handleBurgerButtonTap} on:mouseover={handleBurgerButtonMouseOver} on:mouseleave={handleBurgerButtonMouseLeave} {...ButtonParticleSpec.propsWithDefaults({
              container: { fillHeight: true },
              image: { container: { height: '3.5rem', width: '3.5rem' }, icon: "yc_icon_menu_stack", iconColor: "white" },
              text: { text: "Menu", size: '1rem', weight: '600' },
              imagePosition: 'after',
              showImage: true,
              showText: false
            })}/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <HeaderNavFlyoutMenu page={flyoutMenuPage} on:mouseover={handleBurgerMenuMouseOver} on:mouseleave={handleBurgerMenuMouseLeave} on:navigateBack={handleBackButtonClick} 
    element={{ data: {
      maxWidth: (flyoutMenuPage)? undefined : '30rem',
      menuPadding: (flyoutMenuPage)? undefined : '0.75rem 3.12rem',
      menuBackgroundColor: flyoutMenuBackgroundColor || ((flyoutMenuPage)? 'transparent' : 'var(--theme-primary-background-color)'),
      menuFontSize: '1.25rem',
      menuFontWeight: '600',
      menuFontColor: 'var(--theme-primary-foreground-color)',
      menuItemPadding: '2.12rem 0',
      submenuPadding: '0.75rem 1.5rem',
      submenuBackgroundColor: 'var(--theme-primary-background-color)',
      submenuTitleFontSize: '1rem',
      submenuTitleFontWeight: '400',
      submenuTitleFontColor: 'var(--theme-primary-accent-color)',
      submenuFontSize: '0.75rem',
      submenuFontWeight: '600',
      submenuFontColor: 'var(--theme-primary-foreground-color)',
      items: items
    }}} opened={burgerOpened} openFullPage={burgerOpenFullPage} />
  <div class="search-bar" class:search-bar-open={searchBarOpen}>
    <SearchParticle {...SearchParticleSpec.propsWithDefaults({
      container: { themeMode: 'primary' },
      clearButton: { image: { iconColor: 'var(--theme-mode-accent-color)' }, text: { useAccentColor: true } }
    })} />
  </div>
  {/if}
</BaseAtom>
