import { generateArraySchema, generateBooleanSchema, generateRootObjectSchema, generateStringSchema } from "components/cards/common/schema";
import { BaseParticleSpec, GetParticlePropsType, GetParticleSchemaType, generateParticleContainerSchema } from "components/cards/particles";

export const PageParticleSpec = new BaseParticleSpec("Page", generateRootObjectSchema({
  container: generateParticleContainerSchema(),
  slug: generateStringSchema("Page Slug", "The slug of the page to load."),
  pathParams: generateArraySchema("Path Parameters", "Path parameters for the card list.", 
    generateStringSchema("Path Parameter", "A path parameter.")
  ),
  linkTarget: generateStringSchema("Link Target", "The view in which to open links that are clicked on inside this embedded page.", {
    choices: [ 'main', 'this', 'new' ],
    choiceTitles: [ 'Main View', 'This View', 'New Window' ],
    defaultValue: 'main'
  }),
  inheritThemeMode: generateBooleanSchema("Inherit Theme Mode", "Should this embedded page inherit the theme mode from its container?", {
    defaultValue: false
  }),
}));

export const specification = PageParticleSpec;

export type PageParticleSchema = GetParticleSchemaType<typeof PageParticleSpec>;

export type PageParticleProps = GetParticlePropsType<typeof PageParticleSpec>;
