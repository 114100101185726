<script context="module" lang="ts">var _a, _b;
import 'json-schema';
import 'components/cards/common/CardsElementInterfaces';
export function getDisplayName() {
    return "Basic: Footer Nav Section Menu";
}
export function getElementConfigSpec() {
    return {
        type: "object",
        properties: {}
    };
}
export function getElementDataSourceSpec() {
    return {
        sources: [
            {
                type: 'SOURCE',
                title: 'Card Data',
                description: 'Arbitrary source of data for this card matching the configuration fields.',
                classes: '*'
            }
        ]
    };
}
</script>

<script lang="ts">var _a, _b;
import "svelte/store";
import "js/i18n";
import CardBase from "components/cards/common/CardBase.svelte";
import "yinzcam-cards";
import "components/cards/common/CardsDataSourceRegistration";
import { getFirstSourceStore, getTemplatedElementDataFromSource, buildInlineStyle } from "components/cards/utilities";
import { resolveUrl } from 'js/url';
export let element;
export let sources;
export let sourceStore;
$: {
    sourceStore = getFirstSourceStore($sources);
}
;
$: title = (_a = getTemplatedElementDataFromSource(element, "title", $sourceStore)) === null || _a === void 0 ? void 0 : _a.toString();
$: titlePadding = getTemplatedElementDataFromSource(element, "titlePadding", $sourceStore);
$: titleFontSize = getTemplatedElementDataFromSource(element, "titleFontSize", $sourceStore);
$: titleFontColor = getTemplatedElementDataFromSource(element, "titleFontColor", $sourceStore);
$: titleFontWeight = getTemplatedElementDataFromSource(element, "titleFontWeight", $sourceStore);
$: listPadding = getTemplatedElementDataFromSource(element, "listPadding", $sourceStore);
$: listFontSize = getTemplatedElementDataFromSource(element, "listFontSize", $sourceStore);
$: listFontColor = getTemplatedElementDataFromSource(element, "listFontColor", $sourceStore);
$: listFontWeight = getTemplatedElementDataFromSource(element, "listFontWeight", $sourceStore);
$: listItemPadding = getTemplatedElementDataFromSource(element, "listItemPadding", $sourceStore);
$: listItemDividerSize = getTemplatedElementDataFromSource(element, "listItemDividerSize", $sourceStore);
$: listItemDividerColor = getTemplatedElementDataFromSource(element, "listItemDividerColor", $sourceStore);
$: items = getTemplatedElementDataFromSource(element, "items", $sourceStore) || [];
$: hasItems = items && items.length > 0;
//$: console.log('HeaderNavSectionMenu ITEMS', items);
$: linkUrl = resolveUrl((_b = getTemplatedElementDataFromSource(element, "linkUrl", $sourceStore)) === null || _b === void 0 ? void 0 : _b.toString()).href;
$: style = buildInlineStyle("title-padding", titlePadding, "title-font-size", titleFontSize, "title-font-color", titleFontColor, "title-font-weight", titleFontWeight, "list-padding", listPadding, "list-font-size", listFontSize, "list-font-color", listFontColor, "list-font-weight", listFontWeight, "list-item-padding", listItemPadding, "list-item-divider-size", listItemDividerSize, "list-item-divider-color", listItemDividerColor);
let isHovering = false;
function toggleHover(state) {
    isHovering = state;
}
</script>

<CardBase {element}>
  <div
    class="dropdown"
    class:noitems={!hasItems}
    {style}
  >
    <a class="trigger" href={linkUrl}>
      {title}
    </a>
    <ul class="page-list-menu">
      {#each items as item (item.slug) }
        <li><a href={resolveUrl(item.linkUrl).href}>{item.title}</a></li>
      {/each}
    </ul>
  </div>
</CardBase>

<style>  
  .dropdown {
    position: relative;
    display: inline-block;
    height: 100%;
  }

  .trigger {
    display: flex;
    background-color: var(--title-background-color, unset);
    color: var(--title-font-color, var(--font-color, unset));
    font-size: var(--title-font-size, unset);
    font-weight: var(--title-font-weight, unset);
    padding: var(--title-padding, unset);
  }

  .page-list-menu {
    padding: var(--list-padding, 0px);
    color: var(--list-font-color, unset);
    font-size: var(--list-font-size, unset);
    font-weight: var(--list-font-weight, unset);
  }

  .dropdown.noitems .page-list-menu {
    display: none;
  }

  .page-list-menu li {
    padding: var(--list-item-padding, unset);
    -webkit-text-decoration: none;
    text-decoration: none;
    display: block;
    cursor: pointer;
  }

  .page-list-menu li:not(:last-child) {
    border-bottom: var(--list-item-divider-size, 0px) solid var(--list-item-divider-color, transparent);
  }

  .page-list-menu li:hover {
    -webkit-text-decoration: underline;
    text-decoration: underline;
  }</style>
