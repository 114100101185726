import { JSONSchema4 } from "json-schema";
import { DIYSourceResponse } from "../../DIYSourceResponse";
import { AbstractDomain } from "../AbstractDomain";
import { ReactiveMicrocomponent } from "lib/yinzcam-rma";
import { DIYSourceRequest } from "../../DIYSourceRequest";
import { injectable } from "inversify";
import { ContentfulCDN, stripContentfulMetadata, getContentTypeFromDataSourcePath, ContentfulCDNToken } from "lib/contentful-api";
import _ from "lodash";
import { contentfulSchemaToJSONSchema } from "./utilities";
import { injectToken } from "inversify-token";

@injectable()
export class ContentfulDomain extends AbstractDomain {
  public static DomainName: string = 'contentful.com';
  public static DisplayName: string = 'Contentful';
  
  public constructor(@injectToken(ContentfulCDNToken) private readonly contentful: ContentfulCDN) {
    super();
  }

  public getDomainName(): string {
    return ContentfulDomain.DomainName;
  }

  public getDisplayName(): string {
    return ContentfulDomain.DisplayName;
  }

  public async getPaths(specData?: Record<string, any>): Promise<{ name: string; path: string; }[]> {
    const cts = await this.contentful.getContentModel();
    return cts.map((ct) => ({ name: ct[ct.displayField], description: ct.description, path: ct.sys.id }));
  }

  public async getConfigSchema(path?: string, specData?: Record<string, any>): Promise<JSONSchema4> {
    return {
      type: 'object',
      properties: {
        query: {
          type: "object",
          title: "Query",
          description: "The lookup query to use for the data source.",
          properties: {
            "fields.slug": {
              type: "string",
              title: "Slug",
              description: "The slug of the content item.",
              // TODO: Use Contentful API to look up valid options for slug?
            },
            "order": {
              type: "string",
              title: "Ordering",
              description: "The field to order by. Prepend with a '-' to order in reverse.",
              // TODO: Use Contentful API to look up valid options for fields?
            },
            "metadata.tags.sys.id[in]": {
              type: "string",
              title: "Tags (match at least one)",
              description: "Include items that have at least one of the Contentful tag IDs in the given list.",
              // TODO: Use Contentful API to look up valid options for tags?
            },
            "metadata.tags.sys.id[all]": {
              type: "string",
              title: "Tags (match all)",
              description: "Include items that have all of the Contentful tag IDs in the given list.",
              // TODO: Use Contentful API to look up valid options for tags?
            },
            "query": {
              type: "string",
              title: "Search Terms",
              description: "Include items matching the given search terms.",
            },
          },
          required: [
          ]
        }
      },
      required: [
        "query"
      ]
    }
  }

  public async getResponseSchema(path?: string, specData?: Record<string, any>): Promise<JSONSchema4> {
    const cts = await this.contentful.getContentModel();
    const defs = Object.fromEntries(cts.map((ct) => [ ct.sys.id, ct ]));
    const ctId = getContentTypeFromDataSourcePath(path);
    return contentfulSchemaToJSONSchema(defs, ctId);
  }

  public getExtraParams(path: string, specData?: Record<string, any>): Record<string, string> {
    return specData?.query || {};
  }

  public getRequest(request: DIYSourceRequest): ReactiveMicrocomponent<DIYSourceResponse> {
    return this.contentful.getRequest(request);
  }
  
  public processResponse(path: string, response: DIYSourceResponse, specData?: Record<string, any>): object | any[] {
    const stripped = stripContentfulMetadata(response.items) as object[];
    //console.log('CONTENTFUL RESPONSE', specData, response, stripped);
    let ret: object | any[] = stripped;
    if (stripped.length > 1) {
      // if an object query is provided, get that portion of each sub-object and return it
      // TODO
    } else if (stripped.length === 1) {
      const obj = stripped[0];
      if (specData?.objectQuery) {
        ret = _.get(obj, specData.objectQuery);
      } else if (!specData?.singleObject && 'items' in obj && Array.isArray(obj['items'])) {
        // as a special case, if there is one object and it contains an items array, expand that array as the returned data
        // this behavior can be disabled by setting singleObject = true in the source data
        const { items, ...objWithoutItems } = obj;
        ret = items.map((item) => ({ ...objWithoutItems, ...item }));
      } else if (!specData?.alwaysArray && specData.query['fields.slug']) {
        ret = obj;
      } else {
        // this could be omitted but keeping it here to make it explicit that the output is an array
        ret = [ obj ];
      }
    }
    //console.log("ContentfulCDNQuery data source", stripped, ret);
    return ret;
  }
}
