import { Container } from "inversify";
import { TokenContainerModule } from "inversify-token";
import { YinzCamAPIRequestParameterComponentToken } from "yinzcam-api";
import { YinzCamInjectModule } from "yinzcam-inject";
import { YinzCamAdsParameterComponent } from "./YinzCamAdsParameterComponent";
import { YinzCamAdsServer, YinzCamAdsServerToken } from "./YinzCamAdsServer";

export let YinzCamAdsServerModule: YinzCamInjectModule = new YinzCamInjectModule((container: Container): void => {
  container.load(new TokenContainerModule((bindToken) => {
    bindToken<YinzCamAdsServer>(YinzCamAdsServerToken).to(YinzCamAdsServer).inSingletonScope();
    bindToken(YinzCamAPIRequestParameterComponentToken).to(YinzCamAdsParameterComponent).inSingletonScope();
  }));
});
