
<script context="module" lang="ts">import { createEventDispatcher, onMount } from "svelte";
import CardsTab from "./CardsTab.svelte";
import { JanusInternationalizationManagerToken } from 'js/i18n';
import { getToken } from 'inversify-token';
import ROOT from '../../../inversify.config';
const cache = {};
const i18nManager = getToken(ROOT, JanusInternationalizationManagerToken);
i18nManager.getCurrentLanguageStore().subscribe((lang) => {
    // flush the cache on language change
    Object.keys(cache).forEach(key => delete cache[key]);
});
</script>

<script lang="ts">import "lib/yinzcam-cards";
import { getRenderDelayForSequenceId } from "../utilities";
export let tab;
export let parentSequenceId = null;
export let sequenceId = "";
export let updater;
const dispatch = createEventDispatcher();
let readyToRender = false;
let domLoaded = false;
function cachedCardsTab(parent, initProps) {
    let cct = null;
    function createNewCardsTab(newProps) {
        const container = document.createElement('div');
        const app = new CardsTab({
            target: container,
            props: newProps,
        });
        return { element: container, component: app };
    }
    function update(newProps) {
        if (newProps.tab.fragmentUri) {
            cct = cache[newProps.tab.fragmentUri];
            if (cct) {
                cct.component.$set({
                    parentSequenceId: newProps.parentSequenceId,
                    sequenceId: newProps.sequenceId,
                    updater: newProps.updater
                });
            }
            else {
                cct = cache[newProps.tab.fragmentUri] = createNewCardsTab(newProps);
            }
        }
        else {
            cct = createNewCardsTab(newProps);
        }
        parent.append(cct.element);
        if (!domLoaded) {
            domLoaded = true;
            setTimeout(() => dispatch('domLoaded'), 0);
        }
    }
    function destroy() {
        if (cct === null || cct === void 0 ? void 0 : cct.element) {
            //cct.element.remove();
        }
    }
    try {
        update(initProps);
        return { update, destroy };
    }
    catch (e) {
        console.error("cachedCardsTab action constructor last-chance exception (rethrown)", e);
        throw e;
    }
}
onMount(() => {
    const renderDelay = getRenderDelayForSequenceId(sequenceId);
    if (!isNaN(renderDelay) && renderDelay > 0) {
        setTimeout(() => {
            readyToRender = true;
        }, renderDelay);
    }
    else {
        readyToRender = true;
    }
});
</script>

{#if readyToRender}
<div class="cached-cards-tab" use:cachedCardsTab={{tab, parentSequenceId, sequenceId, updater}}></div>
{/if}