import { generateArraySchema, generateBooleanSchema, generateColorSchema, generateObjectSchema, generateRootObjectSchema, generateStringSchema, generateUrlSchema } from "components/cards/common/schema";
import { BaseParticleSpec, GetParticlePropsType, GetParticleSchemaType, generateParticleContainerSchema } from "components/cards/particles/BaseParticleSpec";
import { TextParticleSpec } from "../Text/spec";

export const SegmentedButtonParticleSpec = new BaseParticleSpec("Segmented Button", generateRootObjectSchema({
    container: generateParticleContainerSchema({
        defaultValues: { themeMode: 'primary' },
    }),
    overlayPadding: generateStringSchema("Overlay Padding", "The padding (inset) of the overlay text/buttons relative to the background image.", {
        defaultValue: '4.31rem 2.25rem'
    }),
    txtTabName: TextParticleSpec.generateSchema("Tab Name", "Enter a tab name", {
        defaultValues: { size: "1.25rem", weight: "600" }
    }),
    headerTabs: generateArraySchema("Header Tabs", "Header Tabs",
        generateStringSchema("Tab Name", "Tab Name")),
    colorActiveTab: generateColorSchema("Active Tab Color", "Pick a color for active tab"),
    colorTabBackground: generateColorSchema("Tab Background Color", "Pick a color for tab background"),
    colorTabBorder: generateColorSchema("Tab Border Color", "Pick a color for tab border"),
    colorTab: generateColorSchema("Tab font color", "Pick a color for tab font"),
    colorContainerBackground: generateColorSchema("Container Color", "Pick a color for container"),
    colorInactiveText: generateColorSchema("Inactive text color", "Inactive text color"),
    colorActiveText: generateColorSchema("Active text color", "Active text color")
}));

export const specification = SegmentedButtonParticleSpec;

export type SegmentedButtonParticleSchema = GetParticleSchemaType<typeof SegmentedButtonParticleSpec>;

export type SegmentedButtonParticleProps = GetParticlePropsType<typeof SegmentedButtonParticleSpec>;
