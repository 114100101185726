import { Container } from "inversify";
import { TokenContainerModule } from "inversify-token";
import { YinzCamInjectModule } from "yinzcam-inject";
import { YinzCamConfigServer, YinzCamConfigServerToken } from "./YinzCamConfigServer";

export let YinzCamConfigServerModule: YinzCamInjectModule = new YinzCamInjectModule((container: Container): void => {
  container.load(new TokenContainerModule((bindToken) => {
    bindToken<YinzCamConfigServer>(YinzCamConfigServerToken).to(YinzCamConfigServer).inSingletonScope();
  }));
});
