<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import "lodash";
import { setStoreOnHoverAction, formatDateTimeLocal, } from "components/cards/utilities";
import { BaseAtom } from "components/cards/atoms";
import { TextParticle, ImageParticle, ButtonParticle, } from "components/cards/particles/YinzCam";
import { writable } from "svelte/store";
import { resolveUrl } from "js/url";
import { DateTime } from "luxon";
const hovering = writable(false);
export let container;
export let overlayPadding;
export let thumbnail;
export let link;
export let imageGradient;
export let publishDateTime;
export let textDateTime;
export let imageLeague;
export let imageTeamOne;
export let imageTeamTwo;
export let shapeBackgroundColor;
export let textTeamOne;
export let textTeamTwo;
export let ctaButtonOne;
export let ctaButtonTwo;
export let shouldDisplayBar;
let atomWidthRem;
$: publishDateTimeDisplay = formatDateTimeLocal(publishDateTime, DateTime.DATE_HUGE);
</script>

<BaseAtom
    _isotope="ArticleAtom"
    _cssVariablePrefix="tickets-item"
    {...container}
    _link={resolveUrl(link).href}
    {overlayPadding}
    {shapeBackgroundColor}
    bind:_clientWidthRem={atomWidthRem}
>
    <div
        class="main-container"
        use:setStoreOnHoverAction={hovering}
        class:layout-mobile-width={atomWidthRem < 30}
        class:layout-tablet-width={atomWidthRem < 45}
    >
        <div class="background-container">
            <ImageParticle _block={true} _hovering={$hovering} {...thumbnail} />
        </div>
        <div
            class={`overlay-area ${imageGradient ? "overlay-area-gradient" : ""}`}
        >
            <div class="overlay-bottom-content">
                <div class="overlay-bottom-images">
                    <div class="overlay-bottom-image-1">
                        <ImageParticle {...imageLeague} />
                    </div>
                    <div class="overlay-bottom-image-2">
                        <ImageParticle {...imageTeamOne} />
                        <ImageParticle {...imageTeamTwo} />
                    </div>
                </div>
                <div class="overlay-bottom-text">
                    <TextParticle {...textTeamOne} />
                    {#if shouldDisplayBar}
                        <div class="rectangle-shape"></div>
                    {/if}
                    <TextParticle {...textTeamTwo} />
                </div>
                <div class="overlay-bottom-buttons">
                    <div class="overlay-date-time">
                        <TextParticle
                            text={publishDateTimeDisplay}
                            {...textDateTime}
                        />
                    </div>
                    <ButtonParticle {...ctaButtonOne} />
                    <ButtonParticle {...ctaButtonTwo} />
                </div>
            </div>
        </div>
    </div>
</BaseAtom>

<style>
    .main-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        background-size: cover;
        background-position: top center;
        cursor: pointer;
        position: relative;
        height: 100%;
        width: 100%;
    }

    .main-container > .background-container {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .main-container > .overlay-area {
        z-index: 2;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: var(--tickets-item-overlay-padding, unset);
    }

    .overlay-area-gradient {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 25.97%, #000 100%);
    }

    .overlay-date-time {
        display: flex;
        flex-direction: row;
        align-items: center;
        white-space: nowrap;
        gap: 1rem;
    }

    .right-content-arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 2.25rem;
        min-height: 2.25rem;
    }

    .right-content-button {
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
    }

    .rectangle-shape {
        width: 7.5rem;
        height: 0.25rem;
        background: var(
            --tickets-item-shape-background-color,
            var(--theme-mode-highlight-color, unset)
        );
    }

    .overlay-bottom-buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 0.625rem;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
    }</style>
