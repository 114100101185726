import { YinzCamAPI, YinzCamAPIResponse } from 'yinzcam-api';
import { AbstractReactiveMicrocomponent, ReactiveMicrocomponent } from 'yinzcam-rma';
import { CardsDataSourceOutput } from './CardsDataSourceOutput';

export abstract class CardsDataSourceComponent<T> extends AbstractReactiveMicrocomponent<CardsDataSourceOutput, [T]> {
  public constructor(name: string, input: ReactiveMicrocomponent<T>) {
    super({ name, saveToLocalStorage: false }, input);
  }

  override refreshSupported(): boolean {
    return true;
  }

  public abstract refresh(): void;
}
