<script lang="ts">import { BaseParticle, } from "components/cards/particles";
import "../Image";
import "../Text";
import ImageParticle from "../Image/ImageParticle.svelte";
import TextParticle from "../Text/TextParticle.svelte";
// props
export let container = undefined;
export let icon = undefined;
export let textOne = undefined;
export let textTwo = undefined;
export let showImage = true;
export let showText = true;
export let gap = undefined;
export let badgeType = undefined;
let atomWidthRem;
const eventsWithSecondText = ["substitution", "others"];
const eventIconMatch = {
    "substitution": "https://resources-uk.yinzcam.com/soccer/shared/icon_substitution.png",
    "goal": "https://resources-uk.yinzcam.com/soccer/shared/soccer_ball.png",
    "yellow-card": "https://resources-uk.yinzcam.com/soccer/shared/yellow_card.png",
    "red-card": "https://resources-uk.yinzcam.com/soccer/shared/red_card.png",
    "others": "yc_icon_add",
};
$: isMobile = atomWidthRem <= 6;
$: hidden = (container === null || container === void 0 ? void 0 : container.hidden) || (!showImage && !showText);
$: textSize = isMobile ? "1rem" : "1.25rem";
$: textWeight = badgeType === "time-display" ? "700" : "500";
$: textColor = badgeType === "time-display" ? "#616161" : "#000";
</script>

<BaseParticle
    _name="BadgeParticle"
    _cssVariablePrefix="badge-particle"
    bind:_clientWidthRem={atomWidthRem}
    {...container}
    _interactive={true}
    {hidden}
    {gap}
>
    <div class="flex-container">
        {#if showImage && badgeType !== "time-display"}
            <div class="icon-box">
                <ImageParticle
                    _block={true}
                    {...icon}
                    source={eventIconMatch[badgeType]}
                />
            </div>
        {/if}
        {#if showText}
            <div class="timeline-text-box">
                <TextParticle _block={true} {...textOne} size={textSize} color={textColor} weight={textWeight} />
                {#if eventsWithSecondText.includes(badgeType)}
                    <TextParticle _block={true} {...textTwo} size={textSize} color={textColor} weight={textWeight}/>
                {/if}
            </div>
        {/if}
    </div>
</BaseParticle>

<style>
    .flex-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: var(--button-particle-gap, 0);
        gap: 0.5rem;
        padding: 0.5rem 1.25rem;
        border: 1px solid #DEDEDE;
        border-radius: 6.25rem;
        background-color: #FFF;
    }

    .flex-container > :global(*) {
        flex: 1 0 max-content;
        flex-wrap: nowrap;
        text-wrap: nowrap;
    }

    .icon-box > :global(*){
        padding: 0;
    }

    .timeline-text-box >:global(*){
        display: flex;
        justify-content: flex-start;
    }</style>
