import { generateRootObjectSchema, generateArraySchema, generateObjectSchema, generateStringSchema, generateUrlSchema, generateColorSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { ButtonParticleSpec, ImageParticleSpec, TextParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("Loyalty Points", generateRootObjectSchema({
    container: generateAtomContainerSchema({ defaultValues: { themeMode: 'primary' } }),
    overlayPadding: generateStringSchema("Overlay Padding", "Overlay Padding"),
    imgCardBackground: ImageParticleSpec.generateSchema("Card Background Image", "Card Background Image", {
        defaultValues: { sizing: "cover" }
    }),
    txtMemberPoints: TextParticleSpec.generateSchema("Member Points", "Member Points", {
        defaultValues: { size: "5.5rem", weight: "800" }
    }),
    txtMemberRank: TextParticleSpec.generateSchema("Member Rank", "Member Rank", {
        defaultValues: { size: "3rem", weight: "800" }
    }),
    txtTotalRank: TextParticleSpec.generateSchema("Total Rank", "Total Rank", {
        defaultValues: { size: "1.25rem", weight: "500" }
    }),
    txtInformation: TextParticleSpec.generateSchema("Information Text", "Information Text", {
        defaultValues: { size: "3rem", weight: "800" }
    }),
    txtPointGeneral: TextParticleSpec.generateSchema("General Points Text", "General Text", {
        defaultValues: { size: "1.25rem", weight: "500" }
    }),
    txtRankGeneral: TextParticleSpec.generateSchema("General Rank Text", "General Text", {
        defaultValues: { size: "1.25rem", weight: "500" }
    }),
    txtInfoGeneral: TextParticleSpec.generateSchema("General Info Text", "General Text", {
        defaultValues: { size: "1.25rem", weight: "500", wrap: "nowrap" }
    }),
    txtPointsYear: generateStringSchema("Points Year", "Points Year"),
    btnCTA: ButtonParticleSpec.generateSchema("CTA Button", "CTA Button", {
        defaultValues: { container: { border: { visible: true, radius: "50%" }, width: "4rem", height: "4rem" }, image: { icon: "yc_icon_arrow_right" } }
    }),
    colorBackgroundFill: generateColorSchema("Card Background Fill", "Card Background Fill"),
    colorPointsCard: generateColorSchema("Point Card Color", "Point Card Color"),
    colorRankCard: generateColorSchema("Rank Card Color", "Rank Card Color"),
    colorCtaButton: generateColorSchema("CTA Button Color", "CTA Button Color"),
    colorLinearGradient: generateColorSchema("Linear Gradient Color", "Linear Gradient Color")
}));
