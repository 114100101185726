import { generateBooleanSchema, generateRootObjectSchema, generateStringSchema, generateUrlSchema } from "components/cards/common/schema";
import { BaseParticleSpec, GetParticlePropsType, GetParticleSchemaType, generateParticleContainerSchema } from "components/cards/particles/BaseParticleSpec";
import { ButtonParticleSpec } from "../../Core";

const buttonDefaultProps = ButtonParticleSpec.generateSchema("Button Defaults", "The default configuration template for the share buttons in the strip.", {
  defaultValues: {
    container: {
      background: { visible: true },
      border: { visible: true, radius: '50%' },
      padding: '0.25rem',
    },
    showText: false,
    image: {
      container: { height: '1.5rem', width: '1.5rem' },
    }
  }
});

export const ShareStripParticleSpec = new BaseParticleSpec("ShareStrip", generateRootObjectSchema({
  container: generateParticleContainerSchema({
    defaultValues: { fillHeight: false, fillWidth: false }
  }),
  link: generateUrlSchema("Link", "The link to share when a share option is clicked."),
  buttonDefaults: ButtonParticleSpec.generateSchema("Button Defaults", "The default configuration template for the share buttons in the strip.", {
    defaultValues: {
      container: {
        background: { visible: true },
        border: { visible: true, radius: '50%' },
        padding: '0.25rem',
      },
      showText: false,
      image: {
        container: { height: '1.5rem', width: '1.5rem' },
      }
    }
  }),
  showShareLink: generateBooleanSchema("Show Link", "Show the button to share via link.", {
    defaultValue: true,
  }),
  showShareMail: generateBooleanSchema("Show Mail", "Show the button to share via mail.", {
    defaultValue: true,
  }),
  showShareLinkedIn: generateBooleanSchema("Show LinkedIn", "Show the button to share via LinkedIn.", {
    defaultValue: true,
  }),
  showShareTwitter: generateBooleanSchema("Show Twitter/X", "Show the button to share via Twitter/X.", {
    defaultValue: true,
  }),
  showShareFacebook: generateBooleanSchema("Show Facebook", "Show the button to share via Facebook.", {
    defaultValue: true,
  }),
  showShareWeibo: generateBooleanSchema("Show Weibo", "Show the button to share via Facebook.", {
    defaultValue: false,
  }),
  showShareLine: generateBooleanSchema("Show Line", "Show the button to share via Facebook.", {
    defaultValue: false,
  }),
  showIconBackground: generateBooleanSchema("Show Icon Background?", "Show Icon Background?", {
    defaultValue: true
  }),
  iconFacebook: { ...buttonDefaultProps, title: "Facebook" },
  iconTwitter: { ...buttonDefaultProps, title: "Twitter" },
  iconCopy: { ...buttonDefaultProps, title: "Copy Link" },
  iconEmail: { ...buttonDefaultProps, title: "Email" },
  iconWeibo: { ...buttonDefaultProps, title: "Weibo" },
  iconLine: { ...buttonDefaultProps, title: "Line" }
}));

export const specification = ShareStripParticleSpec;

export type ShareStripParticleSchema = GetParticleSchemaType<typeof ShareStripParticleSpec>;

export type ShareStripParticleProps = GetParticlePropsType<typeof ShareStripParticleSpec>;
