import { generateRootObjectSchema, generateStringSchema, generateArraySchema, generateObjectSchema, generateColorSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { TextParticleSpec } from "components/cards/particles/YinzCam";

import { statsNameList } from "components/cards/utilities/constants";

export const specification = new BaseAtomSpec("Single Combo Graph", generateRootObjectSchema({
    container: generateAtomContainerSchema(),
    overlayPadding: generateStringSchema("Overlay Padding", "The padding (inset) of the overlay text/buttons relative to the background image.", {
        defaultValue: '3rem'
    }),
    statsListMax: generateStringSchema("Select Stat for Max Bar", "Select Stat to display", {
        choices: statsNameList,
        choiceTitles: statsNameList,
    }),
    statsListProgress: generateStringSchema("Select Stat for Progress Bar", "Select Stat to display", {
        choices: statsNameList,
        choiceTitles: statsNameList,
    }),
    playerStats: generateArraySchema("-NA-", "Specify the number of cards to be created",
        generateObjectSchema("Circle Card", "Enter Stat Name and Value", {
            name: TextParticleSpec.generateSchema("Stat Name", "Enter stat name", {
                defaultValues: { size: "1.25rem", weight: "600" }
            }),
            homeValue: TextParticleSpec.generateSchema("Stat Value", "Enter stat value", {
                defaultValues: { size: "2.5rem", weight: "900" }
            }),
        })),
    txtSubStat: TextParticleSpec.generateSchema("Sub Stat Text", "Enter a sub stat text", {
        defaultValues: { size: "1rem", weight: "400" }
    }),
    colorProgressBackground: generateColorSchema("Progress BG Color", "Pick a color for progress background"),
    colorProgressBar: generateColorSchema("Progress Bar Color", "Pick a color for progress bar"),
}));

