<script context="module" lang="ts">var _a, _b, _c, _d, _e;
import 'json-schema';
import 'components/cards/common/CardsElementInterfaces';
export function getDisplayName() {
    return "Image Card";
}
export function getElementConfigSpec() {
    return {
        type: "object",
        properties: {
            imageUrl: {
                type: "string",
                format: "url",
                title: "Thumbnail",
                description: "The thumbnail image for the card.",
                options: {
                    upload: {}
                },
                links: [{ href: "{{self}}" }]
            },
            linkUrl: {
                type: "string",
                format: "url",
                title: "Link",
                description: "The URL to open when the image is clicked."
            },
            imageAltText: {
                type: "string",
                title: "Image Alt Text",
                description: "The alt text for the image."
            },
            imageWidth: {
                type: "string",
                title: "Image Width",
                description: "The image fixed width in CSS units."
            },
            imageMaxWidth: {
                type: "string",
                title: "Image Max Width",
                description: "The image max width in CSS units."
            },
            imageMinWidth: {
                type: "string",
                title: "Image Min Width",
                description: "The image min width in CSS units."
            },
            imageHeight: {
                type: "string",
                title: "Image Height",
                description: "The image fixed height in CSS units."
            },
            imageMaxHeight: {
                type: "string",
                title: "Image Max Height",
                description: "The image max height in CSS units."
            },
            imageMinHeight: {
                type: "string",
                title: "Image Min Height",
                description: "The image min height in CSS units."
            },
            imageObjectFit: {
                type: "string",
                title: "Image Object Fit",
                description: "The image object fit strategy.",
                enum: [
                    "contain",
                    "cover",
                    "fill",
                    "none",
                    "scale-down"
                ],
                options: {
                    enum_titles: [
                        "Contain",
                        "Cover",
                        "Fill",
                        "None",
                        "Scale Down"
                    ]
                }
            },
            backgroundColor: {
                type: "string",
                title: "Background Color",
                description: "The background color behind the image."
            },
            hoverEffect: {
                type: "string",
                title: "Hover Effect",
                description: "The hover effect type.",
                enum: [
                    "none",
                    "zoom",
                    "overlay",
                    "background"
                ],
                options: {
                    enum_titles: [
                        "None",
                        "Zoom",
                        "Overlay",
                        "Background"
                    ]
                }
            },
            hoverAnimationDuration: {
                type: "string",
                title: "Hover Duration",
                description: "The hover animation duration in CSS units."
            },
            hoverBackgroundColor: {
                type: "string",
                title: "Hover Background Color",
                description: "The hover background color of the button."
            },
            hoverOverlayColor: {
                type: "string",
                title: "Hover Overlay Color",
                description: "The hover overlay color of the button."
            },
            hoverZoomFactor: {
                type: "string",
                title: "Hover Zoom Factor",
                description: "The hover zoom factor (1.0 is no zoom)."
            },
        }
    };
}
export function getElementDataSourceSpec() {
    return {
        sources: [
            {
                type: 'SOURCE',
                title: 'Card Data',
                description: 'Arbitrary source of data for this card matching the configuration fields.',
                classes: '*'
            }
        ]
    };
}
</script>

<script lang="ts">var _a, _b, _c, _d, _e;
import "svelte/store";
import "js/i18n";
import CardBase from "components/cards/common/CardBase.svelte";
import "yinzcam-cards";
import "components/cards/common/CardsDataSourceRegistration";
import { getFirstSourceStore, getTemplatedElementDataFromSource, buildInlineStyle } from "components/cards/utilities";
import { resolveUrl, shouldOpenInNewWindow } from 'js/url';
export let element;
export let sources;
export let sourceStore;
$: {
    sourceStore = getFirstSourceStore($sources);
}
;
$: imageUrl = ((_a = getTemplatedElementDataFromSource(element, "leadImage.file.url", $sourceStore)) === null || _a === void 0 ? void 0 : _a.toString()) ||
    ((_b = getTemplatedElementDataFromSource(element, "file.url", $sourceStore)) === null || _b === void 0 ? void 0 : _b.toString()) ||
    ((_c = getTemplatedElementDataFromSource(element, "imageUrl", $sourceStore)) === null || _c === void 0 ? void 0 : _c.toString());
$: linkUrl = resolveUrl((_d = getTemplatedElementDataFromSource(element, "linkUrl", $sourceStore)) === null || _d === void 0 ? void 0 : _d.toString());
$: imageAltText = (_e = getTemplatedElementDataFromSource(element, "imageAltText", $sourceStore)) === null || _e === void 0 ? void 0 : _e.toString();
$: imageWidth = getTemplatedElementDataFromSource(element, "imageWidth", $sourceStore);
$: imageMaxWidth = getTemplatedElementDataFromSource(element, "imageMaxWidth", $sourceStore);
$: imageMinWidth = getTemplatedElementDataFromSource(element, "imageMinWidth", $sourceStore);
$: imageHeight = getTemplatedElementDataFromSource(element, "imageHeight", $sourceStore);
$: imageMaxHeight = getTemplatedElementDataFromSource(element, "imageMaxHeight", $sourceStore);
$: imageMinHeight = getTemplatedElementDataFromSource(element, "imageMinHeight", $sourceStore);
$: imageObjectFit = getTemplatedElementDataFromSource(element, "imageObjectFit", $sourceStore);
$: backgroundColor = getTemplatedElementDataFromSource(element, "backgroundColor", $sourceStore);
$: hoverEffect = getTemplatedElementDataFromSource(element, "hoverEffect", $sourceStore);
$: hoverBackgroundColor = getTemplatedElementDataFromSource(element, "hoverBackgroundColor", $sourceStore);
$: hoverOverlayColor = getTemplatedElementDataFromSource(element, "hoverOverlayColor", $sourceStore);
$: hoverZoomFactor = getTemplatedElementDataFromSource(element, "hoverZoomFactor", $sourceStore);
$: hoverReplaceImageUrl = getTemplatedElementDataFromSource(element, "hoverReplaceImageUrl", $sourceStore);
$: hoverAnimationDuration = getTemplatedElementDataFromSource(element, "hoverAnimationDuration", $sourceStore);
$: style = buildInlineStyle("image-width", imageWidth, "image-max-width", imageMaxWidth, "image-min-width", imageMinWidth, "image-height", imageHeight, "image-max-height", imageMaxHeight, "image-min-height", imageMinHeight, "image-object-fit", imageObjectFit, "background-color", backgroundColor, "hover-animation-duration", hoverAnimationDuration, "hover-background-color", hoverBackgroundColor, "hover-overlay-color", hoverOverlayColor, "hover-zoom-factor", hoverZoomFactor);
</script>

<CardBase {element}>
  <a href={linkUrl.href} class="link" class:external={linkUrl.external} target={(shouldOpenInNewWindow(linkUrl.href))? '_blank' : '_self'} {style}>
    <img class="configurable-image" class:hover-background={hoverEffect === 'background'} class:hover-overlay={hoverEffect === 'overlay'} class:hover-zoom={hoverEffect === 'zoom'} src={imageUrl} alt={imageAltText}>
    {#if hoverReplaceImageUrl}
    <div class="image-hover">
      <img class="configurable-image" class:hover-replace={hoverEffect === 'replace'} src={hoverReplaceImageUrl} alt={imageAltText}>
    </div>
    {/if}
  </a>
</CardBase>

<style>
  a.link {
    position: relative;
    height: 100%;
    width: 100%;
    margin: auto;
  }
  
  .configurable-image {
    display: block;
    border: none;
    cursor: pointer;
    width: var(--image-width, unset);
    min-width: var(--image-min-width, unset);
    max-width: var(--image-max-width, unset);
    height: var(--image-height, unset);
    min-height: var(--image-min-height, unset);
    max-height: var(--image-max-height, unset);
    object-fit: var(--image-object-fit, unset);
    background-color: var(--background-color, unset);
  }

  .configurable-image.hover-background:hover {
    background-color: var(
      --hover-background-color,
      var(--background-color, unset)
    );
  }

  .configurable-image.hover-overlay::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: transparent;
    transition: background-color var(--hover-animation-duration, .25s);
  }

  .configurable-image.hover-overlay:hover::after {
    background-color: var(--hover-overlay-color, transparent);
  }

  .configurable-image.hover-zoom {
    transform-origin: 0 0;
    transition: transform var(--hover-animation-duration, .25s), visibility var(--hover-animation-duration, .25s) ease-in;
  }

  .configurable-image.hover-zoom:hover {
    transform: scale(var(--hover-zoom-factor, 2));
  }

  .link div.image-hover {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .link div.image-hover img {
    opacity: 0;
    transition: opacity .2s;
    object-fit: contain;
  }

  .link:hover div.image-hover img.hover-replace {
    opacity: 1;
  }</style>
