import { AbstractReactiveMicrocomponent } from "../AbstractReactiveMicrocomponent";
import { ReactiveMicrocomponent } from "../ReactiveMicrocomponent";
import { ReactiveMicrocomponentConfig } from "../ReactiveMicrocomponentConfig";
import _ from 'lodash';

export interface BufferManager<T> {
  getLength: () => number;
  consume: () => T[];
}

export interface BufferManagerConfig<T> extends ReactiveMicrocomponentConfig<BufferManager<T>, unknown, { buffer: T[]; }> {
  bufferMaxLength?: number;
  bufferUndefinedInputs?: boolean;
}

export class BufferedPassthrough<T> extends AbstractReactiveMicrocomponent<BufferManager<T>, [T], unknown, { buffer: T[] }> {
  private bpConfig: BufferManagerConfig<T>;

  public constructor(arg: string | BufferManagerConfig<T>, input: ReactiveMicrocomponent<T>) {
    const defaults: BufferManagerConfig<T> = {
      name: null,
      bufferMaxLength: 50,
      bufferUndefinedInputs: false,
      bufferInputs: true,
      saveToLocalStorage: true
    };
    const initials = {
      initialOutputValue: {
        getLength: () => 0,
        consume: () => []
      },
      initialStateValue: { 
        buffer: []
      }
    };
    const config = (typeof arg === 'string')? 
    { ...defaults, name: arg, ...initials } : 
    { ...defaults, ...arg, ...initials };
    super(config, input);
    this.bpConfig = config;
  }

  protected async update($control, $input: T): Promise<BufferManager<T>> {
    if (this.bpConfig.bufferUndefinedInputs || !_.isUndefined($input)) {
      const buf = this.state.buffer;
      if (buf.length >= this.bpConfig.bufferMaxLength) {
        const discardLength = buf.length - this.bpConfig.bufferMaxLength;
        buf.splice(0, discardLength);
        this.log.debug(`BufferedPassthrough ${this.bpConfig.name}: ${discardLength} input(s) were discarded because the number of buffered inputs exceeded the maximum for this component (${this.bpConfig.bufferMaxLength}). If you expected to process these inputs, you may want to consider increasing bufferMaxLength.`);
      }
      buf.push($input);
    }
    return {
      getLength: () => this.state.buffer.length,
      consume: () => {
        let ret = this.state.buffer;
        this.state.buffer = [];
        this.saveCheckpoint();
        return ret;
      }
    }
  }
}
