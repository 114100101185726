<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import { BaseAtom, } from "components/cards/atoms";
import { RadioButtonParticle } from "components/cards/particles/YinzCam";
export let container = undefined;
export let radio = undefined;
</script>

<BaseAtom
    _isotope="DropdownAtom"
    {...container}
    _cssVariablePrefix="drop-down"
>
    <RadioButtonParticle {...radio} />
</BaseAtom>

<style></style>
