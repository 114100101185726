<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import { get, merge } from "lodash";
import { BaseAtom, getSignonManager } from "components/cards/atoms";
import { TextParticle, ImageParticle, } from "components/cards/particles/YinzCam";
import ButtonParticle from "components/cards/particles/YinzCam/Core/Button/ButtonParticle.svelte";
export let container;
export let overlayPadding;
export let imgCardBackground;
export let txtMemberValue;
export let txtRewardsText;
export let txtSpendText;
export let btnCTA;
export let colorBackgroundFill;
export let colorPointsCard;
export let colorRankCard;
export let colorCtaButton;
export let colorLinearGradient;
export let profileSegment;
export let profileField;
export let hideIfNoValue;
export let contentAvailable;
let atomWidthPx;
$: isMobile = atomWidthPx < 626;
$: isTablet = atomWidthPx <= 1010 && atomWidthPx >= 626;
$: txtMultiplier = isMobile ? "0.50" : isTablet ? "0.75" : "1.00";
$: btnMultiplier = isMobile ? "0.75" : "1.00";
$: backgroundPadding = isMobile
    ? "0.5rem"
    : isTablet
        ? "1.25rem"
        : "1.5rem";
$: console.log({ atomWidthPx });
const sso = getSignonManager();
$: profile = sso.getProfileSegmentStore(profileSegment);
$: value = ($profile || {})[profileField];
$: {
    contentAvailable = !hideIfNoValue || !!value;
}
;
</script>

<BaseAtom
    _isotope="LoyaltyValueAtom"
    _cssVariablePrefix="loyalty-value"
    {...container}
    {overlayPadding}
    {colorBackgroundFill}
    {colorPointsCard}
    {colorRankCard}
    {colorCtaButton}
    {colorLinearGradient}
    {backgroundPadding}
    bind:_clientWidthPx={atomWidthPx}
>
    <div
        class="main-container"
        class:layout-tablet-width={isTablet}
        class:layout-mobile-width={isMobile}
    >
        <div class="background-container">
            <ImageParticle {...imgCardBackground} _block={true} />
        </div>
        <div class="overlay-container">
            <div class="text-container">
                <TextParticle {...txtRewardsText} size={`calc(${get(txtRewardsText,"size")}*${txtMultiplier})`} />
                <TextParticle {...txtMemberValue} size={`calc(${get(txtMemberValue,"size")}*${txtMultiplier})`} {...((value)? { text: value } : undefined)} />
                <TextParticle {...txtSpendText} size={`calc(${get(txtSpendText,"size")}*${txtMultiplier})`} />
            </div>
            <div class="cta-button">
                <ButtonParticle
                    {...merge({}, btnCTA, {
                        container: {
                            width: `calc(${get(btnCTA, "container.width")}*${btnMultiplier})`,
                            height: `calc(${get(btnCTA, "container.height")}*${btnMultiplier})`,
                        },
                    })}
                />
            </div>
        </div>
    </div>
</BaseAtom>

<style>
    .main-container {
        width: 100%;
        height: 100%;
        background-color: var(--loyalty-value-color-background-fill, #eeba40);
        display: grid;
        padding: var(--loyalty-value-background-padding);
        border-radius: 2rem;
        overflow: hidden;
    }
    .main-container > * {
        grid-area: 1/1;
        border-radius: 2rem;
        overflow: hidden;
    }
    .background-container {
        /* background: linear-gradient(
            61deg,
            #061754 30.75%,
            rgba(7, 26, 98, 0.71) 38%,
            rgba(11, 35, 138, 0.18) 52.5%,
            rgba(15, 44, 178, 0) 64.58%
        ); */
        z-index: 1;
    }
    .overlay-container {
        position: relative;
        border-radius: 2rem;
        padding: var(--loyalty-value-overlay-padding, unset);
        z-index: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .text-container {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        flex-basis: min-content;
    }
    .cta-button{
        position: absolute;
        bottom: 2rem;
        right: 2rem;
    }</style>
