<script lang="ts">import { BaseParticle } from "components/cards/particles";
import "../Image";
import "../Text";
import ImageParticle from "../Image/ImageParticle.svelte";
import TextParticle from "../Text/TextParticle.svelte";
import { resolveUrl } from "js/url";
import "./spec";
import { getTranslator } from "components/cards/utilities";
// props
export let container = undefined;
export let link = undefined;
export let translations = [];
export let image = undefined;
export let text = undefined;
export let orientation = 'row';
export let alignment = 'center';
export let justification = 'center';
export let imagePosition = 'before';
export let showImage = true;
export let showText = true;
export let gap = undefined;
$: xlator = getTranslator(translations, { link });
$: direction = orientation + ((imagePosition === 'after') ? '-reverse' : '');
$: hidden = (container === null || container === void 0 ? void 0 : container.hidden) || !showImage && !showText;
$: resolvedLink = (link) ? resolveUrl(xlator({ link })).href : undefined;
</script>

<style>
  .flex-container {
    display: flex;
    flex-direction: var(--button-particle-direction, row);
    align-items: var(--button-particle-alignment, center);
    justify-content: var(--button-particle-justification, center);;
    gap: var(--button-particle-gap, 0);
    width: 100%;
    height: 100%;
  }

  .flex-container > :global(*) {
    flex: 0 1 max-content;
  }</style>

<BaseParticle _name="ButtonParticle" _cssVariablePrefix="button-particle" _link={resolvedLink} _interactive={true} {...container} {hidden} {direction} {gap} {alignment} {justification} on:click on:mouseenter on:mouseleave on:touchstart on:mouseover on:focus>
  <div class="flex-container">
    {#if showImage}
    <ImageParticle _block={true} {...image} />
    {/if}
    {#if showText}
    <TextParticle _block={true} {...text} />
    {/if}
  </div>
</BaseParticle>
