import _ from "lodash";
import { Container } from "inversify";
import { TokenContainerModule } from "inversify-token";
import { YinzCamInjectModule } from "yinzcam-inject";
import { CardsLibraryRecord, CardsLibraryToken } from "../common/CardsLibraryToken";

import * as HTML from "./HTML/HTML.svelte";
import * as SimpleCard from "./SimpleCard/SimpleCard.svelte";

import * as LCFC__Basic__ColumnHeaderA from "./LCFC/Basic/ColumnHeaderA/ColumnHeaderA.svelte";
import * as LCFC__Basic__FooterNavSectionMenu from "./LCFC/Basic/FooterNavSectionMenu/FooterNavSectionMenu.svelte";
import * as LCFC__Basic__HeaderNavFlyoutMenu from "./LCFC/Basic/HeaderNavFlyoutMenu/HeaderNavFlyoutMenu.svelte";
import * as LCFC__Basic__HeaderNavSectionMenu from "./LCFC/Basic/HeaderNavSectionMenu/HeaderNavSectionMenu.svelte";
import * as LCFC__Basic__ImageBorderDivider from "./LCFC/Basic/ImageBorderDivider/ImageBorderDivider.svelte";
import * as LCFC__Media__HorizontalHeadlineMediaA from "./LCFC/Media/HorizontalHeadlineMediaA/HorizontalHeadlineMediaA.svelte";
import * as LCFC__Media__HorizontalHeadlineMediaB from "./LCFC/Media/HorizontalHeadlineMediaB/HorizontalHeadlineMediaB.svelte";
import * as LCFC__Media__HorizontalMediaA from "./LCFC/Media/HorizontalMediaA/HorizontalMediaA.svelte";
import * as LCFC__Media__VerticalHeadlineMediaB from "./LCFC/Media/VerticalHeadlineMediaB/VerticalHeadlineMediaB.svelte";
import * as LCFC__Media__VerticalMediaA from "./LCFC/Media/VerticalMediaA/VerticalMediaA.svelte";
import * as LCFC__Stats__VerticalMatchA from "./LCFC/Stats/VerticalMatchA/VerticalMatchA.svelte";

import * as Tottenham__PageHeader from "./Tottenham/PageHeader/PageHeader.svelte";
import * as Tottenham__MatchCenterHeader from "./Tottenham/MatchCenterHeader/MatchCenterHeader.svelte";
import * as Tottenham__SectionHeading from "./Tottenham/SectionHeading/SectionHeading.svelte";
import * as Tottenham__MatchEvent from "./Tottenham/MatchEvent/MatchEvent.svelte";
import * as Tottenham__CommentaryItem from "./Tottenham/CommentaryItem/CommentaryItem.svelte";
import * as Tottenham__PossessionComparison from "./Tottenham/PossessionComparison/PossessionComparison.svelte";
import * as Tottenham__SingleStatComparison from "./Tottenham/SingleStatComparison/SingleStatComparison.svelte";
import * as Tottenham__PlayerStatsSummary from "./Tottenham/PlayerStatsSummary/PlayerStatsSummary.svelte";
import * as Tottenham__MultiStatComparison from "./Tottenham/MultiStatComparison/MultiStatComparison.svelte";
import * as Tottenham__MatchStatsCard from "./Tottenham/MatchStatsCard/MatchStatsCard.svelte";
import * as Tottenham__MatchTeamSelector from "./Tottenham/MatchTeamSelector/MatchTeamSelector.svelte";
import * as Tottenham__MatchPitchIsometric from "./Tottenham/MatchPitchIsometric/MatchPitchIsometric.svelte";
import * as Tottenham__MatchTeamLineup from "./Tottenham/MatchTeamLineup/MatchTeamLineup.svelte";
import * as Tottenham__SimpleConditionalCard from "./Tottenham/SimpleConditionalCard/SimpleConditionalCard.svelte";
import * as Tottenham__LiveScoreWide from "./Tottenham/LiveScoreWide/LiveScoreWide.svelte";
import * as Tottenham__LeagueTable from "./Tottenham/LeagueTable/LeagueTable.svelte";
import * as Tottenham__PresentedByCard from "./Tottenham/PresentedByCard/PresentedByCard.svelte";
import * as Tottenham__PageFooter from "./Tottenham/PageFooter/PageFooter.svelte";
import * as Tottenham__MatchStatsComment from "./Tottenham/MatchStatsComment/MatchStatsComment.svelte";

import * as YinzCam__Core__AdSlot from "./YinzCam/Core/AdSlot/AdSlot.svelte";
import * as YinzCam__Core__BorderDivider from "./YinzCam/Core/BorderDivider/BorderDivider.svelte";
import * as YinzCam__Core__EmptyCard from "./YinzCam/Core/EmptyCard/EmptyCard.svelte";
import * as YinzCam__Core__HTMLCard from "./YinzCam/Core/HTMLCard/HTMLCard.svelte";
import * as YinzCam__Core__PlaceholderCard from "./YinzCam/Core/PlaceholderCard/PlaceholderCard.svelte";
import * as YinzCam__Core__UnknownCard from "./YinzCam/Core/UnknownCard/UnknownCard.svelte";
import * as YinzCam__Core__ImageButtonCard from "./YinzCam/Core/ImageButtonCard/ImageButtonCard.svelte";
import * as YinzCam__Core__TextButtonCard from "./YinzCam/Core/TextButtonCard/TextButtonCard.svelte";
import * as YinzCam__Media__VideoPlayer from "./YinzCam/Media/VideoPlayer/VideoPlayer.svelte";
import * as YinzCam__PrivacyConsent__CookiePopup from "./YinzCam/PrivacyConsent/CookiePopup/CookiePopup.svelte";

import { library as libYinzCam } from "../atoms/YinzCam";

const CARD_LIBRARY: { [ key: string ]: CardsLibraryRecord } = {
  HTML,
  SimpleCard,

  LCFC__Basic__ColumnHeaderA,
  LCFC__Basic__FooterNavSectionMenu,
  LCFC__Basic__HeaderNavFlyoutMenu,
  LCFC__Basic__HeaderNavSectionMenu,
  LCFC__Basic__ImageBorderDivider,
  LCFC__Media__HorizontalHeadlineMediaA,
  LCFC__Media__HorizontalHeadlineMediaB,
  LCFC__Media__HorizontalMediaA,
  LCFC__Media__VerticalHeadlineMediaB,
  LCFC__Media__VerticalMediaA,
  LCFC__Stats__VerticalMatchA,

  Tottenham__PageHeader,
  Tottenham__MatchCenterHeader,
  Tottenham__SectionHeading,
  Tottenham__MatchEvent,
  Tottenham__CommentaryItem,
  Tottenham__PossessionComparison,
  Tottenham__SingleStatComparison,
  Tottenham__PlayerStatsSummary,
  Tottenham__MultiStatComparison,
  Tottenham__MatchStatsCard,
  Tottenham__MatchTeamSelector,
  Tottenham__MatchPitchIsometric,
  Tottenham__MatchTeamLineup,
  Tottenham__SimpleConditionalCard,
  Tottenham__LiveScoreWide,
  Tottenham__LeagueTable,
  Tottenham__PresentedByCard,
  Tottenham__PageFooter,
  Tottenham__MatchStatsComment,
  
  YinzCam__Core__AdSlot,
  YinzCam__Core__BorderDivider,
  YinzCam__Core__EmptyCard,
  YinzCam__Core__HTMLCard,
  YinzCam__Core__PlaceholderCard,
  YinzCam__Core__UnknownCard,
  YinzCam__Core__ImageButtonCard,
  YinzCam__Core__TextButtonCard,
  YinzCam__Media__VideoPlayer,
  YinzCam__PrivacyConsent__CookiePopup,

  ...libYinzCam,
};

export const CardsLibraryModule: YinzCamInjectModule = new YinzCamInjectModule((container: Container): void => {
  container.load(new TokenContainerModule((bindToken) => {
    for (let k in CARD_LIBRARY) {
      const clazz = k.replace(/__/g, '.');
      const rec = CARD_LIBRARY[k];
      rec.clazz = clazz;
      let filtered = false;
      if (!_.isNil(CONFIG.cardClassFilters) && CONFIG.cardClassFilters.length > 0 && !clazz.startsWith("YinzCam.")) {
        filtered = true;
        for (let filterKey in CONFIG.cardClassFilters) {
          const filter = CONFIG.cardClassFilters[filterKey];
          if (filter === '*' || (filter.slice(-1) === '*' && clazz.startsWith(filter.slice(0, -2))) || clazz === filter) {
            filtered = false;
            break;
          }
        }
      }
      if (!filtered) {
        bindToken(CardsLibraryToken).toConstantValue(rec).whenTargetNamed(clazz);
      }
    }
  }));
});
