import deepEqual from 'deep-equal';
import { AxiosInstance, AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import { AbstractReactiveMicrocomponent, ManualPassthrough, RepeatingTimer } from 'yinzcam-rma';
import { YinzCamAPIRequest } from './YinzCamAPIRequest';
import { YinzCamAPIResponse } from './YinzCamAPIResponse';
import { LOCAL_TIMESTAMP_HEADER_KEY } from './constants';
import { YinzCamAPIRequestParameters } from './YinzCamAPIRequestParameters';
import { YinzCamAPIRequestParameterComponent } from './YinzCamAPIRequestParameterComponent';
import { buildAxiosRequest, buildYinzCamAPIResponse } from './utilities';
import { YinzCamAPIConfig } from './YinzCamAPIConfig';

export class YinzCamAPIRequestComponent extends AbstractReactiveMicrocomponent<YinzCamAPIResponse, [number, YinzCamAPIRequestParameters], { forcedUpdateTime: number }, { lastUpdateTime: number; lastParameters: YinzCamAPIRequestParameters; }> {
  private readonly appConfig: YinzCamAPIConfig;
  private readonly axios: AxiosInstance;
  private readonly service: string;
  private readonly request: YinzCamAPIRequest;
  private readonly frequencyFeedbackOutput: ManualPassthrough<number>;

  public constructor(name: string, appConfig: YinzCamAPIConfig, axios: AxiosInstance, service: string, request: YinzCamAPIRequest, timerInput: RepeatingTimer, parametersInput: YinzCamAPIRequestParameterComponent, frequencyFeedbackOutput: ManualPassthrough<number>) {
    super({ name, expectThrowFromUpdate: true }, timerInput, parametersInput);
    this.appConfig = appConfig;
    this.axios = axios;
    this.service = service;
    this.request = request;
    this.frequencyFeedbackOutput = frequencyFeedbackOutput;
  }

  override refreshSupported(): boolean {
    return true;
  }

  override refresh(): void {
    this.setControl({ forcedUpdateTime: Date.now() })
  }

  protected async update($control: { forcedUpdateTime: number }, $timer: number, $parameters: YinzCamAPIRequestParameters): Promise<YinzCamAPIResponse> {
    //this.log.info(`in update!!! $timer:${$timer}, $parameters:${$parameters}`);
    // TODO: Compute request-specific parameters and compare that here, because we now have service-specific parameters.
    if ( (!$control?.forcedUpdateTime || (this.state.lastUpdateTime || 0) >= $control.forcedUpdateTime) &&
         (!$timer || !$parameters || (this.state.lastUpdateTime >= $timer && deepEqual(this.state.lastParameters, $parameters))) ) {
      //this.log.debug('timer and parameters input not updated, ignoring');
      throw null;
    }
    // TODO: I don't like this dependency on stores.js and it should be a reactive thing, not manual here.
    // Want to wrap a store in a reactive component.
    /*
    if (this.appConfig.stopPollingWhenWindowInvisible && !windowVisible) {
      throw null;
    }
    */
    this.state.lastUpdateTime = Math.max($timer || 0, $control?.forcedUpdateTime || 0);
    this.state.lastParameters = $parameters;

    try {
      let axiosReq: AxiosRequestConfig = buildAxiosRequest(this.appConfig, $parameters, this.service, this.request);
      // console.log("AXIOS REQUEST", axiosReq);
      let rsp = await this.axios.request(axiosReq);
      rsp.headers[LOCAL_TIMESTAMP_HEADER_KEY] = Date.now();
  
      // convert to our API response type
      let ycApiRsp = buildYinzCamAPIResponse(this.appConfig, rsp);
  
      // set the upstream timer to the ttl
      this.frequencyFeedbackOutput.setValue(ycApiRsp.ttl);
  
      return ycApiRsp;
    } catch (error) {
      // exponential backoff
      const defaultRefreshInterval = this.appConfig.defaultCacheTimeSeconds * 1000;
      const maxRefreshInterval = defaultRefreshInterval * 2;
      let currentRefreshInterval = this.frequencyFeedbackOutput.getValue();
      if (!currentRefreshInterval) {
        currentRefreshInterval = this.appConfig.defaultCacheTimeSeconds * 1000;
      }
      const newRefreshInterval = Math.min(currentRefreshInterval * 2, maxRefreshInterval);
      this.frequencyFeedbackOutput.setValue(newRefreshInterval);
      throw error;
    }
  }
}
