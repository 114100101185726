import { getNamed } from "inversify-token";
import { DIYSourceDomainToken } from "./domains/Domain";
import ROOT from "inversify.config";

export function extractDomainNameFromPath(path: string) {
  if (path?.[0] !== '/') {
    throw new Error(`malformed path (must start with forward slash): ${path}`);
  }
  const firstSlashIndex = path?.indexOf('/', 1);
  if (!(firstSlashIndex > 0)) {
    throw new Error(`malformed path (must contain at least two forward slashes): ${path}`);
  }
  const name = path.substring(1, firstSlashIndex);
  const dotIndex = name.indexOf('.');
  if (dotIndex < 0 || dotIndex === name.length - 1 || dotIndex !== name.lastIndexOf('.')) {
    throw new Error(`malformed domain (must contain one dot and not start or end with a dot): ${name}`);
  }
  const rest = path.substring(firstSlashIndex);
  return { name, rest };
}

export function extractDomainFromPath(path: string) {
  const { name, rest } = extractDomainNameFromPath(path);
  const domain = getNamed(ROOT, DIYSourceDomainToken, name);
  if (!domain) {
    throw new Error(`unrecognized domain name: ${name}`);
  }
  return { domain, rest };
}
