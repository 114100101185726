import { CardsDataSourceBase } from "../../common/CardsDataSourceBase";
import { injectable } from "inversify";
import { injectToken } from "inversify-token";
import { ContentfulCDNResponse, ContentfulCDNToken } from "contentful-api";
import type { ContentfulCDN } from "contentful-api";
import _ from "lodash";
import { JSONSchema4 } from "json-schema";
import { stripContentfulMetadata } from "lib/contentful-api/ContentfulUtilities";

@injectable()
export class ContentfulCDNQuery extends CardsDataSourceBase<ContentfulCDNResponse> {
  private readonly cdn: ContentfulCDN;

  public constructor(@injectToken(ContentfulCDNToken) cdn: ContentfulCDN) {
    super({ server: cdn })
    this.cdn = cdn;
  }

  public getDisplayName(): string {
    return 'Contentful CDN';
  }

  // easy way
  public async getDataSourcePaths(specData?: { [key: string]: any }): Promise<{ name: string; path: string; }[]> {
    const metadata = this.cdn.getMetadata();
    if (!metadata) {
      return [];
    }
    return Object.values(metadata.contentTypeMetadata).map((v) => ({
      name: v.name,
      path: v.slug
    }));
  }

  // hard way
  /*
  public async getDataSourcePaths(specData?: { [key: string]: any }): Promise<{ name: string; path: string; }[]> {
    const compId = specData?.compId;
    if (!compId) {
      return null;
    }
    const rsp = await this.config.server.singleRequest({ path: "/Auxiliary/Teams", params: { compId } });
    return [].concat((<any>rsp?.data)?.ArrayOfAuxTeamsXml?.AuxTeamsXml)?.filter(o => o).map((o) => ({
      name: 'Second Team: ' + (o.TeamName?._text || o.Id?._text || ''),
      path: `/Auxiliary/Players?teamId=${o.Id._text}`
    }));
  }
  */

  // easy way
  public async getDataSourceConfigSpec(path?: string, specData?: { [key: string]: any }): Promise<JSONSchema4> {
    return {
      type: "object",
      properties: {
        "singleObject": {
          type: "boolean",
          title: "Single Object",
          description: "If the data source is an array of items, pass the entire array to the first card using this data source, rather than spreading the items in the array among all cards using this data source (the normal behavior).",
        },
        "alwaysArray": {
          type: "boolean",
          title: "Always Array",
          description: "Always return the results of the data source as an array, even if the query targets a specific content slug.",
        },
        "objectQuery": {
          type: "string",
          title: "Extract Data",
          description: "Provide a JSON Path string to extract a portion of this object from the Contentful API response.",
        },        
        query: {
          type: "object",
          title: "Query",
          description: "The lookup query to use for the data source.",
          properties: {
            "fields.slug": {
              type: "string",
              title: "Slug",
              description: "The slug of the content item.",
              // TODO: Use Contentful API to look up valid options for slug?
            },
            "fields.slug[ne]": {
              type: "string",
              title: "Slug Not Equal To",
              description: "Return results where the slug is not equal to the provided value.",
            },
            "order": {
              type: "string",
              title: "Ordering",
              description: "The field to order by. Prepend with a '-' to order in reverse.",
              // TODO: Use Contentful API to look up valid options for fields?
            },
            "metadata.tags.sys.id[in]": {
              type: "string",
              title: "Tags (match at least one)",
              description: "Include items that have at least one of the Contentful tag IDs in the given list.",
              // TODO: Use Contentful API to look up valid options for tags?
            },
            "metadata.tags.sys.id[all]": {
              type: "string",
              title: "Tags (match all)",
              description: "Include items that have all of the Contentful tag IDs in the given list.",
              // TODO: Use Contentful API to look up valid options for tags?
            },
            "query": {
              type: "string",
              title: "Search Terms",
              description: "Include items matching the given search terms.",
            },
            "fields.publishDateTime[gt]": {
              type: "string",
              title: "Publish Date Time Greater Than",
              description: "Include items that have a publish date/time greater than the provided value.",
            },
            "fields.publishDateTime[lt]": {
              type: "string",
              title: "Publish Date Time Less Than",
              description: "Include items that have a publish date/time less than the provided value.",
            },
            "limit": {
              type: "number",
              title: "Limit",
              description: "Limit the number of items that can be returned by the Contentful API.",
            },
          },
          required: [
          ]
        }
      },
      required: [
        "query"
      ]
    };
  }

  // hard way
  /*
  public async getDataSourceConfigSpec(): Promise<JSONSchema4> {
    const rsp = await this.server.singleRequest({ path: "/Auxiliary/Competitions" });
    const comps = [].concat((<any>rsp?.data)?.ArrayOfAuxCompetitionXml?.AuxCompetitionXml)?.filter(o => o?.Id?._text).map((o) => ({
      id: o.Id?._text,
      name: 'Second Team Competition: ' + (o.CompetitionName?._text || o.Id?._text || '')
    }));
    return {
      type: "object",
      properties: {
        compId: {
          type: "string",
          title: "Competition",
          description: "The competition to that the team participates in.",
          enum: comps.map(c => c.id),
          options: {
            enum_titles: comps.map(c => c.name)
          }
        }
      },
      required: [
        "compId"
      ]
    };
  }
  */

  protected getExtraParams(path: string, specData?: { [key: string]: any; }): { [key: string]: string; } {
    const params = specData?.query || {};
    // trim whitespace around tag IDs if people left spaces between commas
    ['metadata.tags.sys.id[all]', 'metadata.tags.sys.id[in]'].forEach((key) => {
      const val = params[key] as string;
      if (val) {
        params[key] = val.split(',').map((tagId) => tagId.trim());
      }
    });
    return params;
  }

  protected processResponse(path: string, response: ContentfulCDNResponse, specData?: { [key: string]: any; }): object | any[] {
    let stripped = stripContentfulMetadata(response.items) as object[];
    //console.log('CONTENTFUL RESPONSE', specData, response, stripped);
    let ret: object | any[] = stripped;
    if (stripped.length > 1 && specData?.singleObject) {
      stripped = stripped.slice(0, 1);
    }
    if (stripped.length > 1) {
      if (specData?.objectQuery) {
        stripped = stripped.map((obj) => _.get(obj, specData.objectQuery));
      }
    } else if (stripped.length === 1) {
      const obj = stripped[0];
      if (specData?.objectQuery) {
        ret = _.get(obj, specData.objectQuery);
      } else if (!specData?.singleObject && 'items' in obj && Array.isArray(obj['items'])) {
        // as a special case, if there is one object and it contains an items array, expand that array as the returned data
        // this behavior can be disabled by setting singleObject = true in the source data
        const { items, ...objWithoutItems } = obj;
        ret = items.map((item) => ({ ...objWithoutItems, ...item }));
      } else if (!specData?.alwaysArray && specData.query['fields.slug']) {
        ret = obj;
      } else {
        // this could be omitted but keeping it here to make it explicit that the output is an array
        ret = [ obj ];
      }
    }
    //console.log("ContentfulCDNQuery data source", stripped, ret);
    return ret;
  }
}
