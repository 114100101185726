<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import { get, merge } from "lodash";
import { BaseAtom, } from "components/cards/atoms";
import { TextParticle, ImageParticle, } from "components/cards/particles/YinzCam";
export let container;
export let containerPadding;
export let txtPlayerNumber;
export let txtPlayerName;
export let txtPlayerPosition;
export let imgPlayerImage;
export let imgTeamLogo;
export let txtTeamTricode;
export let statName;
export let statValue;
export let playerStats = undefined;
export let leaderStatList;
export let teamList;
export let categoryList;
let atomWidthRem;
$: isMobile = atomWidthRem <= 40;
$: isTablet = atomWidthRem > 40 && atomWidthRem <= 70;
$: statsDataSource = leaderStatList === null || leaderStatList === void 0 ? void 0 : leaderStatList.find((stat) => (stat === null || stat === void 0 ? void 0 : stat.categoryName.toLowerCase()) === categoryList.toLowerCase())[teamList];
$: statsDisplay = [].concat({
    name: categoryList,
    value: statsDataSource === null || statsDataSource === void 0 ? void 0 : statsDataSource.value,
});
$: txtPlayerNameMultiplier = isMobile ? "0.6" : "1.00";
$: txtStatNameMultiplier = isMobile ? "0.5" : "1.00";
$: txtStatValueMultiplier = isMobile ? "0.45" : "1.00";
$: imgPlayerImageMultiplier = isMobile
    ? "0.75"
    : isTablet
        ? "0.90"
        : "1.00";
</script>

<BaseAtom
    bind:_clientWidthRem={atomWidthRem}
    _isotope="MCTopPerformersAtom"
    _cssVariablePrefix="mc-top-performers"
    {...container}
    {containerPadding}
>
    <div
        class="main-container"
        class:mobile-layout={isMobile}
        class:tablet-layout={isTablet}
    >
        <div class="player-number">
            <TextParticle
                {...txtPlayerNumber}
                text={`${statsDataSource?.number}`}
            />
        </div>
        <div class="player-details">
            <div class="player-image-card">
                <ImageParticle
                    {...merge({}, imgPlayerImage, {
                        container: {
                            height: `calc(${get(imgPlayerImage, "container.height")}*${imgPlayerImageMultiplier})`,
                        },
                        source: `${statsDataSource?.imageURL}`,
                    })}
                />
            </div>
        </div>
        <div class="stats-container">
            <div class="name-position-logo">
                <div class="name-position">
                    <div class="player-name">
                        <TextParticle
                            {...txtPlayerName}
                            size={`calc(${get(txtPlayerName, "size")}*${txtPlayerNameMultiplier})`}
                            text={`${statsDataSource?.name}`}
                        />
                    </div>
                    <div class="player-position">
                        <TextParticle
                            {...txtPlayerPosition}
                            size={`calc(${get(txtPlayerPosition, "size")}*${txtPlayerNameMultiplier})`}
                            text={`${statsDataSource?.position}`}
                        />
                    </div>
                </div>
                <div class="logo">
                    <ImageParticle
                        {...imgTeamLogo}
                        _block={true}
                        source={`${statsDataSource?.teamLogo}`}
                    />
                </div>
            </div>
            <div class="stat-pills-box">
                {#each statsDisplay || [] as stat}
                    <div class="stat-pills">
                        <div class="stat-name">
                            <TextParticle
                                {...statName}
                                size={`calc(${get(statName, "size")}*${txtStatNameMultiplier})`}
                                text={`${stat?.name}`}
                            />
                        </div>
                        <div class="stat-value">
                            <TextParticle
                                {...statValue}
                                size={`calc(${get(statValue, "size")}*${txtStatNameMultiplier})`}
                                text={`${stat?.value || 0}`}
                            />
                        </div>
                    </div>
                {/each}
            </div>
        </div>
    </div>
</BaseAtom>

<style>
    .main-container {
        width: 100%;
        padding: var(--mc-top-performers-container-padding, unset);
        display: flex;
        position: relative;
        justify-content: center;
        align-items: flex-start;
        gap: 1rem;
    }
    .player-details {
        align-self: flex-end;
        display: flex;
        justify-content: center;
    }
    .player-number {
        /* position: absolute;
        top: 1rem;
        left: 1rem; */
    }
    .player-name {
        line-height: 1;
    }
    .stats-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 0.5rem;
        width: 65%;
    }
    .name-position-logo {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .stat-pills-box {
        /* display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 0.5rem; */
        display: grid;
        grid-template-columns: repeat(3, minmax(10rem, 1fr));
        grid-gap: 0.5rem;
        gap: 0.5rem;
    }
    .stat-pills {
        flex: 0 0 28%;
        border: 1px solid gray;
        border-radius: 0.75rem;
        padding: 0.5rem 0.75rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        word-wrap: break-word;
    }
    .stat-name {
        line-height: 0.5;
    }
    .main-container.mobile-layout {
        width: 100%;
    }
    .main-container.mobile-layout .player-details {
            flex: 1 0 40%;
        }</style>
