import { JSONSchema4 } from "json-schema";
import { Domain } from "./Domain";
import { DIYSourceResponse } from "../DIYSourceResponse";
import { DIYSourceRequest } from "../DIYSourceRequest";
import { ReactiveMicrocomponent } from "lib/yinzcam-rma";

export abstract class AbstractDomain implements Domain {
  public abstract getDomainName(): string;

  public abstract getDisplayName(): string;

  public abstract getPaths(specData?: Record<string, any>): Promise<{ name: string; path: string; }[]>;

  public abstract getConfigSchema(path?: string, specData?: Record<string, any>): Promise<JSONSchema4>;

  public abstract getResponseSchema(path?: string, specData?: Record<string, any>): Promise<JSONSchema4>;

  public abstract getExtraParams(path: string, specData?: Record<string, any>): Record<string, string>;

  public abstract getRequest(request: DIYSourceRequest): ReactiveMicrocomponent<DIYSourceResponse>;

  public abstract processResponse(path: string, response: DIYSourceResponse, specData?: Record<string, any>): object | any[];
}
