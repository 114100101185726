import { inject, injectable } from 'inversify';
import { injectToken, Token } from 'inversify-token';
import { ReactiveMicrocomponent } from 'lib/yinzcam-rma';
import { YinzCamAPI, YinzCamAPIRequest, YinzCamAPIResponse, YinzCamAPIToken, YinzCamServer } from 'yinzcam-api';

export const YinzCamAdsServerToken = new Token<YinzCamAdsServer>(Symbol.for("YinzCamAdsServerToken"));

@injectable()
export class YinzCamAdsServer {
  private readonly server: YinzCamServer;

  public constructor(@injectToken(YinzCamAPIToken) private readonly api: YinzCamAPI) {
    this.server = api.getServer('ads');
  }

  public getRequest(request: YinzCamAPIRequest) {
    return this.server.getRequest(request);
  }

  public getAds(major: string, minor: string = null, ffOverride = null): ReactiveMicrocomponent<YinzCamAPIResponse> {
    return this.server.getRequest({
      path: '/',
      headers: {
      },
      params: {
        major,
        ...((minor)? { minor } : {}),
        ...((ffOverride)? { ff: ffOverride } : {})
      }
    });
  }
}
