import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { AbstractTemporalCachedReactiveMicrocomponent, ReactiveMicrocomponent } from 'yinzcam-rma';
import { YinzCamAPIRequest } from './YinzCamAPIRequest';
import { YinzCamAPIResponse } from './YinzCamAPIResponse';
import { LOCAL_TIMESTAMP_HEADER_KEY } from './constants';
import { YinzCamAPIRequestParameters } from './YinzCamAPIRequestParameters';
import { YinzCamAPIRequestParameterComponent } from './YinzCamAPIRequestParameterComponent';
import { buildAxiosRequest, buildYinzCamAPIResponse } from './utilities';
import { YinzCamAPIConfig } from './YinzCamAPIConfig';

export class TemporalCachedYinzCamAPIRequestComponent extends AbstractTemporalCachedReactiveMicrocomponent<YinzCamAPIResponse, [ YinzCamAPIRequestParameters ]> {
  private readonly appConfig: YinzCamAPIConfig;
  private readonly axios: AxiosInstance;
  private readonly service: string;
  private readonly request: YinzCamAPIRequest;

  public constructor(name: string, appConfig: YinzCamAPIConfig, axios: AxiosInstance, service: string, request: YinzCamAPIRequest, parametersInput: YinzCamAPIRequestParameterComponent) {
    super(name, appConfig, parametersInput);
    this.appConfig = appConfig;
    this.axios = axios;
    this.service = service;
    this.request = request;
  }

  protected override async execUpdate(now: number, previousOutput: YinzCamAPIResponse, $parameters: YinzCamAPIRequestParameters): Promise<YinzCamAPIResponse> {
    let axiosReq: AxiosRequestConfig = buildAxiosRequest(this.appConfig, $parameters, this.service, this.request);
    // console.log("AXIOS REQUEST", axiosReq);
    
    // make the API request
    let rsp = await this.axios.request(axiosReq);
    rsp.headers[LOCAL_TIMESTAMP_HEADER_KEY] = Date.now();

    // convert to our API response type
    let ycApiRsp = buildYinzCamAPIResponse(this.appConfig, rsp);

    // set the upstream timer to the ttl
    this.state.ttl = ycApiRsp.ttl;

    return ycApiRsp;
  }
}
