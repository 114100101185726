<script context="module" lang="ts">import 'json-schema';
import 'components/cards/common/CardsElementInterfaces';
export function getDisplayName() {
    return "Media: Headline Vertical A";
}
export function getElementConfigSpec() {
    return {
        type: "object",
        properties: {
            imageUrl: { type: "string", format: "url", title: "Image URL", description: "URL for the background image." },
            textAreaPadding: { type: "string", title: "Text Area Padding", description: "Padding inside the text area." },
            categoryText: { type: "string", title: "Category Text", description: "Text for the category label." },
            categoryFontColor: { type: "string", title: "Category Font Color", description: "Font color for the category label." },
            categoryFontSize: { type: "string", title: "Category Font Size", description: "Font size for the category label." },
            categoryFontWeight: { type: "string", title: "Category Font Weight", description: "Font weight for the category label." },
            publishDateTime: { type: "string", format: "date-time", title: "Publish Date and Time", description: "Publish date and time for the content." },
            publishDateFontColor: { type: "string", title: "Publish Date Font Color", description: "Font color for the publish date." },
            publishDateFontSize: { type: "string", title: "Publish Date Font Size", description: "Font size for the publish date." },
            publishDateFontWeight: { type: "string", title: "Publish Date Font Weight", description: "Font weight for the publish date." },
            titleText: { type: "string", title: "Title Text", description: "Text for the content title." },
            titleMaxLines: { type: "string", title: "Title Max Lines", description: "Maximum number of lines for the title." },
            titleFontColor: { type: "string", title: "Title Font Color", description: "Font color for the title." },
            titleFontSize: { type: "string", title: "Title Font Size", description: "Font size for the title." },
            titleFontWeight: { type: "string", title: "Title Font Weight", description: "Font weight for the title." },
            descriptionText: { type: "string", title: "Description Text", description: "Text for the content description." },
            descriptionMaxLines: { type: "string", title: "Description Max Lines", description: "Maximum number of lines for the description." },
            descriptionFontColor: { type: "string", title: "Description Font Color", description: "Font color for the description." },
            descriptionFontSize: { type: "string", title: "Description Font Size", description: "Font size for the description." },
            descriptionFontWeight: { type: "string", title: "Description Font Weight", description: "Font weight for the description." },
            ctaText: { type: "string", title: "CTA Text", description: "Text for the call-to-action button." },
            ctaFontSize: { type: "string", title: "CTA Font Size", description: "Font size for the call-to-action text." },
            ctaFontColor: { type: "string", title: "CTA Font Color", description: "Font color for the call-to-action text." },
            ctaFontWeight: { type: "string", title: "CTA Font Weight", description: "Font weight for the call-to-action text." },
        }
    };
}
export function getElementDataSourceSpec() {
    return {
        sources: [
            {
                type: 'SOURCE',
                title: 'Card Data',
                description: 'Arbitrary source of data for this card matching the configuration fields.',
                classes: '*'
            }
        ]
    };
}
</script>
  
  <script lang="ts">import "yinzcam-cards";
import CardBase from "components/cards/common/CardBase.svelte";
import { getFirstSourceStore, getTemplatedElementDataFromSource, buildInlineStyle, addClassOnHoverAction, formatDateTimeLocal } from "components/cards/utilities";
import "svelte/store";
import { t } from "js/i18n";
export let element;
export let sources;
export let sourceStore;
$: {
    sourceStore = getFirstSourceStore($sources);
}
$: imageUrl = getTemplatedElementDataFromSource(element, "imageUrl", $sourceStore);
$: padding = getTemplatedElementDataFromSource(element, "padding", $sourceStore);
$: backgroundColor = getTemplatedElementDataFromSource(element, "backgroundColor", $sourceStore);
$: categoryText = getTemplatedElementDataFromSource(element, "categoryText", $sourceStore);
$: categoryFontColor = getTemplatedElementDataFromSource(element, "categoryFontColor", $sourceStore);
$: categoryFontSize = getTemplatedElementDataFromSource(element, "categoryFontSize", $sourceStore);
$: categoryFontWeight = getTemplatedElementDataFromSource(element, "categoryFontWeight", $sourceStore);
$: publishDateTime = getTemplatedElementDataFromSource(element, "publishDateTime", $sourceStore);
$: publishDateFontColor = getTemplatedElementDataFromSource(element, "publishDateFontColor", $sourceStore);
$: publishDateFontSize = getTemplatedElementDataFromSource(element, "publishDateFontSize", $sourceStore);
$: publishDateFontWeight = getTemplatedElementDataFromSource(element, "publishDateFontWeight", $sourceStore);
$: titleText = getTemplatedElementDataFromSource(element, "titleText", $sourceStore);
$: titleMaxLines = getTemplatedElementDataFromSource(element, "titleMaxLines", $sourceStore);
$: titleFontColor = getTemplatedElementDataFromSource(element, "titleFontColor", $sourceStore);
$: titleFontSize = getTemplatedElementDataFromSource(element, "titleFontSize", $sourceStore);
$: titleFontWeight = getTemplatedElementDataFromSource(element, "titleFontWeight", $sourceStore);
$: descriptionText = getTemplatedElementDataFromSource(element, "descriptionText", $sourceStore);
$: descriptionMaxLines = getTemplatedElementDataFromSource(element, "descriptionMaxLines", $sourceStore);
$: descriptionFontColor = getTemplatedElementDataFromSource(element, "descriptionFontColor", $sourceStore);
$: descriptionFontSize = getTemplatedElementDataFromSource(element, "descriptionFontSize", $sourceStore);
$: descriptionFontWeight = getTemplatedElementDataFromSource(element, "descriptionFontWeight", $sourceStore);
$: ctaText = getTemplatedElementDataFromSource(element, "ctaText", $sourceStore) || $t("VIEW");
$: ctaLinkUrl = getTemplatedElementDataFromSource(element, "ctaLinkUrl", $sourceStore);
$: ctaFontSize = getTemplatedElementDataFromSource(element, "ctaFontSize", $sourceStore);
$: ctaFontColor = getTemplatedElementDataFromSource(element, "ctaFontColor", $sourceStore);
$: ctaFontWeight = getTemplatedElementDataFromSource(element, "ctaFontWeight", $sourceStore);
$: publishDateTimeDisplay = formatDateTimeLocal(publishDateTime, { 'dateStyle': 'medium' });
$: style = buildInlineStyle("padding", padding, "background-color", backgroundColor, "category-font-color", categoryFontColor, "category-font-size", categoryFontSize, "category-font-weight", categoryFontWeight, "publish-date-font-color", publishDateFontColor, "publish-date-font-size", publishDateFontSize, "publish-date-font-weight", publishDateFontWeight, "title-max-lines", titleMaxLines, "title-font-color", titleFontColor, "title-font-size", titleFontSize, "title-font-weight", titleFontWeight, "description-max-lines", descriptionMaxLines, "description-font-color", descriptionFontColor, "description-font-size", descriptionFontSize, "description-font-weight", descriptionFontWeight, "cta-font-color", ctaFontColor, "cta-font-size", ctaFontSize, "cta-font-weight", ctaFontWeight);
</script>
  
  <CardBase {element}>
    <div class="vertical-media" class:hovering={false} use:addClassOnHoverAction={'hovering'} style={style}>
      <div class="header">
        {#if categoryText}<span class="category-text">{categoryText}</span>{/if}
        {#if publishDateTime}<span class="publish-date"><i class="f7-icons">clock</i>{publishDateTimeDisplay}</span>{/if}
      </div>
      {#if titleText}
      <div class="title-text">
        {titleText}
      </div>
      {/if}
      <div class="image-area">
        <img src={imageUrl} alt="">
      </div>
      {#if descriptionText}
      <div class="description-text">
        {descriptionText}
      </div>
      {/if}
      {#if ctaLinkUrl}
      <a href={ctaLinkUrl} class="cta-text">
        <i class="f7-icons content-icon">arrow_right</i>{ctaText}
      </a>
      {/if}
    </div>
  </CardBase>
  
  <style>
    *, *::before, *::after {
      box-sizing: border-box;
    }

    .vertical-media {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1em;
      background-color: var(--background-color, unset);
      padding: var(--padding, unset);
      cursor: pointer;
      width: 100%;
    }

    .image-area {
      max-width: 100%;
      overflow: hidden;
    }

    .image-area img {
      width: 100%;
      object-fit: cover;
      object-position: top center;
      transition: transform 1.0s ease;
    }

    .horizontal-media.hovering .image-area img {
      transform: scale(1.2);
    }

    .header {
      display: flex;
      align-items: center;
    }

    .header .category-text {
      color: var(--category-font-color, unset);
      font-size: var(--category-font-size, 0.9em);
      font-weight: var(--category-font-weight, 600);
      margin-right: 1em;
    }

    .header .publish-date {
      color: var(--publish-date-font-color, unset);
      font-size: var(--publish-date-font-size, 0.9em);
      font-weight: var(--publish-date-font-weight, 600);
    }

    .title-text {
      color: var(--title-font-color, unset);
      font-size: var(--title-font-size, 2em);
      font-weight: var(--title-font-weight, 800);
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: var(--title-max-lines, 3);
      line-clamp: var(--title-max-lines, 3);
      -webkit-box-orient: vertical;
    }

    .vertical-media.hovering .title-text {
      -webkit-text-decoration: underline;
      text-decoration: underline;
    }

    .description-text {
      color: var(--description-font-color, unset);
      font-size: var(--description-font-size, unset);
      font-weight: var(--description-font-weight, unset);
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: var(--description-max-lines, 5);
      line-clamp: var(--description-max-lines, 5);
      -webkit-box-orient: vertical;
    }

    .cta-text {
      display: block;
      color: var(--cta-font-color);
      font-size: var(--cta-font-size, 0.9em);
      font-weight: var(--cta-font-weight, 800);
    }

    .f7-icons {
      font-size: 0.75em;
      font-weight: bold;
      margin-right: 0.4em;
    }</style>