<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import "lodash";
import { BaseAtom } from "components/cards/atoms";
import { resolveUrl } from "js/url";
import { RichTextParticle, TextParticle, ImageParticle, } from "components/cards/particles/YinzCam";
import "svelte";
import "../ArticleBody/ArticleBodyNode";
import ArticleBodyNodeWrapper from "../ArticleBody/ArticleBodyNodeWrapper.svelte";
export let container;
export let textDefaults = undefined;
export let imageDefaults = undefined;
export let videoDefaults = undefined;
export let audioDefaults = undefined;
export let captionDefaults = undefined;
export let accordionTabs = undefined;
export let accordionGap;
export let imgOpenIcon;
export let imgCloseIcon;
export let linkUrl;
export let dividerColor;
let atomWidthPx;
let tabClicked = {};
function getHrefArray(content) {
    const urlStartString = "href";
    const urlEndString = "target";
    let arr = content === null || content === void 0 ? void 0 : content.map((ele) => {
        var _a;
        let searchString = (_a = ele === null || ele === void 0 ? void 0 : ele.content) === null || _a === void 0 ? void 0 : _a.html;
        let startIdx = (searchString === null || searchString === void 0 ? void 0 : searchString.indexOf(urlStartString)) + 6;
        let endIdx = (searchString === null || searchString === void 0 ? void 0 : searchString.indexOf(urlEndString)) - 2;
        return (searchString === null || searchString === void 0 ? void 0 : searchString.includes(urlStartString))
            ? searchString === null || searchString === void 0 ? void 0 : searchString.substring(startIdx, endIdx)
            : null;
    });
    return arr;
}
$: isMobile = atomWidthPx <= 450;
$: isTablet = atomWidthPx <= 800 && atomWidthPx > 450;
$: arrHref = accordionTabs && getHrefArray(accordionTabs);
</script>

<BaseAtom
    _isotope="AccordionAtom"
    _cssVariablePrefix="accordion-atom"
    {...container}
    {accordionGap}
    {dividerColor}
    bind:_clientWidthPx={atomWidthPx}
>
    <div
        class="accordion-container"
        class:layout-tablet-width={isTablet}
        class:layout-mobile-width={isMobile}
    >
        {#each accordionTabs || [] as tab, i}
            <div class="accordion-tab">
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <div
                    class="accordion-tab-title"
                    on:click={() => (tabClicked = { [i]: !tabClicked[i] })}
                >
                    <div class="title" class:link={tab?.linkUrl}>
                        {#if !tab?.linkUrl}
                            <TextParticle {...tab?.heading} />
                        {/if}
                        {#if tab?.linkUrl}
                            <a
                                href={resolveUrl(tab?.linkUrl).href}
                                class:external={resolveUrl(tab?.linkUrl)
                                    .external}
                                target={resolveUrl(tab?.linkUrl).external
                                    ? "_blank"
                                    : "_self"}>{tab?.heading?.text}</a
                            >
                        {/if}
                    </div>
                    {#if !tab?.linkUrl}
                        <div class="icon">
                            {#if !tabClicked[i]}
                                <ImageParticle {...imgOpenIcon} />
                            {:else}
                                <ImageParticle {...imgCloseIcon} />
                            {/if}
                        </div>
                    {/if}
                    {#if tab?.linkUrl}
                        <ImageParticle {...imgOpenIcon} icon={"yc_icon_link"} />
                    {/if}
                </div>
                <div
                    class="accordion-tab-content"
                    class:active={tabClicked[i] && !tab?.linkUrl}
                >
                    {#if tab.articleBody && tab.articleBody.length > 0}
                        <div class="article-container">
                            {#each tab.articleBody as node}
                                <ArticleBodyNodeWrapper
                                    {node}
                                    {textDefaults}
                                    {imageDefaults}
                                    {videoDefaults}
                                    {audioDefaults}
                                    {captionDefaults}
                                />
                            {/each}
                        </div>
                    {:else if tab.content}
                        <RichTextParticle {...tab.content} />
                    {/if}
                </div>
                <hr style="
                {(tab?.heading?.color)? `border-color: ${tab.heading.color}` : ''}
                "/>
            </div>
        {/each}
    </div>
</BaseAtom>

<style>
    .accordion-container {
        border-radius: 0.5rem;
        overflow: hidden;
        width: 100%;
        height: 100%;
        padding: 0 2.25rem;
    }

    .accordion-container.layout-mobile-width {
        padding: 0 1.5rem;
    }

    .accordion-tab {
        position: relative;
    }

    .accordion-tab > .accordion-tab-title {
        position: relative;
        cursor: pointer;
        padding-top: var(--accordion-atom-accordion-gap, 1rem);
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .accordion-tab > .accordion-tab-content {
        position: relative;
        display: none;
        width: 100%;
    }

    .title{
        width: 90%;
    }

    .accordion-tab-content.active {
        display: block;
        transition: all 0.2s ease-out;
        width: 90%;
    }

    .accordion-container.layout-mobile-width
        > .accordion-tab
        > .accordion-tab-title {
        display: flex;
        justify-content: space-between;
    }

    .article-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .title.link {
        display: flex;
        align-items: flex-start;
    }
    .title.link a {
            flex: 1 0 80%;
            color: var(--theme-mode-accent-color, unset);
            font-size: 1.5rem;
            font-weight: 700;
        }

    hr {
        border-width: 0.5px;
        border-style: solid;
        border-color: var(--accordion-atom-divider-color, var(--theme-mode-foreground-color, unset));
        margin: 0.5rem 0 0.25rem 0;
        opacity: 1;
    }</style>
