import { generateRootObjectSchema, generateArraySchema, generateObjectSchema, generateStringSchema, generateUrlSchema, generateColorSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { ImageParticleSpec, TextParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("Profile Header", generateRootObjectSchema({
    container: generateAtomContainerSchema({ defaultValues: { themeMode: 'primary' } }),
    overlayPadding: generateStringSchema("Overlay Padding", "Overlay Padding"),
    txtGreeting: TextParticleSpec.generateSchema("Greeting Text", "Greeting Text", {
        defaultValues: { size: "5rem", weight: "800" }
    }),
    txtInformation: TextParticleSpec.generateSchema("Info Text", "Info Text", {
        defaultValues: { size: "1.5rem", weight: "400", wrap: "wrap" }
    }),
    txtFirstName: TextParticleSpec.generateSchema("First Name", "First Name", {
        defaultValues: { size: "5rem", weight: "800", transform: "uppercase", wrap: "wrap" }
    }),
    txtLastName: TextParticleSpec.generateSchema("Last Name", "Last Name", {
        defaultValues: { size: "3rem", weight: "500", transform: "uppercase" }
    }),
    txtPlayerNumber: TextParticleSpec.generateSchema("Player Number", "Player Number", {
        defaultValues: { size: "7rem", weight: "800" }
    }),
    imgTshirt: ImageParticleSpec.generateSchema("T Shirt Image", "T Shirt Image", {
        defaultValues: {}
    }),
    imgPlayerNumberOne: ImageParticleSpec.generateSchema("First Digit", "First Digit", {
        defaultValues: { container: { height: "8rem" }, sizing: "contain" }
    }),
    imgPlayerNumberTwo: ImageParticleSpec.generateSchema("Second Digit", "Second Digit", {
        defaultValues: { container: { height: "8rem" }, sizing: "contain" }
    }),
    textContainerColor: generateColorSchema("Text Container Color", "Text Container Color"),
    textContainerPadding: generateStringSchema("Text Container Padding", "Text Container Padding"),
    textContainerBorderRadius: generateStringSchema("Text Container Border Radius", "Text Container Border Radius"),
    containerHeight: generateStringSchema("Container Height", "Container Height"),
}));
