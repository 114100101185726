import { readable, writable } from 'svelte/store';
import { v4 as uuid4 } from 'uuid';
import { Device } from 'framework7';
import _ from 'lodash';

// Stores allow components to subscribe to changes in shared state that can be updated by an external service
// Useful tutorial on readable stores in general: https://svelte.dev/tutorial/readable-stores

const screenWidthBreakpoints = [];
for (let i = 0; i < Object.values(CONFIG.breakpoints).length; i++) {
    screenWidthBreakpoints.push({
        index: i,
        maxWidth: Object.values(CONFIG.breakpoints)[i],
        title: Object.values(CONFIG.breakpointTitles)[i],
        icon: Object.values(CONFIG.breakpointIcons)[i],
        remScale: Object.values(CONFIG.breakpointRemScaling)[i],
    });
}

let _overrideWindowWidth = 0;
let _overrideWindowHeight = 0;

export function overrideWindowWidth(width) {
    _overrideWindowWidth = width;
    window.dispatchEvent(new Event('resize'));
}

export function resetWindowWidth() {
    overrideWindowWidth(0);
}

export function overrideWindowHeight(height) {
    _overrideWindowHeight = height;
    window.dispatchEvent(new Event('resize'));
}

export function resetWindowHeight() {
    overrideWindowHeight(0);
}

// This builds a "Window Metrics" object with useful size info and flags about the type of screen inferred from width/height.
// I looked at a number of articles about where the breakpoints for mobile/tablet/desktop/4K should be:
// https://devfacts.com/media-queries-breakpoints-2020/
// https://medium.com/@uiuxlab/the-most-used-responsive-breakpoints-in-2017-of-mine-9588e9bd3a8a
// https://responsivedesign.is/develop/browser-feature-support/media-queries-for-common-device-breakpoints/
// https://docs.microsoft.com/en-us/windows/uwp/design/layout/screen-sizes-and-breakpoints-for-responsive-design
// https://www.freecodecamp.org/news/css-media-queries-breakpoints-media-types-standard-resolutions-and-more/
// https://www.freecodecamp.org/news/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862/
// In the end, the last one did a clustering analysis on several common devices and found breakpoints with a good buffer on each side,
// so I went with that one.
function buildWindowMetrics() {
    const wm = {};

    // base width and height
    wm.width = _overrideWindowWidth || document.documentElement.clientWidth;
    wm.height = _overrideWindowHeight || document.documentElement.clientHeight;
    
    // base width and height
    wm.isPortrait = wm.width < wm.height;
    wm.isLandscape = wm.width >= wm.height; // want portrait to be !landscape
    wm.isSquare = wm.width === wm.height; // not sure if this is useful

    if (screenWidthBreakpoints.length > 0) {
        let breakpointIndex = screenWidthBreakpoints.findIndex((swb) => wm.width <= swb.maxWidth);
        if (breakpointIndex === -1) {
            breakpointIndex = screenWidthBreakpoints.length - 1;
        }
        wm.breakpoint = screenWidthBreakpoints[breakpointIndex];
    }
    
    return wm;
}

// A store for the window width/height that's updated when the JS event fires for window size change
// More info: https://developer.mozilla.org/en-US/docs/Web/API/Window/resize_event

export const windowMetrics = readable(buildWindowMetrics(), function start(set) {
    function resizeListener() {
        set(buildWindowMetrics());
    };
    const debouncedResizeListener = _.debounce(resizeListener, 200, { leading: true, maxWait: 1000 });
    window.addEventListener('resize', debouncedResizeListener);

    function stopHandler() {
        window.removeEventListener('resize', debouncedResizeListener);
    }
	return stopHandler;
});

export const windowVisibility = readable(true, function start(set) {
    function pageHideListener() {
        set(false);
    };
    function pageShowListener() {
        set(true);
    };
    function windowBlurListener() {
        set(false);
    };
    function windowFocusListener() {
        set(true);
    };
    function visibilityListener() {
        var hidden = false;
        if (typeof document.hidden !== "undefined") {
            hidden = document.hidden;
        } else if (typeof document.mozHidden !== "undefined") {
            hidden = document.mozHidden;
        } else if (typeof document.msHidden !== "undefined") {
            hidden = document.msHidden;
        } else if (typeof document.webkitHidden !== "undefined") {
            hidden = document.webkitHidden;
        } else {
            return;
        }
        set(!hidden);
    };

    window.addEventListener("pagehide", pageHideListener);
    window.addEventListener("pageshow", pageShowListener);
    window.addEventListener("blur", windowBlurListener);
    window.addEventListener("focus", windowFocusListener);
    document.addEventListener("visibilitychange", visibilityListener);

    function stopHandler() {
        window.removeEventListener("pagehide", pageHideListener);
        window.removeEventListener("pageshow", pageShowListener);
        window.removeEventListener("blur", windowBlurListener);
        window.removeEventListener("focus", windowFocusListener);
        document.removeEventListener("visibilitychange", visibilityListener);
    }
	return stopHandler;
});

// Temporary hack to work with components that can't subscribe to this cleanly right now
export let windowVisible = true;
windowVisibility.subscribe((visibility) => {
    windowVisible = visibility;
});

export function getBrowserId() {
    let id = window.localStorage.getItem('JanusBrowserId');
    if (!id) {
        id = uuid4();
        window.localStorage.setItem('JanusBrowserId', id);
    }
    return id;
}

/*
export const browserId = readable(getBrowserId(), function start(set) {
    // way to listen to local storage cleared? maybe timer?
    set(getBrowserId());

    function stopHandler() {
        // do nothing
    }
    return stopHandler;
});
*/

export function getDeviceId() {
    let deviceId;
    // @ts-ignore
    if (Device && Device.cordova && device) {
        // @ts-ignore
        deviceId = device.uuid;
    } else {
        let id = getBrowserId();
        if (id) {
            deviceId = id;
        }
    }
    return deviceId;
}
