import { generateRootObjectSchema, generateArraySchema, generateObjectSchema, generateColorSchema, generateStringSchema, generateContainerSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { ImageParticleSpec, TextParticleSpec } from "components/cards/particles/YinzCam";

import { statsNameList } from "components/cards/utilities/constants";

export const specification = new BaseAtomSpec("MC-TopPerformersAtom", generateRootObjectSchema({
    container: generateAtomContainerSchema({ defaultValues: { fillWidth: false, fillHeight: false } }),
    containerPadding: generateStringSchema("Container Padding", "Padding for the container"),
    txtPlayerNumber: TextParticleSpec.generateSchema("Player Number", "Enter player number", {
        defaultValues: { size: "2rem", weight: "700" }
    }),
    txtPlayerName: TextParticleSpec.generateSchema("Player Name", "Enter Player Name", {
        defaultValues: { size: "1.5rem", weight: "700", lineHeight: "1" }
    }),
    txtPlayerPosition: TextParticleSpec.generateSchema("Player Position", "Enter player position", {
        defaultValues: { size: "1.25rem", weight: "400" }
    }),
    imgPlayerImage: ImageParticleSpec.generateSchema("Player Image", "Upload image of the player", {
        defaultValues: { sizing: "contain", container: { height: "20rem" } }
    }),
    imgTeamLogo: ImageParticleSpec.generateSchema("Team Logo", "Upload team logo", {
        defaultValues: { container: { width: "4rem", height: "4rem" }, sizing: "cover" }
    }),
    txtTeamTricode: TextParticleSpec.generateSchema("Team Tricode", "Enter tricode of the team"),
    statName: TextParticleSpec.generateSchema("Stat Name", "Enter stat name", {
        defaultValues: { size: "1.25rem", weight: "600", align: 'center' }
    }),
    statValue: TextParticleSpec.generateSchema("Stat Value", "Enter stat value", {
        defaultValues: { size: "2.5rem", weight: "900", align: 'center' }
    }),
    teamList: generateStringSchema("Team List", "Select a team to display stats", {
        choices: ["home", "away"],
        choiceTitles: ["Home Team", "Away Team"],
        defaultValue: "home"
    }),
    categoryList: generateStringSchema("Category List", "Select a category to display stats", {
        choices: ["Goals", "Shots", "Saves"],
        choiceTitles: ["Goals", "Shots", "Saves"],
        defaultValue: "Goals"
    }),
    leaderStatList: generateArraySchema("Stat List", "Stat List",
        generateObjectSchema("Stat Object", "Stat object", {
            categoryName: generateStringSchema("Stat Category", "Stat Category"),
            home: generateObjectSchema("Player Info", "Player Info", {
                name: generateStringSchema("Player Name", "Player Name"),
                number: generateStringSchema("Player Number", "Player Number"),
                imageURL: generateStringSchema("Player Image URL", "Player Image Url"),
                position: generateStringSchema("Player Position", "Player Position"),
                value: generateStringSchema("Stat Value", "Stat Value"),
                teamLogo: generateStringSchema("Team Logo", "Team Logo")
            }),
            away: generateObjectSchema("Player Info", "Player Info", {
                name: generateStringSchema("Player Name", "Player Name"),
                number: generateStringSchema("Player Number", "Player Number"),
                imageURL: generateStringSchema("Player Image URL", "Player Image Url"),
                position: generateStringSchema("Player Position", "Player Position"),
                value: generateStringSchema("Stat Value", "Stat Value"),
                teamLogo: generateStringSchema("Team Logo", "Team Logo")
            }),
        }
        )),
}));