import { injectable, Container } from "inversify";
import { injectToken, getToken, Token, TokenContainerModule } from "inversify-token";
import { YinzCamInjectModule } from 'yinzcam-inject';
import { Device } from "framework7";
import _ from "lodash";
import Axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { JanusModeContextManager, JanusModeContextManagerToken } from "./mode";
import { get, Readable, writable, Writable } from "svelte/store";
import { f7ready } from 'framework7-svelte';
import { resolveUrl } from "./url";

export const JanusPushManagerToken = new Token<JanusPushManager>(Symbol.for("JanusPushManager"));

export let JanusPushManagerModule: YinzCamInjectModule = new YinzCamInjectModule((container: Container): void => {
  container.load(new TokenContainerModule((bindToken) => {
    bindToken(JanusPushManagerToken).to(JanusPushManager).inSingletonScope();
  }));
});

// temporary
const PUSH_CATEGORIES = [
  { tag: 'ALERTS', label: 'Alertas de partido del equipo que sigues' },
  { tag: 'NEWS', label: 'Notificaciones del equipo que sigues' },
  { tag: 'TOURNAMENT_NEWS', label: 'Noticias del torneo' },
];
/*
const PUSH_CATEGORIES = [
  { tag: 'YC_TEST_4', label: 'Test push notifications' },
];
*/

export interface JanusPushCategory { 
  tag: string;
  label: string;
}

export interface JanusPushCategoryStatus extends JanusPushCategory {
  enabled: boolean;
}

export interface JanusPushStatus {
  enabled: boolean;
  categories: JanusPushCategoryStatus[];
}

@injectable()
export class JanusPushManager {

  private readonly pushAvailable: boolean;
  private readonly axios: AxiosInstance;
  private categoriesAvailable: JanusPushCategory[];
  private registrationInProgress: boolean;
  private pushState: {
    enabled: boolean,
    token: string,
    tags: string[]
  };
  private pushControl: any;
  private pushStatus: Writable<JanusPushStatus>;
  private langId;

  public constructor (
    @injectToken(JanusModeContextManagerToken) private readonly modeManager: JanusModeContextManager) {
    this.categoriesAvailable = PUSH_CATEGORIES;
    this.pushAvailable = Device.cordova && (Device.android || Device.ios);
    this.registrationInProgress = false;
    this.axios = Axios.create({
      timeout: CONFIG.defaultRequestTimeoutSeconds * 1000,
      headers: { Accept: 'application/json' }
    });
    try {
      let pushStateJson = window.localStorage.getItem('JanusPushManager_pushState');
      if (pushStateJson) {
        this.pushState = JSON.parse(pushStateJson);
      } else {
        this.pushState = { enabled: false, token: null, tags: [] };
      }
    } catch (err) {
      this.pushState = { enabled: false, token: null, tags: [] };
    }
    this.pushStatus = writable({ enabled: false, categories: [] });
    // setCategoriesAvailable has to be called after loading push state and push status
    this.setCategoriesAvailable(PUSH_CATEGORIES); // TODO: make dynamic
    this.savePushState();
    // TODO: Move this into some other component that injects non-user-selectable push tags
    this.modeManager.getTeamIdComponent().store.subscribe((teamId) => {
      //console.log(`JanusPushManager: team ID changed to ${teamId}, requesting register`);
      this.requestRegister();
    });
    this.modeManager.getLanguageComponent().store.subscribe((langId) => {
      //console.log(`JanusPushManager: language ID changed to ${langId}, requesting register`);
      this.langId = langId;
      this.requestRegister();
    });
  }

  private removeUnavailablePushTags() {
    this.pushState.tags = this.pushState.tags.filter(tag => this.categoriesAvailable.find(cat => cat.tag === tag));
    this.savePushState();
    this.requestRegister();
  }

  private setCategoriesAvailable(cats: JanusPushCategory[]) {
    this.categoriesAvailable = cats;
    this.removeUnavailablePushTags();
  }

  private allowPushAction() {
    return this.pushAvailable && this.pushState.enabled;
  }

  private savePushState() {
    window.localStorage.setItem('JanusPushManager_pushState', JSON.stringify(this.pushState));
    this.pushStatus.set({
      enabled: this.pushState.enabled,
      categories: this.getAvailablePushCategories().map(cat => {
        return {
          tag: cat.tag,
          label: cat.label,
          enabled: this.isPushTagEnabled(cat.tag)
        };
      })
    });
  }

  private initPush(cb: () => void) {
    if (this.pushControl) {
      // already initialized
      cb();
      return;
    }
    //console.log("JanusPushManager: initializing push");
    // @ts-ignore
    this.pushControl = PushNotification.init({
      "android": {
        "forceShow": true,
        "icon": "app_push",
        "iconColor": "grey"
      },
      "ios": {
        "alert": true,
        "badge": true, 
        "vibration": true,
        "sound": true
      }, 
      "windows": {},
      "browser": {} 
    });
    this.pushControl.on('registration', (data) => {
      let token = data?.registrationId;
      if (!token) {
        return;
      }
      this.pushState.token = token;
      //console.log("JanusPushManager: got token", token);
      this.savePushState();
      cb();
    });
    
    this.pushControl.on('notification', (data) => {
      if (!data) {
        //console.log("JanusPushManager: received push notification with empty data, ignoring");
        return;
      }
      /* fields: message, title, count, sound, image, additionalData */
      //console.log("JanusPushManager: received push notification", data.message, JSON.stringify(data.additionalData));
      if (!data.additionalData) {
        //console.log("JanusPushManager: received push notification without additional data, ignoring");
        return;
      }
      const details = data.additionalData;
      const action = details.action;
      const resolved = (action)? resolveUrl(action) : null;
      f7ready((f7) => {
        function launchPushTarget() {
          //console.log('JanusPushManager: launch push target', JSON.stringify(resolved));
          if (resolved) {
            resolved.func();
          }
        };

        if (details.foreground) {
          //console.log('JanusPushManager: create foreground notification');
          f7.notification.create({
            icon: '<img src="static/icons/128x128.png">',
            title: f7.name,
            titleRightText: 'now',
            subtitle: data.title,
            text: data.message,
            closeOnClick: true,
            closeTimeout: 5000,
            on: {
              click: function () {
                //console.log('JanusPushManager: notification opened (foreground)');
                launchPushTarget();
              }
            },
          }).open();
        } else {
          setTimeout(() => {
            //console.log('JanusPushManager: notification opened (background)');
            launchPushTarget();
          }, 500);
        }
      });
    });
  }

  private finiPush(cb: () => void) {
    if (!this.pushControl) {
      // already finalized
      cb();
      return;
    }
    //console.log("JanusPushManager: finalizing push");
    try {
      this.pushControl.unregister(() => {
        //console.log("JanusPushManager: push finalized");
        this.pushControl = null;
        cb();
      }, () => {
        //console.log('JanusPushManager: error while unregistering for push');
      });
    } catch (e) {
      //console.log('JanusPushManager: error while calling unregister', e);
      cb();
    }
  }

  public getPushStatusStore(): Readable<JanusPushStatus> {
    return this.pushStatus;
  }

  public isPushAvailable(): boolean {
    return this.pushAvailable;
  }

  public handleAppLaunch() {
    if (!this.allowPushAction()) {
      return;
    }
    this.initPush(() => this.requestRegister(true));
  }

  public isPushEnabled(): boolean {
    return this.pushState.enabled;
  }

  // this can be called multiple times
  public enablePush(tags?: string[]) {
    if (!this.pushAvailable) {
      return;
    }
    this.pushState.enabled = true;
    if (!_.isNil(tags)) {
      this.pushState.tags = _.uniq(tags);
    }
    this.savePushState();
    this.initPush(() => this.requestRegister(true));
  }

  public disablePush() {
    if (!this.allowPushAction()) {
      return;
    }
    this.pushState.enabled = false;
    this.savePushState();
    // There seems to be cases where the callback isn't run by the plugin.
    // For now, just call finiPush async and request register separately.
    this.finiPush(() => {});
    this.requestRegister(true);
  }

  public togglePushEnabled() {
    if (this.isPushEnabled()) {
      this.disablePush();
    } else {
      this.enablePush();
    }
  }

  public getAvailablePushCategories(): JanusPushCategory[] {
    return this.categoriesAvailable;
  }

  public getEnabledPushTags(): string[] {
    return this.pushState.tags;
  }

  public isPushTagEnabled(tag: string): boolean {
    return this.pushState.tags.includes(tag);
  }

  public enableAllPushTags() {
    this.setPushTags(this.getAvailablePushCategories().map(cat => cat.tag));
  }

  public disableAllPushTags() {
    this.setPushTags([]);
  }

  public enablePushTag(tag: string) {
    // TODO check if available?
    this.pushState.tags.push(tag);
    this.pushState.tags = _.uniq(this.pushState.tags);
    this.savePushState();
    this.requestRegister();
  }

  public disablePushTag(tag: string) {
    let idx = this.pushState.tags.indexOf(tag);
    if (idx >= 0) {
      this.pushState.tags.splice(idx, 1);
    }
    this.savePushState();
    this.requestRegister();
  }

  public togglePushTag(tag: string) {
    let idx = this.pushState.tags.indexOf(tag);
    if (idx >= 0) {
      this.pushState.tags.splice(idx, 1);
    } else {
      this.pushState.tags.push(tag);
      // don't need to uniq because we know it's not in there
    }
    this.savePushState();
    this.requestRegister();
  }

  public setPushTags(tags: string[]) {
    // TODO check if available?
    this.pushState.tags = _.uniq(tags);
    this.savePushState();
    this.requestRegister();
  }

  private requestRegister(force: boolean = false) {
    if (this.registrationInProgress || (!this.allowPushAction() && !force)) {
      return;
    }
    this.registrationInProgress = true;
    setTimeout(async () => {
      try {
        await this.doRegister();
      } catch (e) {
        //console.log("JanusPushManager: last-chance exception in doRegister timer callback", e);
      } finally {
        this.registrationInProgress = false;
      }      
    }, 5000); /* time delay chosen so that other push changes can happen within the window */
  }

  private async doRegister() {
    // nothing we can do without the push token
    if (!this.pushState.token) {
      return;
    }
    // @ts-ignore
    const deviceId = device.uuid;
    const appId = `${CONFIG.league}_${CONFIG.tricode}`.toUpperCase();
    const teamId = get(this.modeManager.getTeamIdComponent().store);
    const tags = [ ...this.pushState.tags ];
    if (teamId) {
      tags.push(`TEAMID_${teamId}`);
    }
    let axiosReq: AxiosRequestConfig = {
      url: `https://push-${CONFIG.tricode}-${CONFIG.league}.yinzcam.com/register`,
      method: 'POST',
      headers: { 'X-YinzCam-AppId': appId, 'Content-Type': 'application/json' }, // finalHeaders,
      params: { 'application': appId },
      data: {
        "id": (Device.ios)? "0f607264fc6318a92b9e13c65db7cd3c" : deviceId,
        "platform": (Device.android)? "ANDROID" : "iOS",
        "app_id": appId,
        "version": (Device.ios)? "1.0" : "2.0",
        "token": this.pushState.token,
        "tags": (this.pushState.enabled)? tags.join(",") : "",
        "install_id": deviceId,
        "vendor_id": deviceId
        //"YID": "913a625e-e4d2-4e9e-a7d1-4ad933440177"
      }
    };
    if (this.langId) {
      axiosReq.headers['Accept-Language'] = this.langId;
    }
    //console.log('JanusPushManager: making registration request', JSON.stringify(axiosReq));
    let rsp = await this.axios.request(axiosReq);
    //console.log('JanusPushManager: received registration response', JSON.stringify(rsp));
  }
}
