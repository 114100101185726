<CardBase {element}>
  <div style="
    width: {width || 'auto'};
    height: {height || 'auto'};
  ">
  </div>
</CardBase>

<style>
  *, *::before, *::after {
    box-sizing: border-box;
  }</style>

<script lang="ts">import "svelte/store";
import "yinzcam-cards";
import CardBase from "components/cards/common/CardBase.svelte";
import "components/cards/common/CardsDataSourceRegistration";
export let element;
export let sources;
export let width = null;
export let height = null;
</script>
