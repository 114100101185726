import { generateRootObjectSchema, generateArraySchema, generateObjectSchema, generateStringSchema, generateUrlSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { ImageParticleSpec, TextParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("Next Match Card", generateRootObjectSchema({
    container: generateAtomContainerSchema({ defaultValues: { themeMode: 'primary' } }),
    imgLeaguelogo: ImageParticleSpec.generateSchema("Competition logo", "Upload a competition logo", {
        defaultValues: { container: { width: "4rem", height: "4rem" }, sizing: "contain" }
    }),
    imgOpponentLogo: ImageParticleSpec.generateSchema("Opponent Logo", "Upload an opponent logo", {
        defaultValues: { container: { width: "4rem", height: "4rem" }, sizing: "contain" }
    }),
    txtNextMatch: TextParticleSpec.generateSchema("Next Match Text", "Paragraph text | defaultValue: Next Match", {
        defaultValues: { size: "1.5rem", weight: "700", text: "Next Match |" }
    }),
    txtMatchDate: TextParticleSpec.generateSchema("Match Date", "Specify date of next match", {
        defaultValues: { size: "1.5rem", weight: "700", }, uneditableFields: { text: true }
    }),
    txtOpponentTricode: TextParticleSpec.generateSchema("Opponent Tricode", "Specify tricode of opponent team", {
        defaultValues: {
            size: "1.5rem", weight: "700"
        }
    }),
    txtHomeOrAwayCode: TextParticleSpec.generateSchema("Home or Away Code", "Home or away match", {
        defaultValues: { size: "1.5rem", weight: "700" }, uneditableFields: { text: true }
    }),
    optionHomeOrAway: generateStringSchema("Home Or Away", "Select if the match is home or away match"),
    matchDateTime: generateStringSchema("Next Match Date", "Specify date of next match"),
    txtGreaterThanSymbol: TextParticleSpec.generateSchema("Symbol", "Symbol", {
        defaultValues: {
            size: "1.5rem",
            weight: "700",
            text: ">",
        }
    })
}));
