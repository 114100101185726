import { Token } from 'inversify-token';
import { ReactiveMicrocomponent } from 'lib/yinzcam-rma';
import { YinzCamAPI, YinzCamAPIRequest, YinzCamServer, YinzCamAPIResponse } from 'yinzcam-api';

export const YinzCamAppServerToken = new Token<YinzCamAppServer>(Symbol.for("YinzCamAppServer"));

export abstract class YinzCamAppServer {
  private readonly server: YinzCamServer;

  public constructor(private readonly api: YinzCamAPI, private readonly pathPrefix: string) {
    this.server = api.getServer('app', pathPrefix);
  }

  public singleRequest(request: YinzCamAPIRequest) {
    return this.server.singleRequest(request);
  }

  public getRequest(request: YinzCamAPIRequest) {
    return this.server.getRequest(request);
  }

  public getHomeIndex(): ReactiveMicrocomponent<YinzCamAPIResponse> {
    return this.server.getRequest({ path: 'Home/Index' });
  }

  public getMediaShortList(): ReactiveMicrocomponent<YinzCamAPIResponse> {
    return this.server.getRequest({ path: 'Media/ShortList' });
  }

  public getGameList(): ReactiveMicrocomponent<YinzCamAPIResponse> {
    return this.server.getRequest({ path: 'Game/List' });
  }

  public getPlayers(): ReactiveMicrocomponent<YinzCamAPIResponse> {
    return this.server.getRequest({ path: 'Team/Players' });
  }

  public getPlayerID(id: string): ReactiveMicrocomponent<YinzCamAPIResponse> {
    console.log(this.server,'/Stats/Player/${id}');
    return this.server.getRequest({ path: `/Stats/Player/${id}` });
  }

  public getMediaNews(id: string): ReactiveMicrocomponent<YinzCamAPIResponse> {
    return this.server.getRequest({ path: `Media/News/${id}` });
  }

  //yinzchat
  public saveNewGroupRequest(data: any): Promise<YinzCamAPIResponse> {
    console.log('create new group: ', data);
    return this.server.singleRequest({method: 'post', path: `chat`, data});
  }

  public saveNewChatRequest(data: any): Promise<YinzCamAPIResponse> {
    console.log('create new chat: ', data);
    return this.server.singleRequest({method:'post', path: `/drops/${data.Drop_UUID}/messages`, data: {ChatLogItem: data.ChatLogItem}});
  }

  //pga
  public postBet(data: {oddsId: string, betAmount: number}): Promise<YinzCamAPIResponse> {
    console.log('send new bet: ', data);
    return this.server.singleRequest({method:'post', path: `/bet`, data});
  }

  public getLeaderboardBets(userId: string): Promise<YinzCamAPIResponse> {
    return this.server.singleRequest({method:'get', path: `/leaderboard/bets/${userId}`});
  }

  public postRemovePick(data: {oddsId: string}) : Promise<YinzCamAPIResponse> {
    return this.server.singleRequest({method:'delete', path: `/bet`, data});
  }

  public async getTicket(ssoTicket: string) {
    const data = { "UMS": { "Ticket": `${ssoTicket}` } };
    const ticket = await this.server.singleRequest({
      path: `/ticket`,
      method: 'POST',
      data
    });
    return ticket;
  }

  public getUserProfile(): Promise<YinzCamAPIResponse> {
    return this.server.singleRequest({method:'get', path: `/user`});
  }
}
