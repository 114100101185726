import { generateArraySchema, generateBooleanSchema, generateColorSchema, generateObjectSchema, generateRootObjectSchema, generateStringSchema, generateUrlSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema, } from "components/cards/atoms/BaseAtomSpec";
import { TextParticleSpec, ImageParticleSpec, ButtonParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("Player Roster - Flip", generateRootObjectSchema({
    container: generateAtomContainerSchema({ defaultValues: { container: {}, width: "16.5rem" } }),
    imgPlayerImage: ImageParticleSpec.generateSchema("Player Image", "Upload/specify player image", {
        defaultValues: { sizing: 'cover', container: { padding: "0.25rem" }, role: 'general' }
    }),
    txtFirstName: TextParticleSpec.generateSchema("First Name", "Enter player first name (card front)", {
        defaultValues: { size: "1.75rem", weight: "900", transform: "uppercase", color: "var(--theme-mode-highlight-color, unset)" }
    }),
    txtLastName: TextParticleSpec.generateSchema("Last Name", "Enter player last name (card front)", {
        defaultValues: { size: "1.75rem", weight: "900", color: "var(--theme-mode-background-color, unset)", transform: "uppercase" }
    }),
    txtFullName: TextParticleSpec.generateSchema("Full Name", "Enter player full name (card back)", {
        defaultValues: { size: "1.25rem", weight: "700" }
    }),
    txtPlayerNumber: TextParticleSpec.generateSchema("Player Number", "Enter player number", {
        defaultValues: { size: "3rem ", weight: "900" }
    }),
    txtPlayerPosition: TextParticleSpec.generateSchema("Player Position", "Enter player position", {
        defaultValues: { size: "1rem", weight: "400" }
    }),
    txtBodyText: TextParticleSpec.generateSchema("Body Text", "Enter player summary", {
        defaultValues: { size: "1rem", weight: "400", lineClamp: 3, align: "left" }
    }),
    btnPlayerBio: ButtonParticleSpec.generateSchema("Player Bio", "View player bio", {
        defaultValues: {
            text: { size: "0.875rem", weight: "600" },
            container: {
                themeMode: "inverted", padding: "0.625rem 1.25rem",
                border: { visible: true, radius: "0.375rem" },
                background: { visible: true }
            }
        }
    }),
    colorFlag: generateColorSchema("Flag Color", "Specify a color for number flag"),
    colorCardBorder: generateColorSchema("Card Border Color", "Specify a color for card border"),
    colorBGShadow: generateColorSchema("Shadow Color", "Pick a shadow color"),
    colorPillBorder: generateColorSchema("Pill Border Color", "Pick a color for pill border"),
    playerStats: generateArraySchema("Stats List", "The list of stats and values to display.",
        generateObjectSchema("Stat", "Stat like Goals, Achievements etc", {
            name: TextParticleSpec.generateSchema("Stat Name", "Add a stat name", {
                defaultValues: { lineClamp: 2, wrap: "wrap" }
            }),
            value: TextParticleSpec.generateSchema("Stat Value", "Add a stat value", {
                defaultValues: {
                    size: "1.25rem",
                    weight: "700",
                }
            })
        })
    ),
    colorTurnIcon: generateColorSchema("Turn Icon Color", "Select icon for turn icon"),
    colorPlayerNumber: generateColorSchema("Player Number Color", "Pick a color for player number"),
}));
