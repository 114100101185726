import { Token } from 'inversify-token';
import { YinzCamAPIRequestParameterComponent, YinzCamAPIRequestParameters } from 'yinzcam-api';
import { ManualPassthrough } from 'yinzcam-rma';

export const YinzCamSegmentationRequestParameterComponentToken = new Token<YinzCamSegmentationRequestParameterComponent>(Symbol.for("YinzCamSegmentationRequestParameterComponent"));

export class YinzCamSegmentationRequestParameterComponent extends ManualPassthrough<YinzCamAPIRequestParameters>
  implements YinzCamAPIRequestParameterComponent {

  public constructor(name: string) {
    super(name);
  }
}
