import { generateBooleanSchema, generateColorSchema, generateRootObjectSchema, generateStringSchema, generateArraySchema, generateObjectSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { TextParticleSpec, ImageParticleSpec, ButtonParticleSpec } from "components/cards/particles/YinzCam";
import { statsNameList } from "components/cards/utilities/constants";

export const specification = new BaseAtomSpec("Pass Accuracy Card", generateRootObjectSchema({
    container: generateAtomContainerSchema({}),
    overlayPadding: generateStringSchema("Overlay Padding", "The padding (inset) of the overlay text/buttons relative to the background image.", {
        defaultValue: '3rem'
    }),
    txtHeader: TextParticleSpec.generateSchema("Title Text", "The text for the title at the top of the card.", {
        defaultValues: { text: "Successful Passes by Area", size: "1.25rem", weight: "600" }
    }),
    txtOpponentPercent: TextParticleSpec.generateSchema("Opponent Percent", "Enter opponent accuracy %", {
        defaultValues: { size: "2.5rem", weight: "900" }
    }),
    txtOpponentHalf: TextParticleSpec.generateSchema("Opponent Half", "Opponent Half", {
        defaultValues: { size: "1.25rem", weight: "600", text: "Opponent Half" }
    }),
    txtOwnPercent: TextParticleSpec.generateSchema("Own Percent", "Enter own accuracy %", {
        defaultValues: { size: "2.5rem", weight: "900" }
    }),
    txtOwnHalf: TextParticleSpec.generateSchema("Own Half", "Own Half", {
        defaultValues: { size: "1.25rem", weight: "600", text: "Own Half" }
    }),
    imgPlayground: ImageParticleSpec.generateSchema("Field Background", "Specify a background image"),
    colorOwnProgress: generateColorSchema("Own Field Overlay Color", "Pick a color for own field overlay"),
    colorOpponentProgress: generateColorSchema("Opponent Field Overlay Color", "Pick a color for opponent field overlay"),
    colorPercentBoxBorder: generateColorSchema("Percentage Box Border Color", "Pick a color for percentage box border"),
    colorPercentBox: generateColorSchema("Percentage Box Background Color", "Pick a color for percentage box background"),
    statsListLeft: generateStringSchema("Left Side Stat", "Select Stat to display", {
        choices: statsNameList,
        choiceTitles: statsNameList,
    }),
    statsListRight: generateStringSchema("Right Sie Stat", "Select Stat to display", {
        choices: statsNameList,
        choiceTitles: statsNameList,
    }),
    playerStats: generateArraySchema("-NA-", "Specify the number of cards to be created",
        generateObjectSchema("Stat Number Card", "Enter Stat Name and Value", {
            name: TextParticleSpec.generateSchema("Stat Name", "Enter stat name", {
                defaultValues: { size: "1.25rem", weight: "600", align: 'center' }
            }),
            homeValue: TextParticleSpec.generateSchema("Stat Value", "Enter stat value", {
                defaultValues: { size: "2.5rem", weight: "900", align: 'center' }
            }),
        }),
    )
}));
