import { Container, interfaces } from "inversify";
import { Token, TokenContainerModule } from "inversify-token";
import { YinzCamInjectModule } from "yinzcam-inject";
import { Logger } from "loglevel";
import { Logging } from "./Logging";

export const LoggerToken = new Token<Logger>(Symbol.for("Logger"));
//type YinzCamAPIToken = TokenType<typeof YinzCamAPIToken>;

export let LoggerModule: YinzCamInjectModule = new YinzCamInjectModule((container: Container): void => {
  container.load(new TokenContainerModule((bindToken) => {
    // see: https://github.com/inversify/InversifyJS/issues/633
    // see: https://github.com/inversify/InversifyJS/issues/576
    bindToken(LoggerToken).toDynamicValue((context: interfaces.Context) => {
      const injectingClassName = context.currentRequest.parentRequest &&
        context.currentRequest.parentRequest.bindings.length &&
        context.currentRequest.parentRequest.bindings[0].implementationType &&
        (context.currentRequest.parentRequest.bindings[0].implementationType as any).name
      const namedMetadata = context.currentRequest.target.getNamedTag();
      const name = namedMetadata? namedMetadata.value : (injectingClassName? injectingClassName : "default");
      return Logging.getLogger(name);
    });
  }));
});
