<YCPage query={pageQuery}>
  <CardsPage {listName} {pathParams} {pageQuery} {pageData} enableEditorModeOnMount={true}
    {onPageDataUpdate} {onFileUpload} {onDisableSave} {onEnableSave} {onDisableFullscreen} {onEnableFullscreen}
    updater={(page) => pageData.set(page)} />
</YCPage>

<script lang='ts'>import YCPage from '../components/YCPage.svelte';
import CardsPage from '../components/cards/common/CardsPage.svelte';
import { onDestroy, onMount } from 'svelte';
import { writable, get } from 'svelte/store';
import 'yinzcam-cards';
import _ from 'lodash';
import '../components/cards/common/CardsEditorComponentInterfaces';
export let f7route;
const listName = '__builder__'; // check if can remove this
const pathParams = []; // check if can remove this
const pageQuery = f7route.query;
const pageData = writable({});
const fileUploads = {};
function sendMessage(message) {
    var _a;
    // sanitize message by removing any property starting with __
    const payload = JSON.stringify(message, (k, v) => {
        return (k === null || k === void 0 ? void 0 : k.startsWith('__')) ? undefined : v;
    });
    (_a = window === null || window === void 0 ? void 0 : window.parent) === null || _a === void 0 ? void 0 : _a.postMessage(payload, '*');
}
const onPageDataUpdate = _.debounce(() => {
    const data = get(pageData);
    let filtered;
    try {
        filtered = JSON.parse(JSON.stringify(data));
    }
    catch (e) {
        alert('Unable to save page data - your changes may be lost.');
    }
    //console.log('PAGE BUILDER DATA UPDATED', filtered);
    sendMessage({ type: "STORE_PAGE", data: { pageData: filtered } });
}, 1000);
const onFileUpload = (id, name, dataUri, cbs) => {
    fileUploads[id] = { id, cbs }; // no need to keep name or dataUri around, just takes up memory
    sendMessage({ type: "UPLOAD_FILE", data: { id, name, dataUri } });
    cbs.onFileUploadStarted(id);
};
const onDisableSave = () => {
    sendMessage({ type: "DISABLE_SAVE" });
};
const onEnableSave = () => {
    sendMessage({ type: "ENABLE_SAVE" });
};
const onDisableFullscreen = () => {
    sendMessage({ type: "DISABLE_FULLSCREEN" });
};
const onEnableFullscreen = () => {
    sendMessage({ type: "ENABLE_FULLSCREEN" });
};
function handleFileUploadMessage(message, cb) {
    var _a;
    const fu = fileUploads[(_a = message.data) === null || _a === void 0 ? void 0 : _a.id];
    if (!fu) {
        console.error("PageBuilder: received message for unknown file upload", message);
        return;
    }
    cb(message.data, fu.cbs);
}
function handleIncomingMessage(e) {
    var _a;
    const payload = e.data;
    if (typeof payload !== 'string' || payload.charAt(0) !== '{') {
        // sometimes browsers will send down garbage
        return;
    }
    const message = JSON.parse(payload);
    //console.log('PAGE BUILDER RECEIVED MESSAGE', message);
    switch (message.type) {
        case 'LOAD_PAGE':
            pageData.set(((_a = message.data) === null || _a === void 0 ? void 0 : _a.pageData) || {});
            break;
        case 'FILE_UPLOAD_PROGRESS':
            handleFileUploadMessage(message, (data, cbs) => cbs.onFileUploadProgress(data.id, data.percent));
            break;
        case 'FILE_UPLOAD_COMPLETE':
            handleFileUploadMessage(message, (data, cbs) => cbs.onFileUploadComplete(data.id, data.url));
            break;
        case 'FILE_UPLOAD_ERROR':
            handleFileUploadMessage(message, (data, cbs) => cbs.onFileUploadError(data.id, data.reason));
            break;
        default:
            console.warn("PageBuilder: unrecognized message type", message.type);
            break;
    }
}
onMount(() => {
    // set up communication with the CMS
    window.addEventListener('message', handleIncomingMessage);
    sendMessage({ type: 'HELLO' });
});
onDestroy(() => {
    window.removeEventListener('message', handleIncomingMessage);
});
</script>
