{#if layer}
<TreeviewItem bind:this={tvItem} toggle={hasChildren} selected={selected} selectable onClick={handleSelect}>
  <span class="item-prefix-icon" class:selected={selected} slot="content-start">
    <img src="https://static.yinzcam.com/images/disco/layer_{layer?.options?.componentTypeName?.toLowerCase()}.svg" alt={layer?.options?.componentTypeName?.toLowerCase()}>
  </span>
  <div bind:this={tvItemContent} slot="content" contenteditable={renaming} on:click={onRename} on:keypress={onRenameKeypress} on:blur={onRenameEnd}>
    {name}
  </div>
  {#each layer.children as childLayer (childLayer.id) }
    <svelte:self layer={childLayer} on:open-to-root={handleOpenToRoot} />
  {/each}
</TreeviewItem>
{/if}

<style>
  .item-prefix-icon {
    width: 16px;
    height: 24px;
    margin-right: 4px;
  }

  .item-prefix-icon > img {
    width: 100%;
    height: 100%;
  }

  .item-prefix-icon:not(.selected) {
    -webkit-filter: opacity(0.3);
            filter: opacity(0.3);
  }

  .item-prefix-icon.selected {
    -webkit-filter: invert();
            filter: invert();
  }</style>

<script lang="ts">var _a;
import { f7, TreeviewItem } from 'framework7-svelte';
import './CardsEditorComponentInterfaces';
import { CardsPageContextKey } from './context';
import { getContext, createEventDispatcher } from 'svelte';
import 'lodash';
import { getEditorDisplayName } from '../utilities';
const dispatch = createEventDispatcher();
const pageContext = getContext(CardsPageContextKey);
const selectedComponentStore = pageContext.editorModeManager.getSelectedComponentStore();
export let layer = null;
let tvItem;
let tvItemContent;
$: selectedComponentId = $selectedComponentStore === null || $selectedComponentStore === void 0 ? void 0 : $selectedComponentStore.id;
$: selected = (layer === null || layer === void 0 ? void 0 : layer.id) === selectedComponentId;
$: hasChildren = ((_a = layer === null || layer === void 0 ? void 0 : layer.children) === null || _a === void 0 ? void 0 : _a.length) > 0;
//$: console.log('SEQ ID', layer?.sequenceId);
//$: index = parseInt(layer?.sequenceId?.slice(-CONFIG.cardsSequenceNumberDigits));
$: name = getEditorDisplayName(layer === null || layer === void 0 ? void 0 : layer.options);
function handleSelect(e) {
    if (f7.$(e.target).is('.treeview-toggle')) {
        return;
    }
    pageContext.editorModeManager.setSelectedComponentId(layer === null || layer === void 0 ? void 0 : layer.id);
}
function handleOpenToRoot(e) {
    if (tvItem && tvItemContent && !tvItem.opened) {
        const tvItemNode = tvItemContent.closest('.treeview-item');
        f7.treeview.open(tvItemNode);
    }
    dispatch('open-to-root', e);
}
$: if (tvItem && tvItemContent && selected) {
    if (!tvItem.opened) {
        const tvItemNode = tvItemContent.closest('.treeview-item');
        f7.treeview.open(tvItemNode);
        dispatch('open-to-root', {});
        tvItemNode.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    }
}
/*
$: if (tvItem && tvItemContent && !selected) {
  if (tvItem.opened) {
    const tvItemNode = tvItemContent.closest('.treeview-item');
    f7.treeview.close(tvItemNode);
  }
}
*/
let renaming = false;
let originalName = null;
function onRename(e) {
    originalName = e.target.innerText;
    renaming = true;
}
function onRenameKeypress(e) {
    if (e.keyCode === 13) {
        e.preventDefault();
        onRenameEnd(e);
    }
}
function onRenameEnd(e) {
    if (renaming) {
        renaming = false;
        if (originalName !== e.target.innerText) {
            layer.options.component.displayName = e.target.innerText;
            layer.onUpdate(layer.options.component);
            pageContext.editorModeManager.notifyComponentDataUpdate('LAYERS', layer.options.componentId);
        }
    }
}
pageContext.editorModeManager.registerForComponentDataUpdate((event) => {
    var _a;
    if ((event === null || event === void 0 ? void 0 : event.source) !== 'LAYERS' && (event === null || event === void 0 ? void 0 : event.componentId) === ((_a = layer === null || layer === void 0 ? void 0 : layer.options) === null || _a === void 0 ? void 0 : _a.componentId)) {
        layer = layer;
    }
});
</script>
