<script context="module" lang="ts">import 'json-schema';
export function getDisplayName() {
    return "Media: Regular Horizontal A";
}
export function getElementConfigSpec() {
    return {
        type: "object",
        properties: {
            title: {
                type: "string",
                title: "Title Text",
                footer: "The main title of the card."
            },
            footer: {
                type: "string",
                title: "footer Text",
                footer: "The footer text of the card."
            },
            background: {
                type: "string",
                format: "url",
                title: "Background Image URL",
                footer: "URL for the background image."
            },
            titleFontColor: {
                type: "string",
                title: "Title Font Color",
                footer: "Font color for the title."
            },
            titleFontSize: {
                type: "string",
                title: "Title Font Size",
                footer: "Font size for the title."
            },
            titleFontWeight: {
                type: "string",
                title: "Title Font Weight",
                footer: "Font weight for the title."
            },
            footerFontColor: {
                type: "string",
                title: "footer Font Color",
                footer: "Font color for the footer."
            },
            footerFontSize: {
                type: "string",
                title: "footer Font Size",
                footer: "Font size for the footer."
            },
            footerFontWeight: {
                type: "string",
                title: "footer Font Weight",
                footer: "Font weight for the footer."
            },
        }
    };
}
export function getElementDataSourceSpec() {
    return {
        sources: [
            {
                type: 'SOURCE',
                title: 'Card Data',
                description: 'Arbitrary source of data for this card matching the configuration fields.',
                classes: '*'
            }
        ]
    };
}
</script>
  
  <script lang="ts">import "yinzcam-cards";
import CardBase from "components/cards/common/CardBase.svelte";
import { getFirstSourceStore, getTemplatedElementDataFromSource, buildInlineStyle, formatDateTimeRelative, addClassOnHoverAction } from "components/cards/utilities";
import "svelte/store";
import 'components/cards/common/CardsElementInterfaces';
export let element;
export let sources;
export let sourceStore;
$: {
    sourceStore = getFirstSourceStore($sources);
}
$: padding = getTemplatedElementDataFromSource(element, "padding", $sourceStore);
$: backgroundColor = getTemplatedElementDataFromSource(element, "backgroundColor", $sourceStore);
$: imageUrl = getTemplatedElementDataFromSource(element, "imageUrl", $sourceStore);
$: categoryText = getTemplatedElementDataFromSource(element, "categoryText", $sourceStore);
$: categoryFontColor = getTemplatedElementDataFromSource(element, "categoryFontColor", $sourceStore);
$: categoryFontSize = getTemplatedElementDataFromSource(element, "categoryFontSize", $sourceStore);
$: categoryFontWeight = getTemplatedElementDataFromSource(element, "categoryFontWeight", $sourceStore);
$: titleText = getTemplatedElementDataFromSource(element, "titleText", $sourceStore);
$: titleMaxLines = getTemplatedElementDataFromSource(element, "titleMaxLines", $sourceStore);
$: titleFontColor = getTemplatedElementDataFromSource(element, "titleFontColor", $sourceStore);
$: titleFontSize = getTemplatedElementDataFromSource(element, "titleFontSize", $sourceStore);
$: titleFontWeight = getTemplatedElementDataFromSource(element, "titleFontWeight", $sourceStore);
$: publishDateTime = getTemplatedElementDataFromSource(element, "publishDateTime", $sourceStore);
$: footerFontColor = getTemplatedElementDataFromSource(element, "footerFontColor", $sourceStore);
$: footerFontSize = getTemplatedElementDataFromSource(element, "footerFontSize", $sourceStore);
$: footerFontWeight = getTemplatedElementDataFromSource(element, "footerFontWeight", $sourceStore);
$: publishDateTimeDisplay = formatDateTimeRelative(publishDateTime);
$: style = buildInlineStyle("padding", padding, "background-color", backgroundColor, "category-font-color", categoryFontColor, "category-font-size", categoryFontSize, "category-font-weight", categoryFontWeight, "title-max-lines", titleMaxLines, "title-font-color", titleFontColor, "title-font-size", titleFontSize, "title-font-weight", titleFontWeight, "footer-font-color", footerFontColor, "footer-font-size", footerFontSize, "footer-font-weight", footerFontWeight);
</script>
  
  <CardBase {element}>
    <div class="horizontal-media" use:addClassOnHoverAction={'hovering'} style={style}>
      <div class="image-area">
        <img src={imageUrl} alt="" />
      </div>
      <div class="text-area">
        <div class="category">
          {categoryText}
        </div>
        <div class="title">
          {titleText}
        </div>
        <div class="footer">
          <i class="f7-icons">clock</i>{publishDateTimeDisplay}
        </div>
      </div>
    </div>
  </CardBase>
  
  <style>
    *, *::before, *::after {
      box-sizing: border-box;
    }

    .horizontal-media {
      display: flex;
      background-position: top center;
      background-size: 100%;
      background-color: var(--background-color, unset);
      cursor: pointer;
    }

    .image-area {
      max-width: 40%;
      overflow: hidden;
    }

    .image-area img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
      transition: transform 1.0s ease;
    }

    .horizontal-media.hovering .image-area img {
      transform: scale(1.2);
    }

    .text-area {
      padding: var(--padding, 0.5em 1.0em);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .category {
      color: var(--category-font-color, unset);
      font-size: var(--category-font-size, unset);
      font-weight: var(--category-font-weight, 800);
    }

    .title {
      color: var(--title-font-color, unset);
      font-size: var(--title-font-size, 1.2em);
      font-weight: var(--title-font-weight, unset);
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: var(--title-max-lines, 2);
      line-clamp: var(--title-max-lines, 2);
      -webkit-box-orient: vertical;
    }

    .footer {
      color: var(--footer-font-color, unset);
      font-size: var(--footer-font-size, 0.9em);
      font-weight: var(--footer-font-weight, unset);

      display: flex;
      align-items: center;
    }

    .f7-icons {
      font-size: 0.75em;
      font-weight: bold;
      margin-right: 0.4em;
    }</style>