<script lang="ts">import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import { applyImageTransformation, buildInlineStyleFromObject, buildInlineStyleFromObjects, buildThemeModeInlineStyle, getImageTransformationSlug, mergeInlineStyles, rewriteExternalCDNURL } from "components/cards/utilities";
import './BaseAtomSpec';
import { containerActionsHandler, resizeObserverAction } from "../common/actions";
import { isAbsoluteUrl, shouldOpenInNewWindow } from 'js/url';
import { CardsTabContextKey } from '../common/context';
import { getContext } from 'svelte';
import _ from 'lodash';
// inputs
export let _isotope;
export let _link = undefined;
export let _cssVariablePrefix = undefined;
export let _position = 'static';
// outputs
export let _clientWidthPx = null;
export let _clientHeightPx = null;
export let _clientWidthRem = null;
export let _clientHeightRem = null;
export let _borderBoxWidthPx = null;
export let _borderBoxHeightPx = null;
export let _borderBoxWidthRem = null;
export let _borderBoxHeightRem = null;
export let _contentBoxWidthPx = null;
export let _contentBoxHeightPx = null;
export let _contentBoxWidthRem = null;
export let _contentBoxHeightRem = null;
// consumed style props
export let background = undefined;
export let border = undefined;
export let centerContentHorizontally = undefined;
export let centerContentVertically = undefined;
export let fillHeight = undefined;
export let fillWidth = undefined;
export let filter = undefined;
export let overflow = undefined;
export let height = undefined;
export let hidden = undefined;
export let margin = undefined;
export let padding = undefined;
export let themeMode = undefined;
export let width = undefined;
export let boxShadow = undefined;
export let actions = undefined;
export let aspectRatio = undefined;
const tabContext = getContext(CardsTabContextKey);
// when bind:borderBoxSize and bind:contentBoxSize are working (Svelte 5?) then we should move to that.
//let _borderBoxSize: any, _contentBoxSize: any;
function handleResize({ borderBoxSize, contentBoxSize }) {
    if (!(borderBoxSize === null || borderBoxSize === void 0 ? void 0 : borderBoxSize.length) || !contentBoxSize.length) {
        return;
    }
    //console.log('handleResize', borderBoxSize, contentBoxSize);
    _clientWidthPx = _borderBoxWidthPx = borderBoxSize[0].inlineSize;
    _clientHeightPx = _borderBoxHeightPx = borderBoxSize[0].blockSize;
    _contentBoxWidthPx = contentBoxSize[0].inlineSize;
    _contentBoxHeightPx = contentBoxSize[0].blockSize;
    const pxPerRem = parseFloat(getComputedStyle(document.documentElement).fontSize);
    _clientWidthRem = _borderBoxWidthRem = _borderBoxWidthPx / pxPerRem;
    _clientHeightRem = _borderBoxHeightRem = _borderBoxHeightPx / pxPerRem;
    _contentBoxWidthRem = _contentBoxWidthPx / pxPerRem;
    _contentBoxHeightRem = _contentBoxHeightPx / pxPerRem;
}
//$: handleResize({borderBoxSize: _borderBoxSize, contentBoxSize: _contentBoxSize});
$: useHighlightColor = (background === null || background === void 0 ? void 0 : background.useHighlightColor) || false;
let modifiedBackground = background;
$: if (background) {
    try {
        modifiedBackground = _.cloneDeep(background);
        //modifiedBackground.image = xlator({  });
        modifiedBackground.image = rewriteExternalCDNURL(modifiedBackground.image);
        if (_clientWidthPx && _clientHeightPx) {
            const xfSlug = getImageTransformationSlug('background', _clientWidthPx, _clientHeightPx);
            if (xfSlug) {
                modifiedBackground.image = applyImageTransformation(modifiedBackground.image, xfSlug);
            }
        }
    }
    catch (e) {
        console.warn('error modifying background image URL', e);
    }
}
$: inlineStyle = mergeInlineStyles(buildInlineStyleFromObject($$restProps, _cssVariablePrefix), buildThemeModeInlineStyle(themeMode), buildInlineStyleFromObjects([modifiedBackground, 'atom-container-background'], [border, 'atom-container-border'], [filter, 'atom-container-filter'], [overflow, 'atom-container-overflow'], [height, 'atom-container-height'], [margin, 'atom-container-margin'], [padding, 'atom-container-padding'], [width, 'atom-container-width'], [boxShadow, 'atom-container-box-shadow'], [aspectRatio, 'atom-container-aspect-ratio']), `position: ${_position};`);
</script>

<style>
  .yinzcam-atom-base {
    --yinzcam-inside-atom: true;
    display: block;
  }

  .yinzcam-atom-base.atom-with-center-content-horizontally,
  .yinzcam-atom-base.atom-with-center-content-vertically {
    display: flex;
  }

  .yinzcam-atom-base.atom-with-center-content-horizontally {
    justify-content: center;
  }

  .yinzcam-atom-base.atom-with-center-content-vertically {
    align-items: center;
  }

  .yinzcam-atom-base.atom-with-fill-width:not(.atom-with-width) {
    width: 100%;
  }

  .yinzcam-atom-base.atom-with-fill-height:not(.atom-with-height) {
    height: 100%;
  }

  .yinzcam-atom-base.atom-with-width {
    width: var(--atom-container-width);
  }

  .yinzcam-atom-base.atom-with-height {
    height: var(--atom-container-height);
  }

  .yinzcam-atom-base.atom-with-aspect-ratio {
    aspect-ratio: var(--atom-container-aspect-ratio);
  }

  .yinzcam-atom-base.atom-with-background {
    background-image: var(--atom-container-background-image, unset);
    background-color: var(--atom-container-background-color, var(--theme-mode-background-color, unset));
    background-repeat: var(--atom-container-background-repeat, unset);
    background-size: var(--atom-container-background-size, unset);
    background-attachment: var(--atom-container-background-attachment, unset);
    background-position-x: var(--atom-container-background-horizontal-alignment, unset);
    background-position-y: var(--atom-container-background-vertical-alignment, unset);
    background-clip: var(--atom-container-background-clip, unset);
    -webkit-backdrop-filter: var(--atom-container-background-filter, unset);
            backdrop-filter: var(--atom-container-background-filter, unset);
  }

  .yinzcam-atom-base.atom-with-background.use-highlight-color {
    background-color: var(--atom-container-background-color, var(--theme-mode-highlight-color, unset));
  }

  @supports (background-color: lch(from white l c h)) {
    .yinzcam-particle-base.particle-with-background.background-with-lightness-modifier {
      background-color: lch(from var(--particle-container-background-color, var(--theme-mode-background-color, transparent)) calc(l * (var(--atom-container-background-color-lightness, 100)/100)) c h);
    }

@supports (color: lab(from red l 1 1% / calc(alpha + 0.1))) {
.yinzcam-particle-base.particle-with-background.background-with-lightness-modifier {
      background-color: lch(from var(--particle-container-background-color, var(--theme-mode-background-color, transparent)) calc(l * calc(var(--atom-container-background-color-lightness, 100)/100)) c h);
    }
}

    .yinzcam-atom-base.atom-with-background.use-highlight-color.background-with-lightness-modifier {
      background-color: lch(from var(--particle-container-background-color, var(--theme-mode-highlight-color, transparent)) calc(l * (var(--atom-container-background-color-lightness, 100)/100)) c h);
    }

@supports (color: lab(from red l 1 1% / calc(alpha + 0.1))) {
.yinzcam-atom-base.atom-with-background.use-highlight-color.background-with-lightness-modifier {
      background-color: lch(from var(--particle-container-background-color, var(--theme-mode-highlight-color, transparent)) calc(l * calc(var(--atom-container-background-color-lightness, 100)/100)) c h);
    }
}
  }

  .yinzcam-atom-base.atom-with-border {
    border-style: var(--atom-container-border-style, unset);
    border-color: var(--atom-container-border-color, var(--theme-mode-foreground-color, unset));
    border-width: var(--atom-container-border-width, unset);
    border-radius: var(--atom-container-border-radius, unset);
  }

  .yinzcam-atom-base.atom-with-filter {
    -webkit-filter: var(--atom-container-filter, unset);
            filter: var(--atom-container-filter, unset);
  }

  .yinzcam-atom-base.atom-with-overflow{
    overflow: var(--atom-container-overflow, unset)
  }

  .yinzcam-atom-base.atom-with-hidden {
    display: none;
  }

  .yinzcam-atom-base.atom-with-margin {
    margin: var(--atom-container-margin, unset);
  }

  .yinzcam-atom-base.atom-with-padding {
    padding: var(--atom-container-padding, unset);
  }

  .yinzcam-atom-base.atom-with-box-shadow {
    box-shadow: var(--atom-container-box-shadow, unset);
  }

  .yinzcam-atom-base :global(*),
  .yinzcam-atom-base :global(*::before),
  .yinzcam-atom-base :global(*::after) {
    box-sizing: border-box;
  }</style>

<Boundary>
  {#if _link}
    <a 
      use:resizeObserverAction={handleResize}
      data-yinzcam-isotope={_isotope}
      class:yinzcam-atom-base={true}
      class:atom-with-background={background && background.visible}
      class:background-with-lightness-modifier={background && background.colorLightness}
      class:atom-with-border={border && border.visible}
      class:atom-with-center-content-horizontally={centerContentHorizontally}
      class:atom-with-center-content-vertically={centerContentVertically}
      class:atom-with-fill-height={fillHeight}
      class:atom-with-fill-width={fillWidth}
      class:atom-with-filter={filter}
      class:atom-with-overflow={overflow}
      class:atom-with-height={height}
      class:atom-with-hidden={hidden}
      class:atom-with-margin={margin}
      class:atom-with-padding={padding}
      class:atom-with-width={width}
      class:atom-with-box-shadow={boxShadow}
      class:atom-with-aspect-ratio={aspectRatio}
      class:use-highlight-color={useHighlightColor}
      class:external={isAbsoluteUrl(_link)}
      target={(shouldOpenInNewWindow(_link))? '_blank' : '_self'}
      href={_link}
      style={inlineStyle}>
      <slot></slot>
    </a>
  {:else}
    <div 
      use:resizeObserverAction={handleResize}
      use:containerActionsHandler={{ actions, tabContext }}
      data-yinzcam-isotope={_isotope}
      class:yinzcam-atom-base={true}
      class:atom-with-background={background && background.visible}
      class:background-with-lightness-modifier={background && background.colorLightness}
      class:atom-with-border={border && border.visible}
      class:atom-with-center-content-horizontally={centerContentHorizontally}
      class:atom-with-center-content-vertically={centerContentVertically}
      class:atom-with-fill-height={fillHeight}
      class:atom-with-fill-width={fillWidth}
      class:atom-with-filter={filter}
      class:atom-with-overflow={overflow}
      class:atom-with-height={height}
      class:atom-with-hidden={hidden}
      class:atom-with-margin={margin}
      class:atom-with-padding={padding}
      class:atom-with-width={width}
      class:atom-with-box-shadow={boxShadow}
      class:atom-with-aspect-ratio={aspectRatio}
      class:use-highlight-color={useHighlightColor}
      style={inlineStyle}>
      <slot></slot>
    </div>
  {/if}
</Boundary>
