import { generateRootObjectSchema, generateArraySchema, generateObjectSchema, generateStringSchema, generateColorSchema, generateBooleanSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { ImageParticleSpec, TextParticleSpec } from "components/cards/particles/YinzCam";


export const specification = new BaseAtomSpec("MC-Lineup", generateRootObjectSchema({
    container: generateAtomContainerSchema({ defaultValues: { themeMode: 'inverted' } }),
    backgroundImageDesktop: ImageParticleSpec.generateSchema("Background Image", "Background Image"),
    backgroundImageMobile: ImageParticleSpec.generateSchema("Mobile Background", "Mobile background image"),
    overlayPadding: generateStringSchema("Overlay Padding", "Specify a padding for overlay"),
    playerImage: ImageParticleSpec.generateSchema("Player Image", "Player Image", {
        defaultValues: {
            container: {
                width: "4.5rem", height: "4.5rem",
                border: {
                    visible: true, radius: "50%"
                }
            }, sizing: "cover"
        }
    }),
    eventIcon: ImageParticleSpec.generateSchema("Event Icon", "Event Icon", {
        defaultValues: { container: { width: "1.5rem", height: "1.5rem" }, sizing: "contain" }
    }),
    colorPlayerBackground: generateColorSchema("Player Image Background", "Player Image Background"),
    isHomeTeam: generateBooleanSchema("Is home team?", "Is home team selected?", {
        defaultValue: true,
    }),
    teamDetails: generateArraySchema("Team Formation Details", "Team Formation Details",
        generateObjectSchema("Team Row", "Team row information.", {

            players: generateArraySchema("Team Row Detail", "Team Row Detail",
                generateObjectSchema("Player Details", "Player Details", {
                    playerImage: generateStringSchema("Image Url", "Image Url"),
                    goalTimes: generateArraySchema("Goal Times", "Goal Times", generateStringSchema("Goal Times", "Goal Times")),
                    assistTimes: generateArraySchema("Assist Times", "Assist Times", generateStringSchema("Assist Times", "Assist Times")),
                    redCardTimes: generateArraySchema("Assist Times", "Assist Times", generateStringSchema("Red Card Times", "Red Card Times")),
                    yellowCardTimes: generateArraySchema("Yellow Card Times", "Yellow Card Times", generateStringSchema("Yellow Card Times", "Yellow Card Times")),
                    subbedOffTimes: generateArraySchema("Subbed Off Times", "Subbed Off Times", generateStringSchema("Subbed Off Times", "Subbed Off Times")),
                    subbedOnTimes: generateArraySchema("Subbed On Times", "Subbed On Times", generateStringSchema("Subbed On Times", "Subbed On Times")),
                })
            )

        },)
    ),
}));