<Boundary>
  <CardsComponentWrapper {messageHandler} sticky={currentLayout.sticky} style="
    {buildCssPadding((currentLayout)? currentLayout.padding : null, defaultPadding)}
    {buildCssMargin((currentLayout)? currentLayout.margin : null, defaultMargin)}
    {buildCssBackground((currentLayout)? currentLayout.background : null)}
    {buildCssFont((currentLayout)? currentLayout.font : null)}
    {buildCssBorder((currentLayout)? currentLayout.border : null)}
    {(hidden)? 'display: none;' : ''}
    {(currentLayout && currentLayout.zoom)? `zoom: ${currentLayout.zoom};` : ''}
    {(currentLayout && !_.isNil(currentLayout.zIndex))? `z-index: ${currentLayout.zIndex};` : ''}
    {(currentLayout?.createStackingContext)? 'isolation: isolate;' : ''}
  " {editorOptions} {readyToRender}>
    {#if currentLayout && !hidden}
    <svelte:component this={currentLayoutComponent} parentSequenceId={sequenceId} layout={currentLayout} bind:contentAvailable={childContentAvailable} updater={elementsUpdater} bind:messageHandler={childMessageHandler} />
    {/if}
  </CardsComponentWrapper>
</Boundary>

<style>
  *, *::before, *::after {
    box-sizing: border-box;
  }</style>

<script lang="ts">var _a, _b, _c, _d, _e, _f, _g;
import "yinzcam-cards";
import CardsArraySwiperLayout from "./CardsArraySwiperLayout.svelte";
import CardsArrayGridLayout from "./CardsArrayGridLayout.svelte";
import CardsArrayWaistbandLayout from "./CardsArrayWaistbandLayout.svelte";
import CardsArraySingleLayout from "./CardsArraySingleLayout.svelte";
import CardsArrayMasonryLayout from "./CardsArrayMasonryLayout.svelte";
import CardsArrayFlexLayout from "./CardsArrayFlexLayout.svelte";
import CardsArraySplideLayout from "./CardsArraySplideLayout.svelte";
import CardsArraySwiper11Layout from "./CardsArraySwiper11Layout.svelte";
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import { buildCssMargin, buildCssPadding, buildCssBackground, expandRepeats, buildCssFont, rewriteExternalCDNURL, getImageTransformationSlug, applyImageTransformation, getRenderDelayForSequenceId, buildCssBorder, toBooleanSafeExpand } from "../utilities/index";
import _ from "lodash";
import CardsComponentWrapper from './CardsComponentWrapper.svelte';
import "./CardsEditorInterfaces";
import { CARDS_ARRAY_LAYOUT_SCHEMA } from "./CardsEditorComponentSchemata";
import { getContext, onMount } from "svelte";
import { CardsPageContextKey, CardsTabContextKey } from './context';
import { v4 as uuid4 } from "uuid";
import { windowMetrics as wm } from '../../../js/stores';
import "./actions";
export let parentSequenceId = null;
export let sequenceId = null;
export let array;
export let contentAvailable = true;
export let updater;
// TODO: This is kind of crappy to have in all of these components
// just because it needs to override contentAvailable. Is there a better way?
const pageContext = getContext(CardsPageContextKey);
const emmEnabled = pageContext.editorModeManager.getEnabledStore();
const emmPreview = pageContext.editorModeManager.getPreviewStore();
let childContentAvailable;
let childMessageHandler;
let readyToRender = false;
let actionOverrides = {};
function messageHandler(action) {
    // See if this is something I can handle. If not, pass it on to the array layout.
    switch (action.effect) {
        case 'hide':
            actionOverrides.hidden = true;
            break;
        case 'show':
            actionOverrides.hidden = false;
            break;
        case 'set-background':
            actionOverrides.background = action.layerBackground;
            break;
        case 'set-border':
            actionOverrides.border = action.layerBorder;
            break;
        default:
            if (childMessageHandler) {
                childMessageHandler(action);
            }
            else {
                console.log("CardsArray - Ignoring message, no suitable message handler found.");
            }
            break;
    }
}
$: outlineMode = $emmEnabled && !$emmPreview;
// isUndefined is for backwards compatibility with layouts that don't support content availability status
$: {
    contentAvailable = outlineMode || _.isUndefined(childContentAvailable) || childContentAvailable;
}
/*const defaultPadding: YinzCamCardsServicePadding = {
  top: '10px', bottom: '10px',
  left: '12px', right: '12px'
};*/
const defaultPadding = {
    top: '0', bottom: '0',
    left: '0', right: '0'
};
const defaultMargin = {
    top: '0', bottom: '0',
    left: '0', right: '0'
};
let currentLayout = null;
let currentLayoutComponent = null;
let dndMode = false;
$: if (((_a = array.layouts) === null || _a === void 0 ? void 0 : _a.length) > 0 && ($wm === null || $wm === void 0 ? void 0 : $wm.width) && !dndMode) {
    /*
    const layouts = array.layouts.sort((a, b) => a.breakpoint - b.breakpoint);
    currentLayout = layouts[0];
    for (let layout of layouts) {
      if ($wm.width >= layout?.breakpoint) {
        currentLayout = layout;
      }
    }
    */
    const selectedLayout = array.layouts[0];
    //console.log('DND BEFORE EXPAND ELEMENTS!!', selectedLayout.elements);
    currentLayout = _.cloneDeepWith(selectedLayout, (v, k) => {
        return (k === 'elements') ? expandRepeats(v, "", $wm) : undefined;
    });
    // Apply responsive properties
    //console.log('ARRAY CHECKING RESPONSIVE CONFIGURATION', currentLayout?.responsiveness);
    const responsiveConfiguration = (_d = (_c = (_b = currentLayout === null || currentLayout === void 0 ? void 0 : currentLayout.responsiveness) === null || _b === void 0 ? void 0 : _b.filter((i) => i.maxWidth >= 0)) === null || _c === void 0 ? void 0 : _c.sort((a, b) => a.maxWidth - b.maxWidth)) === null || _d === void 0 ? void 0 : _d.find((i) => i.maxWidth >= $wm.width);
    if (responsiveConfiguration) {
        for (const k in responsiveConfiguration) {
            if (k === 'maxWidth') {
                continue;
            }
            const val = responsiveConfiguration[k];
            if (!_.isNil(val)) {
                currentLayout[k] = val;
            }
        }
    }
    // Apply action overrides
    currentLayout = _.merge(currentLayout, actionOverrides);
    // Apply background image optimization
    let modifiedBackground = currentLayout === null || currentLayout === void 0 ? void 0 : currentLayout.background;
    if (modifiedBackground) {
        try {
            modifiedBackground.url = rewriteExternalCDNURL(modifiedBackground.url);
            /*
            if (_clientWidthPx && _clientHeightPx) {
            }
            */
            const xfSlug = getImageTransformationSlug('background', 1, 1);
            if (xfSlug) {
                modifiedBackground.url = applyImageTransformation(modifiedBackground.url, xfSlug);
            }
        }
        catch (e) {
            console.warn('error modifying background image URL', e);
        }
    }
    //console.log('DND AFTER EXPAND ELEMENTS!!', currentLayout.elements);
    switch ((_e = currentLayout.type) === null || _e === void 0 ? void 0 : _e.toUpperCase()) {
        case "GRID":
            currentLayoutComponent = CardsArrayGridLayout;
            break;
        case "MASONRY":
            currentLayoutComponent = CardsArrayMasonryLayout;
            break;
        case "SWIPER":
            currentLayoutComponent = CardsArraySwiperLayout;
            break;
        case "SINGLE":
            currentLayoutComponent = CardsArraySingleLayout;
            break;
        case "WAISTBAND":
            currentLayoutComponent = CardsArrayWaistbandLayout;
            break;
        case "FLEX":
            currentLayoutComponent = CardsArrayFlexLayout;
            break;
        case "SPLIDE":
            currentLayoutComponent = CardsArraySplideLayout;
            break;
        case "SWIPER11":
            currentLayoutComponent = CardsArraySwiper11Layout;
            break;
        default:
            currentLayoutComponent = CardsArrayGridLayout;
            break;
    }
}
const tabContext = getContext(CardsTabContextKey);
$: mergedParams = (_f = tabContext === null || tabContext === void 0 ? void 0 : tabContext.mergedParamsComp) === null || _f === void 0 ? void 0 : _f.store;
$: hidden = toBooleanSafeExpand(currentLayout === null || currentLayout === void 0 ? void 0 : currentLayout.hidden, $mergedParams);
// TODO: determine if this needs to operate on currentLayout instead (see editorOptions.component)
// If everything is working fine this comment can be deleted
function arrayUpdater(layout) {
    array.layouts[0] = layout;
    array = array;
    updater(array);
}
function elementsUpdater(elements) {
    // WARNING: This is very important. It is very easy to break drag and drop with this code.
    // The DND library will call this function when elements are moving. This means the array
    // will contain shadow (placeholder) elements. In this state, we can't push changes up to
    // the unexpanded array, because they will get expanded and mess things up. So we introduce
    // dndMode and set it here if any of the elements are shadow items, and clear it otherwise.
    // Even in DND mode we still need to update the expanded array so that the DOM stays in sync,
    // so we update currentLayout.elements directly in that case.
    dndMode = elements.some(el => el.isDndShadowItem);
    if (dndMode) {
        currentLayout.elements = elements;
        currentLayout = currentLayout;
    }
    else {
        array.layouts[0].elements = elements.filter(el => { var _a; return !((_a = el === null || el === void 0 ? void 0 : el.__expandRepeats) === null || _a === void 0 ? void 0 : _a.cloned); });
        array = array;
        // This is needed to update the elements within arrays that are clones of this one.
        updater(array);
    }
}
function addRepeat() {
    array.repeat = Math.max((array.repeat || 1) + 1, 1);
}
function removeRepeat() {
    array.repeat = (array.repeat || 1) - 1;
    if (array.repeat <= 1) {
        delete array.repeat;
    }
}
function createChild() {
    return {
        id: uuid4(),
        class: 'YinzCam.Core.PlaceholderCard'
    };
}
let editorOptions;
$: editorOptions = {
    parentSequenceId,
    sequenceId,
    componentId: array.id,
    component: array.layouts[0],
    componentTypeName: 'Array',
    childTypeNames: ['Element'],
    primaryColor: '#DE322F',
    reverseTextColor: 'black',
    unselectedBackgroundColor: '#e6e6e6',
    unselectedTextColor: '#000000',
    selectedBackgroundColor: 'rgb(0, 122, 255)',
    selectedTextColor: '#FFFFFF',
    childrenKey: 'elements',
    configSpec: CARDS_ARRAY_LAYOUT_SCHEMA,
    allowChildren: true,
    allowDeletion: true,
    allowDuplication: true,
    allowMovement: true,
    allowRepeats: true,
    allowCopyPaste: true,
    updater: arrayUpdater,
    addRepeat,
    removeRepeat,
    createChild,
    cloned: (_g = array === null || array === void 0 ? void 0 : array.__expandRepeats) === null || _g === void 0 ? void 0 : _g.cloned
};
onMount(() => {
    const renderDelay = getRenderDelayForSequenceId(sequenceId);
    if (!isNaN(renderDelay) && renderDelay > 0) {
        setTimeout(() => readyToRender = true, renderDelay);
    }
    else {
        readyToRender = true;
    }
});
</script>
