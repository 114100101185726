import { Container } from "inversify";
import { YinzCamSegmentationServiceModule } from "yinzcam-segmentation";
import { YinzCamAPIConfig, YinzCamAPIModule } from "yinzcam-api";
import { YinzCamConfigServerModule } from "yinzcam-config";
import { LoggerModule } from "yinzcam-log";
import { YinzCamAppServerModule } from "yinzcam-appserver";
import { YinzCamCardsServerModule } from "yinzcam-cards";
import { CardsDataSourcesModule } from "./components/cards/sources/module";
import { JanusDefaultRequestParameterModule } from "./js/params";
import { JanusModeContextManagerModule } from "./js/mode";
import { JanusPushManagerModule } from "./js/push";
import { JanusInternationalizationManagerModule } from "./js/i18n";
import { JanusSignonManagerModule } from "./js/sso";
import { YinzCamSignonServiceModule } from "yinzcam-signon";
import { JanusConfigManagerModule } from "./js/config";
import { getToken, tokenBinder } from "inversify-token";
import { YinzCamAPIConfigToken } from "yinzcam-api";
import { JanusAnalyticsManagerModule } from "./js/analytics";
import { YinzCamAdsServerModule } from "yinzcam-ads";
import { CardsLibraryModule } from "./components/cards/library/module";
import { InstagramServerModule } from "social-server";
import { PublicAPIModule } from "public-api";
import { YinzCamDropsServerModule } from "yinzcam-drops";
import { ContentfulAPIModule } from "contentful-api/module";
import { CardsDynamicConfigurationProviderToken } from "components/cards/common/CardsDynamicConfigurationProvider";
import { ContentfulCardsDynamicConfigurationProviderToken } from "lib/contentful-api/ContentfulCardsDynamicConfigurationProvider";
import { CardsPageContentManager, CardsPageContentManagerToken } from "components/cards/common/CardsPageContentManager";
import { JanusSEOManagerModule } from "js/seo";

const ROOT: Container = new Container({ skipBaseClassChecks: true });
const bindToken = tokenBinder(ROOT.bind.bind(ROOT));
LoggerModule.configure(ROOT);
// TODO: To support mode switching, this may have to be inside a container for each supported mode (?)
YinzCamAPIModule.configure(ROOT); 

YinzCamAdsServerModule.configure(ROOT);
YinzCamAppServerModule.configure(ROOT);
YinzCamCardsServerModule.configure(ROOT);
YinzCamConfigServerModule.configure(ROOT);
YinzCamSegmentationServiceModule.configure(ROOT);
YinzCamSignonServiceModule.configure(ROOT);
//added by serhii-2021-08-18
PublicAPIModule.configure(ROOT);
InstagramServerModule.configure(ROOT);
// app bundled configuration
JanusConfigManagerModule.configure(ROOT);
bindToken(YinzCamAPIConfigToken).toConstantValue(CONFIG as YinzCamAPIConfig);

// default query string params and headers
JanusDefaultRequestParameterModule.configure(ROOT);

// mode context and query params
JanusModeContextManagerModule.configure(ROOT);

// push notifications
JanusPushManagerModule.configure(ROOT);

// internationalization
JanusInternationalizationManagerModule.configure(ROOT);

// SSO
JanusSignonManagerModule.configure(ROOT);

// Analytics
JanusAnalyticsManagerModule.configure(ROOT);

// SEO
JanusSEOManagerModule.configure(ROOT);

CardsLibraryModule.configure(ROOT);
CardsDataSourcesModule.configure(ROOT);

/*
ROOT.load(new TokenContainerModule((bindToken) => {
  [YinzCamSegmentationRequestParameterComponentToken].forEach((token) => {
    bindToken(YinzCamAPIRequestParameterComponentToken).toConstantValue(getToken(ROOT, token));
  });
}));
*/

// TODO: Move this to some other location, probably in something that instantiates all of the services for an app mode?
//getToken(ROOT, YinzCamSegmentationServiceToken);

YinzCamDropsServerModule.configure(ROOT);

ContentfulAPIModule.configure(ROOT);

switch (CONFIG.dynamicConfigurationProvider) {
  case "contentful":
    bindToken(CardsDynamicConfigurationProviderToken).toDynamicValue(() => getToken(ROOT, ContentfulCardsDynamicConfigurationProviderToken));
    console.info("using Contentful as the dynamic configuration provider");
    break;
  default:
    console.warn("dynamicConfigurationProvider not set or unknown provider, dynamic configuration will not be available");
    break;
}

// TODO: Put into module?
bindToken(CardsPageContentManagerToken).to(CardsPageContentManager);

export default ROOT;
