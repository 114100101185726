<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import "lodash";
import { BaseAtom } from "components/cards/atoms";
import { ButtonParticle } from "components/cards/particles/YinzCam";
export let container = undefined;
export let button = undefined;
</script>

<style></style>

<BaseAtom _isotope="ButtonAtom" {...container}>
  <ButtonParticle {...button} />
</BaseAtom>
