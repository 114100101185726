<script lang="ts">import { onMount } from "svelte";
import "swiper";
import Digit from "./Digit.svelte";
export let resetTime;
let secs = resetTime % 60;
let mins = ((resetTime - secs) / 60) % 60;
let hours = ((resetTime - secs - mins * 60) / 3600) % 24;
let days = (resetTime - secs - mins * 60 - hours * 3600) / 86400;
let digits1 = [];
let swiper = [];
let resetTimeState = 0;
$: {
    resetTimeState = resetTime;
}
const calculateDigits = (time) => {
    const secs1 = time % 60;
    const mins1 = ((time - secs1) / 60) % 60;
    const hours1 = ((time - secs1 - mins1 * 60) / 3600) % 24;
    const days1 = (time - secs1 - mins1 * 60 - hours1 * 3600) / 86400;
    let res = [];
    res[0] = Math.floor(days1 / 10);
    res[1] = Math.floor(days1 % 10);
    res[2] = Math.floor(hours1 / 10);
    res[3] = Math.floor(hours1 % 10);
    res[4] = Math.floor(mins1 / 10);
    res[5] = Math.floor(mins1 % 10);
    res[6] = Math.floor(secs1 / 10);
    res[7] = Math.floor(secs1 % 10);
    return res;
};
onMount(() => {
    const timerId = setInterval(() => {
        // console.log(resetTimeState);
        resetTimeState--;
        if (resetTimeState < 0) {
            return;
        }
        const digits2 = calculateDigits(resetTimeState);
        // console.log("digits1: ", digits1);
        // console.log("digits2: ", digits2);
        const digits3 = [];
        for (let i = 0; i < 8; i++) {
            digits3.push(swiper[i].activeIndex);
        }
        // console.log("digits3: ", digits3);
        for (let i = 0; i < 8; i++) {
            if (digits1[i] != digits2[i]) {
                if ((digits1[i] + 9) % 10 == digits2[i] && digits1[i] === (digits3[i] + 9) % 10) {
                    if (i == 0) {
                        console.log("+9", digits2[i]);
                    }
                    swiper[i].slidePrev();
                }
                else {
                    if (i == 0) {
                        console.log("directly", digits2[i] + 1);
                    }
                    swiper[i].slideTo(digits2[i] + 1);
                }
            }
        }
        digits1 = [...digits2];
    }, 1000);
    return () => clearInterval(timerId);
});
</script>

<div class="watch-timer">
  <Digit on:swiper_init={(e) => (swiper[0] = e.detail.swiper)} />
  <Digit on:swiper_init={(e) => (swiper[1] = e.detail.swiper)} />
  <div class="spliter">:</div>
  <Digit on:swiper_init={(e) => (swiper[2] = e.detail.swiper)} maxDigit={3} />
  <Digit on:swiper_init={(e) => (swiper[3] = e.detail.swiper)} />
  <div class="spliter">:</div>
  <Digit on:swiper_init={(e) => (swiper[4] = e.detail.swiper)} maxDigit={6} />
  <Digit on:swiper_init={(e) => (swiper[5] = e.detail.swiper)} />
  <div class="spliter">:</div>
  <Digit on:swiper_init={(e) => (swiper[6] = e.detail.swiper)} maxDigit={6} />
  <Digit on:swiper_init={(e) => (swiper[7] = e.detail.swiper)} />
</div>

<style lang="scss">*,
*:before,
*:after {
  box-sizing: border-box; }

.watch-timer {
  display: flex;
  gap: 2px; }

.spliter {
  line-height: 45px;
  font-size: 20px;
  padding: 0 6px;
  color: var(--theme-inverted-foreground-color, white); }

@media (max-width: 767px) {
  .spliter {
    line-height: 34px;
    font-size: 16px;
    padding: 0 2px; } }</style>
