<script lang="ts">export let Play = {};
</script>

<div class="live-reporting-image-card">
  <img src={Play.ImageUrl} alt="Image" />
  {#if Play.Text}
    <div class="title">{Play.Text}</div>
  {/if}
</div>

<style lang="scss">*,
*:before,
*:after {
  box-sizing: border-box; }

.live-reporting-image-card {
  width: 100%;
  display: flex;
  flex-direction: column; }
  .live-reporting-image-card img {
    width: 100%;
    border-radius: 12px;
    display: block; }
  .live-reporting-image-card .title {
    margin: 6px 0 0 0;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--theme-primary-foreground-color, black); }

@media (max-width: 767px) {
  .live-reporting-image-card .title {
    margin: 2px 0 0 0;
    font-size: 14px; } }</style>
