import log, { Logger } from 'loglevel';
import prefix from 'loglevel-plugin-prefix';
import chalk from 'chalk';

// I considered a few logging options, but loglevel was the only one being somewhat maintained
// and with plugin options like remote logging that might become useful in the future
// https://github.com/pimterry/loglevel

// See this for more info on logging prefix plugin: https://www.npmjs.com/package/loglevel-plugin-prefix

const colors = {
  TRACE: chalk.magenta,
  DEBUG: chalk.cyan,
  INFO: chalk.blue,
  WARN: chalk.yellow,
  ERROR: chalk.red,
};

prefix.reg(log);

//log.enableAll();
log.setLevel("warn");

prefix.apply(log, {
  format(level, name, timestamp) {
    return `${chalk.gray(`[${timestamp}]`)} ${colors[level.toUpperCase()](level)} ${chalk.green(`${name}:`)}`;
  },
});

// Something I was trying with declaration merging to make logError a method on Logger that takes an exception.
// There's probably a way to do it, just didn't want to spend a lot of time on it right now.
// Fun topic: https://www.typescriptlang.org/docs/handbook/declaration-merging.html
/*
declare module 'loglevel' {
  interface Logger {
    error(e: any): void;
  }
}
*/

export class Logging {
  static getLogger(name: string | symbol): Logger {
    return log.getLogger(name);
  }
  static logError(log: Logger, e: any) {
    if (e instanceof Error) {
      // Logging stack traces: https://stackoverflow.com/questions/9551634/how-to-log-stack-traces-in-node-js
      log.error(e.stack);
    } else {
      log.error(e);
    }
  }
}

// This weird syntax is how you re-export an imported interface or type: https://stackoverflow.com/questions/30712638/typescript-export-imported-interface
export type { Logger } from 'loglevel';
