<script context="module" lang="ts">var _a;
export { specification } from "./spec";
</script>

<script lang="ts">var _a;
import { formatDuration, setStoreOnHoverAction, formatNumber, } from "components/cards/utilities";
import { t } from "js/i18n";
import _ from "lodash";
import { writable } from "svelte/store";
import { ButtonParticle, ImageParticle, ImageParticleSpec, TextParticle, } from "components/cards/particles/YinzCam";
import { BaseAtom, getContentManager, } from "components/cards/atoms";
import { DateTime } from "luxon";
export let container;
export let overlayPadding = undefined;
export let mediaType;
export let slug;
export let link;
export let publishDateTime;
export let viewTimeDuration;
export let views;
export let thumbnail;
export let secondaryThumbnail;
export let categories;
export let header;
export let imgClockIcon;
export let competition;
export let titleText;
export let descriptionText;
export let cta;
export let imageGradient;
export let gradientColor;
export let mediaIconColor;
export let shouldDisplayCategories;
export let dateAndIconOrientation;
export let invertDatePosition;
export let shouldDisplayMediaIcon;
export let shouldDisplayReadDuration;
export let shouldDisplaySecondaryImage;
export let totalPhotos;
export let imgCameraIcon;
export let shouldDisplayPhotosCount;
export let contentAvailable;
$: contentAvailable = !!slug;
$: mtc = getContentManager().getMediaTypeConfigurationStore(mediaType, slug, link);
$: resolvedLink = (_a = $mtc === null || $mtc === void 0 ? void 0 : $mtc.linkUrl) === null || _a === void 0 ? void 0 : _a.href;
$: mediaTypeIcon = $mtc === null || $mtc === void 0 ? void 0 : $mtc.icon;
$: isTablet = atomWidthRem <= 70 && atomWidthRem > 40;
$: isMobile = atomWidthRem <= 40;
//TODO: For time being, this fix is given to format date string. We should mimic something like FixturesCardBAtom fix
// $: publishDateTimeDisplayInter = formatDateTimeLocal(
//   publishDateTime,
//   DateTime.DATE_HUGE,
// );
// $: publishDateTimeDisplay = publishDateTimeDisplayInter
//   ?.split(" ")
//   ?.map((timeEle, idx) => {
//     if (isMobile && (idx === 0 || idx === 2)) {
//       return timeEle?.slice(0, 3);
//     }
//     return timeEle;
//   })
//   ?.map((timeEle, idx) => {
//     if (idx === 2) return timeEle + ",";
//     else return timeEle;
//   })
//   ?.join(" ");
let now = DateTime.fromISO(new Date().toISOString());
$: publishDateTimeDisplayInter = publishDateTime
    ? DateTime.fromISO(publishDateTime)
    : now;
$: [days, hours, mins, seconds] = now
    .diff(publishDateTimeDisplayInter)
    .toFormat("dd:hh:mm:ss")
    .split(":");
$: publishDateTimeDisplay =
    parseInt(days) > 0 || !CONFIG.displayDurationForPublishDateTime
        ? `${publishDateTimeDisplayInter.toFormat(CONFIG.publishDateTimeFormat)}`
        : `${hours ? +hours : ""} ${+hours > 1 ? "hours" : "hour"} ${mins ? +mins : ""} ${+mins > 1 ? "mins" : "min"} ago`;
$: viewTimeDurationDisplay = formatDuration(viewTimeDuration);
$: viewsDisplay = formatNumber(views);
const hovering = writable(false);
let headerFontColor;
let atomWidthRem;
/*
      <div class="comp-area">
        <img class="comp-img" src={compImage || ""} alt="" />
        <span class="comp-text">{compNameText}</span>
      </div>

    <div class="bottom-row">
      <div class="media-icon">
        <img src={icon_video}>
      </div>
      <div class="text-area">
        <div class="title-text">
          {titleText || ""}
        </div>
        <div class="description-text">
          {descriptionText || ""}
        </div>
      </div>
      <div class="cta-row">
        {#if (ctaText && ctaLink) || true}
          <a href={ctaLink} class="cta-button">
            {ctaText}
          </a>
        {/if}
        <div class="author-area">
          <div class="headshot-column">
            <img src={authorImage || ""} alt="" />
          </div>
          <div class="text-column">
            <div class="name-text">
              {authorName || ""}
            </div>
            <div class="description-text">
              {authorDescription || ""}
            </div>
          </div>
        </div>
      </div>
    </div>
    */
</script>

<BaseAtom
  bind:_clientWidthRem={atomWidthRem}
  _isotope="ImageBackgroundMediaAtom"
  _link={resolvedLink}
  _cssVariablePrefix="image-background-media"
  {...container}
  headerSeparatorColor={headerFontColor}
  {overlayPadding}
  {gradientColor}
>
  <div
    class="main-container"
    use:setStoreOnHoverAction={hovering}
    class:layout-mobile-width={atomWidthRem < 30}
    class:layout-tablet-width={atomWidthRem < 45}
  >
    <div class="background-container">
      {#if shouldDisplaySecondaryImage && secondaryThumbnail?.source}
        <ImageParticle
          _block={true}
          _hovering={$hovering}
          {...secondaryThumbnail}
        />
      {:else}
        <ImageParticle _block={true} _hovering={$hovering} {...thumbnail} />
      {/if}
    </div>
    <div class={`overlay-area ${imageGradient ? "overlay-area-gradient" : ""}`}>
      <div class="top-row">
        <div class="left-content">
          <div class="header-area">
            {#if shouldDisplayCategories}
              <div class="category-list">
                {#each categories || [] as category}
                  <ButtonParticle {...category} />
                {/each}
              </div>
            {/if}
            <div class="header-text">
              {#if dateAndIconOrientation === "primary" && invertDatePosition === "top"}
                <div class="clock-duration">
                  <ImageParticle {...imgClockIcon} _block={true} />
                  <TextParticle
                    bind:_computedColor={headerFontColor}
                    {...header}
                    text={publishDateTimeDisplay || ""}
                  />
                </div>
              {/if}
              {#if !_.isNil(viewTimeDuration) && shouldDisplayReadDuration}
                <div class="header-text-separator"></div>
                <TextParticle
                  {...header}
                  text="{viewTimeDurationDisplay || ''} {$t('read')}"
                />
              {/if}
              {#if !_.isNil(views)}
                <div class="header-text-separator"></div>
                <TextParticle
                  {...header}
                  text="{viewsDisplay || ''} {$t('views')}"
                />
              {/if}
            </div>
          </div>
          {#if shouldDisplayPhotosCount && +totalPhotos > 0}
            <div class="photos-count">
              <ImageParticle {...imgCameraIcon} _block={true} />
              <TextParticle {...header} text={`${totalPhotos} photos`} />
            </div>
          {/if}
        </div>
      </div>
      <div class="bottom-row">
        <div class="text-icon-area">
          {#if dateAndIconOrientation === "secondary" && invertDatePosition === "top"}
            <div class="clock-duration">
              <ImageParticle {...imgClockIcon} _block={true} />
              <TextParticle
                bind:_computedColor={headerFontColor}
                {...header}
                text={publishDateTimeDisplay || ""}
              />
            </div>
          {/if}
          {#if shouldDisplayMediaIcon && !_.isNil(mediaTypeIcon) && dateAndIconOrientation === "primary"}
            <ImageParticle
              {...ImageParticleSpec.propsWithDefaults({
                container: { fillWidth: true, height: "3rem", width: "3rem" },
                icon: mediaTypeIcon,
                iconColor: mediaIconColor,
              })}
            />
          {/if}
          <div class="text-area">
            {#if !_.isNil(titleText)}
              <TextParticle {...titleText} lineClamp={2} />
            {/if}
            {#if !_.isNil(descriptionText)}
              <TextParticle
                {...descriptionText}
                lineClamp={atomWidthRem < 45 ? 3 : 2}
              />
            {/if}
            {#if invertDatePosition === "bottom"}
              <div class="clock-duration">
                <ImageParticle {...imgClockIcon} _block={true} />
                <TextParticle
                  bind:_computedColor={headerFontColor}
                  {...header}
                  text={publishDateTimeDisplay || ""}
                />
              </div>
            {/if}
          </div>
        </div>
        {#if cta && (cta.image?.source || cta.image?.icon || cta.text?.text) }
          <div class="icon-area">
            <ButtonParticle {...cta} link={resolvedLink}/>
          </div>
        {:else if shouldDisplayMediaIcon && !_.isNil(mediaTypeIcon) && dateAndIconOrientation === "secondary"}
          <div class="icon-area">
            <ImageParticle
              _block={true}
              {...ImageParticleSpec.propsWithDefaults({
                container: {
                  fillWidth: true,
                  height: "3.5rem",
                  width: "3.5rem",
                },
                icon: mediaTypeIcon,
                iconColor: mediaIconColor,
              })}
            />
          </div>
        {/if}
      </div>
    </div>
  </div>
</BaseAtom>

<style>
  .main-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    background-size: cover;
    background-position: top center;
    cursor: pointer;
    position: relative;
    /*aspect-ratio: var(--aspect-ratio, 21/4);*/
    height: 100%;
    width: 100%;
  }

  .main-container > .background-container {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .main-container > .overlay-area {
    padding: var(--image-background-media-overlay-padding, unset);
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
  }

  .overlay-area-gradient {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 25.97%,
      var(--image-background-media-gradient-color, --theme-mode-accent-color)
        100%
    );
  }

  .main-container > .overlay-area > .top-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
  }

  .main-container > .overlay-area > .top-row > .header-area {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
  }

  .main-container > .overlay-area > .top-row > .header-area > .category-list {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: start;
    gap: 1rem;
  }

  .main-container > .overlay-area > .top-row > .header-area > .header-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    gap: 1rem;
  }

  .main-container
    > .overlay-area
    > .top-row
    > .header-area
    > .header-text
    > .header-text-separator {
    align-self: stretch;
    width: 1px;
    background-color: var(--header-separator-color, white);
  }

  .main-container
    > .overlay-area
    > .top-row
    > .header-area
    > .header-text
    > .content-icon {
    font-size: 0.75rem;
    font-weight: bold;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
  }

  .main-container
    > .overlay-area
    > .top-row
    > .header-area
    > .category-list
    > .category-button {
    display: block;
    padding: 0.5rem;
    font-weight: 600;
    background-color: var(--category-background-color);
    border-radius: 8px;
    white-space: nowrap;
  }

  .main-container > .overlay-area > .top-row > .comp-area {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    font-weight: 600;
    border-radius: 8px;
    background-color: var(--comp-background-color);
  }

  .main-container > .overlay-area > .top-row > .comp-area > .comp-img {
    max-width: 4rem;
    max-height: 2rem;
    display: none;
  }

  .main-container > .overlay-area > .bottom-row {
    display: flex;
    justify-content: space-between;
  }

  .main-container > .overlay-area > .bottom-row > .text-icon-area >.text-area {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .main-container > .overlay-area > .bottom-row > .cta-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .main-container > .overlay-area > .bottom-row > .cta-row > .cta-button {
    background-color: var(--cta-background-color);
    color: var(--cta-font-color);
    font-size: var(--cta-font-size, 1rem);
    font-weight: var(--cta-font-weight, 600);

    display: block;
    padding: 0.5rem;
    border-radius: 8px;
    white-space: nowrap;
  }

  .main-container.layout-tablet-width
    > .overlay-area
    > .top-row
    > .header-area
    > .header-text {
    white-space: inherit;
  }

  .main-container.layout-tablet-width
    > .overlay-area
    > .top-row
    > .comp-area
    > .comp-img {
    display: unset;
  }

  .main-container.layout-tablet-width
    > .overlay-area
    > .top-row
    > .comp-area
    > .comp-text {
    display: none;
  }

  .main-container.layout-tablet-width
    > .overlay-area
    > .bottom-row
    > .cta-row
    > .author-area
    > .text-column {
    display: none;
  }

  .main-container.layout-mobile-width
    > .overlay-area
    > .top-row
    > .header-area {
    flex-direction: column;
    align-items: start;
    flex-wrap: wrap;
  }

  .main-container.layout-mobile-width
    > .overlay-area
    > .top-row
    > .header-area
    > .header-text {
    flex-direction: column;
    align-items: start;
    gap: 0.25rem;
  }

  .main-container.layout-mobile-width
    > .overlay-area
    > .top-row
    > .header-area
    > .header-text
    > .header-text-separator {
    display: none;
  }

  .text-icon-area {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .icon-area {
    display: flex;
    align-self: flex-end;
  }
  .clock-duration {
    display: flex;
    align-items: center;
  }

  .left-content{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .photos-count {
    display: flex;
    align-items: center;
    background: var(--theme-mode-highlight-color);
    border-radius: 8px;
    padding: 0.25rem;
  }</style>
