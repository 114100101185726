import { generateRootObjectSchema, generateStringSchema, generateUrlSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { AuthorParticleSpec, ShareStripParticleSpec, TextParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("Gallery Header Card", generateRootObjectSchema({
  container: generateAtomContainerSchema({
    defaultValues: { themeMode: 'primary' }
  }),
  slug: generateStringSchema("Slug", "The slug for this media item. This will be used to generate the media item link if an explicit link is not provided."),
  link: generateUrlSchema("Link", "The link for this media item. The default link will be generated from the slug if this is not provided (and the slug is)."),
  titleText: TextParticleSpec.generateSchema("Title Text", "The title text of the gallery.", {
    defaultValues: { size: '3rem', weight: '700' },
  }),
  descriptionText: TextParticleSpec.generateSchema("Description Text", "The description text of the gallery.", {
    defaultValues: { size: '1rem', weight: '400' },
  }),
  author: AuthorParticleSpec.generateSchema("Author", "The author of the article.", {
    defaultValues: { },
  }),
  /*
  socialLinks: ShareStripParticleSpec.generateSchema("Social Links", "The social links for the article.", {
    defaultValues: { },
    uneditableFields: { link: true }
  }),
  */
}));
