import { Registry } from "./Registry";

// Cool use of TypeScript class decorators to avoid boilerplate
// https://www.typescriptlang.org/docs/handbook/decorators.html#class-decorators
// ... and it's type safe too!

export function register<T>(registry: Registry<T>, key: string) {
  return function (target: new (...args: any[]) => T) {
    registry.register(key, target);
  };
}
