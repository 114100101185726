<script lang="ts">var _a, _b, _c, _d, _e, _f;
import 'yinzcam-cards';
import '../../../common/CardBase.svelte';
import { getFirstSourceStore } from '../../../utilities';
import '../../../common/CardsDataSourceRegistration';
import { CardsTabContextKey } from '../../../common/context';
import '../../../../../js/i18n';
import 'svelte/store';
import { getContext } from 'svelte';
import { windowMetrics as wm } from '../../../../../js/stores';
export let element;
export let sources;
export let source;
const tabContext = getContext(CardsTabContextKey);
$: mergedParams = (_a = tabContext === null || tabContext === void 0 ? void 0 : tabContext.mergedParamsComp) === null || _a === void 0 ? void 0 : _a.store;
$: source = getFirstSourceStore($sources);
let key;
let HomeTeam = {};
let AwayTeam = {};
let options = [];
let selectedOption = {};
let isDesktop;
$: isDesktop = $wm.width >= 768;
$: key = ((_b = element === null || element === void 0 ? void 0 : element.data) === null || _b === void 0 ? void 0 : _b.key) || "teamId";
$: HomeTeam = ((_c = $source === null || $source === void 0 ? void 0 : $source.HomeTeam) === null || _c === void 0 ? void 0 : _c._attributes) || {};
$: AwayTeam = ((_d = $source === null || $source === void 0 ? void 0 : $source.AwayTeam) === null || _d === void 0 ? void 0 : _d._attributes) || {};
$: options = [HomeTeam, AwayTeam];
$: {
    if (!(selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.Id)) {
        selectedOption = ((_f = (_e = options[1]) === null || _e === void 0 ? void 0 : _e.TriCode) === null || _f === void 0 ? void 0 : _f.toLowerCase()) === CONFIG.tricode ? options[1] : options[0];
        tabContext.setParam(key, selectedOption === HomeTeam ? "HOME" : "AWAY");
    }
}
const onSelectTeam = (option) => {
    selectedOption = option;
    tabContext.setParam(key, selectedOption === HomeTeam ? "HOME" : "AWAY");
};
</script>

<div class="team-selector">
  {#each options as option (option)}
    <button
      type="button"
      class="button"
      class:selected={option.Id === selectedOption.Id}
      on:click={e => onSelectTeam(option)}
    >
      {(isDesktop ? option.FullName : option.TriCode) || ''}
    </button>
  {/each}
</div>

<style lang="scss">*,
*:before,
*:after {
  box-sizing: border-box; }

.team-selector {
  width: 100%;
  height: 50px;
  display: flex; }
  .team-selector :global(.button) {
    width: unset;
    height: unset;
    min-width: unset;
    min-height: unset;
    text-transform: uppercase;
    flex: 1;
    border: solid 2px var(--theme-primary-foreground-color, black);
    border-radius: 0;
    font-size: 16px;
    line-height: 1;
    font-weight: bold;
    color: var(--theme-primary-foreground-color, black); }
    .team-selector :global(.button).selected {
      border-color: var(--theme-primary-highlight-color, white);
      background: var(--theme-primary-highlight-color, white);
      color: var(--theme-primary-foreground-color, black); }
    .team-selector :global(.button):not(:first-child) {
      border-left: none; }
    .team-selector :global(.button):first-child {
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px; }
    .team-selector :global(.button):last-child {
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px; }

@media (max-width: 767px) {
  .team-selector {
    height: 26px; }
    .team-selector :global(.button) {
      border: solid 1px var(--theme-primary-foreground-color, black);
      font-size: 11.6px;
      color: var(--theme-primary-foreground-color, black); } }</style>
