<script lang="ts">import ImageWithPlaceholder from '../../ImageWithPlaceholder/ImageWithPlaceholder.svelte';
import arrowImage from "../../image/round_arrow.png";
import inArrowImage from "../../image/Icon_PlayerOn@3x.png";
import outArrowImage from "../../image/Icon_PlayerOff@3x.png";
export let data = {
    Player1: {},
    Player2: {},
};
</script>

<div class="card-el">
  <div class="top-info">
    <span class="time">{data.Time}</span>
    <ImageWithPlaceholder src={data.TeamLogo} alt="TeamLogo" class="team-logo" placeholder="/static/images/default_club.png" />
  </div>
  <div class="player-in">
    <div class="player">
      <ImageWithPlaceholder
        src={data.Player1.ImageUrl}
        alt={data.Player1.Name}
        title={data.Player1.Name}
        class="player-img"
        placeholder="/static/images/download.png"
      />
    </div>
    <span class="change-label">on</span>
  </div>
  <div class="player-out">
    <span class="change-label">off</span>
    <div class="player">
      <ImageWithPlaceholder
        src={data.Player2.ImageUrl}
        alt={data.Player2.Name}
        title={data.Player2.Name}
        class="player-img"
        placeholder="/static/images/download.png"
      />
    </div>
  </div>
  <img
    src={arrowImage}
    alt="Round Arrow"
    class="round-arrow"
  />
  <div class="player-name">
    <img src={inArrowImage} alt="" class="arrow-in" />
    <span>{(data.Player1 && data.Player1.Name) ? data.Player1.Name : ""}</span>
  </div>
  <div class="player-name">
    <img src={outArrowImage} alt="" class="arrow-out" />
    <span>{(data.Player2 && data.Player2.Name) ? data.Player2.Name : ""}</span>
  </div>
</div>

<style lang="scss">*,
*:before,
*:after {
  box-sizing: border-box; }

.card-el {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 37px 20px 0 20px;
  background: var(--theme-primary-background-color, white);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 12px; }

.top-info {
  position: absolute;
  left: 16px;
  top: 11px;
  right: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .top-info .time {
    line-height: 31px;
    font-size: 20.2px;
    font-weight: bold;
    color: var(--theme-primary-accent-color, black);
    display: block; }
  .top-info :global(.team-logo) {
    height: 36px;
    display: block; }

.player {
  position: relative;
  background-color: #e1e1e1;
  border-radius: 50%;
  width: 80px;
  height: 80px; }
  .player :global(.player-img) {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: block; }

.player-in,
.player-out {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; }
  .player-in .change-label,
  .player-out .change-label {
    text-transform: uppercase;
    line-height: 34px;
    font-size: 28.6px;
    font-weight: bold;
    text-align: center;
    color: var(--theme-primary-foreground-color, black); }

.player-out {
  margin: -8px 0 0 0; }

.round-arrow {
  width: 56px;
  height: 56px;
  position: absolute;
  z-index: 1;
  top: calc(50% - 6px - 28px);
  left: calc(50% - 23px);
  display: block; }

.player-name {
  width: 100%;
  display: flex;
  align-items: center; }
  .player-name img {
    width: 14px;
    display: block;
    margin: 0 6.7px 0 0; }
  .player-name span {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: var(--theme-primary-foreground-color, black); }

@media (max-width: 1px) {
  .card-el {
    padding: 15px 10px 0 10px; }
  .top-info {
    position: absolute;
    left: 8px;
    top: 8px;
    right: 8px; }
    .top-info .time {
      line-height: 21px;
      font-size: 13.8px; }
    .top-info :global(.team-logo) {
      height: 26px; }
  .player {
    width: 60px;
    height: 60px; }
  .player-in .change-label,
  .player-out .change-label {
    font-size: 18px; }
  .player-out {
    margin: 5px 0 0 0; }
  .round-arrow {
    width: 40px;
    height: 40px;
    top: calc(50% - 12px);
    left: calc(50% - 20px); } }</style>
