import { Container } from "inversify";
import { TokenContainerModule } from "inversify-token";
import { YinzCamAPIRequestParameterComponentToken } from "yinzcam-api";
import { YinzCamInjectModule } from "yinzcam-inject";
import { DefaultYinzCamSegmentationService } from "./DefaultYinzCamSegmentationService";
import { YinzCamSegmentationRequestParameterComponent, YinzCamSegmentationRequestParameterComponentToken } from "./YinzCamSegmentationRequestParameterComponent";
import { YinzCamSegmentationServer, YinzCamSegmentationServerToken } from "./YinzCamSegmentationServer";
import { YinzCamSegmentationServiceToken } from "./YinzCamSegmentationService";

export let YinzCamSegmentationServiceModule: YinzCamInjectModule = new YinzCamInjectModule((container: Container): void => {
  container.load(new TokenContainerModule((bindToken) => {
    bindToken<YinzCamSegmentationServer>(YinzCamSegmentationServerToken).to(YinzCamSegmentationServer).inSingletonScope();
    //bindToken(YinzCamSegmentationRequestParameterComponentToken).toConstantValue(new YinzCamSegmentationRequestParameterComponent('YinzCamSegmentationRequestParameterComponent'));
    bindToken(YinzCamSegmentationServiceToken).to(DefaultYinzCamSegmentationService).inSingletonScope();
    //bindToken(YinzCamAPIRequestParameterComponentToken).toConstantValue(getToken(container, YinzCamSegmentationRequestParameterComponentToken));
    bindToken(YinzCamAPIRequestParameterComponentToken).toConstantValue(new YinzCamSegmentationRequestParameterComponent('YinzCamSegmentationRequestParameterComponent'));
  }));
});
