<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import "lodash";
import { BaseAtom } from "components/cards/atoms";
import { PageParticle } from "components/cards/particles/YinzCam";
export let container = undefined;
export let page = undefined;
</script>

<style></style>

<BaseAtom _isotope="EmbeddedPageAtom" {...container}>
  <PageParticle {...page} />
</BaseAtom>
