<script lang='ts'></script>

<div class="presented-by-card">
  <!--<div class="label">Presented By</div>
  <img src="/static/logos/WilliamHill_dark.png" alt="presented by" />-->
</div>

<style lang="scss">*, *::after, *::before {
  box-sizing: border-box; }

.presented-by-card {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14.6px; }
  .presented-by-card .label {
    height: 29px;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.61;
    letter-spacing: normal;
    color: var(--theme-primary-foreground-color, black); }
  .presented-by-card img {
    display: block;
    height: 37px; }</style>
