{#key fullscreenEnabled}
<MenuItem on:click={handleClick}>
  <div class="button-contents">
    {#if fullscreenEnabled}
    Minimize Editor
    {:else}
    Maximize Editor
    {/if}  
  </div>
</MenuItem>
{/key}

<style>
  *, *::before, *::after {
    box-sizing: border-box;
  }
  
  .button-contents {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    font-weight: bold;
    font-size: 13px;

    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0,0,0,0.24);
    text-transform: uppercase;
  }</style>

<script lang="ts">//iconF7={(fullscreenEnabled)? "arrow_down_right_arrow_up_left" : "arrow_up_left_arrow_down_right"} iconOnly {iconSize}
import { MenuItem } from 'framework7-svelte';
import { CardsPageContextKey } from './context';
import { getContext } from 'svelte';
export let iconSize = 20;
const pageContext = getContext(CardsPageContextKey);
const fullscreenStore = pageContext.editorModeManager.getFullscreenStore();
$: fullscreenEnabled = $fullscreenStore;
function handleClick() {
    pageContext.editorModeManager.toggleFullscreen();
}
</script>