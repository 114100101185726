<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import _, { memoize } from "lodash";
import { BaseAtom } from "components/cards/atoms";
import { onDestroy, onMount, setContext } from "svelte";
import { DIYCardContextKey } from "./context";
import { writable } from "svelte/store";
import DIYCardEditorTree from "./DIYCardEditorTree.svelte";
import { getBindingSchemaForPropertiesSchema, getSchemaForType } from "./schema";
import { v4 as uuid4 } from 'uuid';
import DIYCardEditorActions from "./DIYCardEditorActions.svelte";
import DIYCardContentTree from "./DIYCardContentTree.svelte";
import "components/cards/common/CardsEditorComponentInterfaces";
import { blobToDataUri } from "components/cards/utilities";
import "components/cards/common/CardsDataObjectTransform";
import { getNamed } from "inversify-token";
import ROOT from "inversify.config";
import { CardsDataSourceToken } from "components/cards/common/CardsDataSourceToken";
import { Button, Tabs, Tab, Row, Col } from 'framework7-svelte';
import "components/cards/common/CardsDataTransform";
import "json-schema";
import CardsEditorComponentEditPopup from "components/cards/common/CardsEditorComponentEditPopup.svelte";
function generateDefaultContentRoot() {
    return {
        id: uuid4(),
        name: "Root",
        type: "YinzCam__Core__Container",
        data: {},
        items: [
            { id: uuid4(), name: "New Item", type: "", data: {}, items: [] },
        ]
    };
}
const diySource = getNamed(ROOT, CardsDataSourceToken, 'DIYSource');
const editorModeEnabled = writable(false);
const selectedItemControls = writable(null);
export let container;
export const _editable = true;
export const _enableEditorMode = () => {
    var _a;
    if (!((_a = _editorData === null || _editorData === void 0 ? void 0 : _editorData.contentRoot) === null || _a === void 0 ? void 0 : _a.id)) {
        console.log('merge default editor data');
        //_editorData = DEFAULT_EDITOR_DATA;
        _editorData.contentRoot = generateDefaultContentRoot();
        //_.merge(_editorData, DEFAULT_EDITOR_DATA);
    }
    console.log({ _transformData });
    if ((_transformData === null || _transformData === void 0 ? void 0 : _transformData.type) !== 'OBJECT' || !(_transformData === null || _transformData === void 0 ? void 0 : _transformData.properties)) {
        _transformData.type = 'OBJECT';
        _transformData.properties = {};
    }
    editorModeEnabled.set(true);
};
export let _editorData = undefined;
export let _transformData = undefined;
export let _saveEditorData = (ed) => { };
export let _saveTransformData = (st) => { };
$: contentRoot = _editorData === null || _editorData === void 0 ? void 0 : _editorData.contentRoot;
const context = {
    editorModeEnabled,
    selectedItemControls,
    lastCanvasClickedItemId: writable(null),
    refresh: () => {
        contentRoot = contentRoot;
    },
};
setContext(DIYCardContextKey, context);
let winbox = null;
let windowRoot = null;
$: if ($editorModeEnabled && !winbox && windowRoot) {
    winbox = new WinBox({
        mount: windowRoot,
        width: '350px',
        height: '700px',
        y: '100px',
        x: '25px',
        index: 10000,
        onclose: function () {
            $editorModeEnabled = false;
            winbox = null;
            windowRoot = null;
            return false;
        }
    });
}
$: if (!$editorModeEnabled && winbox) {
    winbox.close();
}
let sourcePaths = diySource.getDataSourcePaths();
function onSourcePathChange(e) {
    _editorData.sourcePath = e.currentTarget.value;
    _editorData = _editorData;
    _saveEditorData(_editorData);
}
function refreshSourcePaths() {
    sourcePaths = diySource.getDataSourcePaths();
}
$: selectedItemPropertiesOptions = (function (controls) {
    if (!(controls === null || controls === void 0 ? void 0 : controls.item)) {
        return null;
    }
    return {
        componentId: controls.item.id,
        title: controls.item.name,
        data: controls.item.data,
        schema: getSchemaForType(controls.item.type),
        onUpdate: (data) => {
            controls.item.data = data;
            controls.refresh();
            _saveEditorData(_editorData);
        },
        onFileUpload: async (file, cbs) => {
            const id = uuid4();
            const dataUri = await blobToDataUri(file);
            onFileUpload(id, file.name, dataUri, cbs); // outer onFileUpload
            return id;
        },
    };
})($selectedItemControls);
function getOrCreateTransformForItem(itemId) {
    return _transformData.properties[itemId] = (_transformData.properties[itemId] || {});
}
function doSaveTransformData() {
    _saveTransformData(_transformData);
}
const getBindingSchemaForPropertiesSchemaCached = memoize(getBindingSchemaForPropertiesSchema, (a, b) => JSON.stringify({ a, b }));
$: sourcePath = _editorData.sourcePath;
const sourceSchema = writable(null);
$: diySource.getResponseSchema(sourcePath).then((s) => sourceSchema.set(s));
$: selectedItemBindingsOptions = (function (propOpts, ss) {
    if (!(_editorData === null || _editorData === void 0 ? void 0 : _editorData.sourcePath) || !(propOpts === null || propOpts === void 0 ? void 0 : propOpts.schema) || !(propOpts === null || propOpts === void 0 ? void 0 : propOpts.componentId) || !ss) {
        return null;
    }
    const itemTransform = getOrCreateTransformForItem(propOpts.componentId);
    const schema = getBindingSchemaForPropertiesSchemaCached(propOpts.schema, ss);
    return {
        componentId: propOpts.componentId,
        title: propOpts.title,
        data: itemTransform,
        schema: schema,
        onUpdate(data) {
            //console.log('TRANSFORM UPDATE', data);
            if (_.isEqual(itemTransform, data)) {
                return;
            }
            _.assign(itemTransform, data);
            doSaveTransformData();
        }
    };
})(selectedItemPropertiesOptions, $sourceSchema);
/*
$: selectedItemBindingsOptionsPromise = (async function(cardProperties: DIYCardProperties, cardTransform: CardsDataObjectTransform, propOpts: CardsEditorComponentEditorOptions) {
  console.log("RELOAD selectedItemBindingsOptionsPromise!!!");
  if (!_editorData?.sourcePath || !propOpts) {
    return null;
  }
  const sourceSchema = diySource.getResponseSchema(_editorData.sourcePath);
  const itemTransform = getOrCreateTransformForItem(propOpts.componentId);
  return {
    componentId: propOpts.componentId,
    title: propOpts.title,
    data: itemTransform,
    schema: getBindingSchemaForPropertiesSchema(propOpts.schema, sourceSchema),
    onUpdate(data) {
      console.log('TRANSFORM UPDATE', data);
      if (_.isEqual(itemTransform, data)) {
        return;
      }
      _.assign(itemTransform, data);
      doSaveTransformData();
    }
  } as CardsEditorComponentEditorOptions;
})(undefined, undefined, selectedItemPropertiesOptions);
*/
function onLayerTypeChange(e) {
    if ($selectedItemControls === null || $selectedItemControls === void 0 ? void 0 : $selectedItemControls.item) {
        $selectedItemControls.item.type = e.currentTarget.value;
        $selectedItemControls.refresh();
    }
}
// TODO: Move this stuff into a class
const fileUploads = {};
function sendMessage(message) {
    var _a;
    // sanitize message by removing any property starting with __
    const payload = JSON.stringify(message, (k, v) => {
        return (k === null || k === void 0 ? void 0 : k.startsWith('__')) ? undefined : v;
    });
    (_a = window === null || window === void 0 ? void 0 : window.parent) === null || _a === void 0 ? void 0 : _a.postMessage(payload, '*');
}
const onFileUpload = (id, name, dataUri, cbs) => {
    fileUploads[id] = { id, cbs }; // no need to keep name or dataUri around, just takes up memory
    sendMessage({ type: "UPLOAD_FILE", data: { id, name, dataUri } });
    cbs.onFileUploadStarted(id);
};
function handleFileUploadMessage(message, cb) {
    var _a;
    const fu = fileUploads[(_a = message.data) === null || _a === void 0 ? void 0 : _a.id];
    if (!fu) {
        console.error("PageBuilder: received message for unknown file upload", message);
        return;
    }
    cb(message.data, fu.cbs);
}
function handleIncomingMessage(e) {
    const payload = e.data;
    if (typeof payload !== 'string' || payload.charAt(0) !== '{') {
        // sometimes browsers will send down garbage
        return;
    }
    const message = JSON.parse(payload);
    //console.log('PAGE BUILDER RECEIVED MESSAGE', message);
    switch (message.type) {
        case 'FILE_UPLOAD_PROGRESS':
            handleFileUploadMessage(message, (data, cbs) => cbs.onFileUploadProgress(data.id, data.percent));
            break;
        case 'FILE_UPLOAD_COMPLETE':
            handleFileUploadMessage(message, (data, cbs) => cbs.onFileUploadComplete(data.id, data.url));
            break;
        case 'FILE_UPLOAD_ERROR':
            handleFileUploadMessage(message, (data, cbs) => cbs.onFileUploadError(data.id, data.reason));
            break;
        default:
            console.warn("PageBuilder: unrecognized message type", message.type);
            break;
    }
}
onMount(() => {
    window.addEventListener('message', handleIncomingMessage);
});
onDestroy(() => {
    window.removeEventListener('message', handleIncomingMessage);
});
</script>

<style>
  .diy-card-content {
    width: 100%;
    min-height: 2rem;
  }

  .diy-card-editor-window-content {
    padding: 1rem;
  }

  h5 {
    margin-bottom: 0.5rem;
  }

  .section-divider {
    height: 1px;
    width: 100%;
    background-color: black;
    margin: 20px 0px;
  }</style>

<BaseAtom _isotope="DIYCardAtom" {...container}>
  <div class="diy-card-content">
    <DIYCardContentTree root={contentRoot} />
  </div>
  {#if $editorModeEnabled}
  <div class="diy-card-editor-window-content" bind:this={windowRoot}>
    <h5>Card Properties</h5>
    <label for="diy-card-source-path">Data Source:</label>
    <select name="diy-card-source-path" value={_editorData?.sourcePath || ""} on:change={onSourcePathChange}>
      {#await sourcePaths}
        <option value="">Loading data sources...</option>
      {:then paths}
        <option value="">Select Data Source</option>
        {#each paths as path (path.path)}
          <option value={path.path} selected={path.path === _editorData?.sourcePath}>{path.name}</option>
        {/each}
      {/await}
    </select>
    <div class="section-divider"></div>
    <h5>Layers</h5>
    <DIYCardEditorActions />
    <DIYCardEditorTree root={contentRoot} />
    <div class="section-divider"></div>
    {#if $selectedItemControls}
    <Row>
      <Col>
        <Button fill round tabLink="#tab-layer-props" tabLinkActive>Properties</Button>
      </Col>
      {#if sourcePath && sourceSchema}
      <Col>
        <Button fill round tabLink="#tab-layer-binds">Bindings</Button>
      </Col>
      {/if}
    </Row>
    <Tabs>
      <Tab id="tab-layer-props" tabActive>
        <h5>Layer Properties</h5>
        <label for="diy-card-layer-type">Layer Type:</label>
        <select name="diy-card-layer-type" value={$selectedItemControls?.item?.type || ""} on:change={onLayerTypeChange}>
          <option value="">Select Layer Type</option>
          <option value="YinzCam__Core__Container">Container Layer</option>
          <option value="YinzCam__Core__Text">Text Layer</option>
          <option value="YinzCam__Core__Image">Image Layer</option>
        </select>
        <CardsEditorComponentEditPopup options={selectedItemPropertiesOptions} />
      </Tab>
      <Tab id="tab-layer-binds">
        <h5>Layer Bindings</h5>
        {#if selectedItemBindingsOptions}
          <CardsEditorComponentEditPopup options={selectedItemBindingsOptions} />
        {/if}
      </Tab>
    </Tabs>
    {/if}
  </div>
  {/if}
</BaseAtom>
