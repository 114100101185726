// no-export
import { injectable } from "inversify";
import { injectToken } from "inversify-token";
import { YinzCamSegmentationRequestParameterComponent, YinzCamSegmentationRequestParameterComponentToken } from "./YinzCamSegmentationRequestParameterComponent";
import { YinzCamSegmentationService } from "./YinzCamSegmentationService";
import { YinzCamSegmentationServiceComponent } from "./YinzCamSegmentationServiceComponent";
import { DefaultYinzCamSegmentationServiceComponent } from "./DefaultYinzCamSegmentationServiceComponent";
import { YinzCamSegmentationServer, YinzCamSegmentationServerToken } from "./YinzCamSegmentationServer";

@injectable()
export class DefaultYinzCamSegmentationService implements YinzCamSegmentationService {
  private readonly component: DefaultYinzCamSegmentationServiceComponent;

  public constructor(
    @injectToken(YinzCamSegmentationServerToken) private readonly server: YinzCamSegmentationServer,
    @injectToken(YinzCamSegmentationRequestParameterComponentToken) private readonly rpct: YinzCamSegmentationRequestParameterComponent
  ) {
    this.component = new DefaultYinzCamSegmentationServiceComponent(server.getSegmentationToken(), rpct);
    this.component.store.subscribe((value: string) => {});
  }

  public getComponent(): YinzCamSegmentationServiceComponent {
    throw new Error("Method not implemented.");
  }
}