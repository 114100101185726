<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import { get, merge } from "lodash";
import { BaseAtom } from "components/cards/atoms";
import { TextParticle, ImageParticle, ButtonParticle, } from "components/cards/particles/YinzCam";
import "svelte/store";
const themeModeValue = "primary";
export let container;
export let overlayPadding = undefined;
export let imgPlayerImage;
export let txtFirstName;
export let txtLastName;
export let txtFullName;
export let txtPlayerPosition;
export let txtBodyText;
export let btnPlayerBio;
export let txtPlayerNumber;
export let colorBGShadow;
export let colorPillBorder;
export let playerStats = undefined;
export let colorCardBorder;
export let colorTurnIcon;
export let colorFlag;
export let colorPlayerNumber;
let atomWidthRem;
$: fullName = `${txtFirstName === null || txtFirstName === void 0 ? void 0 : txtFirstName.text} ${txtLastName === null || txtLastName === void 0 ? void 0 : txtLastName.text}`;
$: statsList = ["Appearances", "Minutes Played", "Games Started", "Wins"];
$: playerNumber = txtPlayerNumber === null || txtPlayerNumber === void 0 ? void 0 : txtPlayerNumber.text;
$: displayStats = playerStats === null || playerStats === void 0 ? void 0 : playerStats.filter(player => { var _a; return statsList === null || statsList === void 0 ? void 0 : statsList.includes((_a = player === null || player === void 0 ? void 0 : player.name) === null || _a === void 0 ? void 0 : _a.text); });
</script>

<BaseAtom
    _isotope="PlayerRosterFlipAtom"
    {...merge({}, container, { themeMode: themeModeValue })}
    _cssVariablePrefix="player-roster"
    {overlayPadding}
    {colorBGShadow}
    {colorPillBorder}
    {colorCardBorder}
    {colorTurnIcon}
    {colorFlag}
    {colorPlayerNumber}
    bind:_clientWidthRem={atomWidthRem}
>
    <div class="player-card">
        <div class="card-inner">
            <div class="card-front">
                <div class="icon-3d-turn">
                    <svg
                        width="42"
                        height="42"
                        viewBox="0 0 42 42"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M35.4103 17.8299C35.6007 17.9695 35.9114 18.2258 36.1856 18.6063C37.8646 21.1095 34.125 21.528 34.125 20.3438C34.125 19.9271 33.2621 19.5407 31.7898 19.2225C31.4472 19.1485 31.0716 19.0781 30.6661 19.0119C28.2672 18.6204 24.8247 18.375 21 18.375C17.1753 18.375 13.7328 18.6204 11.3339 19.0119C10.9284 19.0781 10.5528 19.1485 10.2102 19.2225C8.73786 19.5407 7.875 19.9271 7.875 20.3438C7.875 20.7604 8.73786 21.1468 10.2102 21.465C10.5528 21.539 10.9284 21.6094 11.3339 21.6756C13.7328 22.0671 17.1753 22.3125 21 22.3125V20.1296C21 19.7349 21.28 19.5793 21.6231 19.7664L27.3567 23.0575C27.7092 23.2654 27.7092 23.5769 27.3567 23.7842L21.6231 27.0763C21.28 27.2634 21 27.1078 21 26.7127V24.9375C17.2871 24.9375 13.8695 24.7128 11.3298 24.3318C10.0803 24.1444 8.92697 23.9025 8.02904 23.5841C7.59613 23.4306 7.06376 23.2051 6.58974 22.8576C6.18766 22.5629 5.25 21.7478 5.25 20.3438C5.25 18.9397 6.18766 18.1246 6.58974 17.8299C7.06376 17.4824 7.59613 17.2569 8.02904 17.1034C8.92697 16.785 10.0803 16.5431 11.3298 16.3557C13.8695 15.9747 17.2871 15.75 21 15.75C24.7129 15.75 28.1305 15.9747 30.6702 16.3557C31.9197 16.5431 33.073 16.785 33.971 17.1034C34.4039 17.2569 34.9362 17.4824 35.4103 17.8299Z"
                            fill="var(--player-roster-color-turn-icon, var(--theme-mode-accent-color, unset))"
                        />
                    </svg>
                </div>
                <div class="main-container">
                    <div class="background-container">
                        <ImageParticle _block={true} {...imgPlayerImage} />
                    </div>
                    <div class="overlay-area" data-label={playerNumber}>
                        <div class="player-name">
                            <TextParticle {...txtFirstName} />
                            <TextParticle {...txtLastName} />
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-back">
                <!-- <div class="icon-3d-turn">
                    <svg
                        width="42"
                        height="42"
                        viewBox="0 0 42 42"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M35.4103 17.8299C35.6007 17.9695 35.9114 18.2258 36.1856 18.6063C37.8646 21.1095 34.125 21.528 34.125 20.3438C34.125 19.9271 33.2621 19.5407 31.7898 19.2225C31.4472 19.1485 31.0716 19.0781 30.6661 19.0119C28.2672 18.6204 24.8247 18.375 21 18.375C17.1753 18.375 13.7328 18.6204 11.3339 19.0119C10.9284 19.0781 10.5528 19.1485 10.2102 19.2225C8.73786 19.5407 7.875 19.9271 7.875 20.3438C7.875 20.7604 8.73786 21.1468 10.2102 21.465C10.5528 21.539 10.9284 21.6094 11.3339 21.6756C13.7328 22.0671 17.1753 22.3125 21 22.3125V20.1296C21 19.7349 21.28 19.5793 21.6231 19.7664L27.3567 23.0575C27.7092 23.2654 27.7092 23.5769 27.3567 23.7842L21.6231 27.0763C21.28 27.2634 21 27.1078 21 26.7127V24.9375C17.2871 24.9375 13.8695 24.7128 11.3298 24.3318C10.0803 24.1444 8.92697 23.9025 8.02904 23.5841C7.59613 23.4306 7.06376 23.2051 6.58974 22.8576C6.18766 22.5629 5.25 21.7478 5.25 20.3438C5.25 18.9397 6.18766 18.1246 6.58974 17.8299C7.06376 17.4824 7.59613 17.2569 8.02904 17.1034C8.92697 16.785 10.0803 16.5431 11.3298 16.3557C13.8695 15.9747 17.2871 15.75 21 15.75C24.7129 15.75 28.1305 15.9747 30.6702 16.3557C31.9197 16.5431 33.073 16.785 33.971 17.1034C34.4039 17.2569 34.9362 17.4824 35.4103 17.8299Z"
                            fill="var(--player-roster-color-turn-icon, var(--theme-mode-accent-color, unset))"
                        />
                    </svg>
                </div> -->
                <div class="card-back-shape">
                    <svg
                        width="35"
                        height="92"
                        viewBox="0 0 35 92"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0 15V72V92H15L35 72V10C35 4.47715 30.5228 0 25 0H15L0 15Z"
                            fill="var(--player-roster-bg-shadow-color, var(--theme-mode-accent-color, unset))"
                        />
                    </svg>
                </div>
                <div class="header-text">
                    <TextParticle {...txtFullName} text={fullName} />
                    <TextParticle {...txtPlayerPosition} />
                </div>
                <div class="body-text">
                    <TextParticle {...txtBodyText} />
                </div>
                <div class="player-stats">
                    {#each displayStats || [] as stat}
                        {#if get(stat, "name.text") !== undefined && get(stat, "value.text") !== undefined}
                            <div class="player-stat-pill">
                                <span class="pill-stat-name" title="tooltip"
                                    ><TextParticle
                                        {...stat.name}
                                        text={stat.name.text}
                                    /></span
                                >
                                <TextParticle
                                    {...stat.value}
                                    text={stat.value.text}
                                />
                            </div>
                        {/if}
                    {/each}
                </div>
                <div class="cta-button">
                    <ButtonParticle {...btnPlayerBio} />
                </div>
            </div>
        </div>
    </div>
</BaseAtom>

<style>
    .player-card {
        width: 16.5rem;
        height: 20rem;
    }

    .icon-3d-turn {
        position: absolute;
        right: 1.5rem;
    }

    .card-inner {
        position: relative;
        height: 100%;
        text-align: center;
        transition: transform 0.8s;
        -webkit-transition: transform 0.8s;
        transform-style: preserve-3d;
        -webkit-transform-style: preserve-3d;
    }

    .player-card:hover .card-inner {
        transform: rotateY(-180deg);
        -webkit-transform: rotateY(-180deg);
    }

    .player-card:hover .card-back {
        z-index: 1; /* make sure links on the back take priority when the card is flipped */
    }

    .card-front,
    .card-back {
        position: absolute;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        -webkit-perspective: 0;
        -webkit-transform: translate3d(0, 0, 0);
    }

    .card-front {
        color: var(--theme-mode-background-color, unset);
    }

    .card-back {
        background: var(--theme-mode-background-color, unset);
        transform: rotateY(-180deg);
        border-radius: 0.5rem;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        border: 1px solid
            var(
                --player-roster-color-card-border,
                var(--theme-mode-background-color, unset)
            );
    }

    .main-container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        background-size: cover;
        background-position: top center;
        cursor: pointer;
        position: relative;
        height: 100%;
        width: 100%;
    }

    .main-container > .background-container {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .main-container > .overlay-area {
        padding: var(--image-background-media-overlay-padding, unset);
        z-index: 1;
        width: 100%;
        height: 100%;
        border: 1px solid
            var(
                --player-roster-color-card-border,
                var(--theme-mode-background-color, unset)
            );
        border-radius: 10px;
        background: linear-gradient(
            180deg,
            rgba(40, 44, 48, 0) 50%,
            var(--theme-mode-accent-color) 100%
        );
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        text-align: left;
    }

    .player-name {
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .card-back-shape {
        position: absolute;
        right: -1.25rem;
    }

    .card-front::after {
        position: absolute;
        top: 3.75rem;
        left: -3rem;
        content: "";
        width: 0;
        height: 0;
        border-right: 3rem solid
            var(
                --player-roster-color-bg-shadow,
                var(--theme-mode-accent-color, unset)
            );
        border-top: 3rem solid
            var(
                --player-roster-color-bg-shadow,
                var(--theme-mode-accent-color, unset)
            );
        border-bottom: 2rem solid transparent;
    }

    .overlay-area::after {
        position: absolute;
        content: attr(data-label);
        top: 1rem;
        left: -3rem;
        padding: 0rem 0.75rem 0.25rem 1rem;
        width: 6rem;
        background: var(--player-roster-color-flag, var(--theme-mode-highlight-color, unset));
        color: var(--player-roster-color-player-number, var(--theme-mode-foreground-color));
        display: flex;
        justify-content: right;
        align-items: right;
        border-radius: 0.625rem 0.625rem 0.625rem 0;
        font-weight: 900;
        font-size: 3rem;
    }

    .header-text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        border-bottom: 0.125rem solid var(--theme-mode-accent-color);
    }

    .body-text {
        text-align: left;
    }

    .player-stats {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        gap: 0.25rem;
        justify-content: space-between;
        align-items: center;
    }

    .player-stat-pill {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0.5rem 0.5rem;
        align-items: center;
        border: 1px solid var(--player-roster-color-pill-border, #ccc);
        border-radius: 0.75rem;
        gap: 0.5rem;
    }

    .pill-stat-name {
        overflow: hidden;
        text-align: left;
    }</style>
