<Boundary>
  <CardsContainerWrapper style="width: 100%;" {editorOptions}>
  <!-- We need to loop through the list here because of drag and drop editing. -->
  {#each layout.elements as element, i (element.id)}
    <div class:display-none={i !== 0}>
      <CardsElement {parentSequenceId} sequenceId={generateSequenceId('ELT', parentSequenceId, i)} {element} updater={elementUpdater.bind(null, i)} />
    </div>
  {/each}
  </CardsContainerWrapper>
</Boundary>

<style>
  *, *::before, *::after {
    box-sizing: border-box;
  }</style>

<script lang="ts">import "yinzcam-cards";
import { generateSequenceId } from "../utilities";
import CardsElement from "./CardsElement.svelte";
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import "./CardsEditorInterfaces";
import CardsContainerWrapper from "./CardsContainerWrapper.svelte";
export let parentSequenceId = "";
export let layout;
export let updater;
function elementUpdater(i, element) {
    layout.elements[i] = element;
    updater(layout.elements);
}
let editorOptions;
$: editorOptions = {
    parentSequenceId,
    items: layout.elements,
    updater
};
</script>
