<script context="module" lang="ts">import 'json-schema';
import 'components/cards/common/CardsElementInterfaces';
export function getDisplayName() {
    return "Media: Video Player Card";
}
export function getElementConfigSpec() {
    return {
        type: "object",
        properties: {
            name: {
                type: "string",
                title: "Name",
                description: "The name of the video."
            },
            width: {
                type: "string",
                title: "Width",
                description: "The width of the video player in standard CSS units."
            },
            publishDateTime: {
                type: "string",
                title: "publishDateTime",
                description: "The publish date and time of the video."
            },
            videoSourceType: {
                type: "string",
                title: "Video Source Type",
                description: "The type of video source."
            },
            videoSourceLink: {
                type: "string",
                format: "url",
                title: "Video Source Link",
                description: "The link for Direct Link video sources."
            },
            videoSourceKey: {
                type: "string",
                title: "Video Source Key",
                description: "The key for keyed video sources.",
                style: true
            }
        }
    };
}
export function getElementDataSourceSpec() {
    return {
        sources: [
            {
                type: 'SOURCE',
                title: 'Card Data',
                description: 'Arbitrary source of data for this card matching the configuration fields.',
                classes: '*'
            }
        ]
    };
}
</script>
  
  <script lang="ts">import CardBase from "components/cards/common/CardBase.svelte";
import { onMount } from 'svelte';
import StreamAMGPlayer from './components/StreamAMGPlayer.svelte';
import "yinzcam-cards";
import { getFirstSourceStore, getTemplatedElementDataFromSource, formatDateTimeLocal } from "components/cards/utilities";
import "svelte/store";
export let element;
export let sources;
export let sourceStore;
$: {
    sourceStore = getFirstSourceStore($sources);
}
export const contentAvailable = true;
$: name = getTemplatedElementDataFromSource(element, "name", $sourceStore);
$: width = getTemplatedElementDataFromSource(element, "width", $sourceStore);
$: publishDateTime = getTemplatedElementDataFromSource(element, "publishDateTime", $sourceStore);
$: videoSourceType = getTemplatedElementDataFromSource(element, "videoSourceType", $sourceStore);
$: videoSourceLink = getTemplatedElementDataFromSource(element, "videoSourceLink", $sourceStore);
$: videoSourceKey = getTemplatedElementDataFromSource(element, "videoSourceKey", $sourceStore);
$: console.log("VIDEO PLAYER", name, videoSourceType, videoSourceLink, videoSourceKey);
$: publishDateTimeDisplay = formatDateTimeLocal(publishDateTime);
let videoEl;
onMount(() => {
    return () => {
        if (videoEl) {
            videoEl.pause();
            videoEl.currentTime = 0;
        }
    };
});
</script>
  
  <CardBase>
    <div class="outer" style={`--video-player-width: ${width}`}>
    {#if videoSourceType === 'Direct Link'}
        <div class="inner">
            <video class="video-container" bind:this={videoEl} src={videoSourceLink} controls autoplay>
                <track kind="captions">
            </video>
        </div>
    {:else if videoSourceType === 'Web Embed'}
        <div class="inner">
            <iframe class="video-container" title={name} src={videoSourceLink} style="border: none;" allowfullscreen
            sandbox="allow-scripts allow-presentation allow-same-origin"
            allow="autoplay; fullscreen; picture-in-picture; xr-spatial-tracking; clipboard-write">
            </iframe>
        </div>
    {:else if videoSourceType === 'StreamAMG'}
        <div class="inner">
            <StreamAMGPlayer {width} entryId={videoSourceKey} />
        </div>
    {:else}
        <div class="inner">
            (unknown video source)
        </div>
    {/if}
      <div class="info-box">
        <div class="info-box-title">{name}</div>
        <div class="info-box-date">{publishDateTimeDisplay}</div>
      </div>
    </div>
  </CardBase>
  
  <style>
    div.outer {
        max-width: 90vw;
        display: flex;
        flex-direction: column;
    }
    div.inner, video, iframe {
        max-width: 100%;
    }

    .video-container {
      width: var(--video-player-width, auto);
    }

    div.info-box {
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: black;
      color: white;
      padding: 1.75rem 2.5rem;
      gap: 0.5rem;
    }

    div.info-box-title {
      color: #E0E0E0;
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 1.125rem; /* 90% */
    }

    div.info-box-date {
      color: #FAFAFA;
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.25rem; /* 125% */
    }</style>
  