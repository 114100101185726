
<script lang="ts">import "lodash";
import { getIconForType } from "./schema";
import { TreeviewItem } from 'framework7-svelte';
import { getContext } from "svelte";
import { DIYCardContextKey } from "./context";
import { v4 as uuid4 } from 'uuid';
export let parentControls = undefined;
export let item = undefined;
const context = getContext(DIYCardContextKey);
const selectedItemControls = context === null || context === void 0 ? void 0 : context.selectedItemControls;
const lastCanvasClickedItemId = context === null || context === void 0 ? void 0 : context.lastCanvasClickedItemId;
$: icon = getIconForType(item === null || item === void 0 ? void 0 : item.type);
$: name = (item === null || item === void 0 ? void 0 : item.name) || "";
$: console.log("icon change", icon);
const itemControls = {
    parent: null,
    item: null,
    refresh: function () {
        var _a, _b;
        item = item;
        context.refresh();
        if (((_a = this.item) === null || _a === void 0 ? void 0 : _a.id) === ((_b = $selectedItemControls === null || $selectedItemControls === void 0 ? void 0 : $selectedItemControls.item) === null || _b === void 0 ? void 0 : _b.id)) {
            $selectedItemControls = this;
        }
    },
    addChild: function () {
        if (item) {
            item.items.push({ id: uuid4(), name: "New Item", type: "", data: {}, items: [] });
            this.refresh();
        }
    },
    removeChild: function (childId) {
        if (childId && item) {
            const childIndex = item === null || item === void 0 ? void 0 : item.items.findIndex((item) => item.id === childId);
            if (childIndex !== -1) {
                item.items.splice(childIndex, 1);
            }
            this.refresh();
        }
    },
    removeSelf: function () {
        if (parentControls && item) {
            parentControls.removeChild(item.id);
        }
    }
};
function onSelect() {
    var _a;
    if ((item === null || item === void 0 ? void 0 : item.id) === ((_a = $selectedItemControls === null || $selectedItemControls === void 0 ? void 0 : $selectedItemControls.item) === null || _a === void 0 ? void 0 : _a.id)) {
        // already selected so nothing to do
        return;
    }
    if (item) {
        itemControls.parent = parentControls;
        itemControls.item = item;
        $selectedItemControls = itemControls;
        // clear last canvas item clicked so the previous element doesn't get immediately reselected
        $lastCanvasClickedItemId = null;
    }
}
$: if ($lastCanvasClickedItemId === (item === null || item === void 0 ? void 0 : item.id)) {
    // TODO: if already selected, cycle up through parents?
    onSelect();
}
let renaming = false;
function onRename() {
    renaming = true;
}
function onRenameKeypress(e) {
    if (e.keyCode === 13) {
        e.preventDefault();
        onRenameEnd(e);
    }
}
function onRenameEnd(e) {
    if (renaming) {
        renaming = false;
        item.name = e.target.innerText;
        itemControls.refresh();
    }
}
</script>

<style></style>

<TreeviewItem selectable selected={$selectedItemControls?.item?.id === item?.id} onClick={onSelect}>
  <span slot="content-start">
    <i class="icon f7-icons">{icon}</i>
  </span>
  <span slot="content" contenteditable={renaming} on:click={onRename} on:keypress={onRenameKeypress} on:blur={onRenameEnd}>
    {name}
  </span>
  {#each (item?.items || []) as subItem }
    <svelte:self parentControls={itemControls} item={subItem} />
  {/each}
</TreeviewItem>
