<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import "svelte";
import "lodash";
import { BaseAtom } from "components/cards/atoms";
import { TextParticle, ImageParticle, BadgeParticle, } from "components/cards/particles/YinzCam";
export let container;
export let overlayPadding;
export let txtTimelineHeading;
export let imgHomeLogo;
export let imgAwayLogo;
export let matchStatus;
export let colorTimelineBorder;
export let arrTimelineEvents = undefined;
const generateDisplayEvents = (arrTimelineEvents, mins, totalTimeEvents, matchStatus) => {
    let timelineArray = Array.from(Array(mins), () => new Array(1).fill(""));
    if (matchStatus === "pre")
        return timelineArray;
    if (matchStatus === "post")
        totalTimeEvents.reverse();
    let timeStartIndex = Math.floor(mins / 3);
    let timeIndexMap = {};
    totalTimeEvents.forEach((event, idx) => Object.assign(timeIndexMap, { [event]: timeStartIndex + idx }));
    arrTimelineEvents === null || arrTimelineEvents === void 0 ? void 0 : arrTimelineEvents.forEach((event, index) => {
        var _a;
        let sessionTime = Math.floor(mins / 3);
        let timeIndex = timeIndexMap[event === null || event === void 0 ? void 0 : event.eventTime];
        let eventIndex = (event === null || event === void 0 ? void 0 : event.eventTeam) === "home-team"
            ? timeIndex - sessionTime
            : timeIndex + sessionTime;
        (_a = timelineArray[eventIndex]) === null || _a === void 0 ? void 0 : _a.push((event === null || event === void 0 ? void 0 : event.badgeType) && event);
        timelineArray[timeIndexMap[event === null || event === void 0 ? void 0 : event.eventTime]] = Object.assign(Object.assign({}, event), { badgeType: "time-display", badgeTextOne: Object.assign(Object.assign({}, event === null || event === void 0 ? void 0 : event.badgeTextOne), { text: event === null || event === void 0 ? void 0 : event.eventTime }) });
    });
    return timelineArray;
};
$: totalTimeEvents = [
    ...new Set(arrTimelineEvents === null || arrTimelineEvents === void 0 ? void 0 : arrTimelineEvents.map((ele) => ele.eventTime)),
];
$: gridColumns = matchStatus === "pre" ? 5 : totalTimeEvents === null || totalTimeEvents === void 0 ? void 0 : totalTimeEvents.length;
$: mins = gridColumns * 3;
$: homeTeamLastIndex = gridColumns - 1;
$: timeCellLastIndex = homeTeamLastIndex + gridColumns;
$: awayTeamFirstIndex = timeCellLastIndex + 1;
$: displayTimelineEvents = generateDisplayEvents(arrTimelineEvents, mins, totalTimeEvents, matchStatus);
let atomWidthRem;
</script>

<BaseAtom
    bind:_clientWidthRem={atomWidthRem}
    _isotope="MCTimelineAtom"
    _cssVariablePrefix="mc-timeline"
    {...container}
    {overlayPadding}
    {colorTimelineBorder}
    {gridColumns}
>
    <div class="main-container">
        <div class="first-row">
            <div class="timeline-text">
                <TextParticle {...txtTimelineHeading} />
            </div>
        </div>
        <div class="second-row">
            <div class="logo-column">
                <div class="home-logo">
                    <ImageParticle {...imgHomeLogo} _block={true} />
                </div>
                <div class="away-logo">
                    <ImageParticle {...imgAwayLogo} _block={true} />
                </div>
            </div>
            <div class="match-minutes-column">
                {#if matchStatus === "pre"}
                    <div class="grid-box-pre">
                        {#each displayTimelineEvents || [] as event, index}
                            <div
                                class={`grid-item ${index <= homeTeamLastIndex ? "home-team-cell" : index > timeCellLastIndex ? "away-team-cell" : "time-cell"}`}
                            >
                                {event}
                            </div>
                        {/each}
                        <div class="pre-game-text">
                            Come back to view the timeline once the match starts.
                        </div>
                    </div>
                {:else}
                    <div class="grid-box">
                        {#each displayTimelineEvents || [] as event, index}
                            <div
                                class={`grid-item ${index} ${index <= homeTeamLastIndex ? "home-team-cell" : index > timeCellLastIndex ? "away-team-cell" : "time-cell"}`}
                            >
                                {#if event?.length > 0}
                                    {#each event || [] as item}
                                        {#if item?.badgeType}
                                            <div
                                                class={` ${index <= homeTeamLastIndex ? "home-team-cell" : index > timeCellLastIndex ? "away-team-cell" : "time-cell"}`}
                                            >
                                                <BadgeParticle
                                                    {...item?.badgeParticleProps}
                                                    badgeType={item?.badgeType}
                                                    textOne={item?.badgeTextOne}
                                                    textTwo={item?.badgeTextTwo}
                                                />
                                            </div>
                                        {:else}
                                            <div
                                                class={`${index <= homeTeamLastIndex ? "home-team-cell-empty" : index > timeCellLastIndex ? "away-team-cell-empty" : "time-cell"}`}
                                            >
                                                {item}
                                            </div>
                                        {/if}
                                    {/each}
                                {:else if event?.badgeType}
                                    <div
                                        class={` ${index <= homeTeamLastIndex ? "home-team-cell" : index > timeCellLastIndex ? "away-team-cell" : "time-cell"}`}
                                    >
                                        <BadgeParticle
                                            {...event?.badgeParticleProps}
                                            badgeType={event?.badgeType}
                                            textOne={event?.badgeTextOne}
                                            textTwo={event?.badgeTextTwo}
                                        />
                                    </div>
                                {/if}
                            </div>
                        {/each}
                    </div>
                {/if}
            </div>
        </div>
    </div>
    <div></div>
</BaseAtom>

<style>
    .main-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: var(--mc-timeline-overlay-padding, unset);
    }
    .first-row {
        width: 100%;
        align-self: flex-start;
        border-bottom: 1px solid
            var(--mc-timeline-color-timeline-border, #dedede);
    }
    .second-row {
        display: flex;
        align-items: stretch;
    }
    .logo-column {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 1rem;
        z-index: 1;
        box-shadow: 24px 0px 15px -15px rgba(0, 0, 0, 0.25);
    }
    .match-minutes-column {
        display: grid;
        align-content: center;
        justify-content: center;
        place-content: center;
    }
    .match-minutes-column > * {
        grid-area: 1/1;
        background-color: #dedede;
    }

    .grid-box-pre{
        position: relative;
        display: grid;
        grid-template-columns: repeat(
            var(--mc-timeline-grid-columns),
            10rem
        );
        grid-auto-rows: 7rem 0 7rem;
        align-items: stretch;
        overflow: auto;
        grid-gap: 1px;
        gap: 1px;

    }
    .pre-game-text{
        position: absolute;
        top: 40%;
        left: 25%;
        font-size: 1rem;
        font-weight: 500;
    }
    .grid-box {
        display: grid;
        grid-template-columns: repeat(
            var(--mc-timeline-grid-columns),
            minmax(-webkit-max-content, 1fr)
        );
        grid-template-columns: repeat(
            var(--mc-timeline-grid-columns),
            minmax(max-content, 1fr)
        );
        grid-auto-rows: 1fr 0 1fr;
        align-items: stretch;
        overflow: auto;
        grid-gap: 1px;
        gap: 1px;
    }
    .grid-item {
        background-color: #fff;
    }
    .home-team-cell-empty,
    .away-team-cell-empty {
        /* min-width: 3rem; */
    }
    .home-team-cell {
        display: flex;
        /* flex-direction: column; */
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0.25rem;
        padding: 0.75rem 1rem;
    }
    .time-cell {
        display: flex;
        justify-content: flex-start;
        margin-left: 1rem;
        z-index: 1;
    }
    .away-team-cell {
        display: flex;
        /* flex-direction: column; */
        justify-content: flex-end;
        align-items: flex-start;
        gap: 0.25rem;
        padding: 0.75rem 1rem;
    }</style>
