import { CardsDataSourceBase } from "../../common/CardsDataSourceBase";
import { injectable } from "inversify";
import { getAllToken } from "inversify-token";
import _ from "lodash";
import { JSONSchema4 } from "json-schema";
import { DIYSourceResponse } from "./DIYSourceResponse";
import { DIYSourceServer } from "./DIYSourceServer";
import { DIYSourceDomainToken } from "./domains/Domain";
import ROOT from "inversify.config";
import { extractDomainFromPath } from "./utilities";

@injectable()
export class DIYSource extends CardsDataSourceBase<DIYSourceResponse> {
  public constructor() {
    super({ server: new DIYSourceServer() })
  }

  public getDisplayName(): string {
    return 'DIY Data Source';
  }

  // easy way
  public async getDataSourcePaths(specData?: { [key: string]: any }): Promise<{ name: string; path: string; }[]> {
    const domains = getAllToken(ROOT, DIYSourceDomainToken);
    const paths: { name: string; path: string; }[] = [];
    for (const domain of (domains || [])) {
      const domainName = domain.getDomainName();
      const displayName = domain.getDisplayName();
      paths.push(...Object.values(await domain.getPaths(specData)).map((v) => ({
        name: `${displayName} - ${v.name}`,
        path: `/${domainName}/${v.path}`,
      })).sort((a, b) => a.name.localeCompare(b.name)));
    }
    return paths;
  }

  public async getDataSourceConfigSpec(path?: string, specData?: { [key: string]: any }): Promise<JSONSchema4> {
    const { domain, rest } = extractDomainFromPath(path);
    const domainSpec = await domain.getConfigSchema(rest, specData);
    return {
      type: "object",
      properties: {
        "singleObject": {
          type: "boolean",
          title: "Single Object",
          description: "If the data source is an array of items, pass the entire array to the first card using this data source, rather than spreading the items in the array among all cards using this data source (the normal behavior).",
        },
        "alwaysArray": {
          type: "string",
          title: "Always Array",
          description: "Always return the results of the data source as an array, even if the query targets a specific content slug.",
        },
        "objectQuery": {
          type: "string",
          title: "Extract Data",
          description: "Provide a JSON Path string to extract a portion of this object from the Contentful API response.",
        },
        ...domainSpec?.properties
      },
      required: [
        ...(_.isArray(domainSpec?.required) && domainSpec?.required)
      ]
    };
  }

  public getResponseSchema(path?: string, specData?: Record<string, any>): Promise<JSONSchema4> {
    const { domain, rest } = extractDomainFromPath(path);
    return domain.getResponseSchema(rest, specData);
  }

  protected getExtraParams(path: string, specData?: { [key: string]: any; }): { [key: string]: string; } {
    const { domain, rest } = extractDomainFromPath(path);
    return domain.getExtraParams(rest, specData);
  }

  protected processResponse(path: string, response: DIYSourceResponse, specData?: { [key: string]: any; }): object | any[] {
    const { domain, rest } = extractDomainFromPath(path);
    // TODO: postprocess the response to respect singleObject, alwaysArray, and objectQuery.
    return domain.processResponse(rest, response, specData);
  }
}
