import _ from 'lodash';
import { BaseAtomSpec } from '../atoms';
import { CardsLibraryToken } from "./CardsLibraryToken";
import { getAllToken } from 'inversify-token';
import ROOT from '../../../inversify.config';

const cardLibraryRecords = getAllToken(ROOT, CardsLibraryToken);
export const cardLibraryDisplayNamePairs: [string, string][] = [];
export const cardLibraryClassNamePairs: [string, string][] = [];
for (let rec of cardLibraryRecords) {
  const clazz = rec.clazz;
  const spec = rec["specification"] as BaseAtomSpec<unknown>;
  if (spec) {
    cardLibraryDisplayNamePairs.push([clazz, spec.getDisplayName()]);
  } else if (_.isFunction(rec.getDisplayName)) {
    cardLibraryDisplayNamePairs.push([clazz, rec.getDisplayName()]);
  } else {
    cardLibraryClassNamePairs.push([clazz, clazz]);
  }
}
cardLibraryDisplayNamePairs.sort((a, b) => a[1].localeCompare(b[1]));
cardLibraryClassNamePairs.sort((a, b) => a[1].localeCompare(b[1]));
