<Boundary>
  <CardsComponentWrapper {messageHandler} style="
    {buildCssPadding(padding)}
    {buildCssMargin(margin)}
    width: {width};
    flex-grow: {flexGrow};
    flex-shrink: {flexShrink};
    {(hidden)? 'display: none;' : ''}
    {(zoom)? `zoom: ${zoom};` : ''}
    {(justifyContent && justifyContent !== 'start')? `display: flex; flex-direction: column; justify-content: ${justifyContent};` : ''}
    {(createStackingContext)? 'isolation: isolate;' : ''}
  " {editorOptions}>
    {#if !hidden}
    <CardsArrays parentSequenceId={sequenceId} arrays={column.content} updater={(arrays) => column.content = arrays} {reverse} />
    {/if}
  </CardsComponentWrapper>
</Boundary>

<style>
  *, *::before, *::after {
    box-sizing: border-box;
  }</style>

<script lang="ts">var _a, _b, _c, _d;
import "yinzcam-cards";
import { buildCssMargin, buildCssPadding, toBooleanSafeExpand } from "../utilities/index";
import CardsArrays from "./CardsArrays.svelte";
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import _ from "lodash";
import CardsComponentWrapper from './CardsComponentWrapper.svelte';
import "./CardsEditorInterfaces";
import { CARDS_COLUMN_SCHEMA } from "./CardsEditorComponentSchemata";
import { v4 as uuid4 } from "uuid";
import { windowMetrics as wm } from '../../../js/stores';
import "./actions";
import { CardsTabContextKey } from "./context";
import { getContext } from "svelte";
export let parentSequenceId = null;
export let sequenceId = "";
export let column;
export let updater;
let actionOverrides = {};
function messageHandler(action) {
    switch (action.effect) {
        case 'hide':
            actionOverrides.hidden = true;
            break;
        case 'show':
            actionOverrides.hidden = false;
            break;
        default:
            console.log("CardsColumn - Ignoring message, no suitable message handler found.");
            break;
    }
}
const tabContext = getContext(CardsTabContextKey);
$: mergedParams = (_a = tabContext === null || tabContext === void 0 ? void 0 : tabContext.mergedParamsComp) === null || _a === void 0 ? void 0 : _a.store;
$: reverse = toBooleanSafeExpand(column === null || column === void 0 ? void 0 : column.reverse, $mergedParams);
let hidden, zoom, hasWidth, width, flexGrow, flexShrink, padding, margin, justifyContent, createStackingContext;
$: if (column && ($wm === null || $wm === void 0 ? void 0 : $wm.width)) {
    let clone = _.cloneDeep(column);
    // Apply responsive properties
    //console.log('CHECKING RESPONSIVE CONFIGURATION', currentLayout?.responsiveness);
    const responsiveConfiguration = (_d = (_c = (_b = clone.responsiveness) === null || _b === void 0 ? void 0 : _b.filter((i) => i.maxWidth >= 0)) === null || _c === void 0 ? void 0 : _c.sort((a, b) => a.maxWidth - b.maxWidth)) === null || _d === void 0 ? void 0 : _d.find((i) => i.maxWidth >= $wm.width);
    if (responsiveConfiguration) {
        //console.log('COLUMN FOUND RESPONSIVE CONFIGURATION', responsiveConfiguration);
        for (const k in responsiveConfiguration) {
            if (k === 'maxWidth') {
                continue;
            }
            const val = responsiveConfiguration[k];
            if (!_.isNil(val)) {
                clone[k] = val;
            }
        }
    }
    // Apply action overrides
    clone = _.merge(clone, actionOverrides);
    hidden = !_.isNil(clone.hidden) && clone.hidden;
    zoom = clone.zoom;
    hasWidth = _.isNumber(clone.width) && clone.width > 0 && clone.width <= 100;
    width = (hasWidth) ? `${clone.width}%` : '100%';
    flexGrow = (hasWidth) ? ((clone === null || clone === void 0 ? void 0 : clone.grow) ? '1' : '0') : '1';
    flexShrink = (hasWidth) ? ((clone === null || clone === void 0 ? void 0 : clone.shrink) ? '1' : '0') : '1';
    padding = clone.padding;
    margin = clone.margin;
    justifyContent = clone.justifyContent;
    createStackingContext = clone.createStackingContext;
}
else {
    hidden = true, zoom = undefined, hasWidth = false, width = '100%', flexGrow = '1', flexShrink = '1', justifyContent = null, createStackingContext = false;
}
// child of a column is an array that needs a layout embedded in it
function createChild() {
    return {
        id: uuid4(),
        layouts: [{
                id: uuid4(),
                type: 'GRID',
                elements: []
            }]
    };
}
let editorOptions;
$: editorOptions = {
    parentSequenceId,
    sequenceId,
    componentId: column.id,
    component: column,
    componentTypeName: 'Column',
    childTypeNames: ['Array'],
    primaryColor: '#2390DE',
    reverseTextColor: 'black',
    unselectedBackgroundColor: '#e6e6e6',
    unselectedTextColor: '#000000',
    selectedBackgroundColor: 'rgb(0, 122, 255)',
    selectedTextColor: '#FFFFFF',
    childrenKey: 'content',
    configSpec: CARDS_COLUMN_SCHEMA,
    allowChildren: true,
    allowDeletion: true,
    allowDuplication: true,
    allowMovement: true,
    allowCopyPaste: true,
    updater,
    createChild
};
</script>
