<script lang="ts">import { onMount } from 'svelte';
import "components/cards/common/CardBase.svelte";
export let backgroundColor = '#ffffff';
export let textColor = '#000000';
export let buttonColor = '#0056b3';
export let buttonTextColor = '#ffffff';
export let buttonHoverColor = '#003a70';
export let linkTextColor = '#0056b3';
export let titleText = 'We use cookies';
export let detailText = 'By clicking ‘accept all’, you agree to the storing of cookies on your device to enhance site navigation, analyse site usage, and assist in our marketing efforts.';
export let findOutMoreText = 'Find out more';
export let findOutMoreLink = '/more-info';
export let settingsButtonText = 'Cookie Settings';
export let settingsButtonLink = '/cookie-settings';
export let rejectText = 'Reject All';
export let acceptText = 'Accept All';
let cookieConsent;
onMount(() => {
    cookieConsent = localStorage.getItem('cookieConsent');
});
const acceptCookies = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    cookieConsent = 'accepted';
};
const rejectCookies = () => {
    localStorage.setItem('cookieConsent', 'rejected');
    cookieConsent = 'rejected';
};
function openCookieSettings() {
}
</script>

<style>
  .cookie-consent {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1000;
  }

  .cookie-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    z-index: 1000; /* Just below the consent popup */
  }

  .cookie-popup {
    background: white;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    position: relative;
    z-index: 1001; /* High z-index to ensure it's above other content */
  }

  @media (min-width: 768px) {
    .cookie-popup {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .buttons {
    display: flex;
    gap: 0.5rem;
  }

  button {
    padding: 0.5rem 1rem;
    margin: 0.5rem;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }

  button:hover {
    background-color: #0056b3;
  }

  a {
    color: #007bff;
    -webkit-text-decoration: none;
    text-decoration: none;
  }

  a:hover {
    -webkit-text-decoration: underline;
    text-decoration: underline;
  }</style>

{#if cookieConsent === null}
<div class="cookie-consent">
    <div class="cookie-overlay"></div>
    <div class="cookie-popup">
      <p>{titleText}</p>
      <p>{detailText}<a href={findOutMoreLink}>{findOutMoreText}</a></p>
      <div class="buttons">
        <a href={settingsButtonLink}>{settingsButtonText}</a>
        <button on:click={rejectCookies}>{rejectText}</button>
        <button on:click={acceptCookies}>{acceptText}</button>
      </div>
    </div>
  </div>
{/if}
