<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import { get } from "lodash";
import { BaseAtom } from "components/cards/atoms";
import { TextParticle, ImageParticle, ButtonParticle, } from "components/cards/particles/YinzCam";
import { writable } from "svelte/store";
import { resolveUrl } from "js/url";
const hovering = writable(false);
export let container;
export let overlayPadding = undefined;
export let headerText;
export let bodyText;
export let inlineText1;
export let inlineText2;
export let inlineText3;
export let thumbnail;
export let ctaButton;
export let link;
export let headerBarBackgroundColor;
export let reverseOrientation;
export let textBackgroundColor;
export let scaleToThumbnailAspectRatio;
let atomWidthRem;
let thumbnailAspectRatio;
$: headerTextMultiplier =
    atomWidthRem <= 30 ? "0.416" : atomWidthRem <= 45 ? "0.666" : "1.00";
$: bodyTextMultiplier = atomWidthRem <= 30 ? "0.60" : "1.00";
</script>

<BaseAtom
    _isotope="CTAHorizontal2Atom"
    {...container}
    _link={resolveUrl(link).href}
    _cssVariablePrefix="cta-horizontal-2"
    backgroundColor={headerBarBackgroundColor}
    {overlayPadding}
    {textBackgroundColor}
    {thumbnailAspectRatio}
    bind:_clientWidthRem={atomWidthRem}
>
    <div
        class="main-container"
        class:layout-mobile-width={atomWidthRem <= 30}
        class:layout-tablet-width={atomWidthRem <= 45 && atomWidthRem > 30}
        class:reverse-orientation={reverseOrientation === "text"}
        class:scale-to-thumbnail-aspect-ratio={scaleToThumbnailAspectRatio}
    >
        <div class="image-container">
            <ImageParticle
                _block={true}
                _hovering={$hovering}
                bind:_sourceAspectRatio={thumbnailAspectRatio}
                {...thumbnail}
            />
        </div>
        <div
            class="text-container"
            class:layout-mobile-width={atomWidthRem < 30}
            class:layout-tablet-width={atomWidthRem < 45}
        >
            <div class="heading-text">
                <TextParticle
                    {...headerText}
                    size={`calc(${get(headerText, "size")} * ${headerTextMultiplier})`}
                />
            </div>
            <div class="body-text">
                <TextParticle
                    {...bodyText}
                    size={`calc(${get(bodyText, "size")} * ${bodyTextMultiplier})`}
                />
            </div>

            <div
                class="inline-text-container"
                class:layout-mobile-width={atomWidthRem < 30}
                class:layout-tablet-width={atomWidthRem < 45}
            >
                <div class="inline-text-1">
                    <TextParticle
                        {...inlineText1}
                        size={`calc(${get(inlineText1, "size")} * ${bodyTextMultiplier})`}
                    />
                </div>

                <div class="inline-text-2">
                    <TextParticle
                        {...inlineText2}
                        size={`calc(${get(inlineText2, "size")} * ${bodyTextMultiplier})`}
                    />
                </div>

                <div class="inline-text-3">
                    <TextParticle
                        {...inlineText3}
                        size={`calc(${get(inlineText3, "size")} * ${bodyTextMultiplier})`}
                    />
                </div>

            </div>
            
        </div>

        <div class="cta-container">
            
                {#if ctaButton && (ctaButton.text?.text || ctaButton.image?.source || ctaButton.image?.icon)}
                <ButtonParticle {...ctaButton} />
                {/if}
            
        </div>
    </div>
</BaseAtom>

<style>
    .main-container {
        display: flex;
        flex-direction: row;
        background-color: #fff;
        height: 100%;
        width: 100%;
    }
    .main-container.layout-mobile-width,
    .main-container.layout-tablet-width {
        flex-direction: column;
        width: 100%;
    }
    .main-container.layout-mobile-width .text-container, .main-container.layout-tablet-width .text-container {
            width: 100%;
        }
    .main-container.layout-mobile-width .image-container, .main-container.layout-tablet-width .image-container {
            width: 100%;
        }
    .main-container.reverse-orientation {
        flex-direction: row-reverse;
        width: 100%;
    }
    .main-container.layout-mobile-width.reverse-orientation,
    .main-container.layout-tablet-width.reverse-orientation {
        flex-direction: column-reverse;
        width: 100%;
    }
    .main-container.layout-mobile-width.reverse-orientation .text-container, .main-container.layout-tablet-width.reverse-orientation .text-container {
            width: 100%;
        }
    .main-container.layout-mobile-width.reverse-orientation .image-container, .main-container.layout-tablet-width.reverse-orientation .image-container {
            width: 100%;
        }
    .main-container.scale-to-thumbnail-aspect-ratio .image-container {
        aspect-ratio: var(--cta-horizontal-2-thumbnail-aspect-ratio);
    }
    .main-container.scale-to-thumbnail-aspect-ratio {
        height: auto;
    }
    .text-container {
        width: 50%;
        padding: var(--cta-horizontal-2-overlay-padding, unset);
        display: flex;
        flex-direction: column;
        gap: 1.125rem;
        background-color: var(--cta-horizontal-2-text-background-color);
    }
    .inline-text-container{
        display: flex;
        /* width: 40%; */
        flex-direction: row;
        justify-content: flex-start;
        margin-top: auto;
        align-items: self-start;
    }
    .inline-text-container * {
        padding: .25rem;
        display: flex;
        text-align:center;
    }
    .inline-text-container > *:first-child{
        padding: .25rem .25rem .25rem 0;
        display: flex;
        text-align:center;
    }
    .inline-text-container > *:nth-child(2),
    .inline-text-container > *:nth-child(3) {
        padding: .25rem .25rem .25rem .25rem;
        display: flex;
        text-align:center;
    }
    .cta-container{
        display: flex;
        /* width: 25%; */
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: .25rem;
    }
    .image-container {
        width: 25%;
        padding: 2.5rem;
    }</style>
