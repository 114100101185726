import { AbstractReactiveMicrocomponent, InputComponents } from './AbstractReactiveMicrocomponent';
import _ from 'lodash';

// NOTE: All times and durations are in milliseconds. Times are Unix timestamps.

const DEFAULT_TTL: number = 300*1000;
const DEFAULT_BACKOFF_MIN_DELAY = 500;
const DEFAULT_BACKOFF_MAX_DELAY = 60*1000;
const DEFAULT_BACKOFF_MULTIPLIER = 1.5;

export type TemporalCachedControl = {
  forcedUpdateTime: number;
};

export type TemporalCachedState<O> = {
  lastUpdateTime: number;
  ttl: number;
  backoffDelay: number;
  forceUpdate: boolean;
  forceOutput: boolean;
  previousOutput: O;
};

export type TemporalCachedConfig = {
  defaultCacheTimeSeconds?: number;
  backoff?: {
    minDelay?: number;
    maxDelay?: number;
    multiplier?: number;
  };
};

export abstract class AbstractTemporalCachedReactiveMicrocomponent<O, I extends unknown[] = unknown[]> extends AbstractReactiveMicrocomponent<O, I, TemporalCachedControl, TemporalCachedState<O>> {
  private readonly tcConfig: TemporalCachedConfig;
  private timerHandle: number;
 
  public constructor(name: string, tcConfig: TemporalCachedConfig, ...inputs: InputComponents<I>) {
    super(
      {
        name,
        expectThrowFromUpdate: true,
        saveToLocalStorage: true,
        initialControlValue: { 
          forcedUpdateTime: 0
        },
        initialStateValue: { 
            lastUpdateTime: 0,
            ttl: ((tcConfig.defaultCacheTimeSeconds || 0)*1000) || DEFAULT_TTL,
            backoffDelay: 0,
            forceOutput: true, // initially always force output
            forceUpdate: true, // force update on initial update
            previousOutput: null
        },
      },
      ...inputs
    );

    this.tcConfig = tcConfig;
    this.timerHandle = null;

    this.startTimer(Date.now());
  }

  private getNextUpdateTime(): number {
    return (this.state.lastUpdateTime || 0) + 
      (this.state.ttl || (this.tcConfig.defaultCacheTimeSeconds || 300)*1000) + 
      (this.state.backoffDelay || 0);
  }

  private startTimer(now: number) {
    this.stopTimer();
    if (this.state.lastUpdateTime > 0) {
      // minimum TTL is one second to prevent infinite loops
      if (this.state.ttl < 1000) {
        this.state.ttl = 1000;
      }
      const timeToNextUpdate = this.getNextUpdateTime() - now;
      if (timeToNextUpdate <= 0) {
        this.forceUpdate(now);
      } else {
        this.timerHandle = setTimeout(() => {
          this.forceUpdate(Date.now());
        }, timeToNextUpdate);
      }
    }
  }

  private stopTimer() {
    if (this.timerHandle) {
      clearTimeout(this.timerHandle);
      this.timerHandle = null;
    }
  }

  private forceUpdate(now: number) {
    this.stopTimer();
    this.state.forceUpdate = true;
    this.setControl({ forcedUpdateTime: now })
  }

  protected override inputChanged(): void {
    this.stopTimer();
  }

  public override refreshSupported(): boolean {
    return true;
  }

  public override refresh(): void {
    this.state.forceOutput = true;
    this.forceUpdate(Date.now());
  }

  // This should read the input values and update TTLs if necessary.
  // Returns true if the inputs are ready for an update, false otherwise.
  protected async preUpdate(now: number, previousOutput: O, ...$values: I): Promise<boolean> {
    return true;
  }

  // This should read the input values and compute the output value.
  protected abstract execUpdate(now: number, previousOutput: O, ...$values: I): Promise<O>;

  // Returns true if the value changed from the previous output, false otherwise.
  protected async postUpdate(now: number, previousOutput: O, output: O, ...$values: I): Promise<boolean> {
    return !_.isEqual(previousOutput, output);
  }

  protected override async update($control: { forcedUpdateTime: number }, ...$values: I): Promise<O> {
    //console.log('temporal cached update', this.state, ...arguments);
    let now = Date.now(); // this is a let because we need to update it every time we await, since a significant amount of time may have passed

    const ready = await this.preUpdate(Date.now(), this.state.previousOutput, ...$values);
    //console.log('ATCRM preUpdate result (ready)', ready);
    now = Date.now();

    // If inputs are not ready, throw an error which will not change the output and wait for the inputs to change (or a forced update).
    // Note that the timer is not running at this point.
    if (!ready) {
      throw new Error('preUpdate indicated inputs are not ready');
    }

    try {
      // This could happen if the TTL got extended during preUpdate. Just throw null which will not change the output and restart the timer.
      if (!this.state.forceUpdate && now < this.getNextUpdateTime()) {
        //console.log('ATCRM skipping update due to no forceUpdate and next update time has not passed');
        throw null;
      }
      // Reset the flag now that we checked it.
      this.state.forceUpdate = false;

      const output = await this.execUpdate(now, this.state.previousOutput, ...$values);
      //console.log('ATCRM execUpdate result (output)', output);
      now = Date.now();

      // Reset the backoff multiplier and set the update time since we got an output successfully.
      this.state.backoffDelay = 0;
      this.state.lastUpdateTime = now;

      const changed = await this.postUpdate(now, this.state.previousOutput, output, ...$values);
      //console.log('ATCRM postUpdate result (changed)', this.state.previousOutput, output, changed);
      try {
        if (!changed && !this.state.forceOutput) {
          throw null;
        }
      } finally {
        // Reset the flag now that we checked it.
        this.state.forceOutput = false;
        this.state.previousOutput = output;
      }

      return output;
    } catch (error) {
      if (error && error instanceof Error) {
        if (!this.state.backoffDelay) {
          this.state.backoffDelay = this.tcConfig.backoff?.minDelay || DEFAULT_BACKOFF_MIN_DELAY;
        } else {
          this.state.backoffDelay *= this.tcConfig.backoff?.multiplier || DEFAULT_BACKOFF_MULTIPLIER;
          const maxDelay = this.tcConfig.backoff?.maxDelay || DEFAULT_BACKOFF_MAX_DELAY;
          if (this.state.backoffDelay > maxDelay) {
            this.state.backoffDelay = maxDelay;
          }
        }  
      }
      throw error;
    } finally {
      // restart the timer
      this.startTimer(now);
    }
  }
}
