<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import { get, merge } from "lodash";
import { DateTime } from "luxon";
import "components/cards/utilities";
import { BaseAtom } from "components/cards/atoms";
import { TextParticle, ImageParticle, ButtonParticle, } from "components/cards/particles/YinzCam";
import { writable } from "svelte/store";
const hovering = writable(false);
const formatDateTime = (dateString) => {
    var _a, _b;
    const dt = DateTime.fromISO(dateString, { zone: "Europe/London" });
    const convertedDateTime = (_a = dt
        .toLocaleString({
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
        timeZoneName: 'short',
    })) === null || _a === void 0 ? void 0 : _a.split(" ");
    return (_b = convertedDateTime === null || convertedDateTime === void 0 ? void 0 : convertedDateTime.filter((string) => string !== "at")) === null || _b === void 0 ? void 0 : _b.map((string) => string.trim().replace(",", ""));
};
export let container;
export let overlayPadding;
export let backgroundColor;
export let backgroundImage;
export let imgLeaguelogo;
export let matchDateTime;
export let txtMatchDate;
export let txtMatchTime;
export let txtStadiumName;
export let txtBroadcasters;
export let btnBroadcasters;
export let txtHomeTeam;
export let txtAwayTeam;
export let imgHomeTeam;
export let imgAwayTeam;
export let btnCTA;
export let matchStatus;
export let homeTeamScore;
export let awayTeamScore;
export let matchMinutes;
export let isChannelImageRequired;
export let isPenalty;
export let txtLive;
export let colorLiveIconBackground;
export let colorLiveIconBorder;
export let txtPenalties;
export let txtAwayTeamAbbr;
export let txtHomeTeamAbbr;
export let colorFirstButton;
export let colorLiveIconCircle;
export let stadiumBroadcastersGap;
let atomWidthPx;
$: isMobile = atomWidthPx <= 600;
$: isTablet = atomWidthPx <= 1000 && atomWidthPx > 600;
$: leagueIconMultiplier = isMobile ? "0.65" : "1.00";
$: dateTextMultiplier = isMobile ? "0.66" : "1.00";
$: timeTextMultiplier = isMobile ? "0.56" : "1.00";
$: timeZoneTextMultiplier = isMobile ? "0.34" : "0.44";
$: stadiumNameTextMultiplier = isMobile ? "0.75" : "1.00";
$: teamTextMultiplier = isMobile ? "1.00" : "1.00";
$: teamIconMultiplier = isMobile ? "1.00" : isTablet ? "1.00" : "1.18";
$: btnTextMultiplier = isMobile ? "0.70" : "1.00";
$: firstButtonProps = (btnCTA === null || btnCTA === void 0 ? void 0 : btnCTA.length) && btnCTA[0];
$: remainingButtons = (btnCTA === null || btnCTA === void 0 ? void 0 : btnCTA.length) && (btnCTA === null || btnCTA === void 0 ? void 0 : btnCTA.slice(1));
$: scoreTextMultiplier = isMobile ? "0.58" : "1.00";
$: matchMinsTextMultiplier = isMobile ? "0.56" : "1.00";
$: themeModeValue = matchStatus === "live" ? "primary" : "primary";
$: txtPenaltiesMultiplier = isMobile ? "0.5" : "1.00";
$: ctaSizeMultiplier = isMobile ? "0.85" : "1.00";
$: broadcasterTextMultiplier = isMobile ? "0.85" : "1.00";
$: broadcasterImageSizeMultiplier = isMobile || isTablet ? "0.66" : "0.85";
$: [day, date, month, year, time, dayPeriod, timeZone] =
    formatDateTime(matchDateTime);
</script>

<BaseAtom
    _isotope="ScheduleFixturesCardGrid"
    {...merge({}, container, { themeMode: themeModeValue })}
    _cssVariablePrefix="fix-horizon"
    {overlayPadding}
    {backgroundColor}
    {backgroundImage}
    {colorLiveIconBackground}
    {colorLiveIconBorder}
    {colorFirstButton}
    {colorLiveIconCircle}
    {stadiumBroadcastersGap}
    bind:_clientWidthPx={atomWidthPx}
>
    <div
        class="main-container"
        class:layout-mobile-width={isMobile}
        class:layout-tablet-width={isTablet}
        class:live-match={matchStatus === "live"}
    >
        <div class="match-container">
            <div class="match-content">
                <div class="league-match-channel-box">
                    <div class="league-logo">
                        <ImageParticle
                            {...merge({}, imgLeaguelogo, {
                                container: {
                                    height: `calc(${get(imgLeaguelogo, "container.height")} * ${leagueIconMultiplier})`,
                                    width: `calc(${get(imgLeaguelogo, "container.width")} * ${leagueIconMultiplier})`,
                                },
                            })}
                            _block={true}
                        />
                    </div>
                    <div
                        class="match-details"
                        class:live-match={matchStatus === "live"}
                    >
                        {#if matchStatus !== "live"}
                            <TextParticle
                                {...merge({}, txtMatchDate, {
                                    size: `calc(${get(txtMatchDate, "size")} * ${dateTextMultiplier})`,
                                    text: `${day} ${date} ${month?.slice(0, 3)}`,
                                })}
                            />
                        {/if}
                        {#if matchStatus === "live"}
                            <div class="live-status-icon">
                                <div class="circle-icon"></div>
                                <TextParticle
                                    {...txtLive}
                                    size={`calc(${get(txtLive, "size")}*${teamTextMultiplier})`}
                                />
                            </div>
                            <div class="match-minutes">
                                <TextParticle
                                    {...matchMinutes}
                                    text={`${matchMinutes.text || ""}'`}
                                    size={`calc(${get(matchMinutes, "size")} * ${matchMinsTextMultiplier})`}
                                />
                            </div>
                        {/if}
                        {#if matchStatus === "pre"}
                            <div class="time-details">
                                <TextParticle
                                    {...txtMatchTime}
                                    text={time}
                                    size={`calc(${get(txtMatchTime, "size")} * ${timeTextMultiplier})`}
                                    weight={(isMobile)? 'bold' : undefined}
                                />
                                <div class="time-zone">
                                    <TextParticle
                                        {...txtMatchTime}
                                        text={dayPeriod}
                                        size={`calc(${get(txtMatchTime, "size")} * ${timeZoneTextMultiplier})`}
                                        weight={(isMobile)? 'bold' : undefined}
                                    />
                                    <TextParticle
                                        {...txtMatchTime}
                                        text={timeZone}
                                        size={`calc(${get(txtMatchTime, "size")} * ${timeZoneTextMultiplier})`}
                                        weight={(isMobile)? 'bold' : undefined}
                                    />
                                </div>
                            </div>
                        {/if}
                        <TextParticle
                            {...merge({}, txtStadiumName, {
                                size: `calc(${get(txtStadiumName, "size")}*${stadiumNameTextMultiplier})`,
                            })}
                        />
                        {#if btnBroadcasters?.length > 0 && btnBroadcasters[0].image?.source && matchStatus !== "post"}
                        <div class="broadcaster-content">
                            <div class="broadcaster-text">
                                <TextParticle
                                    {...txtBroadcasters}
                                    size={`calc(${get(txtBroadcasters, "size")} * ${broadcasterTextMultiplier})`}
                                    _block={true}
                                />
                            </div>
                            <div class="broadcaster-list">
                            {#each btnBroadcasters || [] as button}
                                <ButtonParticle
                                    {...merge({}, button, {
                                        text: {
                                            size: `calc(${get(button, "text.size")} * ${btnTextMultiplier})`,
                                        },
                                        image: {
                                            container: {
                                                width: `calc(${get(button, "image.container.width")} * ${broadcasterImageSizeMultiplier})`,
                                                height: `calc(${get(button, "image.container.height")} * ${broadcasterImageSizeMultiplier})`,
                                            }
                                        }
                                    })}
                                />
                            {/each}
                            </div>
                        </div>
                        {/if}
                    </div>
                </div>
                <div class="team-penalty" class:penalty-mobile={isMobile}>
                    <div
                        class="team-box"
                        class:pre-match={matchStatus === "pre"}
                    >
                        <div class="home-team-box">
                            {#if !isMobile}
                                <div class="home-team-name">
                                    <TextParticle
                                        _block={true}
                                        {...merge({}, txtHomeTeam, {
                                            size: `calc(${get(txtHomeTeam, "size")}*${teamTextMultiplier})`,
                                        })}
                                    />
                                </div>
                            {/if}
                            {#if isMobile}
                                <TextParticle
                                    _block={true}
                                    {...merge({}, txtHomeTeamAbbr, {
                                        size: `calc(${get(txtHomeTeamAbbr, "size")}*${teamTextMultiplier})`,
                                    })}
                                />
                            {/if}
                            <ImageParticle
                                _block={true}
                                {...merge({}, imgHomeTeam, {
                                    container: {
                                        height: `calc(${get(imgHomeTeam, "container.height")}*${teamIconMultiplier})`,
                                        width: `calc(${get(imgHomeTeam, "container.width")}*${teamIconMultiplier})`,
                                    },
                                })}
                            />
                        </div>

                        <div class="score-details">
                            {#if matchStatus !== "pre"}
                                <TextParticle
                                    {...merge({}, homeTeamScore, {
                                        size: `calc(${get(homeTeamScore, "size")}*${scoreTextMultiplier})`,
                                    })}
                                />
                                <span
                                    class={matchStatus === "live" &&
                                        "live-match-color"}>-</span
                                >
                                <TextParticle
                                    {...merge({}, awayTeamScore, {
                                        size: `calc(${get(awayTeamScore, "size")}*${scoreTextMultiplier})`,
                                    })}
                                />
                            {/if}
                        </div>
                        <div class="away-team-box">
                            <ImageParticle
                                _block={true}
                                {...merge({}, imgAwayTeam, {
                                    container: {
                                        height: `calc(${get(imgAwayTeam, "container.height")}*${teamIconMultiplier})`,
                                        width: `calc(${get(imgAwayTeam, "container.width")}*${teamIconMultiplier})`,
                                    },
                                })}
                            />
                            {#if !isMobile}
                                <div class="away-team-name">
                                    <TextParticle
                                        _block={true}
                                        {...merge({}, txtAwayTeam, {
                                            size: `calc(${get(txtAwayTeam, "size")}*${teamTextMultiplier})`,
                                        })}
                                    />
                                </div>
                            {/if}
                            {#if isMobile}
                                <TextParticle
                                    _block={true}
                                    {...merge({}, txtAwayTeamAbbr, {
                                        size: `calc(${get(txtAwayTeamAbbr, "size")}*${teamTextMultiplier})`,
                                    })}
                                />
                            {/if}
                        </div>
                    </div>
                    {#if isPenalty && matchStatus === "post"}
                        <div class="penalty-box">
                            <TextParticle
                                {...txtPenalties}
                                size={`calc(${get(txtPenalties, "size")}*${txtPenaltiesMultiplier})`}
                            />
                        </div>
                    {/if}
                </div>
                <div class="button-box">
                    <div class="button-inner">
                        <ButtonParticle
                            {...merge({}, firstButtonProps, {
                                container: {
                                    background: {
                                        visible: true,
                                        color: `var(--fix-horizon-color-first-button, var(--theme-mode-highlight-color, unset))`,
                                    },
                                },
                                image: {
                                    container: {
                                        width: `calc(${get(firstButtonProps, "image.container.width")}*${ctaSizeMultiplier})`,
                                        height: `calc(${get(firstButtonProps, "image.container.height")}*${ctaSizeMultiplier})`,
                                    },
                                },
                                text: {
                                    size: `calc(${get(firstButtonProps, "text.size")}*${btnTextMultiplier})`,
                                },
                                showText: !isMobile,
                            })}
                        />

                        {#each remainingButtons || [] as button}
                            <ButtonParticle
                                {...merge({}, button, {
                                    image: {
                                        container: {
                                            width: `calc(${get(button, "image.container.width")}*${ctaSizeMultiplier})`,
                                            height: `calc(${get(button, "image.container.height")}*${ctaSizeMultiplier})`,
                                        },
                                    },
                                    text: {
                                        size: `calc(${get(button, "text.size")}*${btnTextMultiplier})`,
                                    },
                                    showText: !isMobile,
                                })}
                            />
                        {/each}
                    </div>
                </div>
            </div>
        </div>
    </div>
</BaseAtom>

<style>
    .main-container {
        display: flex;
        flex-direction: row;
        gap: 1.5rem;
        height: 100%;
        width: 100%;
        padding: 1.5rem 0;
    }

    .main-container.live-match > .match-container {
        border-left: 0.625rem solid var(--theme-mode-accent-color);
    }

    .main-container.live-match.layout-mobile-width > .match-container,
    .main-container.live-match.layout-tablet-width > .match-container {
        border-left: 0.25rem solid var(--theme-mode-accent-color);
    }

    .main-container.layout-tablet-width .match-content .league-match-channel-box .league-logo {
                    width: 7rem;
                    height: 7rem;
                    text-align: center;
                }

    .main-container.layout-mobile-width .match-content .league-match-channel-box .league-logo {
                    width: 5rem;
                    height: 5rem;
                    text-align: center;
                }

    .main-container.layout-mobile-width,
    .main-container.layout-tablet-width {
        flex-direction: column;
        width: 100%;
        padding: 0.75rem 0;
    }

    .main-container.layout-mobile-width .match-container, .main-container.layout-tablet-width .match-container {
            border-left: 0.25rem solid var(--theme-mode-accent-color);
            border-right: 0.25rem solid transparent;
            width: 100%;
            height: 100%;
            padding: 0 0.75rem;
        }

    .main-container.layout-mobile-width .match-content, .main-container.layout-tablet-width .match-content {
            width: 100%;
            display: grid;
            grid-template-rows: repeat(auto, mimax(0.25fr, 1fr));
            grid-template-columns: unset;
            align-items: center;
            justify-items: center;
            place-items: center;
        }

    .main-container.layout-mobile-width .button-box, .main-container.layout-tablet-width .button-box {
            flex-direction: row;
            justify-content: center;
            align-self: center;
            justify-self: center;
            place-self: center;
        }

    .main-container.layout-mobile-width .match-details, .main-container.layout-tablet-width .match-details {
            flex-direction: row;
            align-items: center;
            flex-grow: 1;
            justify-content: flex-start;
            gap: 1rem;
            margin-left: 0;
        }

    .main-container.layout-mobile-width .league-match-channel-box, .main-container.layout-tablet-width .league-match-channel-box {
            width: 100%;
            align-items: center;
            gap: 0.5rem;
        }

    .main-container.layout-mobile-width .button-inner, .main-container.layout-tablet-width .button-inner {
            display: flex;
            flex-direction: row;
            gap: 0.5rem;
        }

    .main-container.layout-mobile-width .match-content {
            grid-template-rows: repeat(auto, mimax(0.25fr, 1fr));
            grid-template-columns: unset;
        }

    .main-container.layout-mobile-width .match-details {
            margin-left: 0;
            padding-left: 1rem;
            gap: 0;
            justify-content: space-between;
        }

    .main-container.layout-mobile-width .live-match {
            align-items: center;
            justify-content: flex-start;
            gap: 1rem;
        }

    .main-container.layout-mobile-width .score-details span {
                font-size: 1.75rem;
                font-weight: 700;
            }

    .main-container.layout-mobile-width .penalty-box {
            font-size: 0.75rem;
        }

    .match-container {
        border-left: 0.625rem solid var(--theme-mode-accent-color);
        border-right: 0.625rem solid transparent;
        padding: 0 1.5rem;
        width: 100%;
        height: 100%;
    }

    .match-content {
        display: grid;
        grid-template-columns: 2fr 4fr 1.5fr;
        grid-template-rows: unset;
        grid-column-gap: 1rem;
        -webkit-column-gap: 1rem;
           -moz-column-gap: 1rem;
                column-gap: 1rem;
        align-items: center;
        justify-items: center;
        place-items: center;
        grid-row-gap: 1.0rem;
        row-gap: 1.0rem;
    }

    .league-match-channel-box {
        display: flex;
        align-items: center;
        flex: 0 0 35%;
        align-self: start;
        justify-self: start;
        place-self: start;
    }

    .match-details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        border-left: 0.125rem solid gray;
        margin-left: 1rem;
        padding-left: 1rem;
        height: 100%;
        gap: 0.125rem;
    }

    .time-details {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    .time-zone {
        display: flex;
        flex-direction: column;
        gap: 0;
        line-height: 1rem;
    }

    .channel-images {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0.5rem;
    }

    .team-box {
        width: 100%;
        display: grid;
        grid-template-columns: 2fr 8rem 2fr;
    }

    .team-box.pre-match {
        grid-template-columns: 2fr 0.25fr 2fr;
    }

    .home-team-box > *,
    .away-team-box > * {
        flex: 1 0 max-content;
    }

    .home-team-name,
    .away-team-name {
        flex-basis: min-content;
    }
    .home-team-name {
        text-align: right;
    }

    .button-box {
        display: flex;
        flex-direction: column;
        align-self: center;
        justify-self: center;
        place-self: center;
    }

    .score-details {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .score-details span {
            font-size: 3rem;
            font-weight: 700;
        }

    .live-status-icon {
        display: flex;
        align-items: center;
        padding: 0.5rem 0.75rem;
        border-radius: 3.125rem;
        border: 2px solid var(--fix-horizon-color-live-icon-border, #b8b9b5);
        background: var(--fix-horizon-color-live-icon-background, #fff);
        gap: 0.5rem;
    }

    .live-status-icon > .circle-icon {
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background: var(
            --fix-horizon-color-live-icon-circle,
            var(--theme-mode-background-color, unset)
        );
    }

    .penalty-box {
        text-align: center;
    }

    .team-penalty {
        display: flex;
        flex-direction: column;
    }

    .live-match-color {
        color: var(--theme-mode-foreground-color, #000);
    }

    .button-inner {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .away-team-box,
    .home-team-box {
        display: flex;
        align-items: center;
        overflow: auto;
    }
    .home-team-box {
        align-self: end;
        justify-self: end;
        place-self: end;
    }
    .away-team-box {
        align-self: start;
        justify-self: start;
        place-self: start;
    }
    .away-team-box.wrap-class {
        flex-direction: column;
        align-items: flex-start;
    }
    .home-team-box.wrap-class {
        flex-direction: column-reverse;
        align-items: flex-end;
    }
    .penalty-mobile {
        gap: 0.5rem;
    }

    .broadcaster-content {
        padding-top: var(--fix-horizon-stadium-broadcasters-gap, unset);
        flex-basis: 100%;

        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: center;
        justify-content: start;
    }

    .main-container.layout-mobile-width .broadcaster-content {
        display: flex;
        flex-flow: row wrap;
        gap: 1rem;
        align-items: center;
    }

    .main-container.layout-mobile-width .broadcaster-content {
        display: flex;
        flex-flow: row wrap;
        gap: 1rem;
        align-items: center;
    }</style>
