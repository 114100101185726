import { AbstractReactiveMicrocomponent, InputComponents, ReactiveMicrocomponentConfig } from 'yinzcam-rma';
import { YinzCamAPIRequestParameterComponent } from './YinzCamAPIRequestParameterComponent';
import { YinzCamAPIRequestParameters } from './YinzCamAPIRequestParameters';

export class MergedYinzCamAPIRequestParameterComponent
  extends AbstractReactiveMicrocomponent<YinzCamAPIRequestParameters, YinzCamAPIRequestParameters[]>
  implements YinzCamAPIRequestParameterComponent<YinzCamAPIRequestParameters[]> {

  public constructor(config: ReactiveMicrocomponentConfig<YinzCamAPIRequestParameters, YinzCamAPIRequestParameters[]>, ...inputs: InputComponents<YinzCamAPIRequestParameters[]>) {
    super(config, ...inputs);
  }

  protected async update($control: unknown, ...$values: YinzCamAPIRequestParameters[]): Promise<YinzCamAPIRequestParameters> {
    // merge headers and parameters, warn about conflicts
    // TODO: add a merge mode where conflicting headers are appended together?
    let outputValue: YinzCamAPIRequestParameters = { headers: {}, params: {}, services: {} };
    $values.forEach((value, i) => {
      if (!value) {
        return;
      }
      //console.log(`MERGE PARAMS ${i}`, value);
      this.mergeParameters(outputValue.headers, value.headers);
      this.mergeParameters(outputValue.params, value.params);
      if (value.services) {
        for (const service in value.services) {
          let inputServiceValue = value.services[service];
          let outputServiceValue = outputValue.services[service] || { headers: {}, params: {} };
          this.mergeParameters(outputServiceValue.headers, inputServiceValue?.headers);
          this.mergeParameters(outputServiceValue.params, inputServiceValue?.params);
          outputValue.services[service] = outputServiceValue;
        }
      }
    });
    return outputValue;
  }

  private mergeParameters(output: { [key: string]: string }, input?: { [key: string]: string }): void {
    if (!input) {
      return;
    }
    Object.keys(input).forEach((key) => {
      if (key in output) {
        this.log.warn(`Request parameter ${key} set by multiple components! When multiple components set a parameter, the last one wins.`);
      }
      output[key] = input[key];
    });
  }
}
