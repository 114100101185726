import { injectable } from 'inversify';
import { injectToken, Token } from 'inversify-token';
import { PublicAPI, PublicAPIToken, PublicServer } from 'public-api';
import { YinzCamAPIRequest } from 'yinzcam-api';
import { INSTAGRAM_HOST, RAPID_API_KEY, TWITTER_HOST } from './constants';

export const InstagramServerToken = new Token<InstagramServer>(Symbol.for("InstagramServer"));

@injectable()
export class InstagramServer {
  private server: PublicServer;
  constructor(@injectToken(PublicAPIToken) private readonly api: PublicAPI) {
    console.log('socialserver: ', this.server);
    this.server = this.api.getServer('all', 'https://api.countrystatecity.in'); // url
  }

  public getRequest(request: YinzCamAPIRequest) {
    console.log('instagramrequest: ', request);

    const config = this._getConfig(request.path);
    console.log ('instagramconfig: ', config);
    this.server = this.api.getServer(config.path, config.url);
    let socialRequest:YinzCamAPIRequest = {...request, path: config.path, headers: config.headers, params: config.params};
    let wrappedRequest = this.server.getRequest(socialRequest);
    
    console.log('socialrequestwrapped: ', wrappedRequest);
    return wrappedRequest;
  }

  private _getConfig(request_path:string) {
    let url;
    let path;
    let headers;
    let params;

    let paths = request_path.split('/');
    let type = paths[1];
    let username =  paths[2];

    switch (type.toLowerCase()) {
      case 'all':
        url = "https://api.countrystatecity.in";
        path = '/v1/countries';
        headers = {"X-CSCAPI-KEY": "QmhnbE9NR3Z1TGJZdzVEVVNUaGtSOTRzV0NKWXQ0UHpiMTQwVzdoTA=="};
        break;
      case 'instagram':
        url = 'https://' + INSTAGRAM_HOST ;
        path = '/user_posts';
        headers =  {
          'x-rapidapi-key': RAPID_API_KEY,
          'x-rapidapi-host': INSTAGRAM_HOST,
          useQueryString: "true",
        };
        params = {username};
      case 'twitter':
        url = 'https://' + TWITTER_HOST ;
        path = '/twitter/user/tweets';
        headers =  {
          'x-rapidapi-key': RAPID_API_KEY,
          'x-rapidapi-host': TWITTER_HOST,
          useQueryString: "true",
        };
        params = {username};
        break;
    }
    return {url, path, headers, params};
  }
}
