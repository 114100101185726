import { Container, interfaces } from "inversify";
import { getToken, TokenContainerModule } from "inversify-token";
import { YinzCamAPI, YinzCamAPIToken } from "yinzcam-api";
import { YinzCamAppServer, YinzCamAppServerToken } from "./YinzCamAppServer";
import { APP_SERVER_REGISTRY } from "./constants";
import { YinzCamInjectModule } from "yinzcam-inject";

export let YinzCamAppServerModule: YinzCamInjectModule = new YinzCamInjectModule((container: Container): void => {
  container.load(new TokenContainerModule((bindToken) => {
    bindToken<YinzCamAppServer>(YinzCamAppServerToken).toDynamicValue((context: interfaces.Context) => {
      const api = getToken(context.container, YinzCamAPIToken);
      return APP_SERVER_REGISTRY.create(api.league, api);
    });
  }));
});
