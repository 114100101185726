import { CardsDataSourceBase } from "../../common/CardsDataSourceBase";
import { injectable } from "inversify";
import { injectToken } from "inversify-token";
import { JSONSchema4 } from "json-schema";
import { YinzCamAPIResponse } from "yinzcam-api";
import { YinzCamAppServerToken } from "yinzcam-appserver";

@injectable()
export class YinzCamAppServerGameScoresFilter extends CardsDataSourceBase {
  public constructor(@injectToken(YinzCamAppServerToken) server) {
    super({ server })
  }

  public async getDataSourceConfigSpec(path?: string, specData?: { [key: string]: any }): Promise<JSONSchema4> {
    return {
      type: "object",
      properties: {
        "param": {
          type: "string",
          title: "Parameter",
          description: "Select the parameter of the filter.",
          "enum": [
            "seasonStartYear",
            "compId",
          ],
          "options": {
            "enum_titles": [
              "Season",
              "Competition",
            ]
          }
        },
      },
    };
  }

  protected processResponse(path: string, response: YinzCamAPIResponse, specData?: { [key: string]: any }): object | any[] {
    let data = response?.data as any;
    let filter = [].concat(data?.Scores?.Filters?.Filter).find(e => e?._attributes?.QueryParameter === specData?.param);
    let options = [].concat(filter?.FilterItems?.Items).filter(item => item?._attributes?.Id).map(item => {
      return Object.fromEntries([].concat(Object.entries(item._attributes), Object.entries(item).filter(([k, _]) => k != '_attributes').map(([k, v]) => [k, (<any>v)._text])));
    });
    return { param: filter._attributes.QueryParameter, options };
  }
}
