import { generateBooleanSchema, generateRootObjectSchema, generateStringSchema, generateUrlSchema, generateDateTimeSchema, generateArraySchema, generateObjectSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { TextParticleSpec, ImageParticleSpec, ButtonParticleSpec, RichTextParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("Cookie Consent Popup", generateRootObjectSchema({
    container: generateAtomContainerSchema({
        defaultValues: {
            themeMode: 'primary'
        }
    }),
    overlayPadding: generateStringSchema("Overlay Padding", "The padding (inset) of the overlay text/buttons relative to the background image.", {
        defaultValue: '2.5rem'
    }),
    txtHeading: TextParticleSpec.generateSchema("Cookies Heading", "Cookies Heading", {
        defaultValues: { text: "Cookies", size: "2rem", weight: "900", useAccentColor: true }
    }),
    txtConsent: RichTextParticleSpec.generateSchema("Consent Text", "Consent Text", {
        defaultValues: { 
            html: "By clicking \"Accept All Cookies\" you agree to this website storing its own cookies and third party cookies on your device to improve the performance of the web site, for analytical, navigational, and advertising purposes related to your preferences. For more information, please review the Settings.",
            textDefaults: { size: "1rem", weight: "400" }
        },
    }),
    txtAcceptanceText: RichTextParticleSpec.generateSchema("Acceptance text", "Acceptance text", {
        defaultValues: { html: "Do you accept cookies?", textDefaults: { size: "1rem", weight: "400" }, },
    }),
    btnAcceptAll: ButtonParticleSpec.generateSchema("Accept All", "Accept All Cookies", {
        defaultValues: {
            text: { text: "Accept All Cookies", size: "1rem", weight: "600", color: "var(--theme-mode-background-color)" },
            container: {
                padding: "0.875rem 1.5rem",
                border: { visible: true, radius: "0.75rem" },
                background: { visible: true, color: "var(--theme-mode-accent-color)" }
            }
        }
    }), 
    btnAcceptEssential: ButtonParticleSpec.generateSchema("Accept Essential", "Accept Essential Cookies Only", {
        defaultValues: {
            text: { text: "Accept Essential Cookies Only", size: "1rem", weight: "600", color: "var(--theme-mode-background-color)" },
            container: {
                padding: "0.875rem 1.5rem",
                border: { visible: true, radius: "0.75rem" },
                background: { visible: true, color: "var(--theme-mode-accent-color)" }
            }
        }
    }),
    btnReject: ButtonParticleSpec.generateSchema("Reject", "Reject All Cookies", {
        defaultValues: {
            text: { text: "Reject Cookies", size: "1rem", weight: "600", color: "var(--theme-mode-accent-color)" },
            container: {
                padding: "0.875rem 1.5rem",
                border: { visible: true, radius: "0.75rem", style: "solid", width: "1px", color: "var(--theme-mode-accent-color)" },
            }
        }
    }),
    btnCookieSettings: ButtonParticleSpec.generateSchema("Cookie Settings", "Cookie Settings", {
        defaultValues: {
            text: { text: "Settings", size: "1rem", weight: "600", useAccentColor: true },
            container: {
                padding: "0.875rem 1.5rem",
            }
        }
    }),
    btnAcceptSelected: ButtonParticleSpec.generateSchema("Accept Selected", "Accept Selected Cookies", {
        defaultValues: {
            text: { text: "Accept Selected Cookies", size: "1rem", weight: "600", color: "var(--theme-mode-background-color)" },
            container: {
                padding: "0.875rem 1.5rem",
                border: { visible: true, radius: "0.75rem" },
                background: { visible: true, color: "var(--theme-mode-accent-color)" }
            }
        }
    }),
    settingsOptions: generateArraySchema("Settings Options", "List of cookie settings options.", 
        generateObjectSchema("Settings Option", "A cookie settings option.", {
            consentKey: generateStringSchema("Consent Type", "The type of consent being granted.", {
                choices: [ 'mandatory', 'analytical', 'marketing', 'other' ],
                choiceTitles: [ 'Mandatory', 'Analytical', 'Marketing', 'Other' ]
            }),
            txtHeading: TextParticleSpec.generateSchema("Cookies Heading", "Cookies Heading", {
                defaultValues: { size: "2rem", weight: "900", color: "var(--theme-mode-accent-color)" }
            }),
            txtConsent: RichTextParticleSpec.generateSchema("Consent Text", "Consent Text", {
                defaultValues: { textDefaults: { size: "1rem", weight: "400" } }
            }),
            showToggle: generateBooleanSchema("Show Toggle", "Allow the user to toggle this consent on or off."),
        }),
    ),
    btnCloseButton: ButtonParticleSpec.generateSchema("Close Button", "Close Button", {
        defaultValues: { container: { width: "2.875rem", height: "2.875rem" } }
    }),
}));

