<script lang="ts">var _a, _b, _c;
import 'yinzcam-cards';
import CardBase from '../../../common/CardBase.svelte';
import { getFirstSourceStore, findSourceStore } from '../../../utilities';
import '../../../common/CardsDataSourceRegistration';
import 'svelte/store';
export let element;
export let sources;
/* NOTE: disabled this card */
let source;
let available = true;
let text = "";
let style = "";
$: source = getFirstSourceStore($sources);
$: sourceGame = findSourceStore($sources, 'YinzCamAppServerGameBox');
$: available = !($source === null || $source === void 0 ? void 0 : $source.dataReady) && ((_a = $sourceGame === null || $sourceGame === void 0 ? void 0 : $sourceGame.State) === null || _a === void 0 ? void 0 : _a._text) === "P";
$: text = (_b = element === null || element === void 0 ? void 0 : element.data) === null || _b === void 0 ? void 0 : _b.text;
$: style = (_c = element === null || element === void 0 ? void 0 : element.data) === null || _c === void 0 ? void 0 : _c.style;
</script>

<CardBase {element}>
  <div class="simple-conditional-card" style={style || ""} class:display-none={true || !available}>
    {text}
  </div>
</CardBase>

<style lang="scss">*,
*:before,
*:after {
  box-sizing: border-box; }

.simple-conditional-card {
  height: calc(100vh - 200px); }</style>
