<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import { get, merge } from "lodash";
import { BaseAtom } from "components/cards/atoms";
import { TextParticle, ImageParticle, } from "components/cards/particles/YinzCam";
import ButtonParticle from "components/cards/particles/YinzCam/Core/Button/ButtonParticle.svelte";
export let container;
export let overlayPadding;
export let imgCardBackground;
export let txtMemberPoints;
export let txtMemberRank;
export let txtTotalRank;
export let txtInformation;
export let txtPointGeneral;
export let txtRankGeneral;
export let txtInfoGeneral;
export let txtPointsYear;
export let btnCTA;
export let colorBackgroundFill;
export let colorPointsCard;
export let colorRankCard;
export let colorCtaButton;
export let colorLinearGradient;
let atomWidthPx;
$: isMobile = atomWidthPx < 626;
$: isTablet = atomWidthPx <= 1010 && atomWidthPx >= 626;
$: txtMultiplier = isMobile ? "0.50" : isTablet ? "0.75" : "1.00";
$: backgroundPadding = isMobile
    ? "0.5rem"
    : isTablet
        ? "1.25rem"
        : "1.5rem";
$: console.log({ atomWidthPx });
</script>

<BaseAtom
    _isotope="LoyaltyPointsAtom"
    _cssVariablePrefix="loyalty-points"
    {...container}
    {overlayPadding}
    {colorBackgroundFill}
    {colorPointsCard}
    {colorRankCard}
    {colorCtaButton}
    {colorLinearGradient}
    {backgroundPadding}
    bind:_clientWidthPx={atomWidthPx}
>
    <div
        class="main-container"
        class:layout-tablet-width={isTablet}
        class:layout-mobile-width={isMobile}
    >
        <div class="background-container">
            <ImageParticle {...imgCardBackground} _block={true} />
        </div>
        <div class="overlay-container">
            <div class="text-container">
                <div class="points-rank">
                    <div class="points">
                        <TextParticle
                            {...txtMemberPoints}
                            size={`calc(${get(txtMemberPoints, "size")}*${txtMultiplier})`}
                        />
                        <TextParticle
                            {...txtPointGeneral}
                            text={`POINTS ${txtPointsYear}`}
                        />
                    </div>
                    <div class="rank">
                        <TextParticle
                            {...txtMemberRank}
                            text={`#${txtMemberRank?.text}`}
                            size={`calc(${get(txtMemberRank, "size")}*${txtMultiplier})`}
                        />
                        <TextParticle
                            {...txtTotalRank}
                            text={`of ${txtTotalRank?.text} members RANK`}
                            wrap={isMobile ? "balance" : "nowrap"}
                        />
                    </div>
                </div>
                <div class="information-text">
                    <TextParticle
                        {...txtInformation}
                        size={`calc(${get(txtInformation, "size")}*${txtMultiplier})`}
                    />
                </div>
                <div class="general-info-cta">
                    <div class="general-text">
                        <TextParticle
                            {...txtInfoGeneral}
                            text={`See your ${txtPointsYear} scores & points`}
                        />
                    </div>
                    <div class="cta-button">
                        <ButtonParticle
                            {...btnCTA}
                            {...merge({}, btnCTA, {
                                container: {
                                    width: `calc(${get(btnCTA, "container.width")}*${txtMultiplier})`,
                                    height: `calc(${get(btnCTA, "container.height")}*${txtMultiplier})`,
                                },
                            })}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</BaseAtom>

<style>
    .main-container {
        width: 100%;
        height: 100%;
        background-color: var(--loyalty-points-color-background-fill, #011858);
        display: grid;
        padding: var(--loyalty-points-background-padding);
        border-radius: 2rem;
        overflow: hidden;
    }
    .main-container > * {
        grid-area: 1/1;
        border-radius: 2rem;
        overflow: hidden;
    }
    .background-container {
        background: linear-gradient(
            61deg,
            #061754 30.75%,
            rgba(7, 26, 98, 0.71) 38%,
            rgba(11, 35, 138, 0.18) 52.5%,
            rgba(15, 44, 178, 0) 64.58%
        );
        z-index: 1;
    }
    .overlay-container {
        border-radius: 2rem;
        padding: var(--loyalty-points-overlay-padding, unset);
        z-index: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .text-container {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        flex-basis: min-content;
    }
    .points-rank {
        display: flex;
        align-items: center;
        flex-basis: max-content;
    }
    .points {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1.25rem 0.75rem;
        border-radius: 0.75rem;
        background-color: var(--loyalty-points-color-points-card, #d8a438);
        text-align: center;
    }
    .rank {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1.5rem 1rem;
        border-radius: 0.75rem;
        background-color: var(--loyalty-points-color-rank-card, #164194);
        height: 98%;
        flex-basis: max-content;
        text-align: center;
    }
    .general-info-cta {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0.5rem;
    }</style>
