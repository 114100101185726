<script lang="ts">var _a, _b, _c, _d;
import playgroundImage from '../image/transparent.png';
export let data = {
    HomeTeam: {},
    AwayTeam: {}
};
$: {
    data.HomeTeam.Logo = `https://resources-uk.yinzcam.com/soccer/shared/logos/${(_b = (_a = data === null || data === void 0 ? void 0 : data.HomeTeam) === null || _a === void 0 ? void 0 : _a.LogoId) === null || _b === void 0 ? void 0 : _b.toLowerCase()}_light.png`;
    data.AwayTeam.Logo = `https://resources-uk.yinzcam.com/soccer/shared/logos/${(_d = (_c = data === null || data === void 0 ? void 0 : data.AwayTeam) === null || _c === void 0 ? void 0 : _c.LogoId) === null || _d === void 0 ? void 0 : _d.toLowerCase()}_light.png`;
}
</script>

<div class="comparison-card">
  <h3>{data.Name}</h3>
  <div class="card-body-el">
    <div class="team">
      <img src={data.HomeTeam.Logo} alt="Logo" class="logo" />
      <span class="percent">{data.Home}</span>
    </div>
    <div class="playground">
      <img src={playgroundImage} alt="Playground" />
      <div class="colored" style={`
        ${data.HomeValue ? `width: ${data.ClientIsHome ? data.HomeValue : data.AwayValue}%;` : ''}
        ${data.ClientIsHome ? `left: 0;` : 'right: 0'}
      `} />
    </div>
    <div class="team">
      <img src={data.AwayTeam.Logo} alt="Logo" class="logo" />
      <span class="percent">{data.Away}</span>
    </div>
  </div>
</div>

<style lang="scss">*,
*:before,
*:after {
  box-sizing: border-box; }

.comparison-card {
  width: 100%;
  height: 100%;
  background: var(--theme-primary-background-color, white);
  display: flex;
  flex-direction: column; }

h3 {
  margin: 0 30px;
  padding: 23px 0 18px 0;
  border-bottom: 2px solid #d8d8d8;
  font-size: 18px;
  font-weight: 500;
  color: var(--theme-primary-foreground-color, black);
  text-transform: uppercase; }

.card-body-el {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; }
  .card-body-el .team {
    width: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .card-body-el .team .logo {
      height: 52px;
      display: block; }
    .card-body-el .team .percent {
      margin: 12px 0 0 0;
      line-height: 1;
      font-size: 18px;
      font-weight: 600;
      color: var(--theme-primary-foreground-color, black); }
  .card-body-el .playground {
    background: #c5c5c5;
    width: 200px;
    height: 120px;
    position: relative; }
    .card-body-el .playground .colored {
      position: absolute;
      top: 0;
      height: 100%;
      z-index: 1;
      background: var(--theme-inverted-background-color, black); }
    .card-body-el .playground img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2; }

@media (max-width: 767px) {
  h3 {
    margin: 0 19px;
    padding: 14px 0 10px 0;
    border-bottom: 2px solid #d8d8d8;
    font-size: 11.1px;
    line-height: 13px; }
  .card-body-el .team {
    width: 35px; }
    .card-body-el .team .logo {
      height: 31px; }
    .card-body-el .team .percent {
      margin: 7px 0 0 0;
      font-size: 11.1px; }
  .card-body-el .playground {
    width: 120px;
    height: 75px;
    margin: 0 10px; } }</style>
