<script context="module" lang="ts">var _a, _b;
import 'json-schema';
import 'components/cards/common/CardsElementInterfaces';
export function getDisplayName() {
    return "Basic: Image Border Divider";
}
export function getElementConfigSpec() {
    return {
        type: "object",
        properties: {
            imageUrl: {
                type: "string",
                format: "url",
                title: "Thumbnail",
                description: "The thumbnail image for the card.",
                options: {
                    upload: {}
                },
                links: [{ href: "{{self}}" }]
            },
            linkUrl: {
                type: "string",
                format: "url",
                title: "Link",
                description: "The URL to open when the image is clicked."
            },
            imageAltText: {
                type: "string",
                title: "Image Alt Text",
                description: "The alt text for the image."
            },
            imageWidth: {
                type: "string",
                title: "Image Width",
                description: "The image fixed width in CSS units."
            },
            imageMaxWidth: {
                type: "string",
                title: "Image Max Width",
                description: "The image max width in CSS units."
            },
            imageMinWidth: {
                type: "string",
                title: "Image Min Width",
                description: "The image min width in CSS units."
            },
            imageHeight: {
                type: "string",
                title: "Image Height",
                description: "The image fixed height in CSS units."
            },
            imageMaxHeight: {
                type: "string",
                title: "Image Max Height",
                description: "The image max height in CSS units."
            },
            imageMinHeight: {
                type: "string",
                title: "Image Min Height",
                description: "The image min height in CSS units."
            },
            imageObjectFit: {
                type: "string",
                title: "Image Object Fit",
                description: "The image object fit strategy.",
                enum: [
                    "contain",
                    "cover",
                    "fill",
                    "none",
                    "scale-down"
                ],
                options: {
                    enum_titles: [
                        "Contain",
                        "Cover",
                        "Fill",
                        "None",
                        "Scale Down"
                    ]
                }
            },
            backgroundColor: {
                type: "string",
                title: "Background Color",
                description: "The background color behind the image."
            },
            hoverEffect: {
                type: "string",
                title: "Hover Effect",
                description: "The hover effect type.",
                enum: [
                    "none",
                    "zoom",
                    "overlay",
                    "background"
                ],
                options: {
                    enum_titles: [
                        "None",
                        "Zoom",
                        "Overlay",
                        "Background"
                    ]
                }
            },
            hoverAnimationDuration: {
                type: "string",
                title: "Hover Duration",
                description: "The hover animation duration in CSS units."
            },
            hoverBackgroundColor: {
                type: "string",
                title: "Hover Background Color",
                description: "The hover background color of the button."
            },
            hoverOverlayColor: {
                type: "string",
                title: "Hover Overlay Color",
                description: "The hover overlay color of the button."
            },
            hoverZoomFactor: {
                type: "string",
                title: "Hover Zoom Factor",
                description: "The hover zoom factor (1.0 is no zoom)."
            },
        }
    };
}
export function getElementDataSourceSpec() {
    return {
        sources: [
            {
                type: 'SOURCE',
                title: 'Card Data',
                description: 'Arbitrary source of data for this card matching the configuration fields.',
                classes: '*'
            }
        ]
    };
}
</script>

<script lang="ts">var _a, _b;
import "svelte/store";
import "js/i18n";
import CardBase from "components/cards/common/CardBase.svelte";
import "yinzcam-cards";
import "components/cards/common/CardsDataSourceRegistration";
import { getFirstSourceStore, getTemplatedElementDataFromSource, buildInlineStyle } from "components/cards/utilities";
import 'js/url';
export let element;
export let sources;
export let sourceStore;
$: {
    sourceStore = getFirstSourceStore($sources);
}
;
$: padding = getTemplatedElementDataFromSource(element, "padding", $sourceStore);
$: backgroundColor = getTemplatedElementDataFromSource(element, "backgroundColor", $sourceStore);
$: borderColor = getTemplatedElementDataFromSource(element, "borderColor", $sourceStore);
$: borderWidth = getTemplatedElementDataFromSource(element, "borderWidth", $sourceStore);
$: borderStyle = getTemplatedElementDataFromSource(element, "borderStyle", $sourceStore);
$: imageUrl = (_a = getTemplatedElementDataFromSource(element, "imageUrl", $sourceStore)) === null || _a === void 0 ? void 0 : _a.toString();
$: imageAltText = (_b = getTemplatedElementDataFromSource(element, "imageAltText", $sourceStore)) === null || _b === void 0 ? void 0 : _b.toString();
$: imageWidth = getTemplatedElementDataFromSource(element, "imageWidth", $sourceStore);
$: imageMaxWidth = getTemplatedElementDataFromSource(element, "imageMaxWidth", $sourceStore);
$: imageMinWidth = getTemplatedElementDataFromSource(element, "imageMinWidth", $sourceStore);
$: imageHeight = getTemplatedElementDataFromSource(element, "imageHeight", $sourceStore);
$: imageMaxHeight = getTemplatedElementDataFromSource(element, "imageMaxHeight", $sourceStore);
$: imageMinHeight = getTemplatedElementDataFromSource(element, "imageMinHeight", $sourceStore);
$: imageObjectFit = getTemplatedElementDataFromSource(element, "imageObjectFit", $sourceStore);
$: imageTopOffset = getTemplatedElementDataFromSource(element, "imageTopOffset", $sourceStore);
$: style = buildInlineStyle("padding", padding, "background-color", backgroundColor, "border-color", borderColor, "border-width", borderWidth, "border-style", borderStyle, "image-width", imageWidth, "image-max-width", imageMaxWidth, "image-min-width", imageMinWidth, "image-height", imageHeight, "image-max-height", imageMaxHeight, "image-min-height", imageMinHeight, "image-object-fit", imageObjectFit, "image-top-offset", imageTopOffset);
</script>

<CardBase {element}>
  <div class="image-border-divider" {style}>
    <div class="image-area">
      <img class="image" src={imageUrl} alt={imageAltText}>
    </div>
  </div>
</CardBase>

<style>
  .image-border-divider {
    padding: var(--padding, unset);
    background-color: var(--background-color, unset);
    border-top: var(--border-width, 2px) var(--border-style, solid) var(--border-color, black);

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image-area {
    position: relative;
    top: var(--image-top-offset, unset);
  }
  
  .image {
    width: var(--image-width, unset);
    min-width: var(--image-min-width, unset);
    max-width: var(--image-max-width, unset);
    height: var(--image-height, unset);
    min-height: var(--image-min-height, unset);
    max-height: var(--image-max-height, unset);
    object-fit: var(--image-object-fit, unset);
  }</style>
