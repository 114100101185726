<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import "lodash";
import { BaseAtom } from "components/cards/atoms";
import { TextParticle, ImageParticle, } from "components/cards/particles/YinzCam";
export let container;
export let overlayPadding;
export let teamLogo;
export let txtStartersHeading;
export let txtSubHeading;
export let eventIcon;
export let eventText;
export let playerImage;
export let teamSelection;
export let homePlayerStarterStats = undefined;
export let homePlayerSubStats = undefined;
export let awayPlayerStarterStats = undefined;
export let awayPlayerSubStats = undefined;
export let colorTableRow;
export let homeTeamLogo;
export let awayTeamLogo;
let atomWidthRem;
function handleImageError(event) {
    console.log("Error Called");
    event.target.src =
        "https://resources-au.yinzcam.com/apl/shared/headshots/silhouette.png";
}
const eventIconNameMap = {
    goalTimes: "https://resources-uk.yinzcam.com/soccer/shared/soccer_ball.png",
    yellowCardTimes: "https://resources-uk.yinzcam.com/soccer/shared/yellow_card.png",
    redCardTimes: "https://resources-uk.yinzcam.com/soccer/shared/red_card.png",
    subbedOffTimes: "https://resources-uk.yinzcam.com/soccer/shared/red_arrow.png",
    subbedOnTimes: "https://resources-uk.yinzcam.com/soccer/shared/green_arrow.png",
};
$: isMobile = atomWidthRem <= 58;
$: isTablet = atomWidthRem <= 70 && atomWidthRem > 58;
$: playerImageMultiplier = isMobile ? "1.00" : isTablet ? "0.7" : "1.00";
$: eventIconMultiplier = isMobile ? "0.75" : isTablet ? "0.6" : "0.9";
$: playerStarterStats =
    teamSelection === "home"
        ? homePlayerStarterStats
        : awayPlayerStarterStats;
$: playerSubStats =
    teamSelection === "home" ? homePlayerSubStats : awayPlayerSubStats;
$: logoImgSource = teamSelection === "home" ? homeTeamLogo : awayTeamLogo;
</script>

<BaseAtom
    _isotope="MCLineupTableAtom"
    _cssVariablePrefix="mc-lineup-table"
    {...container}
    {overlayPadding}
    {colorTableRow}
    bind:_clientWidthRem={atomWidthRem}
>
    <div
        class="table-container"
        class:mobile-layout={isMobile}
        class:tablet-layout={isTablet}
    >
        <table class="players-table">
            <tr>
                <td></td>
                <td class="name-heading">
                    <div class="logo-image">
                        <ImageParticle {...teamLogo} source={logoImgSource}/>
                    </div>
                    <TextParticle {...txtStartersHeading} text="Starters" />
                </td>
            </tr>
            {#each playerStarterStats || [] as playerStat}
                <tr>
                    <td class="position"
                        ><TextParticle {...playerStat?.position} /></td
                    >
                    <td>
                        <div class="player-details">
                            <div class="image-name-box">
                                <div class="player-image-container">
                                    <ImageParticle
                                        {...playerImage}
                                        source={playerStat?.playerImageUrl}
                                        _block={true}
                                        {handleImageError}
                                    />
                                </div>
                                <TextParticle {...playerStat?.playerName} />
                            </div>
                            <div class="event-box">
                                {#each Object.entries(playerStat?.events || {}) || [] as event}
                                    <div class="event-pill">
                                        <ImageParticle
                                            {...eventIcon}
                                            source={eventIconNameMap[event[0]]}
                                            _block={true}
                                        />
                                        {#each event[1] || [] as subEvent}
                                            <TextParticle
                                                {...eventText}
                                                text={subEvent}
                                            />
                                        {/each}
                                    </div>
                                {/each}
                            </div>
                        </div>
                    </td>
                </tr>
            {/each}
            <tr>
                <td></td>
                <td class="name-heading">
                    <div></div>
                    <TextParticle {...txtSubHeading} text="Substitutes" />
                </td>
            </tr>
            {#each playerSubStats || [] as playerStat}
                <tr>
                    <td class="position"
                        ><TextParticle {...playerStat?.position} /></td
                    >
                    <td>
                        <div class="player-details">
                            <div class="image-name-box">
                                <div class="player-image-container">
                                    <ImageParticle
                                        {...playerImage}
                                        source={playerStat?.playerImageUrl}
                                        _block={true}
                                        {handleImageError}
                                    />
                                </div>
                                <TextParticle {...playerStat?.playerName} />
                            </div>
                            <div class="event-box">
                                {#each Object.entries(playerStat?.events || {}) || [] as event}
                                    <div class="event-pill">
                                        <ImageParticle
                                            {...eventIcon}
                                            source={eventIconNameMap[event[0]]}
                                            _block={true}
                                        />

                                        {#each event[1] || [] as subEvent}
                                            <TextParticle
                                                {...eventText}
                                                text={subEvent}
                                            />
                                        {/each}
                                    </div>
                                {/each}
                            </div>
                        </div>
                    </td>
                </tr>
            {/each}
        </table>
    </div>
</BaseAtom>

<style>
    table {
        border-collapse: collapse;
    }
    table tr td {
                padding: 0.25rem 0;
            }
    table tr:nth-child(even) {
            background-color: var(--mc-lineup-table-color-table-row, #dedede);
        }
    .table-container {
        width: 100%;
        display: grid;
        padding: var(--mc-lineup-table-overlay-padding, unset);
    }
    .player-details {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
        grid-gap: 0.5rem;
        gap: 0.5rem;
    }
    .name-heading {
        display: grid;
        grid-template-columns: repeat(2, 1fr 5fr);
        grid-row: 1fr;
        align-items: center;
        justify-items: center;
        place-items: center;
    }
    .image-name-box {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        margin-left: 0.5rem;
    }
    .event-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
    }
    .event-pill {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
    }
    .player-image-container {
        border-radius: 50%;
        background-color: var(
            --mc-lineup-table-color-player-background,
            var(--theme-mode-accent-color, gray)
        );
        display: flex;
        justify-content: center;
        align-items: flex-start;
        overflow: hidden;
        padding: 0.25rem;
    }
    .position {
        vertical-align: top;
        padding-left: 0.5rem;
    }
    .logo-image {
        justify-self: flex-start;
    }</style>
