<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import "lodash";
import { BaseAtom } from "components/cards/atoms";
import { RichTextParticle } from "components/cards/particles/YinzCam";
export let container;
export let richText;
</script>

<style></style>

<BaseAtom _isotope="ArticleAtom" {...container}>
  <RichTextParticle {...richText} />
</BaseAtom>
