<Boundary>
  <div bind:this={wrapperEl} class="wrapper" class:force-horizontal-scrollbar={outlineMode && editorOptions.forceHorizontalScrollbar}
    {style} use:cardsEditorContainerAction={{pageContext, ...internalEditorOptions}}>
    <slot></slot>
  </div>
</Boundary>

<style>
  *, *::before, *::after {
    box-sizing: border-box;
  }

  .force-horizontal-scrollbar::-webkit-scrollbar:horizontal {
    height: 12px;
  }

  .force-horizontal-scrollbar::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  .force-horizontal-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, .5);
  }
  
  .force-horizontal-scrollbar::-webkit-scrollbar-track { 
    background-color: #fff; 
    border-radius: 8px;
  }</style>

<script lang="ts">import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import { CardsPageContextKey } from './context';
import { getContext } from "svelte";
import './CardsEditorInterfaces';
import { cardsEditorContainerAction } from './CardsEditorActions';
import 'lodash';
export let style = "";
export let editorOptions;
export let wrapperEl = null;
const pageContext = getContext(CardsPageContextKey);
const emm = pageContext.editorModeManager;
const emmEnabledStore = emm.getEnabledStore();
const emmPreviewStore = emm.getPreviewStore();
let emmEnabled;
let emmPreview;
$: emmEnabled = $emmEnabledStore;
$: emmPreview = $emmPreviewStore;
$: outlineMode = emmEnabled && !emmPreview;
$: internalEditorOptions = Object.assign(Object.assign({}, editorOptions), { updater: internalUpdater });
function internalUpdater(newItems) {
    editorOptions.updater(newItems);
    emm.notifyPageDataUpdate();
}
</script>