<script context="module" lang="ts">var _a, _b;
export { specification } from "./spec";
</script>

<script lang="ts">var _a, _b;
import "lodash";
import "svelte";
import { BaseAtom, getViewContext, } from "components/cards/atoms";
import { SegmentedButtonParticle, } from "components/cards/particles/YinzCam";
import "../../../../common/context";
export let container;
export let overlayPadding;
export let txtHeaderTabs;
export let defaultSelectTabTeam;
let atomWidthRem;
let selectedTab = "";
const viewContext = getViewContext();
function changeSelectedTab(event) {
    var _a;
    selectedTab = (_a = event === null || event === void 0 ? void 0 : event.detail) === null || _a === void 0 ? void 0 : _a.selectedTab;
}
$: selectedTab = defaultSelectTabTeam;
$: viewContext.setParam("selectedTeam", mapTeamTab[selectedTab]);
$: isMobile = atomWidthRem <= 58;
$: isTablet = atomWidthRem <= 70 && atomWidthRem > 58;
$: playerImageMultiplier = isMobile ? "1.00" : isTablet ? "0.7" : "1.00";
$: eventIconMultiplier = isMobile ? "0.75" : isTablet ? "0.6" : "0.9";
$: mapTeamTab = {
    [(_a = txtHeaderTabs === null || txtHeaderTabs === void 0 ? void 0 : txtHeaderTabs.headerTabs) === null || _a === void 0 ? void 0 : _a[0]]: "home",
    [(_b = txtHeaderTabs === null || txtHeaderTabs === void 0 ? void 0 : txtHeaderTabs.headerTabs) === null || _b === void 0 ? void 0 : _b[1]]: "away",
};
</script>

<BaseAtom
    _isotope="PlayerHeaderAtom"
    _cssVariablePrefix="player-header"
    {...container}
    {overlayPadding}
    bind:_clientWidthRem={atomWidthRem}
>
    <div
        class="main-container"
        class:mobile-layout={isMobile}
        class:tablet-layout={isTablet}
    >
        <SegmentedButtonParticle
            {...txtHeaderTabs}
            {selectedTab}
            on:change={changeSelectedTab}
        />
    </div>
</BaseAtom>

<style>
    .main-container {
        width: 100%;
        padding: var(--player-header-overlay-padding);
    }</style>
