import { JSONSchema4 } from "json-schema";
import _ from "lodash";

export function contentfulSchemaToJSONSchema(contentTypeDefinitions: Record<string, import('contentful').ContentType>, rootContentTypeId: string, contentTypesUsed?: Record<string, true>): JSONSchema4 {
  if (_.isNil(contentTypesUsed)) {
    contentTypesUsed = {};
  }

  const mapContentfulTypeToJSONSchema = (contentfulType) => {
    const typeMap = {
      Symbol: "string",
      Text: "string",
      RichText: "string",
      Integer: "integer",
      Number: "number",
      Boolean: "boolean",
      Date: "string",
      Object: "object",
      Link: "object", // Temporarily treat links as objects to embed their schemas.
    };
    return typeMap[contentfulType] || "string";
  };

  const getFormatSpecifier = (field) => {
    if (field.type === "Date") {
      return "date-time";
    }
    if (field.validations) {
      for (const validation of field.validations) {
        if (validation.regexp) {
          const pattern = validation.regexp.pattern;
          if (pattern === "^https?://") {
            return "uri";
          }
          if (pattern === "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$") {
            return "email";
          }
          if (pattern === "^[a-zA-Z0-9.-]+$") {
            return "hostname";
          }
          if (pattern === "^([0-9]{1,3}\\.){3}[0-9]{1,3}$") {
            return "ipv4";
          }
          if (pattern === "^([0-9a-fA-F]{0,4}:){1,7}[0-9a-fA-F]{1,4}$") {
            return "ipv6";
          }
        }
      }
    }
    return null;
  };

  const resolveLinkContentType = (linkContentType) => {
    if (!contentTypeDefinitions[linkContentType]) {
      throw new Error(`Content type ${linkContentType} not found in content types definitions.`);
    }
    if (contentTypesUsed[linkContentType]) {
      console.warn(`Recursive content types not yet fully supported. Returning generic object.`);
      return { type: "object", additionalProperties: true } as JSONSchema4;
    }
    contentTypesUsed[linkContentType] = true;
    return contentfulSchemaToJSONSchema(contentTypeDefinitions, linkContentType, contentTypesUsed);
  };

  const resolveLinkField = (linkField: import('contentful').ContentTypeField | import('contentful').FieldItem) => {
    if (linkField.linkType === 'Entry') {
      const validations = linkField.validations;
      if (validations?.length > 0) {
        if (validations.length > 1) {
          console.log(`contentfulSchemaToJSONSchema: mixed type links are not supported, using first type`);
        }
        return resolveLinkContentType(validations[0].linkContentType);
      } else {
        console.log(`contentfulSchemaToJSONSchema: Entry link type missing validations`);
        return { type: 'object', additionalProperties: true } as JSONSchema4;
      }
    } else {
      console.log(`contentfulSchemaToJSONSchema: unsupported link type ${linkField.linkType}`);
      return { type: 'object', additionalProperties: true } as JSONSchema4;
    }
  };

  const contentType = contentTypeDefinitions[rootContentTypeId];

  const jsonSchema = {
    id: contentType.sys.id,
    title: contentType[contentType.displayField || 'name'],
    description: contentType.description,
    type: "object",
    properties: {},
    required: [],
  } as JSONSchema4;

  contentType.fields.forEach((field) => {
    let fieldSchema = {
      type: mapContentfulTypeToJSONSchema(field.type),
    } as JSONSchema4;

    const format = getFormatSpecifier(field);
    if (format) {
      fieldSchema.format = format;
    }

    if (field.type === "Array") {
      fieldSchema.items = {
        type: mapContentfulTypeToJSONSchema(field.items.type),
      };
      if (field.items.type === "Link") {
        //console.log('RESOLVE LINK (on array)', field);
        fieldSchema.items = resolveLinkField(field.items);
      }
    } else if (field.type === "Link") {
      //console.log('RESOLVE LINK (on object)', field);
      fieldSchema = resolveLinkField(field);
    }

    jsonSchema.properties[field.id] = fieldSchema;

    if (field.required) {
      (<string[]>jsonSchema.required).push(field.id);
    }
  });

  return jsonSchema;
}
