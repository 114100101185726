import { Container } from "inversify";
import { TokenContainerModule } from "inversify-token";
import { YinzCamInjectModule } from "yinzcam-inject";
import { YinzCamDSPServer, YinzCamDSPServerToken } from "./YinzCamDSPServer";
import { YinzCamSignonServer, YinzCamSignonServerToken } from "./YinzCamSignonServer";

export let YinzCamSignonServiceModule: YinzCamInjectModule = new YinzCamInjectModule((container: Container): void => {
  container.load(new TokenContainerModule((bindToken) => {
    bindToken<YinzCamDSPServer>(YinzCamDSPServerToken).to(YinzCamDSPServer).inSingletonScope();
    bindToken<YinzCamSignonServer>(YinzCamSignonServerToken).to(YinzCamSignonServer).inSingletonScope();
  }));
});
