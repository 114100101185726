<script lang="ts">var _a, _b, _c, _d, _e, _f;
import 'yinzcam-cards';
import CardBase from '../../../common/CardBase.svelte';
import { getFirstSourceStore, findSourceStore } from '../../../utilities';
import '../../../common/CardsDataSourceRegistration';
import 'svelte/store';
import 'svelte';
import '../../../common/context';
export let element;
export let sources;
let heading = '';
let fontSize = (_a = element === null || element === void 0 ? void 0 : element.data) === null || _a === void 0 ? void 0 : _a.fontSize;
let gameSource;
let collapseId = "";
let isPre;
let isLive;
let isFinal;
let collapsed = false;
let originalHeight = 0;
$: source = getFirstSourceStore($sources);
$: gameSource = findSourceStore($sources, 'YinzCamAppServerGameBox');
$: {
    isPre = ((_b = $gameSource === null || $gameSource === void 0 ? void 0 : $gameSource.State) === null || _b === void 0 ? void 0 : _b._text) === 'P';
    isLive = ((_c = $gameSource === null || $gameSource === void 0 ? void 0 : $gameSource.State) === null || _c === void 0 ? void 0 : _c._text) === 'C';
    isFinal = ((_d = $gameSource === null || $gameSource === void 0 ? void 0 : $gameSource.State) === null || _d === void 0 ? void 0 : _d._text) === 'F';
}
$: {
    heading = ($source === null || $source === void 0 ? void 0 : $source.heading) || ((_e = element === null || element === void 0 ? void 0 : element.data) === null || _e === void 0 ? void 0 : _e.heading) || "";
    if (heading === "Live Reporting" && isFinal) {
        heading = "";
    }
    if (heading === 'Match Stats' && isPre) {
        heading = "PL Season Stats";
    }
    collapseId = ($source === null || $source === void 0 ? void 0 : $source.collapseId) || ((_f = element === null || element === void 0 ? void 0 : element.data) === null || _f === void 0 ? void 0 : _f.collapseId) || "";
}
function onCollapse() {
    collapsed = !collapsed;
    const e = document.querySelector("#" + collapseId);
    if (e != null) {
        if (!originalHeight) {
            originalHeight = e.offsetHeight;
            e.style.height = originalHeight + "px";
            e.style.transition = "all 0.4s";
            e.style.overflow = "hidden";
        }
        // e.style.display = collapsed ? "none" : "block";
        e.style.height = collapsed ? "0px" : originalHeight + "px";
    }
}
</script>

<CardBase {element}>
  <div class="heading" class:display-none={!heading}>
    <h2 style={fontSize && `font-size: ${fontSize}`}>
      {heading || 'No Header'}
    </h2>

    <div class="collapse" on:click={onCollapse} class:display-none={true || !collapseId}>
      <label>Collapse</label>
      <i class="fa fa-caret-up" aria-hidden="true"></i>
      {#if collapsed}
        <i class="f7-icons">arrowtriangle_down_fill</i>
      {:else}
        <i class="f7-icons">arrowtriangle_up_fill</i>
      {/if}
    </div>
  </div>
</CardBase>

<style lang="scss">*,
*:before,
*:after {
  box-sizing: border-box; }

.heading {
  width: 100%;
  display: flex;
  justify-content: space-between; }
  .heading .collapse {
    opacity: 0.6;
    display: flex;
    align-items: center; }
    .heading .collapse label {
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: left;
      color: var(--theme-primary-foreground-color, black);
      margin: 0 12px 0 0;
      cursor: pointer; }
    .heading .collapse i {
      scale: 1.2 0.5;
      font-size: 14px; }

h2 {
  margin: 0;
  font-size: 30px;
  font-weight: 500;
  position: relative; }
  h2::after {
    content: '';
    display: block;
    background: var(--theme-primary-highlight-color, gray);
    width: 40px;
    height: 3px;
    left: 0;
    bottom: 0; }</style>
