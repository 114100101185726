export function stripContentfulMetadata(obj: any): unknown {
  if (obj && Array.isArray(obj)) {
    obj = obj.map((item) => stripContentfulMetadata(item));
  } else if (obj && typeof obj === 'object' && 'fields' in obj) {
    obj = Object.fromEntries(Object.entries(obj['fields']).map(([ key, val ]) => [ key, stripContentfulMetadata(val) ]));
    /*
    if ('file' in obj) {
      obj = obj.file.url;
    }
    */
  }
  return obj;
}

export function getContentTypeFromDataSourcePath(path: string) {
  // path might be of the form "/{content_type}/...", so we need to extract the parts
  const toks = path?.split('/');
  if (!toks) {
    return null;
  }
  const contentType = (!toks[0])? toks[1] : toks[0];
  return contentType;
}
