import BaseAtom from "./BaseAtom.svelte";
import { CardsPageContext, CardsPageContextKey, CardsTabContext, CardsTabContextKey } from '../common/context';
import { getContext } from 'svelte';
import { CardsPageContentManager } from '../common/CardsPageContentManager'
import { JanusSignonManager } from "js/sso";
import { Readable } from "svelte/store";
import { JanusModeContextManager } from "js/mode";
import { JanusSEOManager } from "js/seo";

export { BaseAtom };
export { BaseAtomSpec, generateAtomContainerSchema } from './BaseAtomSpec';
export type { AtomContainerProps, AtomContainerSchema } from './BaseAtomSpec';

export function getPageContext(): CardsPageContext {
  return getContext(CardsPageContextKey);
}

export function getViewContext(): CardsTabContext {
  return getContext(CardsTabContextKey);
}

/**
 * @deprecated
 */
export function getTabContext(): CardsTabContext {
  return getViewContext();
}

export function getContentManager(): CardsPageContentManager {
  return getPageContext().contentManager;
}

export function getSignonManager(): JanusSignonManager {
  return getPageContext().signonManager;
}

export function getModeManager(): JanusModeContextManager {
  return getPageContext().modeManager;
}

export function getMergedParams(): Readable<{ [k: string]: string }> {
  return getViewContext().mergedParamsComp.store;
}

export function getSEOManager(): JanusSEOManager {
  return getPageContext().seoManager;
}
