<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import { get, merge } from "lodash";
import { DateTime } from "luxon";
import "components/cards/utilities";
import { BaseAtom } from "components/cards/atoms";
import { TextParticle, ImageParticle, ButtonParticle, } from "components/cards/particles/YinzCam";
import { writable } from "svelte/store";
const hovering = writable(false);
const formatDateTime = (dateString) => {
    var _a;
    const dt = DateTime.fromISO(dateString, { zone: "Europe/London" });
    const convertedDateTime = (_a = dt
        .toLocaleString(Object.assign(Object.assign({ weekday: "long", year: "numeric", month: "long", day: "numeric", hour: "2-digit", minute: "2-digit" }, DateTime.DATE_FULL), DateTime.TIME_24_SIMPLE))) === null || _a === void 0 ? void 0 : _a.split(" ");
    return convertedDateTime === null || convertedDateTime === void 0 ? void 0 : convertedDateTime.map((string) => string.trim().replace(",", ""));
};
export let container;
export let overlayPadding;
export let backgroundColor;
export let imgLeaguelogo;
export let matchDateTime;
export let txtMatchDate;
export let txtMatchTime;
export let txtStadiumName;
export let imgBroadcastPartner;
export let txtHomeTeam;
export let txtAwayTeam;
export let imgHomeTeam;
export let imgAwayTeam;
export let btnActions;
export let matchStatus;
export let homeTeamScore;
export let awayTeamScore;
export let matchMinutes;
export let isChannelImageRequired;
export let isPenalty;
export let txtLive;
export let txtLiveSmall;
export let colorHeaderBackground;
export let colorLiveIconBorder;
export let txtStatus;
export let txtAwayTeamAbbr;
export let txtHomeTeamAbbr;
export let colorFirstButton;
export let colorLiveIconCircle;
export let isTicketSoldOut;
export let ticketSaleStartDate;
export let attendanceCount;
export let txtCenterBoxText;
export let lastButtonColor;
export let buyTicketColor;
export let defaultButtonColor;
export let bottomRowColor;
export let isAdditionalContentReqd;
export let lastButtonFontColor;
export let defaultButtonFontColor;
export let shouldDisplayBorder;
export let boxBorderColor;
let atomWidthRem;
$: isMobile = atomWidthRem <= 40;
$: isTablet = atomWidthRem <= 64 && atomWidthRem > 40;
$: leagueIconMultiplier = isMobile ? "0.65" : "1.00";
$: dateTextMultiplier = isMobile ? "0.66" : "1.00";
$: timeTextMultiplier = isMobile ? "0.56" : "1.00";
$: timeZoneTextMultiplier = isMobile ? "0.34" : "0.44";
$: stadiumNameTextMultiplier = isMobile ? "0.75" : "1.00";
$: teamTextMultiplier = isMobile ? "0.66" : "1.00";
$: teamIconMultiplier = isMobile ? "0.73" : isTablet ? "1.18" : "1.00";
$: btnTextMultiplier = isMobile ? "0.70" : "1.00";
$: firstButtonProps = (btnActions === null || btnActions === void 0 ? void 0 : btnActions.length) && btnActions[0];
$: remainingButtons = (btnActions === null || btnActions === void 0 ? void 0 : btnActions.length) && (btnActions === null || btnActions === void 0 ? void 0 : btnActions.slice(1));
$: scoreTextMultiplier = isMobile ? "0.58" : "1.00";
$: matchMinsTextMultiplier = isMobile ? "0.56" : "1.00";
$: themeModeValue = matchStatus === "live" ? "overlay" : "primary";
$: txtPenaltiesMultiplier = isMobile ? "0.5" : "1.00";
$: ctaSizeMultiplier = isMobile ? "0.6" : "1.00";
$: headerText =
    matchStatus === "live"
        ? "LIVE"
        : matchStatus === "post"
            ? "RESULT"
            : "NEXT UP";
$: [day, date, month, year, , time, timezone] =
    formatDateTime(matchDateTime);
</script>

<BaseAtom
    _isotope="FixturesCardBAtom"
    {...merge({}, container, { themeMode: themeModeValue })}
    _cssVariablePrefix="fix-stack"
    {overlayPadding}
    {backgroundColor}
    {colorHeaderBackground}
    {colorLiveIconBorder}
    {colorFirstButton}
    {colorLiveIconCircle}
    {lastButtonColor}
    {buyTicketColor}
    {defaultButtonColor}
    {bottomRowColor}
    {lastButtonFontColor}
    {defaultButtonFontColor}
    {boxBorderColor}
    bind:_clientWidthRem={atomWidthRem}
>
    <div
        class="main-container"
        class:layout-mobile-width={isMobile}
        class:layout-tablet-width={isTablet}
        class:live-match={matchStatus === "live"}
    >
        <div class="match-center-grid" class:live={matchStatus === "live"}>
            <div class="top-row">
                <div class="league-icon">
                    <ImageParticle
                        {...merge({}, imgLeaguelogo, {
                            container: {
                                height: `calc(${get(imgLeaguelogo, "container.height")} * ${leagueIconMultiplier})`,
                                width: `calc(${get(imgLeaguelogo, "container.width")} * ${leagueIconMultiplier})`,
                            },
                        })}
                        _block={true}
                    />
                </div>
                <div class="date-venue">
                    <div class="match-date">
                        <TextParticle
                            {...txtMatchDate}
                            text={isMobile
                                ? `${day?.slice(0, 3)} ${date} ${month?.slice(0, 3)}`
                                : `${day} ${date} ${month}`}
                        />
                    </div>
                    <div class="match-venue">
                        <TextParticle {...txtStadiumName} _block={true} />
                    </div>
                </div>
                <div class="status-specific-content">
                    {#if matchStatus === "pre" && isAdditionalContentReqd}
                        <div class="top-row-pre-content">
                            <div class="text-content">
                                <TextParticle
                                    {...ticketSaleStartDate}
                                    text={`Tickets ${isTicketSoldOut ? "" : "on sale from"}`}
                                    weight="400"
                                />
                                <TextParticle
                                    {...ticketSaleStartDate}
                                    text={`${isTicketSoldOut ? "SOLD" : `${time?.slice(0, 5)} ${day?.slice(0, 3)} ${date} ${month?.slice(0, 3)}`}`}
                                />
                            </div>
                            <div class="icon-content">
                                <ImageParticle
                                    icon="yc_icon_ticket"
                                    container={{
                                        width: "2.5rem",
                                        height: "2.5rem",
                                    }}
                                />
                            </div>
                        </div>
                    {/if}
                    {#if matchStatus === "live"}
                        <div class="top-row-live-content">
                            <div class="icon-content">
                                <ImageParticle
                                    icon="yc_icon_target"
                                    container={{
                                        width: "2.5rem",
                                        height: "2.5rem",
                                    }}
                                />
                            </div>
                            <div class="text-content">
                                <TextParticle {...txtLive} text="LIVE" />
                            </div>
                        </div>
                    {/if}
                    {#if matchStatus === "post" && isAdditionalContentReqd}
                        <div class="top-row-post-content">
                            <div class="text-content">
                                <TextParticle
                                    {...ticketSaleStartDate}
                                    text={"Attendance"}
                                    weight="400"
                                />
                            </div>
                            <div class="icon-content">
                                <TextParticle
                                    {...ticketSaleStartDate}
                                    text={"32845"}
                                />
                            </div>
                        </div>
                    {/if}
                </div>
            </div>
            <div class="bottom-row" class:bordered-box={shouldDisplayBorder}>
                <div class="left-content"></div>
                <div class="mid-content">
                    <div class="home-logo">
                        <ImageParticle {...imgHomeTeam} />
                    </div>
                    <div class="center-content">
                        {#if matchStatus === "pre"}
                            <div class="pre-match-time">
                                <div class="match-time">
                                    <TextParticle
                                        {...txtMatchTime}
                                        text={`${time?.slice(0, 5)}`}
                                    />
                                </div>
                                <div class="timezone">
                                    <TextParticle
                                        {...txtCenterBoxText}
                                        text={timezone}
                                    />
                                </div>
                            </div>
                        {/if}
                        {#if matchStatus === "live"}
                            <div class="live-match-score">
                                <div class="home-score">
                                    <TextParticle {...homeTeamScore} />
                                </div>
                                <div class="match-minutes">
                                    <TextParticle {...matchMinutes} />
                                </div>
                                <div class="away-score">
                                    <TextParticle {...awayTeamScore} />
                                </div>
                            </div>
                        {/if}
                        {#if matchStatus === "post"}
                            <div class="post-match-score">
                                <div class="home-score">
                                    <TextParticle {...homeTeamScore} />
                                </div>
                                <div class="status-text">
                                    <TextParticle {...txtStatus} />
                                </div>
                                <div class="away-score">
                                    <TextParticle {...awayTeamScore} />
                                </div>
                            </div>
                        {/if}
                    </div>
                    <div class="away-logo">
                        <ImageParticle {...imgAwayTeam} />
                    </div>
                </div>
                <div class="right-content"></div>
            </div>
        </div>
        <div class="button-grid">
            {#each btnActions || [] as button, index}
                <div class="cta-button">
                    <ButtonParticle
                        {...button}
                        {...merge({}, button, {
                            container: {
                                background: {
                                    color:
                                        btnActions?.length > 1 &&
                                        index === btnActions?.length - 1
                                            ? "var(--fix-stack-last-button-color, #EAEAEA)"
                                            : button?.text?.text?.toLowerCase() ===
                                                "buy tickets"
                                              ? "var(--fix-stack-buy-ticket-color, #164194)"
                                              : "var(--fix-stack-default-button-color, #011858)",
                                },
                            },
                            image: {
                                source:
                                    btnActions?.length > 1 &&
                                    index === btnActions?.length - 1
                                        ? "https://resources-uk.yinzcam.com/soccer/shared/icon_foxes_hub.png"
                                        : "",
                            },
                            text: {
                                color:
                                    btnActions?.length > 1 &&
                                    index === btnActions?.length - 1
                                        ? "var(--fix-stack-last-button-font-color, #000)"
                                        : "var(--fix-stack-default-button-font-color, #011858)",
                            },
                            showText:
                                btnActions?.length > 1 &&
                                index === btnActions?.length - 1 &&
                                isMobile
                                    ? false
                                    : true,
                        })}
                    />
                </div>
            {/each}
        </div>
    </div>
</BaseAtom>

<style>
    .main-container {
        width: 100%;
        padding: var(--fix-stack-overlay-padding);
        display: grid;
        grid-template-columns: 9fr 3fr;
        grid-gap: 1rem;
        gap: 1rem;
    }
    .bordered-box{
        border: 2px solid var(--fix-stack-box-border-color, #EAEAEA);
        border-top: none;
    }
    .match-center-grid {
        display: flex;
        flex-direction: column;
        align-content: space-between;
        justify-content: space-between;
        place-content: space-between;
    }
    .top-row {
        display: grid;
        grid-template-columns: 2.5fr 7fr 2.5fr;
        align-items: center;
        justify-items: center;
        place-items: center;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        padding: 0 1rem;
        background-color: var(--fix-stack-color-header-background, #eaeaea);
        grid-gap: 0.5rem;
        gap: 0.5rem;
    }
    .league-icon {
        justify-self: flex-start;
    }
    .bottom-row {
        display: grid;
        grid-template-columns: 2.5fr 7fr 2.5fr;
        align-items: center;
        justify-items: center;
        place-items: center;
        padding: 1rem;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        background-color: var(--fix-stack-bottom-row-color, #fff);
    }
    .bottom-row .mid-content {
            display: flex;
        }
    .bottom-row .mid-content .center-content {
                width: 10rem;
                border-radius: 0.75rem;
                background-color: var(--fix-stack-background-color, #cbcbcb);
                padding: 0.75rem 1.25rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }
    .date-venue {
        text-align: center;
    }
    .status-specific-content {
        text-align: right;
        justify-self: flex-end;
    }
    .top-row-pre-content {
        display: flex;
    }
    .top-row-pre-content .text-content {
            display: flex;
            flex-direction: column;
        }
    .top-row-post-content {
        display: flex;
        flex-direction: column;
    }
    .top-row-live-content {
        display: flex;
        align-items: center;
    }
    .pre-match-time {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .live-match-score,
    .post-match-score {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .match-center-grid.live .top-row {
            background-color: #011858;
        }
    .match-center-grid.live .bottom-row {
            background-color: #164194;
        }
    .cta-button {
        /* padding: 1rem; */
    }
    .button-grid {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        justify-content: space-evenly;
    }
    .main-container.layout-mobile-width {
        display: flex;
        flex-direction: column;
    }
    .main-container.layout-mobile-width .button-grid {
            width: 100%;
            flex-direction: row;
            justify-content: center;
        }</style>
