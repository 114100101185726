import { generateRootObjectSchema, generateStringSchema, generateUrlSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { AuthorParticleSpec, CaptionedParticleSpec, ImageParticleSpec, ShareStripParticleSpec, TextParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("Article Lead Content Card", generateRootObjectSchema({
  container: generateAtomContainerSchema({
    defaultValues: { }
  }),
  thumbnail: ImageParticleSpec.generateSchema("Thumbnail", "The image thumbnail for the background of the card.", {
    defaultValues: { sizing: 'contain' },
  }),
  caption: CaptionedParticleSpec.generateSchema("Caption", "The caption for the lead content."),
}));
