<Boundary>
  <div id={`GPT_AD_SLOT_${uuidv4()}`} align="center" bind:this={adSlotDiv}>
  </div>  
</Boundary>

<script lang="ts">import { onMount } from 'svelte';
import loader from '@beyonk/async-script-loader';
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import { v4 as uuidv4 } from 'uuid';
export let adUnitId;
export let size;
let adSlotDiv;
let adSlots = [];
function test() {
    var _a;
    return Array.isArray((_a = window.googletag) === null || _a === void 0 ? void 0 : _a.cmd);
}
function callback() {
    let id = adSlotDiv.getAttribute("id");
    window.googletag = window.googletag || { cmd: [] };
    googletag.cmd.push(function () {
        let slot = googletag.defineSlot(adUnitId, size, id);
        slot.addService(googletag.pubads());
        googletag.pubads().collapseEmptyDivs(true);
        googletag.pubads().enableSingleRequest();
        googletag.enableServices();
        googletag.display(slot);
        //console.log('GoogleAdSlot', 'pushing ad slot', slot);
        adSlots.push(slot);
    });
}
function init() {
    loader([
        {
            type: 'script',
            url: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js'
        }
    ], test, callback);
}
function unload() {
    window.googletag = window.googletag || { cmd: [] };
    googletag.cmd.push(function () {
        //console.log('GoogleAdSlot', 'destroying slots', adSlots);
        let destroyed = googletag.destroySlots(adSlots);
        //console.log('GoogleAdSlot', 'destroyed?', destroyed);
    });
}
onMount(() => {
    init();
    return unload;
});
</script>