import { Readable, derived } from "svelte/store";
import { ContentfulCDN } from "./ContentfulCDN";
import { CardsDynamicConfiguration } from "components/cards/common/CardsDynamicConfiguration";
import { stripContentfulMetadata } from "./ContentfulUtilities";

export class ContentfulCardsDynamicConfiguration implements CardsDynamicConfiguration {
  private readonly objectCache: Record<string, Record<string, object>> = {};

  public constructor(private readonly cdn: ContentfulCDN) {}

  public getObjectStore(type: string, slug: string): Readable<object> {
    // TODO: Does this need language support?
    const req = {
      path: type,
      params: {
        'fields.slug': slug
      }
    };
    //console.log('GOT REQ', req);

    let typeCache = this.objectCache[type];
    if (!typeCache) {
      typeCache = this.objectCache[type] = {};
    }
    return derived([this.cdn.getRequest(req).store], ([rsp]) => {
      //console.log('GOT RSP', rsp);
      let ret;
      if (rsp?.items?.length > 0) {
        ret = { ...(stripContentfulMetadata(rsp.items[0]) as object), _success: true };
      } else if (rsp?.items?.length === 0) {
        ret = { _success: true };
      } else {
        ret = { _success: false };
      }
      typeCache[slug] = ret;
      return ret;
    }, typeCache[slug]);
  }
}
