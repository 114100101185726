import { generateBooleanSchema, generateRootObjectSchema, generateStringSchema, generateUrlSchema } from "components/cards/common/schema";
import { BaseParticleSpec, GetParticlePropsType, GetParticleSchemaType, generateParticleContainerSchema } from "components/cards/particles/BaseParticleSpec";
import { ImageParticleSpec } from "../../Core/Image/spec";
import { TextParticleSpec } from "../../Core/Text/spec";

export const AuthorParticleSpec = new BaseParticleSpec("Author", generateRootObjectSchema({
  container: generateParticleContainerSchema({
    defaultValues: { fillHeight: false, fillWidth: false }
  }),
  link: generateUrlSchema("Link", "The link to open when the author is clicked. If not specified, the author will not be clickable."),
  image: ImageParticleSpec.generateSchema("Author Image", "The image for the author.", {
    defaultValues: { container: { height: '2rem', border: { visible: true, radius: '50%' } } }
  }),
  nameText: TextParticleSpec.generateSchema("Name Text", "The text for the author's name.", {
    defaultValues: { wrap: 'nowrap', weight: '600' }
  }),
  positionText: TextParticleSpec.generateSchema("Position/Title Text", "The text for the author's position/title.", {
    defaultValues: { wrap: 'nowrap', weight: '400', size: '0.875rem' }
  }),
  showImage: generateBooleanSchema("Show Image", "Show the author image.", {
    defaultValue: true,
  }),
  showText: generateBooleanSchema("Show Text", "Show the author name and position text.", {
    defaultValue: true,
  }),
}));

export const specification = AuthorParticleSpec;

export type AuthorParticleSchema = GetParticleSchemaType<typeof AuthorParticleSpec>;

export type AuthorParticleProps = GetParticlePropsType<typeof AuthorParticleSpec>;
