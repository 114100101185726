import { injectable } from 'inversify';
import { injectToken, Token } from 'inversify-token';
import { YinzCamAPI, YinzCamAPIRequest, YinzCamAPIResponse, YinzCamAPIToken, YinzCamServer } from 'yinzcam-api';

export const YinzCamDSPServerToken = new Token<YinzCamDSPServer>(Symbol.for("YinzCamDSPServerToken"));

@injectable()
export class YinzCamDSPServer {
  private readonly server: YinzCamServer;

  public constructor(@injectToken(YinzCamAPIToken) private readonly api: YinzCamAPI) {
    this.server = api.getServer('dsp');
  }

  public getRequest(request: YinzCamAPIRequest) {
    return this.server.getRequest(request);
  }

  public async postRegisterGuest(countryCode: string, appId: string): Promise<YinzCamAPIResponse> {
    return this.server.singleRequest({
      method: 'POST',
      path: 'register/guest',
      params: {
        country: countryCode,
        device_type: '0',
        application: appId,
        rt: 'web',
        platform: 'iOS',
        app_version: '0.0.0',
        os_version: '0.0.0'
      },
      data: {}
    });
  }

  public async postPoliciesAccept(dspGuestId: string, consentsVersion: string, acceptanceDate: Date): Promise<YinzCamAPIResponse> {
    return this.server.singleRequest({
      method: 'POST',
      path: 'policies/accept',
      headers: {
        'X-YinzCam-DeviceId': dspGuestId,
      },
      data: {
        'Version': consentsVersion,
        'AcceptanceDate': acceptanceDate
      }
    });
  }

  public async postConsentsStatus(dspGuestId: string, consentFlags: { [idConsent: string]: boolean }): Promise<YinzCamAPIResponse> {
    return this.server.singleRequest({
      method: 'POST',
      path: 'consents/status',
      headers: {
        'X-YinzCam-DeviceId': dspGuestId,
      },
      data: {
        'Consents': Object.entries(consentFlags).map(e => ({ IdConsent: e[0], Consent: (e[1])? 'true' : 'false' })),
      }
    });
  }
}
