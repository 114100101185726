<script lang="ts">import { BaseParticle } from "components/cards/particles";
import "../../Core/Image";
import "../../Core/Text";
import ImageParticle from "../../Core/Image/ImageParticle.svelte";
import TextParticle from "../../Core/Text/TextParticle.svelte";
// props
export let container = undefined;
export let link = undefined;
export let image = undefined;
export let nameText = undefined;
export let positionText = undefined;
export let showImage = true;
export let showText = true;
let particleWidthRem;
</script>

<style>
  .author-area {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }

  .author-area > .headshot-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
  }

  .author-area > .text-column {
  }</style>

<BaseParticle bind:_clientWidthRem={particleWidthRem} _name="AuthorParticle" _cssVariablePrefix="author-particle" _link={link} {...container}>
  <div class="author-area">
    {#if showImage && image && (image.source || image.icon)}
    <div class="headshot-column">
      <ImageParticle {...image} />
    </div>
    {/if}
    <div class="text-column">
      {#if showText}
      <div class="name-text">
        <TextParticle {...nameText} />
      </div>
      <div class="position-text">
        <TextParticle {...positionText} />
      </div>
      {/if}
    </div>
  </div>
</BaseParticle>
