<YCPage titleText={title} query={pageQuery}>
  {#if url}
    <Boundary>
      <iframe bind:this={iframe} src={url} title={iframeTitle} width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0"></iframe>
    </Boundary>
  {/if}
</YCPage>

<style>
  *, *:before, *:after {
    box-sizing:border-box;
  }
  iframe {
    width: 100%;
    height: 100%;
    border: 0;
    margin: 0;
  }</style>

<script lang="ts">var _a, _b;
import YCPage from '../components/YCPage.svelte';
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import 'svelte-google-analytics/src';
import { onMount } from 'svelte';
export let f7route;
const url = (_a = f7route === null || f7route === void 0 ? void 0 : f7route.query) === null || _a === void 0 ? void 0 : _a.url;
const title = (_b = f7route === null || f7route === void 0 ? void 0 : f7route.query) === null || _b === void 0 ? void 0 : _b.title;
const pageQuery = (f7route === null || f7route === void 0 ? void 0 : f7route.query) || {};
const iframeTitle = title || '';
const origin = (function (url) {
    let origin = null;
    try {
        if (url) {
            origin = new URL(url).origin;
        }
    }
    catch (e) {
        console.log(`WebView: error determining origin for URL ${url}; iframe messaging will be unavailable`, e);
    }
    return origin;
}(url));
let iframe;
function postMessage(msg) {
    if (!iframe || !iframe.contentWindow) {
        console.log(`WebView: postMessage called while iframe not loaded, dropping message`);
        return;
    }
    console.log(`WebView: postMessage to iframe`, JSON.stringify(msg));
    iframe.contentWindow.postMessage(msg, "*");
}
function handleMessage(msg) {
    if (msg.origin !== origin) {
        console.log(`WebView: received message from origin ${msg.origin} but expected message from ${origin}. Ignoring...`);
        return;
    }
    let data = msg.data;
    let type = data.type;
    console.log(`WebView: handling message with type ${type}`, JSON.stringify(data));
    switch (type) {
        case "SET_LOCAL_STORAGE": {
            let key = data.key;
            if (key) {
                window.localStorage.setItem(`WebViewStorage__${origin}__${key}`, data.value);
            }
            break;
        }
        case "GET_LOCAL_STORAGE": {
            let key = data.key;
            if (key) {
                let value = window.localStorage.getItem(`WebViewStorage__${origin}__${key}`);
                postMessage({ type, key, value });
            }
            break;
        }
        default:
            console.log(`WebView: received unrecognized message type ${type}`);
            break;
    }
}
onMount(() => {
    console.log(`WebView: adding window event listener for origin ${origin}`);
    window.addEventListener("message", handleMessage, false);
    return () => {
        console.log(`WebView: removing window event listener for origin ${origin}`);
        window.removeEventListener("message", handleMessage, false);
    };
});
</script>
