import { ReactiveMicrocomponent } from "lib/yinzcam-rma";
import { DIYSourceResponse } from "./DIYSourceResponse";
import { extractDomainFromPath } from "./utilities";

export class DIYSourceServer {
  public getRequest(request: { path: string, params?: { [key: string]: string; } }): ReactiveMicrocomponent<DIYSourceResponse> {
    const { domain, rest } = extractDomainFromPath(request.path);
    return domain.getRequest({ path: rest, params: request.params });
  }
}
