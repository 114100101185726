import { injectable } from 'inversify';
import { injectToken, Token } from 'inversify-token';
import { YinzCamDropsServer, YinzCamDropsServerToken } from './YinzCamDropsServer';

export const YinzCamDropsServiceToken = new Token<YinzCamDropsService>(Symbol.for("YinzCamDropsService"));

@injectable()
export class YinzCamDropsService {
  public constructor(
    @injectToken(YinzCamDropsServerToken) private readonly srv: YinzCamDropsServer) {
  }
}
