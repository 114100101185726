import { generateRootObjectSchema, generateHtmlSchema, generateTranslationSchema } from "components/cards/common/schema";
import { BaseParticleSpec, GetParticlePropsType, GetParticleSchemaType, generateParticleContainerSchema } from "components/cards/particles";
import { TextParticleSpec } from "../Text";

export const RichTextParticleSpec = new BaseParticleSpec("Text", generateRootObjectSchema({
  container: generateParticleContainerSchema(),
  html: generateHtmlSchema("Rich Text (HTML)", "The HTML markup for this text."),
  translations: generateTranslationSchema("Rich Text", {
    html: generateHtmlSchema("Rich Text (HTML)", "The translated HTML markup for this text."),
  }),
  textDefaults: TextParticleSpec.generateSchema("Text Defaults", "The default styling for rich text that is not otherwise styled."),
}));

export const specification = RichTextParticleSpec;

export type RichTextParticleSchema = GetParticleSchemaType<typeof RichTextParticleSpec>;

export type RichTextParticleProps = GetParticlePropsType<typeof RichTextParticleSpec>;
