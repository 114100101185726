import { Container } from "inversify";
import { TokenContainerModule } from "inversify-token";
import { YinzCamInjectModule } from "lib/yinzcam-inject";
import { DIYSourceDomainToken } from "./domains/Domain";
import { ContentfulDomain } from "./domains/contentful.com/ContentfulDomain";
import { ExampleDomain } from "./domains/example.org/ExampleDomain";

export const DIYSourceModule: YinzCamInjectModule = new YinzCamInjectModule((container: Container): void => {
  container.load(new TokenContainerModule((bindToken) => {
    for (const domainClass of [ ExampleDomain, ContentfulDomain ]) {
      bindToken(DIYSourceDomainToken).to(domainClass).whenTargetNamed(domainClass.DomainName);
    }
  }));
});
